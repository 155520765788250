<template>  
    <div>
        <!-- :color="tb.color"  -->
        <!-- :height="tb.height"  -->
        <v-app-bar app    
            :clipped-left="false"
            v-if="tb.show"  
            :fixed="tb.fixed" 
            outlined
            :flat="tb.flat"
            :height="tb.height" 
            class="index3"> 
            <v-container grid-list-xs  >  
                <v-layout fill-height="" justify-center="" align-center="" class="ma-0" :class="vs.smAndDown?'px-':''" >
                    <span v-if="us">
                        <v-app-bar-nav-icon
                            @click="navOpen()"
                            :color="'primary'"
                            v-if="!nav.show && !vs.smAndDown"
                            /> 
                    </span>
                    <router-link :to="'/'" class="not-line0 mr-1" v-if="!us">
                        <div > 
                            <v-img src="/logo/k-pos.svg" width="30" /> 
                        </div> 
                    </router-link>
                    <router-link :to="'/'" class="not-line0 font-weight-bold" v-if="!us"> 
                        <v-toolbar-title>{{DATA.APP.NAME}}</v-toolbar-title> 
                    </router-link>
                    <mbs-company-select  v-else/>
                    <!-- title --> 
                    <router-link :to="'/'" :class="'not-line0 '"> 
                        <!-- <v-toolbar-title :class="tb.textColor+'--text font-weight-bold'">{{mbs.TEXT_UP11(app.name)}}</v-toolbar-title> -->
                    </router-link> 
                    <!-- links -->
                    <v-layout justify-center="" v-if="false">
                        <v-toolbar-items v-if="!vs.smAndDown" :class="tb.textColor+'--text font-weight-bold'"> 
                          <mbs-toolbar-items 
                                :color2="'c5'" 
                                :items="TOOLBAR_LINKS(1)"/>  
                        </v-toolbar-items> 
                    </v-layout> 

                    <v-spacer></v-spacer> 
                    <div v-if="!CurrentTill?.server"> 
                        <div v-if="SHOW_OFFLINE && !online">
                            <v-avatar  
                                size="18"
                                :color="'red'" 
                                :class="MBS.data.animate.zoom.in"
                                class="mx-2 white--text font-weight-bold not-f3"  > 
                            </v-avatar> 
                        </div>
                    </div>
                    <mbs-live-till class="mr-2" /> 
                    <mbs-sign-with />    
                    <!-- side menu -->
                    <v-app-bar-nav-icon
                        @click="navOpen()"
                        :color="'primary'" 
                        v-if="vs.smAndDown"/>  
                </v-layout>  
            </v-container>
        </v-app-bar>   
        <div v-if="!SUBTOOLBAR_VISIBLE">  
            <v-progress-linear  
                v-if="progressToolbar.show && online" 
                class="fixed-top"
                :style="'top:'+tb.height+'px'"
                :color="progressToolbar.color"
                :height="progressToolbar.height" 
                :indeterminate="true"> 
            </v-progress-linear>  
            <mbs-alert-toolbar /> 
        </div>
    </div> 
</template>

<script>
    import DATA from "../../plugins/DATA"
    import {mapState } from "vuex" 
    let PAGE_NAME="toolbar"
    export default {
        props:[],
        data(){
            return{
                alert: true,
                show:false, 
                descriptionLimit: 60,
                entries: [],
                isLoading: false,
                model: null,
                search: null,
                menuSearch: false,
                SUBTOOLBAR_VISIBLE:false,
                DATA:DATA, 
            }
        },
        created() {
            try { 
                this.MBS.events.$on('SUBTOOLBAR_VISIBILITY', this.SUBTOOLBAR_VISIBILITY);   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted(){   
            try {  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
        },
        computed:{
            fields () {
                if (!this.model) return []

                return Object.keys(this.model).map(key => {
                return {
                    key,
                    value: this.model[key] || 'n/a',
                }
                })
            },
            items () {
                return this.entries.map(entry => {
                const Description = entry.Description.length > this.descriptionLimit
                    ? entry.Description.slice(0, this.descriptionLimit) + '...'
                    : entry.Description

                return Object.assign({}, entry, { Description })
                })
            },
            //commony
            mbs(){
                return this.MBS.actions
            },
            vs(){
                    return this.$vuetify.breakpoint
            }, 
            vsn(){
                return this.vs.name
            },
            us(){
                return this.mbs.us
            },
            ud(){
                return this.mbs.ud
            },
            app(){ 
                return this.$store.getters.getApp
            },
            nav(){ 
                return this.$store.getters.getMBS_nav
            }, 
            tb(){ 
                return this.$store.getters.getMBS_toolbar
            },
            progressToolbar(){  
                return this.$store.getters.getMBS_progress_toolbar
            },
            alertToolbar(){ 
                return this.$store.getters.getMBS_alert_toolbar
            },
            myCompanies(){ 
                return this.$store.getters.getCompanies
            },
            WEB_LINKS(){ 
                return this.MBS.actions.WEB_LINKS
            },
            OTHER_LINKS(){
                let all = this.WEB_LINKS
                if(!all){return null}
                let toolbar = all.filter(link=>{
                    return link.important > 1
                })
                return toolbar 
                
            },
            TOOLBAR_LINKS(){
                return important=>{
                    let all = this.WEB_LINKS
                    if(!all){return null}
                    let toolbar = all.filter(link=>{
                        return link.important == important
                    })
                    return toolbar 
                }
            },
            SOCIAL_MEDIA_LINKS(){
                let links = this.$store.getters.getSocialMediaLinks
                return links
            },
            online(){   
                return this.$store.getters.getOnlineStatus
            }, 
            NfcLive(){   
                return this.MBS.events.NfcLive
            }, 

            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
                SHOW_OFFLINE: state=> state.items['SHOW_OFFLINE'],
                SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
                K_CONNECTOR: state=> state.items['K_CONNECTOR'],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                CompanyQuickActions: state=> state.items[(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values).toUpperCase()], 
                Devices: state=> state.items[(DATA.ITEMS.DEVICES.values).toUpperCase()],
                JoinedDevices: state=> state.join[(DATA.ITEMS.DEVICES.values).toUpperCase()],
                Tills: state=> state.items[(DATA.ITEMS.TILLS.values).toUpperCase()],
                JoinedTills: state=> state.join[(DATA.ITEMS.TILLS.values).toUpperCase()], 
                LocalCashups: state=> state.locals[(DATA.ITEMS.LOCAL_CASHUPS.values).toUpperCase()],

                LocalDevices: state=> state.locals[(DATA.ITEMS.LOCAL_DEVICES.values).toUpperCase()],
            }), 
            TillsData(){
                return this.JoinedTills?this.JoinedTills:this.Tills
            },
            LocalCashup(){    
                let cashups = this.LocalCashups  
                let local_cashup = cashups?cashups.find(item=>{
                    return !item.closed
                }):null 
                this.day_shift_key = local_cashup?.day_shift_key 
                return local_cashup
            }, 
            CurrentTill(){
                let tills = this.TillsData
                let cashup = this.LocalCashup 
                if(!tills || !cashup){return null}
                let filled = tills.find(item=>{
                    return item.key == cashup.till_key
                })  
                return filled
            }, 
        },
        methods:{  
            navOpen(){ 
                this.MBS.actions.nav({
                    show:true,
                    min:false
                })   
            },
            onlogout(){
                this.show=false
                this.$store.dispatch('fi_signUserOut')
            },
            onCPanel(){
                this.show=false 
                this.$router.push('/cpanel')
            },
            SUBTOOLBAR_VISIBILITY(value){
                this.SUBTOOLBAR_VISIBLE = value.visible 
            },
            minimizeErrorToolbar(){
                this.MBS.actions.alertDialog({
                    ...this.alertToolbar,
                    min:this.alertToolbar.min?false:true
                })
            }
        },
        watch:{
        search (val) {
            // Items have already been loaded
            if (this.items.length > 0) return

            // Items have already been requested
            if (this.isLoading) return

            this.isLoading = true

            // Lazily load input items
            fetch('https://api.publicapis.org/entries')
            .then(res => res.json())
            .then(res => {
                const { count, entries } = res
                this.count = count
                this.entries = entries
            })
            .catch(err => { 
            })
            .finally(() => (this.isLoading = false))
        },
        
        } 
    }
</script>
<style>
.fixed-top{
    position: fixed !important; 
    z-index: 2; 
    background-color: white;
} 
.index3{ 
    z-index: 2  !important; 
}
  
</style>

