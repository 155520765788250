import DATA from '../../plugins/DATA' 
import DB from '../../plugins/DB' 
import  { 
  toDateTime, 
  toDate,
  toTime,
  TEXT_UP,
  TEXT_UP1,
  TEXT_UP11,
  TEXT_UP0,
  TEXT_EQ,
  TEXT_SPLIT_N,
  TEXT_SPACE0,
  SIZE, 
} from '../../plugins/MBS_actions' 
import users from './../users'   
import formatNumber  from '../../plugins/formatNumber'   
import moment from 'moment'  
import WORKER from 'simple-web-worker'
import { mdiAccountCreditCard } from '@mdi/js';
import { mdiInvoiceTextFast } from '@mdi/js';
 
import { mdiInvoiceList } from '@mdi/js';
import { mdiInvoiceTextCheck } from '@mdi/js';
import { mdiInvoiceTextArrowRight } from '@mdi/js';
import { mdiInvoiceTextEdit } from '@mdi/js';
import { mdiInvoiceTextMultiple } from '@mdi/js';

let NAME = "JOINED" 

const toNumber=formatNumber.toNumber
const toMoney=formatNumber.toMoney
const toSameLength=formatNumber.toSameLength

export default{
    state: { 
      BILLING_PLANS : null,
      BILLING_PLAN_FEATURES : null,
      CURRENT_BILLING_PLANS : null,
      SUBSCRIPTIONS : null,

      JOINING:[],  
      COUNTRIES:null,  
      REGIONS:null,  
      DISTRICTS:null, 

      ATTENDANCES:null,  
      DESIGNATIONS:null,  

      ID_TYPES:null, 

      MEASUREMENT_UNITS:null,  
      COMPANIES:null, 
      COMPANY_TYPES:null, 
      COMPANY_SIZES:null, 
      COMPANY_USERS:null, 
      COMPANY_USER_GROUPS:null, 

      DEVICES:null, 
      CARDS:null, 
      TILLS:null, 
      PRICING_TIERS:null, 
      DAYS_SHIFTS:null, 
      DAY_SALES:null, 
      DAY_ORDERS:null, 
      CASHUPS:null, 
      STOCKS:null, 
      STOCK_OUTLETS:null, 
      STOCK_ORDERS:null, 
      STOCK_ORDER_ITEMS:null, 
      STOCKS_RETURN:null, 
      LOCAL_SALE_ORDERS:null, 
      SALE_ORDERS:null, 
      SALE_ORDER_ITEMS:null, 
      RETURNED_SALES:null, 
      QUOTATIONS_AND_INVOICES:null, 
      QUOTATIONS:null, 
      INVOICES:null, 
      BRANDS:null, 
      MANUFACTURERS:null, 
      SUPPLIERS:null, 
      SUPPLIER_CATEGORIES:null, 
      CUSTOMERS:null, 
      CUSTOMER_GROUPS:null, 
      ITEMS:null, 
      ALL_ITEMS:null, 
      ITEM_CATEGORIES:null, 
      ITEM_DEPARTMENTS:null, 
      ITEM_KITS:null, 
      ITEM_ORDERS:null, 
      LAN_ITEM_ORDERS:null, 
      ITEM_ORDER_OPTIONS:null, 
      PATIENT_ORDERS:null, 
      PATIENT_HISTORIES:null, 
      LOCAL_ITEM_ORDERS:null, 

      ASSETS:null, 
      ASSET_TYPES:null, 
      PAYABLES:null, 
      RECEIVABLES:null, 
      PAID_INVOICES:null, 
      UNPAID_INVOICES:null, 
      CREDITORS:null, 
      DEBTORS:null, 
      PAYMENTS:null, 
      EXPENSES:null, 
      EXPENSE_ACCOUNTS:null, 

      EXPIRIES:null, 
      PAYMENT_METHODS:null, 
      MONEY_ACCOUNTS:null, 
      MONEY_ACCOUNT_TRANSACTIONS:null, 
      STOCK_TAKINGS:null, 
      STOCK_TAKING_ORDERS:null, 
    },
    //___________________-GETTERS-_________________
    getters: { 
      getJoinedItemsState(state) { 
        return state
      }, 
      getJoinedLocalSaleOrders(state) { 
        return state.LOCAL_SALE_ORDERS
      }, 
      isJoining(state) { 
        return name=>{
          if (SIZE(state.JOINING)==0){return false}
          let join = state.JOINING.find(item=>{
            return TEXT_UP(item.name) == [TEXT_UP(name)]
          })
          return join?join.loading:false
        }
      }, 
      getJoined(state) { 
        return name=>{
          if (SIZE(state.JOINING)==0){return null}
          let joins = state.JOINING.find(join=>{
            return TEXT_UP(join.name) == [TEXT_UP(name)]
          })
          return joins
        }
      },    
      getAllJoinedItems(state) { 
        return state[TEXT_UP("ALL_"+DATA.ITEMS.ITEMS.values)]
      }, 
      getJoinState(state) { 
        return state
      }, 
    },
    //___________________-MUTATIONS-___________________
    mutations:{  
      setJoining(state, payload) {
        let name = payload?payload.name:null
        let loading = payload?payload.loading:null
        let response = payload?payload.response:null
        if(!name){return}
        if (!state.JOINING) {
          state.JOINING = []
        }
        let joining_item = state.JOINING.find(item=>{
          return item.name == name
        })
        if (joining_item) {
          joining_item.loading = loading
          joining_item.response = response
        }else{
          state.JOINING.push({
            name:name,
            loading:loading,
            response:response,
          })
        } 
      },   
      setJoinedItems(state, payload) {
        try {
          let name = payload?payload.name:null
          let data = payload?payload.data:null
          state[name] = data  
        } catch (error) {
          console.log(error);
        }
      }, 
      updateJoinedItemsByKey(state, payload) {
        try {
          let name = payload?payload.name:null
          let data = payload?payload.data:null
          let stateData =[]
          if (state[name]) {stateData = [...state[name] ]}  
          if (data) {  
            for (const element of data) {
              var foundIndex = stateData.findIndex(x => x.key == element.key); 
              if(foundIndex != -1) { 
                stateData[foundIndex] = element;
              }else{
                stateData.push(element)
              } 
            }  
            state[name]=stateData
          }
        } catch (error) {
          console.log(error);
        }
      }, 
    },
    //____________________-ACTIONS-_____________________
    actions: { 
      //-------------[JOIN]---------------
      join({ commit,dispatch},payload) {
        let response = {
          from:'join',
          page:NAME, 
          payload:payload
        }
        let item_name = null
        let processed_data = null
        return new Promise(async(resolve,reject)=>{
          try { 
            let name = payload?payload.name:null  
            let data = payload?payload.data:null  
            let add = payload?payload.add:null  
            let isUpdates = payload?payload.isUpdates:null  
            let join_commit_action = null  
            
            if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.USERS.values)) {
              join_commit_action = "join_user"
              item_name = DATA.ITEMS.USERS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.ATTENDANCES.values)) {
              join_commit_action = "join_attendance"
              item_name = DATA.ITEMS.ATTENDANCES.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.DESIGNATIONS.values)) {
              join_commit_action = "join_designations"
              item_name = DATA.ITEMS.DESIGNATIONS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.ITEMS.values)) {
              join_commit_action = "join_item"
              item_name = DATA.ITEMS.ITEMS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.ITEM_CATEGORIES.values)) {
              join_commit_action = "join_item_category"
              item_name = DATA.ITEMS.ITEM_CATEGORIES.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.ITEM_ORDER_OPTIONS.values)) {
              join_commit_action = "join_item_order_options"
              item_name = DATA.ITEMS.ITEM_ORDER_OPTIONS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.ITEM_ORDERS.values)) {
              join_commit_action = "join_item_orders"
              item_name = DATA.ITEMS.ITEM_ORDERS.values
            }else if (TEXT_UP(name)===TEXT_UP("LAN_ITEM_ORDERS")) {
              join_commit_action = "join_item_orders"
              item_name = "LAN_ITEM_ORDERS"
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.SUPPLIERS.values)) {
              join_commit_action = "join_supplier"
              item_name = DATA.ITEMS.SUPPLIERS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.CUSTOMERS.values)) {
              join_commit_action = "join_customer"
              item_name = DATA.ITEMS.CUSTOMERS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.PATIENT_ORDERS.values)) {
              join_commit_action = "join_patient_orders"
              item_name = DATA.ITEMS.PATIENT_ORDERS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.PATIENT_HISTORIES.values)) {
              join_commit_action = "join_patient_orders"
              item_name = DATA.ITEMS.PATIENT_HISTORIES.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.DAYS_SHIFTS.values)) {
              join_commit_action = "join_day_shift"
              item_name = DATA.ITEMS.DAYS_SHIFTS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.CASHUPS.values)) {
              join_commit_action = "join_cashup"
              item_name = DATA.ITEMS.CASHUPS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.PRINTERS.values)) {
              join_commit_action = "join_printer"
              item_name = DATA.ITEMS.PRINTERS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.TILLS.values)) {
              join_commit_action = "join_till"
              item_name = DATA.ITEMS.TILLS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.DEVICES.values)) {
              join_commit_action = "join_device"
              item_name = DATA.ITEMS.DEVICES.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.STOCK_OUTLETS.values)) {
              join_commit_action = "join_outlet"
              item_name = DATA.ITEMS.STOCK_OUTLETS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.CARDS.values)) {
              join_commit_action = "join_cards"
              item_name = DATA.ITEMS.CARDS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.COMPANIES.values)) {
              join_commit_action = "join_company"
              item_name = DATA.ITEMS.COMPANIES.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.ASSETS.values)) {
              join_commit_action = "join_asset"
              item_name = DATA.ITEMS.ASSETS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.SALE_ORDER_ITEMS.values)) {
              join_commit_action = "join_sale_order_items"
              item_name = DATA.ITEMS.SALE_ORDER_ITEMS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.SALE_ORDERS.values)) {
              join_commit_action = "join_sale_orders"
              item_name = DATA.ITEMS.SALE_ORDERS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.DAY_SALES.values)) {
              join_commit_action = "join_sale_orders"
              item_name = DATA.ITEMS.DAY_SALES.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.DAY_ORDERS.values)) {
              join_commit_action = "join_sale_orders"
              item_name = DATA.ITEMS.DAY_ORDERS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.LOCAL_SALE_ORDERS.values)) {  
              join_commit_action = "join_sale_orders"
              item_name = DATA.ITEMS.LOCAL_SALE_ORDERS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.STOCK_ORDERS.values)) {
              join_commit_action = "join_stock_orders"
              item_name = DATA.ITEMS.STOCK_ORDERS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.QUOTATIONS_AND_INVOICES.values)) {
              join_commit_action = "join_stock_orders"
              item_name = DATA.ITEMS.QUOTATIONS_AND_INVOICES.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.QUOTATIONS.values)) {
              join_commit_action = "join_sale_orders"
              item_name = DATA.ITEMS.QUOTATIONS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.EXPIRIES.values)) {
              join_commit_action = "join_expiry"
              item_name = DATA.ITEMS.EXPIRIES.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.EXPENSES.values)) {
              join_commit_action = "join_expenses"
              item_name = DATA.ITEMS.EXPENSES.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.EXPENSE_ACCOUNTS.values)) {
              join_commit_action = "join_expense_accounts"
              item_name = DATA.ITEMS.EXPENSE_ACCOUNTS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.PAYMENT_METHODS.values)) {
              join_commit_action = "join_payment_methods"
              item_name = DATA.ITEMS.PAYMENT_METHODS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.MONEY_ACCOUNTS.values)) {
              join_commit_action = "join_money_accounts"
              item_name = DATA.ITEMS.MONEY_ACCOUNTS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.RECEIVABLES.values)) {
              join_commit_action = "join_receivables"
              item_name = DATA.ITEMS.RECEIVABLES.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.PAYABLES.values)) {
              join_commit_action = "join_payables"
              item_name = DATA.ITEMS.PAYABLES.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.PAID_INVOICES.values)) {
              join_commit_action = "join_sale_order_payment"
              item_name = DATA.ITEMS.PAID_INVOICES.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.UNPAID_INVOICES.values)) {
              join_commit_action = "join_sale_order_invoice"
              item_name = DATA.ITEMS.UNPAID_INVOICES.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.STOCK_TAKING_ORDERS.values)) {
              join_commit_action = "join_stock_taking_orders"
              item_name = DATA.ITEMS.STOCK_TAKING_ORDERS.values
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.RETURNED_SALES.values)) {
              join_commit_action = "join_sale_return"
              item_name = DATA.ITEMS.RETURNED_SALES.values
            }

            else if (TEXT_UP(name)===TEXT_UP(DATA.ITEMS.LOCAL_ITEM_ORDERS.values)) { 
              join_commit_action = "join_local_item_orders"
              item_name = DATA.ITEMS.LOCAL_ITEM_ORDERS.values 
            }else if (TEXT_UP(name)===TEXT_UP(DATA.ITEMS.LOCAL_QUOTATIONS.values)) { 
              join_commit_action = "join_sale_orders"
              item_name = DATA.ITEMS.LOCAL_QUOTATIONS.values 
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.BILLING_PLAN_FEATURES.values)) { 
              join_commit_action = "join_billing_plan_features"
              item_name = DATA.ITEMS.BILLING_PLAN_FEATURES.values 
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.BILLING_PLANS.values)) { 
              join_commit_action = "join_billing_plans"
              item_name = DATA.ITEMS.BILLING_PLANS.values 
            }else if (TEXT_UP(name)===TEXT_UP("ALL_"+DATA.ITEMS.SUBSCRIPTIONS.values)) { 
              join_commit_action = "join_subscriptions"
              item_name = DATA.ITEMS.SUBSCRIPTIONS.values 
            }
            else if (name) {
              let index = TEXT_UP(name).indexOf("ALL_") 
              if (index == 0) {  
                join_commit_action = "join_others"
                item_name = name.slice(4) 
              } 
            }   
            commit("setJoining",{name:item_name,loading:true}) 
            setTimeout(async() => {
              if (join_commit_action) { 
                let isArray = Array.isArray(data) 
                if (isArray) {
                  let joined_items = []
                  let all_joined_items = []
                  let archived_joined_items = []
                  let NUMBER = 0 
                  for (const element of data) {
                    NUMBER = NUMBER+1  
                    let res = await dispatch(join_commit_action,{
                      name:name,
                      data:element,
                      add:add,
                      NUMBER:NUMBER,
                    })
                    let res_data = res?res.data:null 
                    if (res_data) { 
                      all_joined_items.push(res_data)  
                      if (res_data.archive) {
                        archived_joined_items.push(res_data)
                      }else{
                        joined_items.push(res_data)
                      }
                    }  
                  } 
                   
                  //save
                  if (add) { 
                    if (isUpdates) {
                      commit("updateJoinedItemsByKey",{
                        name:TEXT_UP(item_name),
                        data:joined_items,
                      })
                      commit("updateJoinedItemsByKey",{
                        name:TEXT_UP("all_"+item_name),
                        data:all_joined_items,
                      })
                      commit("updateJoinedItemsByKey",{
                        name:TEXT_UP("archived_"+item_name),
                        data:archived_joined_items,
                      })  
                    } else { 
                      commit("setJoinedItems",{
                        name:TEXT_UP(item_name),
                        data:joined_items,
                      })
                      commit("setJoinedItems",{
                        name:TEXT_UP("all_"+item_name),
                        data:all_joined_items,
                      })
                      commit("setJoinedItems",{
                        name:TEXT_UP("archived_"+item_name),
                        data:archived_joined_items,
                      }) 
                    }
                  }
                  processed_data = {
                    joined:joined_items,
                    all_joined:all_joined_items,
                    archived_joined:archived_joined_items,
                  } 
                } else {
                  let res = await dispatch(join_commit_action,{
                    name:item_name,
                    data:data,
                    add:add 
                  })
                  processed_data = res?res.data:null
                }   
                response = {
                  success:true, 
                  data:processed_data,
                  is_array:isArray,
                  isArray:isArray,
                }
                commit("setJoining",{name:item_name,loading:false,response:response})
                resolve(response) 
              } else {
                response = {
                  ...response,
                  success:false,
                  error:'action not found', 
                }
                commit("setJoining",{name:item_name,loading:false,response:response})
                reject(response)
              }  
            }, 1); 
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            commit("setJoining",{name:item_name,loading:false,response:response})
            reject(response)  
          }  
        })
      },
      join_user({ commit,getters},payload) {
        let response = {
          from:'join_user',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try { 
            let name = payload?payload.name:null  
            let element = payload?payload.data:null  
            let add = payload?payload.add:null  
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              let uid = element.key
              let email = element.email
              if (!email) {
                  let user = getters.getUserByUid(uid)
                  if (user) {
                      element = {
                          ...user,
                          element,
                      } 
                  }
              }
              //find Designation
              let designation_code = element.designation_code 
              let designation = getters.getDesignationByCode(designation_code)
              let designation_name = designation?designation.name:'...'
              
              //find User group
              let user_group_code = element.user_group_code 
              let user_group = getters.getUserGroupByCode(user_group_code)
              let user_group_name = user_group?user_group.name:'...'
              let active_status = element.disabled?'Disabled':'Active'
              active_status = element.reset_password?'Rest Password':active_status
              let user_status = getters.getUserStatusByKey(uid)

              let selectedCompany = getters.getSelectedCompany
              let company_key = selectedCompany?selectedCompany.key:null
              let companies = element.companies
              let company  = companies && company_key?companies[company_key]:null
              let company_user_group_code = company?company.company_user_group_code:null
              let company_user_group = getters.getCompanyUserGroupByCode(company_user_group_code)
              let company_user_group_name = company_user_group?company_user_group.name:'...'
      
              let newData = {
                  ...element,
                  no:NUMBER,  
                  designation:designation,
                  designation_name:designation_name,
                  user_group:user_group,
                  user_group_name:user_group_name, 
                  reg_status:element.registered?'Registered':element.signup_status,
                  active_status:active_status,
                  user_status:user_status,
                  company:company,
                  company_key:company_key,
                  company_user_group:company_user_group,
                  company_user_group_name:company_user_group_name,
                  name:TEXT_UP11(element.surname+' '+element.names),
                  last_changed:user_status?user_status.last_changed:null,
                  logged:user_status?user_status.logged:null,
                  online:user_status?user_status.online:null,
                  last_changed_:user_status? toDateTime(user_status.last_changed):'', 
                  created_at_:toDateTime(element.created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 

              } 
              resolve({
                success:true, 
                data:newData
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            } 

             
            
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      },
      join_attendance({ commit,getters},payload) {
        let response = {
          from:'join_attendance',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){ 

              //Day Shift 
              let day_shift_key = element?element.day_shift_key:null 
              let day_shift = getters.getDayShiftByKey(day_shift_key) 
              let operating_date = day_shift?day_shift.operating_date:"..."
              let outlet_code = day_shift?.outlet_code
              let local_close_at = day_shift?.local_close_at
              
              
              //category 
              let outlet = getters.getStockOutletByCode(outlet_code)
              let outlet_name = outlet?outlet.name:'--' 
              
              //user
              let uid = element.uid
              let user = getters.getCompanyUserByKey(uid) 
              let user_name = user?user.surname+' '+user.names:'--' 

              //calculate time
              let checkin_date =  element.checkin_date
              let checkout_date =  element.checkout_date
              let not_checked_out = false
              if (!checkout_date) {
                not_checked_out = false
                if (local_close_at) {
                  checkout_date = local_close_at
                }
                
              }
              let diff_hours = moment(checkout_date).diff(checkin_date,'hours')
              let diff_minute = moment(checkout_date).diff(checkin_date,'minutes')
              let minutes = diff_minute-(diff_hours*60)
              let attend_time = toSameLength(diff_hours,2)+"h "+toSameLength(minutes,2)+"m"
              
              
              let newItem = {
                  ...element,
                  no:NUMBER,  
                  outlet_name:outlet_name,
                  outlet:outlet,  
                  to:outlet_name, 

                  not_checked_out:not_checked_out, 
                  day_shift:day_shift, 
                  operating_date:operating_date, 
                  operating_date_:toDate(operating_date),  
                  user:user, 
                  user_name:user_name, 
                   
                  // checkin_date_:moment(element.checkin_date).format('yyyy/MM/D'),
                  // checkin_date_time_:moment(element.checkin_date).format('HH:mm:ss'),
                  attend_time:attend_time,
                  diff_hours:diff_hours,
                  diff_minute:diff_minute,
                  checkin_date_:toDateTime(element.checkin_date), 
                  checkin_date_date:toDate(element.checkin_date), 
                  checkin_date_time:toTime(element.checkin_date), 
                  
                  checkout_date:checkout_date,
                  checkout_date_:element.checkout_date?toDateTime(element.checkout_date):'...', 
                  checkout_date_date:element.checkout_date?toDate(element.checkout_date):'...', 
                  checkout_date_time:element.checkout_date?toTime(element.checkout_date):'...', 
                   
                  created_at_server_:toDateTime(element.created_at_server), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 
      join_designations({ commit,getters},payload) {
        let response = {
          from:'join_designations',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try { 
            let name = payload?payload.name:null  
            let element = payload?payload.data:null  
            let add = payload?payload.add:null  
            let NUMBER = payload?payload.NUMBER:1   
            if(element){ 
              //category 
              let outlet_code = element.outlet_code 
              let outlet = getters.getStockOutletByCode(outlet_code)
              let outlet_name = outlet?outlet.name:'--' 
      
              let newData = {
                  ...element,
                  no:NUMBER,  
                  outlet:outlet, 
                  outlet_name:outlet_name,
                  created_at_:toDateTime(element.created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 

              } 
              resolve({
                success:true, 
                data:newData
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            } 

             
            
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      },
      join_item({ commit,dispatch,getters},payload) {
        let response = {
          from:'join_item',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              let item_code = element.item_code 
              let item_key = element.item_key
              if (item_key) { 
                  let item = getters.getItemByKey(item_key)
                  if (item) {
                      element={
                          ...item,
                          ...element,
                      }
                  } 
              }else if (item_code) { 
                  let item = getters.getItemByKey(item_code)
                  if (item) {
                      element={
                          ...item,
                          ...element,
                      }
                  }
              }  
              //category
              // fixing old version 0.0.28
              if (!element.category_code && element.item_category_code) {
                  element.category_code = element.item_category_code
              }

              //GET PARENT ITEM
              let parent = null
              let parent_name = null
              let name = element.name
              let child_name = element.name
              if (element.parent_item_code) {
                parent = getters.getItemByCode(element.parent_item_code)
                const index = name.indexOf(" ("+parent?.name+")")
                if (index == -1) {
                  name = name+" ("+parent?.name+")" 
                }
              }

              
              

              let category_code = element.category_code 
              let item_category = getters.getItemCategoryByCode(category_code)
              let category_name = item_category?item_category.name:'--'
              let item_department_code = item_category?item_category.item_department_code:null
              
              //department
              item_department_code = element.item_department_code?element.item_department_code:item_department_code 
              let item_department = getters.getItemDepartmentByCode(item_department_code)
              let item_department_name = item_department?item_department.name:'--'
              
              //brand
              let brand_code = element.brand_code
              let brand = getters.getBrandByCode(brand_code)
              let brand_name = brand?brand.name:'--'

              //manufacturers
              let manufacturer_code = element.manufacturer_code
              let manufacturer = getters.getManufacturerByCode(manufacturer_code)
              let manufacturer_name = manufacturer?manufacturer.name:'--'
              
              //Tax
              let tax_group_code = element.tax_group_code
              let tax_group = getters.getTaxGroupByCode(tax_group_code)
              let tax_group_name = tax_group?tax_group.name:'Taxable (16.5%)'
              
              //supplier
              let supplier_code = element.supplier_code
              let supplier = getters.getSupplierByCode(supplier_code)  

              //Users
              let created_uid = element.uid
              let user = getters.getCompanyUserByKey(created_uid) 
              let user_name = user?user.surname+' '+user.names:'--'
              
              //Users
              let updated_uid = element.updated_uid
              let updated_user = getters.getCompanyUserByKey(updated_uid) 
              let updated_user_name = updated_user?updated_user.surname+''+updated_user.names:'--'

              //ITEM KITS
              let item_kits = null
              let item_kits_size = 0
              if (element.item_kits) {
                item_kits_size = SIZE(element.item_kits)
                item_kits = []
                element.item_kits.forEach(kit => {
                  let item = getters.getItemByKey(item_code)
                  if (item) {
                      item_kits.push({
                        ...item,
                        ...kit
                      })
                  }else{
                    item_kits.push(kit)
                  }
                  
                });
              }

              // number
              let number_sales = toNumber(element.number_sales?element.number_sales:0)
              let number_returned_sales = toNumber(element.number_returned_sales?element.number_returned_sales:0)
              let number_stock_receives = toNumber(element.number_stock_receives?element.number_stock_receives:0)
              let number_stocks_returns = toNumber(element.number_stocks_returns?element.number_stocks_returns:0)
              let number_stock_adjustments = toNumber(element.number_stock_adjustments?element.number_stock_adjustments:0)
              let number_stock_transfers = toNumber(element.number_stock_transfers?element.number_stock_transfers:0)
              let number_opening_stocks = toNumber(element.number_opening_stocks?element.number_opening_stocks:0)

              let opening_stocks = toNumber(element.opening_stocks)

              //calculate cost
              let number_average_cost = toNumber(element.number_average_cost?element.number_average_cost:0)
              let cost_price = toNumber(element.cost_price)
              if (number_average_cost===0) {
                number_average_cost = cost_price
              }

              //calculate price 
              let pricing_tiers_amounts = {}
              let pricing_tiers = getters.getPricingTiers
              if (pricing_tiers) {
                pricing_tiers.forEach(tier => {
                  let price = toNumber(element[tier.key])
                  let profit_margin = toNumber(tier.profit_margin)
                  if (!price) {
                    let new_price = toNumber(element.cost_price?element.cost_price:element.retail_price)
                    let profit = new_price*profit_margin/100
                    pricing_tiers_amounts[tier.key+"_"] =  toMoney(new_price+profit) 
                    pricing_tiers_amounts[tier.key] =  new_price+profit
                  }else{
                    pricing_tiers_amounts[tier.key+"_"]=toMoney(price) 
                  }
                });
              }


              let number_quantity = 0
              if (element.item_type_key !="child" && element.item_type_key !="parent") {
                number_quantity = 
                  number_stock_receives
                  +opening_stocks
                  +number_opening_stocks
                  -number_stocks_returns
                  -number_stock_transfers
                  -number_sales
                  +number_returned_sales
                  +number_stock_adjustments 
              }
              let index = (""+number_quantity).indexOf(".")
              if (index !=-1) {
                number_quantity=number_quantity.toFixed(3)
              }

              let number_total_cost = cost_price*number_quantity 
              // number_average_cost = number_total_cost==0?0:number_average_cost

              const inventory_item_code = element?.inventory_item_code
              const inventory_item_key = element?.inventory_item_key
              const inventory_item_keycode = inventory_item_key?inventory_item_key:inventory_item_code
              const inventory = !inventory_item_keycode || inventory_item_keycode == "main"?true:false 
              let item_icon = "mdi-tag"
              // item_icon = "mdi-palette-swatch-outline"

              if (element.item_type_key == "parent") {
                item_icon = "mdi-tag-multiple-outline"
              }else if (element.item_type_key == "child") {
                item_icon = "mdi-tag-outline"
              }else if (element.item_type_key == "service" && inventory) {
                item_icon = "mdi-tag-text"
              }else if (element.item_type_key == "service" && !inventory) {
                item_icon = "mdi-tag-text-outline"
              }else if (element.item_type_key == "raw_material" && inventory) {
                item_icon = "mdi-tag-heart"
              }else if (element.item_type_key == "raw_material" && !inventory) {
                item_icon = "mdi-tag-heart-outline"
              }else if(element.item_kits) {
                item_icon = "mdi-group"
              }else if(!inventory) {
                item_icon = "mdi-tag-outline"
              }
      
              let icon_image = {
                icon:item_icon,
              }
              let newItem = {
                  ...element,
                  ...pricing_tiers_amounts,
                  no:NUMBER, 
                  user:user,
                  user_name:user_name,
                  parent,
                  parent_name,
                  name,
                  child_name, 
                  updated_user:updated_user,
                  updated_user_name:updated_user_name,
                  icon:item_icon,
                  icon_image:icon_image,
                  item_kits:item_kits,
                  item_kits_size:item_kits_size, 
                  tax_:element.tax?"Yes":"No", 
                  department_code:item_department_code,
                  item_category:item_category,
                  category_name:category_name, 
                  item_department:item_department, 
                  item_department_name:item_department_name, 
                  brand:brand,
                  brand_name:brand_name, 
                  manufacturer:manufacturer,
                  manufacturer_name:manufacturer_name, 
                  supplier:supplier,  
                  tax_group_code:tax_group_code,  
                  tax_group:tax_group,   
                  tax_group_name:tax_group_name,   
                  number_quantity:number_quantity,
                  number_sales:number_sales,
                  number_returned_sales:number_returned_sales,
                  number_stock_adjustments:number_stock_adjustments,
                  number_opening_stocks:number_opening_stocks,
                  all_opening_stocks:number_opening_stocks+opening_stocks,
                  number_stock_receives:number_stock_receives,
                  
                  number_average_cost:number_average_cost,
                  number_average_cost_:toMoney(number_average_cost),
                  number_total_cost:number_total_cost,
                  number_total_cost_:toMoney(number_total_cost),
                  cost_price_:toMoney(element.cost_price),
  
                  retail_price_:toMoney(element?.retail_price), 
                  wholesale_price_:toMoney(element.wholesale_price),  
                  code_:toSameLength(element.code),
                  created_at_:toDateTime(element.created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }  
              
              //calculate outlet number
              let stock_outlets = getters.getStockOutlets
              let data_outlets = element.outlets
              let outlet_items = []
              if (stock_outlets) {
                stock_outlets.forEach(outlet => {
                      let item = data_outlets?data_outlets[outlet.code]:null
                      if (item) {
                        outlet_items.push({
                          ...outlet,
                          ...item
                        })
                      } else {
                        outlet_items.push({
                          ...outlet 
                        })
                      }
                       
                  });
              }
              if (outlet_items) {
                outlet_items.forEach(item => {
                  let outlet_code = item.code
                  let number_sales = toNumber(item.number_sales?item.number_sales:0)
                  let number_returned_sales = toNumber(item.number_returned_sales?item.number_returned_sales:0)
                  let number_stock_receives = toNumber(item.number_stock_receives?item.number_stock_receives:0)
                  let number_stocks_returns = toNumber(item.number_stocks_returns?item.number_stocks_returns:0)
                  let number_stock_adjustments = toNumber(item.number_stock_adjustments?item.number_stock_adjustments:0)
                  let number_stock_transfers = toNumber(item.number_stock_transfers?item.number_stock_transfers:0)
                  let number_opening_stocks = toNumber(item.number_opening_stocks?item.number_opening_stocks:0)
                  let opening_stocks = toNumber(item.opening_stocks?item.opening_stocks:0) 
                  let number_quantity = 
                    number_stock_receives
                    +opening_stocks
                    +number_opening_stocks
                    -number_stocks_returns
                    -number_stock_transfers
                    -number_sales
                    +number_returned_sales
                    +number_stock_adjustments
                  let index = (""+number_quantity).indexOf(".")
                  if (index !=-1) {
                    number_quantity=number_quantity.toFixed(3)
                  }
  
                  newItem[outlet_code+'_number_sales'] = number_sales
                  newItem[outlet_code+'_number_returned_sales'] = number_returned_sales
                  newItem[outlet_code+'_number_stock_receives'] = number_stock_receives
                  newItem[outlet_code+'_number_stocks_return'] = number_stocks_returns
                  newItem[outlet_code+'_number_stock_adjustments'] = number_stock_adjustments
                  newItem[outlet_code+'_number_stock_transfers'] = number_stock_transfers
                  newItem[outlet_code+'_number_opening_stocks'] = number_opening_stocks
                  newItem[outlet_code+'_all_opening_stocks'] = number_opening_stocks+opening_stocks
                  newItem[outlet_code+'_number_quantity'] = number_quantity 
                }); 
              }

              //ITEM TYPE
              let item_type_key = element.item_type_key 
              newItem.item_type_name = TEXT_UP1(item_type_key)  
              let stock_parent_items = []
              let out_of_stock_parent_items = [] 
              if (item_type_key=="parent") {
                newItem.parent_items = getters.getParentItems(element.code)
                newItem.parent_items_size = SIZE(newItem.parent_items)
                
                let res = await dispatch("join",{name:"ALL_"+DATA.ITEMS.ITEMS.values,data:newItem.parent_items})
                newItem.joined_parent_items = res?res.data?res.data.all_joined:null:null 
                if(newItem.joined_parent_items){
                  let number_sales = 0
                  let number_returned_sales = 0
                  let number_stock_receives = 0
                  let number_stocks_returns = 0
                  let number_stock_adjustments = 0
                  let number_stock_transfers = 0
                  let number_opening_stocks = 0
                  let number_quantity = 0
                  newItem.joined_parent_items.forEach(item => {
                    const stock = toNumber(item.number_quantity)>0?true:false
                    if (stock) {
                      stock_parent_items.push(item)
                    } else {
                      out_of_stock_parent_items.push(item) 
                    } 
                    number_sales = number_sales+item.number_sales
                    number_returned_sales = number_returned_sales+item.number_returned_sales
                    number_stock_receives = number_stock_receives+item.number_stock_receives
                    number_stocks_returns = number_stocks_returns+item.number_stocks_returns
                    number_stock_adjustments = number_stock_adjustments+item.number_stock_adjustments
                    number_stock_transfers = number_stock_transfers+item.number_stock_transfers
                    number_opening_stocks = number_opening_stocks+item.number_opening_stocks
                    number_quantity = number_quantity+item.number_quantity
                  });

                  newItem.stock_parent_items = stock_parent_items,
                  newItem.out_of_stock_parent_items = out_of_stock_parent_items,
                  newItem.stock_parent_items_size = SIZE(stock_parent_items)
                  newItem.out_of_stock_parent_items_size = SIZE(out_of_stock_parent_items)
                  newItem.item_type_name = newItem.item_type_name+" (s"+newItem.stock_parent_items_size+" o"+
                    newItem.out_of_stock_parent_items_size+" i"+newItem.parent_items_size+")"
                  
                    newItem.number_sales = number_sales
                    newItem.number_returned_sales = number_returned_sales
                    newItem.number_stock_receives = number_stock_receives
                    newItem.number_stocks_returns = number_stocks_returns
                    newItem.number_stock_adjustments = number_stock_adjustments
                    newItem.number_stock_transfers = number_stock_transfers
                    newItem.number_opening_stocks = number_opening_stocks
                    newItem.number_quantity = number_quantity 
                } 

                 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      },
      join_item_category({ commit,getters},payload) {
        let response = {
          from:'join_item_category',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){ 
              
              //department
              let item_department_code = element.item_department_code 
              let item_department = getters.getItemDepartmentByCode(item_department_code)
              let item_department_name = item_department?item_department.name:'--' 

               
              let newItem = {
                  ...element,
                  no:NUMBER,  
                  item_department:item_department, 
                  item_department_name:item_department_name, 
                   
                  created_at_:toDateTime(element.created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }  
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      },
      join_item_order_options({ commit,getters},payload) {
        let response = {
          from:'join_item_order_options',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              let outlet_code = element.outlet_code
              let outlet = getters.getStockOutletByCode(outlet_code)   
              let outlet_name = outlet?outlet.name:'...'
              
               
                    
              let newItem = {
                  ...element,
                  no:NUMBER,  
                  outlet:outlet,  
                  outlet_name:outlet_name,   
                  created_at_:toDateTime(element.created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 
      join_item_orders({commit,dispatch,getters},payload) {
        let response = {
          from:'join_item_orders',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   

            let res = await dispatch("join_sale_orders",{
              ...payload
            })
            
            let processed_data = res?res.data:null   
 
            if(processed_data){ 
              let newItem = {
                  ...processed_data,  
                  icon_image:{
                    icon:"mdi-cart"
                  }
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      },
      join_supplier({ commit,getters},payload) {
        let response = {
          from:'join_supplier',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              let supplier_category_code = element.supplier_category_code
              let supplier_category = getters.getSupplierCategoryByCode(supplier_category_code)
              let supplier_category_name = supplier_category?supplier_category.name:'--'
                  
              let newItem = {
                  ...element,
                  no:NUMBER, 
                  supplier_category:supplier_category,
                  supplier_category_name:supplier_category_name, 
                  name:TEXT_UP11(element.name),
                  created_at_:toDateTime(element.created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              } 
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      },
      join_customer({ commit,getters},payload) {
        let response = {
          from:'join_customer',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              let customer_group_code = element.customer_group_code
              let customer_group = getters.getCustomerGroupByCode(customer_group_code)
              let customer_group_name = customer_group?customer_group.name:'--'
              let name = element.name
              let next_of_kin_name = element.next_of_kin_name
              if (element.surname) {
                name = element.surname+" "+element.names
              }
              if (element.next_of_kin_surname) {
                next_of_kin_name = element.next_of_kin_surname+" "+element.next_of_kin_names
              }

              // Calculate age
              const date_of_birth = element.date_of_birth
              let age = 0
              if (date_of_birth) {
                const today = moment();
                const birthDate = moment(date_of_birth);
                age = today.diff(birthDate, 'years');
                
              }
                  
              let newItem = {
                  ...element,
                  no:NUMBER, 
                  customer_group:customer_group,
                  customer_group_name:customer_group_name, 
                  age:age, 
                  name:TEXT_UP11(name),
                  next_of_kin_name:TEXT_UP11(next_of_kin_name),
                  date_of_birth_:toDate(date_of_birth), 
                  created_at_:toDateTime(element.created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              } 
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      },
      join_patient_orders({ commit,getters},payload) {
        let response = {
          from:'join_patient_orders',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              let customer_key = element.customer_key
              let customer = getters.getCustomerByKey(customer_key)
              let customer_name = customer?customer.name:null
              let customer_names = customer?customer.names:null
              let customer_surname = customer?customer.surname:null
              let gender = customer?customer.gender:null
              if (!customer_name) {
                customer_name = customer_surname+" "+customer_names
              }
              if (element.next_of_kin_surname) {
                next_of_kin_name = element.next_of_kin_surname+" "+element.next_of_kin_names
              }

              // Calculate age
              const date_of_birth = customer?customer.date_of_birth:null
              let age = 0
              if (date_of_birth) {
                const today = moment();
                const birthDate = moment(date_of_birth);
                age = today.diff(birthDate, 'years');
                
              }
                  
              let newItem = {
                  ...element,
                  no:NUMBER, 
                  customer_name:customer_name, 
                  customer:customer, 
                  age:age, 
                  gender:gender, 
                  order_status:element.stage, 
                  date_of_birth_:toDate(date_of_birth), 
                  local_created_at_:toDateTime(element.local_created_at), 
                  created_at_:toDateTime(element.created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              } 
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      },
      join_day_shift({ commit,dispatch,getters},payload) {
        let response = {
          from:'join_day_shift',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){

              //Company 
              let key = element.key
              let cashups = element.cashups
              let joined_cashups = null
              let cashup_total_sales = 0
              try {
                if (cashups) { 
                  let array_cashups = Object.keys(cashups).map((key) => {return{...cashups[key],key:key}}); 
                  let res = await dispatch("join",{
                     data:array_cashups,
                     name:"ALL_"+DATA.ITEMS.CASHUPS.values
                  }) 
                  joined_cashups = res?res.data?res.data.all_joined:null:null   
                  if (array_cashups) {
                    array_cashups.forEach(cashup => {
                      cashup_total_sales = cashup_total_sales+toNumber(cashup?.cashup_total_sales)
                      
                    });
                  }

                }  
              } catch (error) {
              } 

              let closed = element.total_closing_cash!=null&&element.total_closing_cash!=undefined?true:false
              if (element.local_close_at) {
                closed = true
              }

              //Company 
              let company_key = element.company_key
              let company = getters.getCompanyByKey(company_key)
              let company_name = company?company.company_name:'--' 
              
              //Outlet 
              let outlet_code = element.outlet_code
              let outlet = getters.getStockOutletByCode(outlet_code)
              let outlet_name = outlet?outlet.name:'--' 
              
              //Users
              let created_uid = element.created_uid
              let user = getters.getCompanyUserByKey(created_uid) 
              let user_name = user?user.surname+' '+user.names:'--'
              
              //Users
              let updated_uid = element.updated_uid
              let close_user = getters.getCompanyUserByKey(updated_uid) 
              let close_user_name = close_user?close_user.surname+''+close_user.names:'--'

              //find cashup
              let tillClosedCashups = key=>{
                if(!key,!joined_cashups){return null}
                return joined_cashups.filter(cashup=>cashup.closed && cashup.till_key == key)
              }
              let tillRunningCashups = key=>{
                if(!key,!joined_cashups){return null}
                return joined_cashups.filter(cashup=>!cashup.closed && cashup.till_key == key)
              }


              //tills  
              let joined_tills =[]
              if ( element.tills ) {
                  const array_tills = Object.keys(element.tills).map((key) => {return{...element.tills[key],key:key}}); 
                  const all_tills = getters.getTills
                  let dayshift_tills = []
                  array_tills.forEach(cashier => {
                    dayshift_tills.push(cashier)
                    const assistant_tills = all_tills?.filter(t=>t.cashier_till_key == cashier.till_key)
                    if (assistant_tills) {
                        assistant_tills.forEach(assistant => {
                            dayshift_tills.push({
                                ...assistant,
                                till_key:assistant.key
                                // day_shift_key:cashier.day_shift_key,
                                // day_shift_and_till_key:cashier.day_shift_key+"/"+assistant.key,
                                // operating_date:cashier.operating_date, 

                            }) 
                        });
                    }
                    
                  });
 
                  dayshift_tills.forEach(item => {
                      let till_key = item.till_key
                      let till = getters.getTillByKey(till_key)
                      let closed = tillClosedCashups(till_key)
                      let running_cashups = tillRunningCashups(till_key)
                      let running_cashups_size = 0
                      let running = false
                      let cashup = null
                      if (running_cashups) {
                        running_cashups_size =  running_cashups.length
                        running = running_cashups_size?true:false
                        cashup= running_cashups.pop()
                      } else {
                        running = false
                        if (joined_cashups) {
                          cashup= joined_cashups.pop()
                        }
                      }
                      let teller = null 
                      let teller_uid = cashup?cashup.uid:null
                      if (cashup) {
                        if (!cashup.teller) {
                          let user = getters.getCompanyUserByKey(teller_uid)
                          if (user) {
                            teller = user
                          }
                        }else{
                          teller = cashup.teller
                        }
                      }
                      let teller_name = teller?teller.surname+' '+teller.names:'--'

                      if (till) {
                          joined_tills.push({
                              ...till,
                              ...item,
                              key:till_key,
                              closed:!running,
                              status:!running?'Close':'Running...',
                              cashup:cashup,
                              running:running,
                              running_cashups:running_cashups,
                              running_cashups_size:running_cashups_size,
                              teller:teller,
                              teller_uid:teller_uid,
                              teller_name:teller_name,
                              day_shift_and_till_key:element.key+"/"+till_key,
                              day_shift_key:element.key,
                              outlet:outlet,
                              outlet_name:outlet_name,
                              operating_date:element.operating_date,  
                              operating_date_:toDate(element.operating_date),
                              
                          })
                      }
                  });
              }  
 
              if (!element.local_created_at) { 
                  element.local_created_at = moment(element.created_at).valueOf()
              } 

              let total_sales = toNumber(element.totals_sales_retail)+toNumber(element.totals_sales_wholesales)  
              if (cashup_total_sales) {
                total_sales = cashup_total_sales
              } 

              let newItem = {
                  ...element,
                  no:NUMBER,  
                  closed:closed,    
                  status:closed?"Closed":"Running...",    

                  company:company,    
                  company_name:company_name,    
                  outlet_name:outlet_name,
                  outlet:outlet,   

                  joined_tills:joined_tills, 
                  user:user, 
                  user_name:user_name,  
                  close_user:close_user,  
                  close_user_name:close_user_name,  

                  cashups:cashups,    
                  joined_cashups:joined_cashups,      

                  cashup_total_sales:cashup_total_sales,    
                  total_sales:total_sales,    
                  total_sales_:toMoney(total_sales), 

                  total_closing_cash_:toMoney(element.total_closing_cash), 
                  total_floating_cash_:toMoney(element.total_floating_cash), 
                  operating_date_:toDate(element.operating_date), 
                  created_at_:toDateTime(element.created_at), 
                  created_at_date:toDate(element.created_at), 
                  created_at_time:toTime(element.created_at), 
                  created_at_server_:toDateTime(element.created_at_server), 
                  created_at_server_date:toDate(element.created_at_server), 
                  created_at_server_time:toTime(element.created_at_server), 
                  local_created_at_:toDateTime(element.local_created_at), 
                  local_created_at_date:toDate(element.local_created_at), 
                  local_created_at_time:toTime(element.local_created_at), 
                  local_close_at_:toDateTime(element.local_close_at), 
                  local_close_at_date:toDate(element.local_close_at), 
                  local_close_at_time:toTime(element.local_close_at), 
                  local_updated_at_:toDateTime(element.local_updated_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
                  time_closed:closed?toDateTime(element.local_close_at):"Running...", 
                  close_time_:element.local_close_at?moment(element.local_close_at).format('hh:mm:ss a'):'---',
                  close_date_time_:element.local_close_at?toDateTime(element.local_close_at):'---',
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      },
      join_cashup({ commit,getters},payload) {
        let response = {
          from:'join_day_cashup',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              let till_key = element?element.till_key:null  
              //Day Shift 
              let day_shift_key = element?element.day_shift_key:null 
              let day_shift = getters.getDayShiftByKey(day_shift_key) 
              let operating_date = day_shift?day_shift.operating_date:"..."
              
              //Till 
              let till = getters.getTillByKey(till_key)
              let till_name = till?till.name:'--' 
              let outlet_code = till?till.outlet_code:null
              
              //category 
              let outlet = getters.getStockOutletByCode(outlet_code)
              let outlet_name = outlet?outlet.name:'--' 
              
              //teller
              let teller_uid = element.uid
              let teller = getters.getCompanyUserByKey(teller_uid) 
              let teller_name = teller?teller.surname+' '+teller.names:'--'

              //INPUT PAYMENTS
              const input_payments = element.input_payments?element.input_payments:element.InputPayments
              let input_cash_payments = 0
              let input_other_payments = 0
              if (input_payments) {
                input_payments.forEach(payment => {
                  if (payment.key == "cash") {
                    input_cash_payments = input_cash_payments+toNumber(payment.given_amount)
                  } else {
                    input_other_payments = input_other_payments+toNumber(payment.given_amount)
                  }
                  
                });
              } 
              
              let cash_expenses = toNumber(element?.expenses?.cash_expenses)
              let other_expenses = toNumber(element?.expenses?.other_expenses)
              let total_expenses = toNumber(element?.expenses?.total_expenses)
              let expected_cash = toNumber(element.expected_cash) 
              
              if (element.InputPayments) {
                element.input_payments = element.InputPayments
                element.InputPayments = null
                expected_cash = expected_cash+input_cash_payments
              }

              const floating_cash = toNumber(element.floating_cash)
              let closing_cash = toNumber(element.closing_cash)
              let sales_retail = toNumber(element.sales_retail)
              let sales_wholesales = toNumber(element.sales_wholesales)
              let sales_cash_payments = toNumber(element.sales_cash_payments)
              let sales_on_account = toNumber(element.sales_on_account)
              let sales_total_other_option_payments = toNumber(element.sales_total_other_option_payments)
              let invoice_cash_payments = toNumber(element.invoice_cash_payments)
              let invoice_total_other_option_payments = toNumber(element.invoice_total_other_option_payments)


              
              
              const total_sales = sales_retail+sales_wholesales
              const total_cash_payments = sales_cash_payments+invoice_cash_payments+input_cash_payments
              const total_other_option_payments = sales_total_other_option_payments+invoice_total_other_option_payments+input_other_payments
              const calculated_expected_cash = total_cash_payments+cash_expenses+floating_cash
              const shortage = closing_cash-calculated_expected_cash
              const isShortage = shortage<0?true:false
              
              

              const newItem = {
                  ...element,
                  no:NUMBER,  
                  outlet_name:outlet_name,
                  outlet:outlet,  
                  to:outlet_name, 

                  day_shift:day_shift, 
                  operating_date:operating_date, 
                  operating_date_:toDate(operating_date),  
                  till:till, 
                  till_name:till_name, 
                  teller:teller, 
                  teller_name:teller_name,    
                  date_:moment(element.local_created_at).format('yyyy/MM/D'),
                  open_time_:moment(element.local_created_at).format('HH:mm:ss'),
                  close_time_:element.closed?moment(element.local_updated_at).format('HH:mm:ss'):'Running...',
                  close_date_time_:element.closed?toDateTime(element.local_updated_at):'Running...', 
                  floating_cash_:toMoney(element.floating_cash), 
                  opening_cash_:toMoney(element.opening_cash), 
                  closing_cash_:toMoney(element.closing_cash), 
                  expected_cash:expected_cash, 
                  expected_cash_:toMoney(expected_cash), 

                  input_cash_payments:input_cash_payments,
                  input_other_payments:input_other_payments,

                  sales_on_account_:toMoney(sales_on_account), 
                  sales_retail_:toMoney(sales_retail), 
                  sales_wholesales_:toMoney(sales_wholesales),
                  shortage_:isShortage?'('+ toMoney(shortage*-1)+')':toMoney(shortage),
                  shortage:shortage,
                  isShortage:isShortage, 
                  total_sales:total_sales, 
                  cash_expenses_:toMoney(cash_expenses),
                  other_expenses_:toMoney(other_expenses),
                  total_expenses_:toMoney(total_expenses),
                  total_sales_:toMoney(total_sales), 
                  cashup_total_sales_:toMoney(element.cashup_total_sales), 
                  total_cash_payments_:toMoney(total_cash_payments), 
                  total_other_option_payments_:toMoney(total_other_option_payments), 

                  created_at_:toDateTime(element.created_at), 
                  local_created_at_:toDateTime(element.local_created_at), 
                  local_created_at_date:toDate(element.local_created_at), 
                  local_created_at_time:toTime(element.local_created_at), 
                  local_updated_at_:toDateTime(element.local_updated_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 
      join_printer({ commit,getters},payload) {
        let response = {
          from:'join_printer',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              let printer_type_code = element.printer_type_code
              let printer_type = getters.getPrinterTypeByCode(printer_type_code)   
              let printer_type_name = printer_type?printer_type.name:'...'
              
              let server_type_code = element.server_type_code
              let server_type = getters.getServerTypeByCode(server_type_code)   
              let server_type_name = server_type?server_type.name:'...'
                    
              let newItem = {
                  ...element,
                  no:NUMBER,  
                  printer_type:printer_type,  
                  printer_type_name:printer_type_name,  
                  server_type:server_type,  
                  server_type_name:server_type_name,  
                  created_at_:toDateTime(element.created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      },
      join_till({ commit,getters},payload) {
        let response = {
          from:'join_till',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              let outlet_code = element.outlet_code
              let outlet = getters.getStockOutletByCode(outlet_code)   
              let outlet_name = outlet?outlet.name:'...'
              
              let server_code = element.server_code
              let server = getters.getDeviceByCode(server_code)   
              let server_name = server?server.name:'...'
              
              let computer_code = element.computer_code
              let computer = getters.getDeviceByCode(computer_code)   
              let computer_name = computer?computer.name:'...'
              


              //Scanner
              let scanner_code = element.scanner_code
              let scanner = getters.getDeviceByCode(scanner_code)   
              let scanner_name = scanner?scanner.name:'...' 
              
              //Cashier Till
              let cashier_till_key = element.cashier_till_key
              let cashier_till = getters.getTillByKey(cashier_till_key)   
              let cashier_till_name = cashier_till?cashier_till.name:'...' 
              
              //printers
              let printer_code = element.printer_code
              let printer = getters.getDeviceByCode(printer_code)   
              let printer_name = printer?printer.name:'...'
              let assigned_printers = element.assigned_printers
              let printers = []
              let printer_names = printer_name
              if (assigned_printers) {
                assigned_printers.forEach((p,index) => {
                  let printer = getters.getDeviceByCode(p.printer_code) 
                  let printer_name = printer?printer.name:'...'
                  printers.push({...p,...printer})
                  if (index) {
                    printer_names = printer_names+", "+printer_name
                  } else {
                    printer_names = printer_name
                  } 
                }); 
              }
              
              //pricing tier 
              let pricing_tiers_keys = element.pricing_tiers_keys
              let pricing_tiers = []
              let pricing_tiers_names = printer_name
              if (pricing_tiers_keys) {
                pricing_tiers_keys.forEach((p,index) => {
                  let pricing_tier = getters.getPricingTierByKey(p) 
                  let pricing_tier_name = pricing_tier?pricing_tier.name:'...'
                  pricing_tiers.push(pricing_tier)
                  if (index) {
                    pricing_tiers_names = pricing_tiers_names+", "+pricing_tier_name
                  } else {
                    pricing_tiers_names = pricing_tier_name
                  } 
                }); 
              }
              
              //department
              let selected_department_codes = element.selected_department_codes
              let isArray = Array.isArray(selected_department_codes)
              let departments = []
              let department_names = []
              
              if (isArray) {
                selected_department_codes.forEach((code,index) => {
                  let department = getters.getItemDepartmentByCode(code) 
                  let name = department?department.name:'...'
                  departments.push(department)
                  if (index) {
                    department_names = department_names+", "+name
                  } else {
                    department_names = name
                  } 
                }); 
              }else{
                let department = getters.getItemDepartmentByCode(selected_department_codes) 
                let name = department?department.name:'...'
                departments.push(department)
                department_names = name
              }
              
              let vfd_display_code = element.vfd_display_code
              let vfd_display = getters.getDeviceByCode(vfd_display_code)   
              let vfd_display_name = vfd_display?vfd_display.name:'...'

              let customer_display_codes = element.customer_display_codes
              let customer_displays = []
              let customer_display_names = []
              let customer_display_size = 0
              
              if (customer_display_codes) {
                customer_display_codes.forEach(code => {
                  let device = getters.getDeviceByCode(code)   
                  let device_name = device?device.name:'...'
                  customer_displays.push(device)
                  customer_display_names.push(device_name)
                  customer_display_size = customer_display_size+1
                }); 
              }

              if (vfd_display) {
                customer_displays.push(vfd_display)
                customer_display_names.push(vfd_display_name)
                customer_display_size = customer_display_size+1
              }
                    
              let newItem = {
                  ...element,
                  no:NUMBER,  
                  outlet:outlet,  
                  outlet_name:outlet_name,  
                  server:server,  
                  server_name:server_name,  
                  cashier_till:cashier_till,  
                  cashier_till_name:cashier_till_name,  
                  computer:computer,  
                  computer_name:computer_name,  
                  printers:printers,  
                  printer_names:printer_names, 
                  scanner:scanner,  
                  scanner_name:scanner_name,  
                  printer:printer,  
                  printer_name:printer_name,
                  departments:departments,  
                  department_names:department_names,  
                  pricing_tiers:pricing_tiers,  
                  pricing_tiers_names:pricing_tiers_names,  
                  vfd_display:vfd_display,  
                  vfd_display_name:vfd_display_name,  
                  customer_displays:customer_displays,  
                  customer_display_names:customer_display_names,  
                  customer_display_size:customer_display_size,  
                  created_at_:toDateTime(element.created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      },
      join_device({ commit,getters},payload) {
        let response = {
          from:'join_device',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){ 
              let outlet_code = element.outlet_code
              let outlet = getters.getStockOutletByCode(outlet_code)   
              let outlet_name = outlet?outlet.name:'...'

              let printer_type_code = element.printer_type_code
              let printer_type = getters.getPrinterTypeByCode(printer_type_code)   
              let printer_type_name = printer_type?printer_type.name:'...'
              
              let computer_type_code = element.computer_type_code
              let computer_type = getters.getComputerTypeByCode(computer_type_code)   
              let computer_type_name = computer_type?computer_type.name:'...'

              let server_type_code = element.server_type_code
              let server_type = getters.getServerTypeByCode(server_type_code)   
              let server_type_name = server_type?server_type.name:'...'
              
              let computer_usage_option_code = element.computer_usage_option_code
              let computer_usage_option = getters.getComputerUsageOptionByCode(computer_usage_option_code)   
              let computer_usage_option_name = computer_usage_option?computer_usage_option.name:'...'

              let server_code = element.server_code
              let server = getters.getDeviceByCode(server_code)   
              let server_name = server?server.name:'...'
              
              let icon_image = {
                icon:'mdi-devices' 
              }

              if (element.device_type == DATA.ITEMS.PRINTERS.value) {
                icon_image.icon = "mdi-printer" 
              } else if (element.device_type == DATA.ITEMS.COMPUTERS.value) {
                if (computer_type_code == 2) {
                  icon_image.icon = "mdi-laptop"
                } else if (computer_type_code == 3) {
                  icon_image.icon = "mdi-cellphone"
                } else {
                  icon_image.icon = "mdi-desktop-classic"
                }
                
              } 

              const network_ip_address = element.network_ip_address
              const proxy_type = element.proxy_type
              const port_number = element.port_number
              const network_ip_address_link = proxy_type+"://"+network_ip_address+":"+port_number
              if (proxy_type) {
                element.network_ip_address_link = network_ip_address_link
              }

              
              let newItem = {
                  ...element,
                  no:NUMBER,    
                  icon_image:icon_image,    
                  printer_type:printer_type,  
                  printer_type_name:printer_type_name,  
                  computer_type:computer_type,  
                  computer_type_name:computer_type_name,  
                  server_type:server_type,  
                  server_type_name:server_type_name,  
                  computer_usage_option:computer_usage_option,  
                  computer_usage_option_name:computer_usage_option_name,  
                  server:server,  
                  server_name:server_name,  
                  outlet:outlet,  
                  outlet_name:outlet_name,  
                  created_at_:toDateTime(element.created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      },
      join_outlet({ commit,getters},payload) {
        let response = {
          from:'join_outlet',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){ 
              
              let outlet_type_code = element.outlet_type_code
              let outlet_type = getters.getOutletTypeByCode(outlet_type_code)   
              let outlet_type_name = outlet_type?outlet_type.name:'...'
              
              
                    
              let newItem = {
                  ...element,
                  no:NUMBER,    
                  outlet_type:outlet_type,  
                  outlet_type_name:outlet_type_name,  
                   
                  created_at_:toDateTime(element.created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      },
      join_cards({ commit,getters},payload) {
        let response = {
          from:'join_cards',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){ 

              let card_type_name = element.card_type?DATA.ITEMS[TEXT_UP(element.card_type+'s')].name:'--'
              
              //created user
              let uid = element.created_uid
              let created_user = getters.getCompanyUserByKey(uid) 
              let created_user_name = created_user?created_user.surname+' '+created_user.names:'--' 

              // icon
              let icon_image = {
                icon:'mdi-receipt' 
              }
              icon_image.icon = 'mdi-credit-card'
              
              //Customer user
              let customer_key = element.card_customer_key
              let customer = getters.getCustomerByKey(customer_key) 
              let customer_name = customer?customer.name:'--' 
              let user_name = customer_name
              
              let balance_amount = toNumber(element.loaded_amount)-toNumber(element.used_amount)
                    
              let newItem = {
                  ...element,
                  no:NUMBER,    
                  icon_image:icon_image,    
                  card_type_name:card_type_name,    
                  created_user:created_user,  
                  created_user_name:created_user_name,  
                  customer:customer,  
                  customer_name:customer_name,  
                  user_name:user_name,  
                  balance_amount:balance_amount,  
                  balance_amount_:toMoney(balance_amount), 
                  loaded_amount_:toMoney(element.loaded_amount), 
                  used_amount_:toMoney(element.used_amount), 
                  created_at_:toDateTime(element.created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      },
      join_company({ commit,getters},payload) {
        let response = {
          from:'join_company',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              let key = element.key
                let company_name = element.company_name
                if (!company_name) {
                    let company = getters.getCompanyByKey(key)
                    if (company) {
                        element = {
                            ...company,
                            ...element
                        } 
                    }  
                }    
                let uid = element.created_uid
                let user = getters.getUserByUid(uid)
                let user_name = user?user.surname+' '+user.names:'--'
                let newItem = {
                    ...element,
                    no:NUMBER,  
                    user:user,
                    user_name:user_name,
                    created_at_:toDateTime(element.created_at), 
                    created_at_server_:toDateTime(element.created_at_server),
                    updated_at_:toDateTime(element.updated_at), 
                    updated_at_server_:toDateTime(element.updated_at_server), 
                }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 
      join_asset({ commit,getters},payload) {
        let response = {
          from:'join_asset',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              let asset_type_code = element.asset_type_code
              let asset_type = getters.getAssetTypeByCode(asset_type_code)   
              let asset_type_name = asset_type?asset_type.name:'...'
                    
              let newItem = {
                  ...element,
                  no:NUMBER,  
                  asset_type:asset_type,  
                  asset_type_name:asset_type_name,  
                  created_at_:toDateTime(element.created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      },   
      join_expenses({ commit,getters},payload) {
        let response = {
          from:'join_expenses',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              let code = element.code
              //supplier
              let supplier_code = element.supplier_code
              let supplier = getters.getSupplierByCode(supplier_code) 
              let supplier_name = supplier?supplier.name:"Unknown"
              
              //payment_method
              const payment_method_code = element.payment_method_code
              const payment_method_key = element.payment_method_key
              const method = payment_method_key?payment_method_key:payment_method_code
              let payment_method = null
              let payment_method_name = null
              if (!method || method == '0' || method == 'cash') {
                payment_method =  {name:"Cash",code:'0'}
                payment_method_name = "Cash"
              } else if (method == -1||method == '-3'||method == 'bill') {
                payment_method =  {name:"Bill",code:'-3'}
                payment_method_name = "Bill"
              } else {
                if (payment_method_key) {
                  payment_method = getters.getPaymentMethodByKey(payment_method_key)  
                } else {
                  payment_method = getters.getPaymentMethodByCode(payment_method_code)  
                }
                payment_method_name = payment_method?payment_method.name:"Unknown" 
                if (payment_method_name == 'Unknown') { 
                }
              }
              
              //Expense Account
              let expense_account_code = element.expense_account_code
              let expense_account = null
              if (toNumber(expense_account_code)>0) { 
                expense_account = getters.getExpenseAccountByCode(expense_account_code) 
              } else {
                expense_account = getters.getDefaultExpenseAccountByCode(expense_account_code)  
              }
              let expense_account_name = expense_account?expense_account.name:"Unknown" 
              const payables = element.payables
              const paid = element.paid
              const paid_amount = element.paid_amount
              let payment_status = "Paid"
              if (method == "bill") {
                if (payables) {
                  if (paid) {
                    payment_status = "Paid"
                  } else if (paid_amount) {
                    payment_status = "Half Paid"
                  } else {
                     payment_status = "Not Paid"
                  } 
                } else {
                  payment_status = "N/A"
                } 
              } else {
                payment_status = "Paid"
              }
              let newItem = {
                  ...element,
                  no:NUMBER,  
                  code:code,  
                  payment_status:payment_status,
                  
                  supplier:supplier,  
                  supplier_name:supplier_name,  
                  expense_account:expense_account,  
                  expense_account_name:expense_account_name,   
                  
                  payment_method:payment_method,  
                  payment_method_name:payment_method_name,    

                  amount_:toMoney(element.amount),    

                  expense_date_:toDateTime(element.expense_date), 
                  expense_date_date:toDate(element.expense_date),
                  expense_date_time:toTime(element.expense_date),
                  
                  created_at_:toDateTime(element.created_at), 
                  created_at_date:toDate(element.created_at),
                  created_at_time:toTime(element.created_at),
                  created_at_server_:toDateTime(element.created_at_server),

                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 
      join_expense_accounts({ commit,getters},payload) {
        let response = {
          from:'join_expense_accounts',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              let code = element.code 
              
              //Expense Account
              let expense_category_code = element.expense_category_code
              let expense_category = null
              if (toNumber(expense_category_code)<0) {
                expense_category = getters.getDefaultExpenseCategoryByCode(expense_category_code) 
              } else {
                expense_category = getters.getExpenseCategoryByCode(expense_category_code) 
              } 
              let expense_category_name = expense_category?expense_category.name:"Unknown" 

              let newItem = {
                  ...element,
                  no:NUMBER,  
                  code:code,   
                  
                   
                  expense_category:expense_category,  
                  expense_category_name:expense_category_name,   
                  
                  created_at_:toDateTime(element.created_at), 
                  created_at_date:toDate(element.created_at),
                  created_at_time:toTime(element.created_at),
                  created_at_server_:toDateTime(element.created_at_server),

                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 
      join_sale_order_items({ commit,getters},payload) {
        let response = {
          from:'join_sale_order_items',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              //order
              let order_key = element.order_key
              let order = getters.getStockOrderByKey(order_key)
              let order_name = order?order.name:'--' 
              
              //Item
              let item_key = element.item_key
              let item = getters.getItemByKey(item_key) 
              let item_name = item?item.name:'--'  
                  
              let newItem = {
                  ...element,
                  no:NUMBER, 
                  order:order,
                  order_name:order_name, 
                  item:item,
                  // join_item:join_item,
                  item_name:item_name,  
                  sold_price_:toMoney(element.sold_price), 
                  created_at_:toDateTime(element.created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 
      join_sale_orders({ commit,dispatch,getters},payload) {
        let response = {
          from:'join_sale_orders',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              let order_key = element.transaction_type == "sale_invoice_payment"?element.transaction_key:element.key

              //table
              let table_key = element.table_key
              let table = getters.getItemOrderOptionByKey(table_key) 
              let table_name = table?table.name:'--'  
              
              //waiter
              let waiter_key = element.waiter_key
              let waiter = getters.getItemOrderOptionByKey(waiter_key) 
              let waiter_name = waiter?waiter.name:'--' 
              let user_uid = waiter?waiter.uid:null
              
              let user = getters.getCompanyUserByKey(user_uid) 
              let user_name = user?user.surname+' '+user.names:'--'  
              
              //cashup
              let day_shift_key = element.day_shift_key
              let day_shift = getters.getDayShiftByKey(day_shift_key)  
              
              
              //cashup
              let cashup_key = element.cashup_key
              let cashups = day_shift?day_shift.cashups:null
              let cashup = cashups?cashups[cashup_key]:null
              let cashier_till_key = cashup?cashup.till_key:null
              let teller_key = cashup?cashup.uid:null
 
              
              //Till 
              let company = getters.getCompanyByKey(element.company_key)
              let company_name = company?company.company_name:'--'  
              
              //Till 
              let till_key = element.till_key
              let till_code = element.till_code
              let till = null
              if (till_key) {
                till = getters.getTillByKey(till_key) 
              }else if (till_code) {
                till = getters.getTillByCode(till_code) 
              }
              let till_name = till?till.name:'--'  
              let outlet_code = till?till.outlet_code:null
              
              //Cashier Till 
              let cashier_till = getters.getTillByKey(cashier_till_key)
              let cashier_till_name = till?till.name:'--'  
              
              //Cashier Till 
              let assistant_till_key = element?.assistant_till_key
              let assistant_till = getters.getTillByKey(assistant_till_key)
              let assistant_till_name = assistant_till?assistant_till.name:'--'  
              
              //category 
              let outlet = getters.getStockOutletByCode(outlet_code)
              let outlet_name = outlet?outlet.name:'--' 
              
              //Assistant
              let assistant_uid = element.assistant_uid
              let assistant = getters.getCompanyUserByKey(assistant_uid) 
              let assistant_name = assistant?assistant.surname+' '+assistant.names:'--'  
              
              //teller
              let teller_uid = element.created_uid
              let teller = getters.getCompanyUserByKey(teller_uid) 
              let teller_name = teller?teller.surname+' '+teller.names:'--'  
              
              //teller
              let updated_uid = element.updated_uid
              let updated_user = getters.getCompanyUserByKey(updated_uid) 
              let updated_user_name = updated_user?updated_user.surname+' '+updated_user.names:'--'  
              
              //customer
              let customer_key = element.customer_key
              let customer = getters.getCustomerByKey(customer_key) 
              let customer_name = customer?customer.name:null 
              let customer_names = customer?customer.names:'..' 
              let customer_surname = customer?customer.surname:'..' 
              customer_name = customer_name?customer_name:customer_names+" "+customer_surname
              
              //receivable
              let receivable_code = element.receivable_code
              let receivable_key = element.receivable_key
              let receivable = null
              let receivable_name ='Unknown' 

              if (receivable_key) {
                receivable = getters.getReceivableByKey(receivable_key) 
                receivable_name = receivable?receivable.name:'Unknown'  
              } else if(receivable_code){
                receivable = getters.getReceivableByCode(receivable_code) 
                receivable_name = receivable?receivable.name:'Unknown'  
                
              }

              // icon
              let icon_image = {
                icon:"mdi-invoice-list",
              }
              if (element.account_type == DATA.ITEMS.CARD_PAYMENTS.value) {
                
              }else if (element.account_type == DATA.ITEMS.CARD_PAYMENTS.value) {
                
              }

              //payment_method
              let input_payments = element.input_payments 
              let joined_input_payments = []
              let payment_method_code = element.payment_method_code?element.payment_method_code:element.payment_option_code
              let payment_method_key = element.payment_option_key
              let payment_method = null
              let payment_method_name = ''
              if (element.account_type == "multiple" && input_payments) { 
                input_payments.forEach((element,index) => {
                  const given_amount = toNumber(element.given_amount)
                  if (given_amount) {
                    if (element.key=='cash') {
                      const name = index?", Cash":"Cash"
                      payment_method_name= payment_method_name+name
                     joined_input_payments.push({
                        ...element,
                        name:"cash",
                        payment_method_name:"Cash"
                     }) 
                    }else{
                      const m = getters.getPaymentMethodByCode(element.code) 
                      const m_name = m?m.name:"Unknown" 
                      const name = index?", "+m_name:m_name
                      payment_method_name = payment_method_name+name
                      joined_input_payments.push({
                        ...m,
                        ...element,
                        payment_method_name:m_name
                     }) 
                    } 
                  }
                  
                });
                payment_method =  {
                  name:payment_method_name,
                  code:'multiple',
                  input_payments:input_payments,
                  joined_input_payments:joined_input_payments
                } 
                icon_image.icon = 'mdi-account-cash'  
              } else if (element.account_type == DATA.ITEMS.ON_ACCOUNTS.value) {
                payment_method =  {name:DATA.ITEMS.ON_ACCOUNTS.name,code:DATA.ITEMS.ON_ACCOUNTS.value}
                payment_method_name = DATA.ITEMS.ON_ACCOUNTS.name+' ('+receivable_name+')'
                icon_image.icon = 'mdi-cash-clock'
              } else if (element.account_type == DATA.ITEMS.CARD_PAYMENTS.value) {
                payment_method =  {name:DATA.ITEMS.CARD_PAYMENTS.name,code:DATA.ITEMS.CARD_PAYMENTS.value}
                payment_method_name = DATA.ITEMS.CARD_PAYMENTS.name
                icon_image.icon = 'mdi-credit-card'
              } else if (payment_method_key) {
                payment_method =  getters.getPaymentMethodByKey(payment_method_key) 
                payment_method_name = payment_method?payment_method.name:"Unknown Option" 
                icon_image.icon = 'mdi-account-cash'
              } else if (!payment_method_code || payment_method_code == '0') {
                payment_method =  {name:"Cash",code:'0'}
                payment_method_name = "Cash" 
                icon_image.icon = 'mdi-account-cash ' 
              } else if (payment_method_code == -1||payment_method_code == '-3') {
                payment_method =  {name:"Bill",code:'-3'}
                payment_method_name = "Bill" 
                icon_image.icon = 'mdi-account-cash ' 
              } else {
                payment_method = getters.getPaymentMethodByCode(payment_method_code) 
                payment_method_name = payment_method?payment_method.name:"Unknown" 
              }
              if (element.order_type == "quotation") {
                icon_image.icon = 'mdi-invoice' 
              }


              //calculate order items
              let key = element.key
              let sale_order_items =element.items?element.items:getters.getSaleOrderItemsByOrderKey(key)
              let joined_items=null
              try {
                let res = await dispatch("join",{
                  name:"ALL_"+DATA.ITEMS.ITEMS.values,
                  data:sale_order_items
                }) 
                let join_data = res?res.data:null
                joined_items = join_data?join_data.joined:null 
              } catch (error) {  
                console.log(error,'e........');
              }    
              let item_discounts = 0
              if (sale_order_items) {
                sale_order_items.forEach(item => {
                  let discount = toNumber(item.discount)
                  let quantity = toNumber(item.quantity)
                  item_discounts = item_discounts+(discount*quantity)
                });
              }
              const mix_sale_order_items = joined_items?joined_items:sale_order_items
              let sale_order_size = SIZE(sale_order_items)
              let total_cost_price = 0
              let total_sold_price = 0
              let total_quantity = 0
              let total_price = toNumber(element.total_price)
              let total_discount = toNumber(element.total_discount)
              let joined_items_departments = []
              let joined_items_dockets = []
              if (mix_sale_order_items) {
                  mix_sale_order_items.forEach(item => {
                    //-------ITEM DEPARTMENT 
                    const tip_department = {
                      name:"Tip",
                      key:'tip',
                      code:'tip'
                    }
                    let item_department = item.item_department?item.item_department:{code:'unknown',name:"Unknown"}
                    if (item.key == 'tip') {
                      item_department = tip_department
                    }
                    const d_index = joined_items_departments.findIndex(depart=>depart.code == item_department.code)
                    if (d_index == -1) { 
                      joined_items_departments.push({
                        ...item_department,
                        joined_items:[item]
                      })
                    } else {
                      joined_items_departments[d_index].joined_items.push(item)  
                    } 
                    
                    //-------ITEM DOCKETS 
                    const update_key = item.update_key?item.update_key:"unknown"
                    const docket_index = joined_items_dockets.findIndex(docket=>docket.update_key == update_key)
                    
                    // if(item.key=='tip'){

                    // }else
                    if (docket_index == -1) { 
                      joined_items_dockets.push({
                        update_key:update_key,
                        updated_at_:toDateTime(update_key), 
                        updated_at_date:toDate(update_key), 
                        updated_at_time:toTime(update_key), 
                        key:element.key,
                        company_key:element.company_key,
                        day_shift_key:element.day_shift_key,
                        cashup_key:element.cashup_key,
                        customer_key:element.customer_key,
                        waiter_key:element.waiter_key,
                        table_key:element.table_key,
                        joined_items:[item]
                      })
                    } else {
                      joined_items_dockets[docket_index].joined_items.push(item)  
                    } 

                    //
                    let sold_price = toNumber(item.sold_price)
                    let discount = toNumber(item.discount)
                    let quantity = toNumber(item.quantity)

                    //check cost
                    let sold_cost_price = toNumber(item.sold_cost_price)
                    let average_cost_price = toNumber(item.average_cost_price)
                    let cost_price = toNumber(item.cost_price)
                    //verify
                    let  mix_average_cost_price = average_cost_price?average_cost_price:cost_price
                    sold_cost_price = sold_cost_price?sold_cost_price:mix_average_cost_price

                    //calculate
                    let sold = quantity*(sold_price-discount)
                    let cost = sold_cost_price*quantity
                    total_sold_price = total_sold_price+sold
                    total_cost_price = total_cost_price+cost
                    total_quantity = total_quantity+quantity 
                  }); 
                  total_sold_price = total_sold_price-total_discount
              } 
               
              
              if (element.selected_card_option == 'load_money') {
                payment_method_name = "Load Money ("+payment_method_name+")"  
                total_quantity = 1
                sale_order_size = 1
                total_cost_price = element.paid_amount
                total_sold_price = element.paid_amount
                icon_image.icon = 'mdi-cash-plus' 
              }

              //receipt_number
              let receipt_number = element.order_key?element.order_key:order_key
              receipt_number = element.receiptNumber?element.receiptNumber:receipt_number
              receipt_number = element.ReceiptNumber?element.ReceiptNumber:receipt_number
              receipt_number = element.receipt_number?element.receipt_number:receipt_number
              receipt_number = element.receipt_number?element.receipt_number:receipt_number
               
              
              let receipt_number_ = toSameLength(order_key,5)
              if (element.order_id && element.order_id_generator!='timestamp') {
                receipt_number_ =receipt_number_+" ("+toSameLength(element.order_id,4)+")"
              }
              //-----------vat
              let calculated_vat = element?.calculated_vat
              let taxable_a = calculated_vat?.taxable_a
              let taxable_b = calculated_vat?.taxable_b
              let taxable_c = calculated_vat?.taxable_c
              let vat_a = calculated_vat?.vat_a
              let vat_b = calculated_vat?.vat_b
              let vat_c = calculated_vat?.vat_c
              let total_vat = calculated_vat?.total_vat
              let newItem = {
                  ...element,
                  no:NUMBER, 
                  order_key_number:element.key+'('+element.mkey+')', 

                  taxable_a:taxable_a,  
                  taxable_b:taxable_b,  
                  taxable_c:taxable_c,  
                  vat_a:vat_a,  
                  vat_b:vat_b,  
                  vat_c:vat_c,  
                  total_vat:total_vat,  
                  taxable_a_:toMoney(taxable_a),  
                  taxable_b_:toMoney(taxable_b),  
                  taxable_c_:toMoney(taxable_c),  
                  vat_a_:toMoney(vat_a),  
                  vat_b_:toMoney(vat_b),  
                  vat_c_:toMoney(vat_c),   
                  total_vat_:toMoney(total_vat),  
                   
                  table:table,
                  table_name:table_name,
                  waiter:waiter,
                  waiter_key:waiter_key,
                  waiter_name:waiter_name,
                  user:user,
                  user_name:user_name,
                  icon_image:icon_image,
                  day_shift:day_shift,
                  cashup:cashup,
                  outlet_name:outlet_name,
                  outlet:outlet,  
                  to:outlet_name, 
                  receipt_number:receipt_number, 
                  receipt_number_:receipt_number_,

                  payment_method:payment_method,  
                  payment_method_name:payment_method_name,  

                  company:company,  
                  company_name:company_name,  

                  receivable:receivable, 
                  receivable_name:receivable_name, 
                  till:till,  
                  till_name:till_name, 
                  cashier_till:cashier_till,  
                  cashier_till_name:cashier_till_name, 
                  assistant_till:assistant_till, 
                  assistant_till_name:assistant_till_name, 
                  assistant:assistant, 
                  assistant_name:assistant_name, 
                  teller:teller, 
                  teller_name:teller_name, 
                  updated_user:updated_user, 
                  updated_user_name:updated_user_name, 
                  customer:customer, 
                  customer_name:customer_name, 
                  customer_names:customer_names, 
                  customer_surname:customer_surname, 
                  joined_items:joined_items, 
                  joined_items_departments:joined_items_departments, 
                  joined_items_dockets:joined_items_dockets, 
                  items_size:sale_order_size, 
                  total_cost_price:total_cost_price, 
                  total_cost_price_:toMoney(total_cost_price), 
                  total_sold_price:total_sold_price, 
                  total_sold_price_:toMoney(total_sold_price), 
                  sum_amount_:toMoney(element.sum_amount), 
                  given_amount_:toMoney(element.given_amount), 
                  total_discount_:toMoney(total_discount), 
                  item_discounts:item_discounts, 
                  item_discounts_:toMoney(item_discounts), 
                  all_discounts:total_discount+item_discounts, 
                  all_discounts_:toMoney(total_discount+item_discounts), 
                  total_quantity:total_quantity,  
                  total_quantity_:total_quantity+"/"+sale_order_size,  
                  code_:toSameLength(element.code),
                  created_at_:toDateTime(element.created_at), 
                  created_at_date:toDate(element.created_at), 
                  created_at_time:toTime(element.created_at), 
                  local_created_at_:toDateTime(element.local_created_at), 
                  local_created_at_date:toDate(element.local_created_at), 
                  local_created_at_time:toTime(element.local_created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  created_at_server_date:toDate(element.created_at_server),
                  created_at_server_time:toTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 
      join_sale_return({ commit,dispatch,getters},payload) {
        let response = {
          from:'join_sale_return',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){

              //Customer
             //customer
             let customer_key = element.customer_key
             let customer = getters.getCustomerByKey(customer_key) 
             let customer_name = customer?customer.name:null 
             let customer_names = customer?customer.names:'..' 
             let customer_surname = customer?customer.surname:'..' 
             customer_name = customer_name?customer_name:customer_names+" "+customer_surname
              
              //Return
              let return_uid = element.return_uid 
              let return_user = getters.getCompanyUserByKey(return_uid) 
              let return_user_name = return_user?return_user.surname+' '+return_user.names:'--'  
              
              //cashup
              let day_shift_key = element.day_shift_key
              let day_shift = getters.getDayShiftByKey(day_shift_key)  
              
              
              //cashup
              let cashup_key = element.cashup_key
              let cashups = day_shift?day_shift.cashups:null
              let cashup = cashups?cashups[cashup_key]:null
              let till_key = cashup?cashup.till_key:null
              let teller_key = cashup?cashup.uid:null
 
              
              //Till 
              let company = getters.getCompanyByKey(element.company_key)
              let company_name = company?company.company_name:'--'  
              
              //Till 
              let till = getters.getTillByKey(till_key)
              let till_name = till?till.name:'--' 
              let till_code = till?till.code:null  
              let outlet_code = till?till.outlet_code:null
              
              //category 
              let outlet = getters.getStockOutletByCode(outlet_code)
              let outlet_name = outlet?outlet.name:'--' 
              
              //teller 
              let teller = getters.getCompanyUserByKey(teller_key) 
              let teller_name = teller?teller.surname+' '+teller.names:'--'  
              
              //teller
              let updated_uid = element.updated_uid
              let updated_user = getters.getCompanyUserByKey(updated_uid) 
              let updated_user_name = updated_user?updated_user.surname+' '+updated_user.names:'--'  
              
            
 
              // icon
              let icon_image = {
                icon:"mdi-invoice-list",
              }
              

              //payment_method 
              let payment_method_code = element.payment_method_code?element.payment_method_code:element.payment_option_code
              let payment_method_key = element.payment_method_key
              let payment_method = null
              let payment_method_name = ''
              if (payment_method_key) {
                payment_method =  getters.getPaymentMethodByKey(payment_method_key) 
                payment_method_name = payment_method?payment_method.name:"Unknown Option" 
                icon_image.icon = 'mdi-account-cash'
              }else {
                payment_method = getters.getPaymentMethodByCode(payment_method_code) 
                payment_method_name = payment_method?payment_method.name:"Unknown" 
              }  


              //calculate order items
              let key = element.key
              let return_items =element.items
              let joined_items=null
              try {
                let res = await dispatch("join",{
                  name:"ALL_"+DATA.ITEMS.ITEMS.values,
                  data:return_items
                }) 
                let join_data = res?res.data:null
                joined_items = join_data?join_data.joined:null 
              } catch (error) {  
                console.log(error,'e........');
              }    
              let item_discounts = 0
              if (return_items) {
                return_items.forEach(item => {
                  let discount = toNumber(item.discount)
                  let quantity = toNumber(item.quantity)
                  item_discounts = item_discounts+(discount*quantity)
                });
              } 
              let return_items_size = SIZE(return_items)
            
               
              
               
              let newItem = {
                  ...element,
                  no:NUMBER,   

                  return_user:return_user,
                  return_user_name:return_user_name,
                  icon_image:icon_image,
                  day_shift:day_shift,
                  cashup:cashup,
                  outlet_name:outlet_name,
                  outlet:outlet,  
                  to:outlet_name,  

                  payment_method:payment_method,  
                  payment_method_name:payment_method_name,  

                  company:company,  
                  company_name:company_name,  

                  customer:customer, 
                  customer_name:customer_name, 
                  customer_names:customer_names, 
                  customer_surname:customer_surname, 

                  till:till, 
                  till_key:till_key, 
                  till_code:till_code, 
                  till_name:till_name, 
                  teller:teller, 
                  teller_name:teller_name, 
                  updated_user:updated_user, 
                  updated_user_name:updated_user_name,  
                  joined_items:joined_items, 
                  items_size:return_items_size,  
                   
                  total_refund_amount_:toMoney(element.total_refund_amount),  
                  sum_amount_:toMoney(element.sum_amount), 
                  given_amount_:toMoney(element.given_amount), 
                   
                  code_:toSameLength(element.code),
                  created_at_:toDateTime(element.created_at), 
                  created_at_date:toDate(element.created_at), 
                  created_at_time:toTime(element.created_at), 
                  local_created_at_:toDateTime(element.local_created_at), 
                  local_created_at_date:toDate(element.local_created_at), 
                  local_created_at_time:toTime(element.local_created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  created_at_server_date:toDate(element.created_at_server),
                  created_at_server_time:toTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 
      join_stock_orders({ commit,dispatch,getters},payload) {
        let response = {
          from:'join_stock_orders',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              if (!element.created_at) {
                element.created_at = element.updated_at
              }if (!element.created_at_server) {
                element.created_at_server = element.updated_at_server
              }
              //category
              let created_uid = element.created_uid 
              let all_users = users.state.USERS
              let user = all_users?all_users.find(us=>us.uid==created_uid):null 
              let user_name = user?user.names+' '+user.surname:'--' 
 
              
              //category
              let deliver_to_outlet_code = element.deliver_to_outlet_code?element.deliver_to_outlet_code:element.outlet_code
              let deliver_to_outlet = getters.getStockOutletByCode(deliver_to_outlet_code)
              let deliver_to_outlet_name = deliver_to_outlet?deliver_to_outlet.name:'--' 
              
              //supplier
              let supplier_code = element.supplier_code
              let supplier = getters.getSupplierByCode(supplier_code) 
              let supplier_name = supplier?supplier.name:'--' 

              //payment_method
              const payment_method_code = element.payment_method_code
              const payment_method_key = element.payment_method_key
              const method = payment_method_key?payment_method_key:payment_method_code

              let payment_method_old = element.payment_method
              let payment_method = null
              let payment_method_name = null
              if (TEXT_UP0(payment_method_old) == "bill"||TEXT_UP0(method) == "bill"||method == -1||method == '-3') {
                payment_method =  {name:"Bill",code:'bill'}
                payment_method_name = "Bill"
              }else if (TEXT_UP0(method) == "petty_cash" || method == '-2') {
                payment_method =  {name:"Petty Cash",code:'petty_cash'}
                payment_method_name = "Petty Cash"
              }else if (!method || TEXT_UP0(method) == "cash"|| method == '0') {
                payment_method =  {name:"Cash",code:'cash'}
                payment_method_name = "Cash"
              }else {
                if (payment_method_key) {
                  payment_method = getters.getPaymentMethodByKey(payment_method_key)  
                } else {
                  payment_method = getters.getPaymentMethodByCode(payment_method_code)  
                } 
                payment_method_name = payment_method?payment_method.name:"Unknown" 
              }

              if (!element.payment_method_code && payment_method) {
                element.payment_method_code=payment_method.code
              }

              //calculate order items
              let key = element.key
              let joined_items = null
              if (!element.items) {
                // to do
                joined_items = getters.getStockOrderItemsByOrderKey(key)  
              }else{ 
                let res =  await dispatch("join",{
                  name:"ALL_"+DATA.ITEMS.ITEMS.values,
                  data:element.items,
                  add:false 
                })
                let res_data = res?res.data:null
                joined_items = res_data?res_data.joined:null
                joined_items = res_data?res_data.joined:null 
              }
              let items_size = SIZE(element.items)
              let total_price = 0
              let total_quantity = 0
              if (joined_items) {
                  joined_items.forEach(item => {
                      let price = toNumber(item.price)
                      let discount = toNumber(item.discount)
                      let quantity = toNumber(item.quantity)
                      let cost = quantity*(price-discount)
                      total_price = total_price+cost
                      total_quantity = total_quantity+quantity

                  }); 
              }

              let icon_name = "mdi-cart-outline"
              let type_name = ''
              let deliver_from = "--"
              let deliver_to = "--"
              if (element.order_type == DATA.ITEMS.STOCK_ORDERS.value) {
                  icon_name = "mdi-cart"
                  type_name = DATA.ITEMS.STOCK_ORDERS.name
                  deliver_from = supplier_name
                  deliver_to = deliver_to_outlet_name
              }else if (element.order_type == DATA.ITEMS.STOCK_RECEIVES.value) {
                  icon_name = "mdi-cart-check"
                  type_name = DATA.ITEMS.STOCK_RECEIVES.name
                  deliver_from = supplier_name
                  deliver_to = deliver_to_outlet_name
              }else if (element.order_type == DATA.ITEMS.OPENING_STOCK.value) {
                  icon_name = "mdi-cart-check"
                  type_name = DATA.ITEMS.OPENING_STOCK.name
                  deliver_from = supplier_name
                  deliver_to = deliver_to_outlet_name
              }else if (element.order_type == DATA.ITEMS.STOCKS_RETURN.value) {
                  icon_name = "mdi-cart-remove"
                  type_name = DATA.ITEMS.STOCKS_RETURN.name
                  deliver_from = supplier_name
                  deliver_to = deliver_to_outlet_name
              }else if (element.order_type == DATA.ITEMS.STOCK_TRANSFERS.value) {
                  icon_name = "mdi-cart-arrow-right"
                  type_name = DATA.ITEMS.STOCK_TRANSFERS.name
                  deliver_from = supplier_name
                  deliver_to = deliver_to_outlet_name
              }else if (element.order_type == DATA.ITEMS.RETURNED_SALES.value) {
                  icon_name = "mdi-cart-arrow-right"
                  type_name = DATA.ITEMS.RETURNED_SALES.name
                  deliver_from = supplier_name
                  deliver_to = deliver_to_outlet_name
              }else if (element.order_type == DATA.ITEMS.STOCK_ADJUSTMENTS.value) {
                  icon_name = "mdi-cart-arrow-right"
                  type_name = DATA.ITEMS.STOCK_ADJUSTMENTS.name
                  deliver_from = supplier_name
                  deliver_to = deliver_to_outlet_name
              }else if (element.order_type == DATA.ITEMS.INVOICES.value) {
                icon_name = "mdi-file-certificate-outline "
                type_name = DATA.ITEMS.INVOICES.name
                deliver_from = supplier_name
                deliver_to = deliver_to_outlet_name
              }else if (element.order_type == DATA.ITEMS.QUOTATIONS.value) {
                icon_name = "mdi-file-check-outline"
                type_name = DATA.ITEMS.QUOTATIONS.name
              }

              const payables = element.payables
              const paid = element.paid
              const paid_amount = element.paid_amount
              let payment_status = "Paid"
              if (method == "bill") {
                if (payables) {
                  if (paid) {
                    payment_status = "Paid"
                  } else if (paid_amount) {
                    payment_status = "Half Paid"
                  } else {
                     payment_status = "Not Paid"
                  } 
                } else {
                  payment_status = "N/A"
                } 
              } else {
                payment_status = "Paid"
              }

              let newItem = {
                  ...element,
                  no:NUMBER, 
                  user:user,
                  payment_status:payment_status,
                  user_name:user_name, 
                  supplier:supplier,
                  supplier_name:supplier_name, 
                  deliver_to_outlet:deliver_to_outlet, 
                  deliver_to_outlet_name:deliver_to_outlet_name,
                  deliver_from:deliver_from, 
                  deliver_to:deliver_to, 
                  icon:icon_name, 
                  type_name:type_name, 
                  joined_items:joined_items, 
                  items_size:items_size, 
                  total_price:total_price, 
                  payment_method:payment_method, 
                  payment_method_name:payment_method_name, 
                  total_price_:toMoney(total_price), 
                  total_quantity:total_quantity,  
                  total_quantity_:total_quantity+"/"+items_size,  
                  code_:toSameLength(element.code),
                  created_at_:toDateTime(element.created_at), 
                  created_at_date:toDate(element.created_at),
                  created_at_time:toTime(element.created_at),
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 
      join_quotation_and_invoice({ commit,dispatch,getters},payload) {
        let response = {
          from:'join_quotation_and_invoice',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              //category
              let outlet_code = element.outlet_code
              let outlet = getters.getStockOutletByCode(outlet_code)
              let outlet_name = outlet?outlet.name:'--' 
              
              //supplier
              let supplier_code = element.supplier_code
              let supplier = getters.getSupplierByCode(supplier_code) 
              let supplier_name = supplier?supplier.name:'--' 

              //payment_method
              //payment_method
              const payment_method_code = element.payment_method_code
              const payment_method_key = element.payment_method_key
              const method = payment_method_key?payment_method_key:payment_method_code
                
              let payment_method_old = element.payment_method
              let payment_method = null
              let payment_method_name = null
              if (TEXT_UP0(payment_method_old) == "bill"||TEXT_UP0(method) == "bill"||method == -1||method == '-3') {
                payment_method =  {name:"Bill",code:'bill'}
                payment_method_name = "Bill"
              }else if (TEXT_UP0(method) == "petty_cash" || method == '-2') {
                payment_method =  {name:"Petty Cash",code:'petty_cash'}
                payment_method_name = "Petty Cash"
              }else if (!method || TEXT_UP0(method) == "cash"|| method == '0') {
                payment_method =  {name:"Cash",code:'cash'}
                payment_method_name = "Cash"
              }else {
                if (payment_method_key) {
                  payment_method = getters.getPaymentMethodByKey(payment_method_key)  
                } else {
                  payment_method = getters.getPaymentMethodByCode(payment_method_code)  
                } 
                payment_method_name = payment_method?payment_method.name:"Unknown" 
              }

              if (!element.payment_method_code && payment_method) {
                element.payment_method_code=payment_method.code
              }

              //calculate order items 
              let items = element.items
              let items_size = SIZE(items)
              let total_price = 0
              let total_quantity = 0
              if (items) {
                  items.forEach(item => {
                      let price = toNumber(item.price)
                      let discount = toNumber(item.discount)
                      let quantity = toNumber(item.quantity)
                      let sold = quantity*(price-discount)
                      total_price = total_price+sold
                      total_quantity = total_quantity+quantity 
                  }); 
              }  
              let res =  await dispatch("join",{
                name:"ALL_"+DATA.ITEMS.ITEMS.values,
                data:items,
                add:false 
              })
              let res_data = res?res.data:null
              let joined_items = res_data?res_data.joined:null



              let icon_name = "mdi-file-clock"
              let type_name = ''
              if (element.order_type == DATA.ITEMS.REQUEST_FOR_QUOTATIONS.value) {
                  icon_name = "mdi-file-clock-outline"
                  type_name = DATA.ITEMS.REQUEST_FOR_QUOTATIONS.name
              }else if (element.order_type == DATA.ITEMS.QUOTATIONS.value) {
                  icon_name = "mdi-file-check-outline"
                  type_name = DATA.ITEMS.QUOTATIONS.name
              }else if (element.order_type == DATA.ITEMS.INVOICES.value) {
                  icon_name = "mdi-file-certificate-outline "
                  type_name = DATA.ITEMS.INVOICES.name
              }else if (element.order_type == DATA.ITEMS.STOCK_RECEIVES.value) {
                  icon_name = "mdi-file-certificate-outline " 
                  if (TEXT_UP0(element.payment_method)=="bill") {
                      type_name = DATA.ITEMS.STOCK_RECEIVES.name+'d (Invoice)' 
                  }else if (TEXT_UP0(element.payment_method)=="cash") {
                      type_name = DATA.ITEMS.STOCK_RECEIVES.name+'d (Cash)' 
                  }else if (TEXT_UP0(element.payment_method)=="check") {
                      type_name = DATA.ITEMS.STOCK_RECEIVES.name+'d (Check)' 
                  }else{
                      type_name = DATA.ITEMS.STOCK_RECEIVES.name+'d (Credit Card)' 
                  } 
              } 

              const payables = element.payables
              const paid = element.paid
              const paid_amount = element.paid_amount
              let payment_status = "Paid"
              if (method == "bill") {
                if (payables) {
                  if (paid) {
                    payment_status = "Paid"
                  } else if (paid_amount) {
                    payment_status = "Half Paid"
                  } else {
                     payment_status = "Not Paid"
                  } 
                } else {
                  payment_status = "N/A"
                } 
              } else {
                payment_status = "Paid"
              }



              let newItem = {
                  ...element,
                  no:NUMBER,  
                  outlet_name:outlet_name,
                  outlet:outlet,  
                  to:outlet_name, 
                  supplier:supplier, 
                  supplier_name:supplier_name, 
                  from:supplier_name,
                  joined_items:joined_items, 
                  items_size:items_size, 
                  total_price:total_price, 
                  total_price_:toMoney(total_price), 
                  total_quantity:total_quantity,  
                  total_quantity_:total_quantity+"/"+items_size,   
                  icon:icon_name,
                  type_name:type_name,
                  payment_status:payment_status,
                  payment_method:payment_method,
                  payment_method_name:payment_method_name,
                  created_at_:toDateTime(element.created_at), 
                  created_at_date:toDate(element.created_at),
                  created_at_time_:toTime(element.created_at),
                  local_created_at_:toDateTime(element.local_created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 
      join_sale_order_invoice({ commit,dispatch,getters},payload) {
        let response = {
          from:'join_sale_order_invoice',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              let icon_image = { 
                icon:mdiInvoiceList,
              }
              let sale_order_res = await dispatch("join_sale_orders",{
                ...payload
              })
              let sale_order_data = sale_order_res?sale_order_res.data:null
              let sum_amount = toNumber(element.sum_amount)
              let invoice_paid_amount = toNumber(element.invoice_paid_amount) 
              let invoice_balance_amount =  sum_amount-invoice_paid_amount
              let before_invoice_paid_amount = toNumber(element.before_invoice_paid_amount) 
              let before_invoice_balance_amount = toNumber(element.before_invoice_balance_amount)  

              let receivable_balance_amount = toNumber(element.receivable_balance_amount) 
              let receivable_paid_amount = toNumber(element.receivable_paid_amount) 
              let receivable_unpaid_amount = toNumber(element.receivable_unpaid_amount)  
              let payment_balance_amount = toNumber(element.payment_balance_amount) 
              let payment_paid_amount = toNumber(element.payment_paid_amount) 
              let payment_unpaid_amount = toNumber(element.payment_unpaid_amount) 
              
              let before_receivable_balance_amount = toNumber(element.before_receivable_balance_amount) 
              let before_receivable_paid_amount = toNumber(element.before_receivable_paid_amount) 
              let before_receivable_unpaid_amount = toNumber(element.before_receivable_unpaid_amount)  
              let before_payment_balance_amount = toNumber(element.before_payment_balance_amount) 
              let before_payment_paid_amount = toNumber(element.before_payment_paid_amount) 
              let before_payment_unpaid_amount = toNumber(element.before_payment_unpaid_amount) 

              if (invoice_paid_amount) {
                icon_image.icon = mdiInvoiceTextArrowRight
              }
              if (element.full_paid) {
                icon_image.icon = mdiInvoiceTextCheck
              }
              
              let newItem = {
                  ...sale_order_data,
                  no:NUMBER,  
                  icon_image:icon_image,
                  receivable_balance_amount:receivable_balance_amount,
                  receivable_paid_amount:receivable_paid_amount,
                  receivable_unpaid_amount:receivable_unpaid_amount,
                  payment_balance_amount:payment_balance_amount,
                  payment_paid_amount:payment_paid_amount,
                  payment_unpaid_amount:payment_unpaid_amount,
                  
                  before_receivable_balance_amount:before_receivable_balance_amount,
                  before_receivable_paid_amount:before_receivable_paid_amount,
                  before_receivable_unpaid_amount:before_receivable_unpaid_amount,
                  before_payment_balance_amount:before_payment_balance_amount,
                  before_payment_paid_amount:before_payment_paid_amount,
                  before_payment_unpaid_amount:before_payment_unpaid_amount,
 
                  sum_amount_:toMoney(sum_amount),
                  amount_:toMoney(element.amount),
                  invoice_paid_amount_:toMoney(invoice_paid_amount),
                  invoice_balance_amount:invoice_balance_amount,
                  invoice_balance_amount_:toMoney(invoice_balance_amount), 
                  before_invoice_paid_amount_:toMoney(before_invoice_paid_amount),
                  before_invoice_balance_amount_:toMoney(before_invoice_balance_amount), 
                  created_at_:toDateTime(element.created_at), 
                  created_at_date:toDate(element.created_at),
                  created_at_time_:toTime(element.created_at),
                  local_created_at_:toDateTime(element.local_created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 
      join_sale_order_payment({ commit,dispatch,getters},payload) {
        let response = {
          from:'join_sale_order_payment',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){ 
              let code = element.code
              let code_ = toSameLength(code,5)
              let transaction_type = element.transaction_type
              let icon_image = {
                icon:mdiInvoiceTextArrowRight 
              }
              
              //DAY SHIFT
              let day_shift_key = element.day_shift_key
              let day_shift = getters.getDayShiftByKey(day_shift_key)  
              let day_company_key = day_shift?day_shift.company_key:null

              if (!day_shift_key && transaction_type == "adjust_receivable_account_balance") {
                day_shift_key = "adjust balance"
                icon_image.icon = mdiInvoiceTextEdit
              }if (transaction_type == "receivable_account_payment") { 
                icon_image.icon = mdiInvoiceTextMultiple
              }

              //cashup
              let cashup_key = element.cashup_key
              let cashups = day_shift?day_shift.cashups:null
              let cashup = cashups?cashups[cashup_key]:null
              let till_key = cashup?cashup.till_key:null
              let teller_key = cashup?cashup.uid:null

              //COMPANY 
              let company_key = element.company_key?element.company_key:day_company_key
              let company = getters.getCompanyByKey(company_key)
              let company_name = company?company.company_name:'--'  
              
              //Till 
              let till = getters.getTillByKey(till_key)
              let till_name = till?till.name:'--' 
              let till_code = till?till.code:null  
              let outlet_code = till?till.outlet_code:null

              //category 
              let outlet = getters.getStockOutletByCode(outlet_code)
              let outlet_name = outlet?outlet.name:'--' 
              
              //teller
              let teller_uid = element.created_uid
              let teller = getters.getCompanyUserByKey(teller_uid) 
              let teller_name = teller?teller.surname+' '+teller.names:'--'  

              //customer
              let customer_key = element.customer_key
              let customer = getters.getCustomerByKey(customer_key) 
              let customer_name = customer?customer.name:null 
              let customer_names = customer?customer.names:'..' 
              let customer_surname = customer?customer.surname:'..' 
              customer_name = customer_name?customer_name:customer_names+" "+customer_surname
              
              //receivable
              let receivable_key = element.receivable_key
              let receivable = getters.getReceivableByKey(receivable_key) 
              let receivable_name = receivable?receivable.name:'Unknown'  

              let payment_option_key = element.payment_option_key
              let payment_method = getters.getPaymentMethodByKey(payment_option_key) 
              let payment_method_name = payment_method?payment_method.name:'Unknown' 
              
              let money_account_key = element.money_account_key
              let money_account = getters.getMoneyAccountByKey(money_account_key) 
              let money_account_name = money_account?money_account.name:'Unknown'  

              let sum_amount = toNumber(element.sum_amount)
              let invoice_paid_amount = toNumber(element.invoice_paid_amount) 
              let invoice_balance_amount = sum_amount-invoice_paid_amount 
              
              let paid_invoices = element.paid_invoices
              let invoices = []
              let joined_paid_invoices = []
              

              if (paid_invoices) {
                for (const invoice of paid_invoices) {
                  if (element.full_paid) {
                    icon_image.icon = mdiInvoiceTextCheck
                  }
                  invoices.push({
                    ...element,
                    ...invoice,
                    key:invoice.transaction_key,
                    local_created_at:invoice.transaction_key,
                    icon_image:icon_image,
                  })
                }
              } 
              if (element.full_paid) {
                icon_image.icon = mdiInvoiceTextCheck
              } 
              if (transaction_type == "sale_invoice_payment") {
                invoices.push({
                  ...element,
                  key:element.transaction_key,
                  local_created_at:element.transaction_key,
                  icon_image:icon_image,
                
                }) 
              } 
              
              let paid_invoices_size = SIZE(invoices)
              
              if (invoices) {
                for (const invoice of invoices) {
                  let sale_order_res = await dispatch("join_sale_order_invoice",{
                    data:invoice
                  })   
                  let data = sale_order_res?sale_order_res.data:null
                  if (data) {
                    joined_paid_invoices.push(data)
                  }
                }
              }  

              let pay_to = receivable_name
              if (customer) {
                pay_to = pay_to+" ("+customer_name+")"
              }

              let receivable_balance_amount = toNumber(element.receivable_balance_amount) 
              let receivable_paid_amount = toNumber(element.receivable_paid_amount) 
              let receivable_unpaid_amount = toNumber(element.receivable_unpaid_amount)  
              let payment_balance_amount = toNumber(element.payment_balance_amount) 
              let payment_paid_amount = toNumber(element.payment_paid_amount) 
              let payment_unpaid_amount = toNumber(element.payment_unpaid_amount) 
              
              let before_receivable_balance_amount = toNumber(element.before_receivable_balance_amount) 
              let before_receivable_paid_amount = toNumber(element.before_receivable_paid_amount) 
              let before_receivable_unpaid_amount = toNumber(element.before_receivable_unpaid_amount)  
              let before_payment_balance_amount = toNumber(element.before_payment_balance_amount) 
              let before_payment_paid_amount = toNumber(element.before_payment_paid_amount) 
              let before_payment_unpaid_amount = toNumber(element.before_payment_unpaid_amount) 
              
              let newItem = {
                  ...element,
                  no:NUMBER,  
                  icon_image:icon_image,
                  code_:code_,
                  payment_number:day_shift_key+" ("+code_+")",
                  day_shift:day_shift,
                  cashup:cashup,
                  outlet_name:outlet_name,
                  outlet:outlet, 
                  payment_method:payment_method,  
                  payment_method_name:payment_method_name,  
                  joined_paid_invoices:joined_paid_invoices,  
                  paid_invoices_size:paid_invoices_size,  

                  company:company,  
                  company_name:company_name,  

                  receivable:receivable, 
                  receivable_name:receivable_name, 
                  till:till, 
                  till_key:till_key, 
                  till_code:till_code, 
                  till_name:till_name, 
                  teller:teller, 
                  teller_name:teller_name, 
                  customer:customer, 
                  customer_name:customer_name, 
                  customer_names:customer_names, 
                  customer_surname:customer_surname, 
                  pay_to:pay_to, 

                  receivable_balance_amount:receivable_balance_amount,
                  receivable_paid_amount:receivable_paid_amount,
                  receivable_unpaid_amount:receivable_unpaid_amount,
                  payment_balance_amount:payment_balance_amount,
                  payment_paid_amount:payment_paid_amount,
                  payment_unpaid_amount:payment_unpaid_amount,
                  
                  before_receivable_balance_amount:before_receivable_balance_amount,
                  before_receivable_paid_amount:before_receivable_paid_amount,
                  before_receivable_unpaid_amount:before_receivable_unpaid_amount,
                  before_payment_balance_amount:before_payment_balance_amount,
                  before_payment_paid_amount:before_payment_paid_amount,
                  before_payment_unpaid_amount:before_payment_unpaid_amount,

                  invoice_balance_amount:invoice_balance_amount,
                  invoice_balance_amount_:toMoney(invoice_balance_amount),
                  sum_amount_:toMoney(sum_amount),
                  amount_:toMoney(element.amount),
                  invoice_paid_amount_:toMoney(invoice_paid_amount),
                  created_at_:toDateTime(element.created_at), 
                  created_at_date:toDate(element.created_at),
                  created_at_time_:toTime(element.created_at),
                  local_created_at_:toDateTime(element.local_created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  created_at_server_date:toDate(element.created_at_server),
                  created_at_server_time:toTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 
      join_expiry({ commit,dispatch,getters},payload) {
        let response = {
          from:'join_quotation_and_invoice',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              //category
              let item_key = element.item_key
              let item = getters.getItemByKey(item_key)
              let item_name = item?item.name:'--' 
              let expiring_date = element.expiring_date
              let manufacturing_date = element.manufacturing_date
              let diff_days = "--"
              let expired = false
              let icon = "mdi-cart-heart"  
              if (expiring_date) {
                diff_days = moment(expiring_date).diff(moment(),'days')  
                expired = moment(expiring_date).isSameOrBefore(moment())
                icon = expired?"mdi-recycle":"mdi-cart-heart"  
              }
              
              let newItem = {
                  ...element,
                  no:NUMBER,  
                  item:item, 
                  item_name:item_name, 
                  diff_days:diff_days, 
                  expired:expired, 
                  icon:icon, 
                  manufacturing_date_:toDate(element.manufacturing_date), 
                  expiring_date_:toDate(element.expiring_date), 
                  created_at_:toDateTime(element.created_at), 
                  created_at_date:toDate(element.created_at),
                  created_at_time_:toTime(element.created_at),
                  updated_at_:toDateTime(element.updated_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 
      join_payment_methods({ commit,dispatch,getters},payload) {
        let response = {
          from:'join_payment_methods',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              //money account
              let money_account_code = element.money_account_code
              let money_account = getters.getMoneyAccountByCode(money_account_code)
              let money_account_name = money_account?money_account.name:'--' 

               
              
              let newItem = {
                  ...element,
                  no:NUMBER,   
                  money_account:money_account, 
                  money_account_name:money_account_name, 
                   
                  created_at_:toDateTime(element.created_at), 
                  created_at_date:toDate(element.created_at),
                  created_at_time_:toTime(element.created_at),
                  updated_at_:toDateTime(element.updated_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 
      join_money_accounts({ commit,dispatch,getters},payload) {
        let response = {
          from:'join_money_accounts',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              //category
              let item_key = element.item_key
              let item = getters.getItemByKey(item_key)
              let item_name = item?item.name:'--' 
              let expiring_date = element.expiring_date
              let manufacturing_date = element.manufacturing_date
              let diff_days = "--"
              let expired = false
              let icon = "mdi-cart-heart"  
              if (expiring_date) {
                diff_days = moment(expiring_date).diff(moment(),'days')  
                expired = moment(expiring_date).isSameOrBefore(moment())
                icon = expired?"mdi-recycle":"mdi-cart-heart"  
              }
              
              let newItem = {
                  ...element,
                  no:NUMBER,  
                  item:item, 
                  item_name:item_name, 
                  diff_days:diff_days, 
                  expired:expired, 
                  icon:icon, 
                  manufacturing_date_:toDate(element.manufacturing_date), 
                  expiring_date_:toDate(element.expiring_date), 
                  created_at_:toDateTime(element.created_at), 
                  created_at_date:toDate(element.created_at),
                  created_at_time_:toTime(element.created_at),
                  updated_at_:toDateTime(element.updated_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      },
      join_receivables({ commit,dispatch,getters},payload) {
        let response = {
          from:'join_receivables',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              //category
              let receivable_paid_amount = toNumber(element.receivable_paid_amount)
              let receivable_unpaid_amount = toNumber(element.receivable_unpaid_amount) 
              let receivable_balance_amount = toNumber(element.receivable_balance_amount) 
               
              
              let newItem = {
                  ...element,
                  no:NUMBER,   
                  receivable_paid_amount_:toMoney(receivable_paid_amount), 
                  receivable_unpaid_amount_:toMoney(receivable_unpaid_amount), 
                  receivable_balance_amount_:toMoney(receivable_balance_amount), 
                  created_at_:toDateTime(element.created_at), 
                  created_at_date:toDate(element.created_at),
                  created_at_time_:toTime(element.created_at),
                  updated_at_:toDateTime(element.updated_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 
      join_payables({ commit,dispatch,getters},payload) {
        let response = {
          from:'join_payables',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){ 
               //customer
               let customer_key = element.customer_key
               let customer = getters.getCustomerByKey(customer_key) 
               let customer_name = customer?customer.name:'Unknown' 

               //supplier
               let supplier_code = element.supplier_code
               let supplier = getters.getSupplierByCode(supplier_code) 
               let supplier_name = supplier?supplier.name:'--' 

               let payee = null
               let payee_name = null
               let transaction_type_name = null
               let icon = "mdi-cash-clock"  
               if (element.transaction_type == DATA.ITEMS.INVOICES.value) {
                  payee = supplier
                  payee_name = supplier_name
                  transaction_type_name = DATA.ITEMS.INVOICES.name
                  icon = "invoice-text"  
              }else if (element.transaction_type == DATA.ITEMS.STOCK_RECEIVES.value) {
                  payee = supplier
                  payee_name = supplier_name
                  transaction_type_name = DATA.ITEMS.STOCK_RECEIVES.name
                  icon = "mdi-cash-clock"  
              }else if (element.transaction_type == DATA.ITEMS.EXPENSES.value) {
                  payee = supplier
                  payee_name = supplier_name
                  transaction_type_name = DATA.ITEMS.EXPENSES.name
                  icon = "mdi-calculator-variant"  
              }else if (element.transaction_type == DATA.ITEMS.RETURNED_SALES.value) {
                  payee = supplier
                  payee_name = supplier_name
                  transaction_type_name = DATA.ITEMS.RETURNED_SALES.name
                  icon = "mdi-cart-off"  
              }else {
                  payee = supplier
                  payee_name = supplier_name
                  transaction_type_name = "N/A"
              }


               const paid_amount = toNumber(element.paid_amount)
               const amount = toNumber(element.amount)
               const balance_amount = amount-paid_amount
               
              
              let newItem = {
                  ...element,
                  no:NUMBER,    
                  icon:icon,    
                  supplier:supplier,    
                  supplier_name:supplier_name,    
                  customer:customer,    
                  customer_name:customer_name,  
                  payee:payee,  
                  payee_name:payee_name,   
                  transaction_type_name:transaction_type_name,  
                  balance_amount:balance_amount,    
                  balance_amount_ : toMoney(element.balance_amount),
                  paid_amount_ : toMoney(element.paid_amount),
                  amount_ : toMoney(element.amount),
                  bill_due_date_ : toDate(element.bill_due_date),
                  created_at_:toDateTime(element.created_at), 
                  created_at_date:toDate(element.created_at),
                  created_at_time_:toTime(element.created_at),
                  updated_at_:toDateTime(element.updated_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 
      join_stock_taking_orders({ commit,dispatch,getters},payload) {
        let response = {
          from:'join_stock_taking_orders',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){

              //Created user
              let created_uid = element.created_uid
              let created_user = getters.getCompanyUserByKey(created_uid) 
              let created_user_name = created_user?created_user.surname+' '+created_user.names:'--'
              
              //Close user
              let closed_uid = element.closed_uid
              let closed_user = getters.getCompanyUserByKey(closed_uid) 
              let closed_user_name = closed_user?closed_user.surname+' '+closed_user.names:'--'
              
              //items
              let items = element.items
              let items_ = items?Object.values(items):null
              let item_counted = []
              let item_uncounted = []
              let items_size = 0
              let item_counted_size = 0
              let item_uncounted_size = 0
              if (items) {
                item_counted = items_.filter(item=>toNumber(item.new_quantity)>0)
                item_uncounted = items_.filter(item=>toNumber(item.new_quantity)==0)
                items_size = items_.length
                item_counted_size = item_counted.length
                item_uncounted_size = item_uncounted.length
              }

              //joined users
              let users = element.users
              let joined_users = null
              
              try {
                if (users) { 
                  let array_users = Object.keys(users).map((key) => {return{...users[key],key:key}}); 
                  let res = await dispatch("join",{
                     data:array_users,
                     name:"ALL_"+DATA.ITEMS.CASHUPS.values
                  }) 
                  joined_users = res?res.data?res.data.all_joined:null:null 
                }  
              } catch (error) {
              } 
              let users_size = joined_users?joined_users.length:0
              //outlet
              let outlet_code = element.outlet_code
              let outlet = getters.getStockOutletByCode(outlet_code)
              let outlet_name = outlet?outlet.name:'--'  
              
              let newItem = {
                  ...element,
                  no:NUMBER,  
                  outlet:outlet, 
                  outlet_name:outlet_name,  
                  joined_users:joined_users,  
                  users_size:users_size,  

                  created_user:created_user,  
                  created_user_name:created_user_name,  
                  closed_user:closed_user,  
                  closed_user_name:closed_user_name,  

                  items_:items_,  
                  item_counted:item_counted,  
                  item_uncounted:item_uncounted,  
                  items_size:items_size,  
                  item_counted_size:item_counted_size,  
                  item_counted_size_:items_size+"/"+item_counted_size,  
                  item_uncounted_size:item_uncounted_size,  
                  item_uncounted_size_:items_size+"/"+item_uncounted_size,  

                  closed_at_:element.closed?toDateTime(element.closed_at):"Running...", 
                  closed_at_date:element.closed?toDate(element.closed_at):"...",
                  closed_at_time_:element.closed?toTime(element.closed_at):"...",
                  
                  created_at_:toDateTime(element.created_at), 
                  created_at_date:toDate(element.created_at),
                  created_at_time_:toTime(element.created_at),
                  updated_at_:toDateTime(element.updated_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      },  

      join_local_item_orders({commit,dispatch,getters},payload) {
        let response = {
          from:'join_local_item_orders',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   

            let res = await dispatch("join_sale_orders",{
              ...payload
            })
            let processed_data = res?res.data:null  
            if(processed_data){ 
              let newItem = {
                  ...processed_data, 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      },  

      join_local_quotations({ commit,dispatch,getters},payload) {
        let response = {
          from:'join_local_quotations',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){
              //cashup
              let day_shift_key = element.day_shift_key
              let day_shift = getters.getDayShiftByKey(day_shift_key)  
              
              
              //cashup
              let cashup_key = element.cashup_key
              let cashups = day_shift?day_shift.cashups:null
              let cashup = cashups?cashups[cashup_key]:null
              let till_key = cashup?cashup.till_key:null
              let teller_key = cashup?cashup.uid:null
 
              
              //Till 
              let company = getters.getCompanyByKey(element.company_key)
              let company_name = company?company.company_name:'--'  
              
              //Till 
              let till = getters.getTillByKey(till_key)
              let till_name = till?till.name:'--' 
              let till_code = till?till.code:null  
              let outlet_code = till?till.outlet_code:null
              
              //category 
              let outlet = getters.getStockOutletByCode(outlet_code)
              let outlet_name = outlet?outlet.name:'--' 
              
              //teller
              let teller_uid = element.created_uid
              let teller = getters.getCompanyUserByKey(teller_uid) 
              let teller_name = teller?teller.surname+' '+teller.names:'--'  
              
              //customer
              let customer_key = element.customer_key
              let customer = getters.getCustomerByKey(customer_key) 
              let customer_name = customer?customer.name:'Unknown' 
              
              //receivable
              //receivable
              let receivable_code = element.receivable_code
              let receivable_key = element.receivable_key
              let receivable = null
              let receivable_name ='Unknown' 

              if (receivable_key) {
                receivable = getters.getReceivableByKey(receivable_key) 
                receivable_name = receivable?receivable.name:'Unknown'  
              } else if(receivable_code){
                receivable = getters.getReceivableByCode(receivable_code) 
                receivable_name = receivable?receivable.name:'Unknown'  
                
              }

              // icon
              let icon_image = {
                icon:'mdi-receipt' 
              }
              if (element.account_type == DATA.ITEMS.CARD_PAYMENTS.value) {
                
              }else if (element.account_type == DATA.ITEMS.CARD_PAYMENTS.value) {
                
              } 


              //calculate order items
              let key = element.key
              let sale_order_items =element.items?element.items:null
              let joined_items=null
              try {
                let res = await dispatch("join",{
                  name:"ALL_"+DATA.ITEMS.ITEMS.values,
                  data:sale_order_items
                }) 
                let join_data = res?res.data:null
                joined_items = join_data?join_data.joined:null 
              } catch (error) {  
                console.log(error,'e........');
              }    
              let item_discounts = 0
              if (sale_order_items) {
                sale_order_items.forEach(item => {
                  let discount = toNumber(item.discount)
                  let quantity = toNumber(item.quantity)
                  item_discounts = item_discounts+(discount*quantity)
                });
              }
              let mix_sale_order_items = joined_items?joined_items:sale_order_items
              let sale_order_size = SIZE(sale_order_items)
              let total_cost_price = 0
              let total_sold_price = 0
              let total_quantity = 0
              let total_price = toNumber(element.total_price)
              let total_discount = toNumber(element.total_discount)
              if (mix_sale_order_items) {
                  mix_sale_order_items.forEach(item => {
                    let sold_price = toNumber(item.sold_price)
                    let discount = toNumber(item.discount)
                    let quantity = toNumber(item.quantity)

                    //check cost
                    let sold_cost_price = toNumber(item.sold_cost_price)
                    let average_cost_price = toNumber(item.average_cost_price)
                    let cost_price = toNumber(item.cost_price)
                    //verify
                    let  mix_average_cost_price = average_cost_price?average_cost_price:cost_price
                    sold_cost_price = sold_cost_price?sold_cost_price:mix_average_cost_price

                    //calculate
                    let sold = quantity*(sold_price-discount)
                    let cost = sold_cost_price*quantity
                    total_sold_price = total_sold_price+sold
                    total_cost_price = total_cost_price+cost
                    total_quantity = total_quantity+quantity 
                  }); 
                  total_sold_price = total_sold_price-total_discount
              } 
               
              
              if (element.selected_card_option == 'load_money') {
                payment_method_name = "Load Money ("+payment_method_name+")"  
                total_quantity = 1
                sale_order_size = 1
                total_cost_price = element.paid_amount
                total_sold_price = element.paid_amount
                icon_image.icon = 'mdi-cash-plus' 
              }
              
              let newItem = {
                  ...element,
                  no:NUMBER,  
                  icon_image:icon_image,
                  day_shift:day_shift,
                  cashup:cashup,
                  outlet_name:outlet_name,
                  outlet:outlet,  
                  to:outlet_name, 
                  receipt_number:element.order_key?element.order_key:element.key,  

                  company:company,  
                  company_name:company_name,  

                  receivable:receivable, 
                  receivable_name:receivable_name, 
                  till:till, 
                  till_key:till_key, 
                  till_code:till_code, 
                  till_name:till_name, 
                  teller:teller, 
                  teller_name:teller_name, 
                  customer:customer, 
                  customer_name:customer_name, 
                  joined_items:joined_items, 
                  items_size:sale_order_size, 

                  total_price_:toMoney(total_price), 

                  total_cost_price:total_cost_price, 
                  total_cost_price_:toMoney(total_cost_price), 
                  total_sold_price:total_sold_price, 
                  total_sold_price_:toMoney(total_sold_price), 
                  total_discount_:toMoney(total_discount), 
                  item_discounts:item_discounts, 
                  item_discounts_:toMoney(item_discounts), 
                  all_discounts:total_discount+item_discounts, 
                  all_discounts_:toMoney(total_discount+item_discounts), 
                  total_quantity:total_quantity,  
                  total_quantity_:total_quantity+"/"+sale_order_size,  

                  code_:toSameLength(element.code),
                  created_at_:toDateTime(element.created_at), 
                  created_at_date:toDate(element.created_at), 
                  created_at_time:toTime(element.created_at), 
                  local_created_at_:toDateTime(element.local_created_at), 
                  local_created_at_date:toDate(element.local_created_at), 
                  local_created_at_time:toTime(element.local_created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  created_at_server_date:toDate(element.created_at_server),
                  created_at_server_time:toTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 

      join_billing_plan_features({ commit,getters},payload) {
        let response = {
          from:'join_billing_plan_features',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){  

              //permissions 
              let permissions = element.permissions
              let join_permission = [] 
              let permissions_size = 0
              if (permissions) {
                permissions_size = permissions.length
                permissions.forEach((p,index) => {
                  let permission = getters.getUserPermissionByCode(p.permission_code)   
                  if (permission) {
                    join_permission.push(permission)   
                  }
                }); 
              }
              
              let uid = element.created_uid
              let user = getters.getUserByUid(uid) 
              let user_name = user?user.surname+' '+user.names:'--' 
                    
              let newItem = {
                  ...element,
                  no:NUMBER,  
                  join_permission:join_permission,  
                  permissions_size:permissions_size,  
                  user:user,  
                  user_name:user_name,   
                  monthly_price_:toMoney(element.monthly_price), 
                  created_at_:toDateTime(element.created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      },

      join_billing_plans({ commit,getters},payload) {
        let response = {
          from:'join_billing_plans',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){  

              //permissions 
              let billing_plan_features = element.billing_plan_features
              let join_billing_plan_features = [] 
              let billing_plan_features_size = 0
              if (billing_plan_features) {
                billing_plan_features_size = billing_plan_features.length
                billing_plan_features.forEach((p,index) => {
                  let permission = getters.getBillingPlanFeatureByCode(p.billing_plan_feature_code)   
                  if (permission) {
                    join_billing_plan_features.push(permission)   
                  }
                }); 
              }
              
              //permissions 
              let added_plan_features = element.billing_plan_features
              let join_added_plan_features = [] 
              let added_plan_features_size = 0
              if (added_plan_features) {
                added_plan_features_size = added_plan_features.length
                added_plan_features.forEach((p,index) => {
                  let permission = getters.getBillingPlanFeatureByCode(p.billing_plan_feature_code)   
                  if (permission) {
                    join_added_plan_features.push(permission)   
                  }
                }); 
              }
              
              let uid = element.created_uid
              let user = getters.getUserByUid(uid) 
              let user_name = user?user.surname+' '+user.names:'--' 
                    
              let newItem = {
                  ...element,
                  no:NUMBER,  
                  join_billing_plan_features:join_billing_plan_features,  
                  billing_plan_features_size:billing_plan_features_size, 
                  join_added_plan_features:join_added_plan_features,  
                  added_plan_features_size:added_plan_features_size,  
                  user:user,  
                  user_name:user_name,   
                  monthly_price_:toMoney(element.monthly_price), 
                  created_at_:toDateTime(element.created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      },
      join_subscriptions({ commit,dispatch,getters},payload) {
        let response = {
          from:'join_subscriptions',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){  

              //permissions 
              let plan_details = element.plan_details
              let join_plan_details=null
              if (plan_details) {
                let res = await dispatch("join_billing_plans",{
                  data:plan_details
                })
                let join_data = res?res.data:null
                join_plan_details = join_data 
              }
               
              
              let uid = element.created_uid
              let user = getters.getUserByUid(uid) 
              let user_name = user?user.surname+' '+user.names:'--' 
                    
              let newItem = {
                  ...element,
                  no:NUMBER,   
                  user:user,  
                  user_name:user_name,   
                  join_plan_details:join_plan_details,   
                  amount_:toMoney(element.amount), 
                  monthly_price_:toMoney(element.monthly_price), 
                  created_at_:toDateTime(element.created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      },


      join_others({ commit,getters},payload) {
        let response = {
          from:'join_others',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try {  
            let element = payload?payload.data:null    
            let NUMBER = payload?payload.NUMBER:1   
            if(element){ 
              //teller
              let uid = element.created_uid
              let user = getters.getCompanyUserByKey(uid) 
              let user_name = user?user.surname+' '+user.names:'--' 
              
              
              let newItem = {
                  ...element,
                  no:NUMBER,   
                  user:user,
                  user_name:user_name, 
                  code_:toSameLength(element.code),
                  created_at_:toDateTime(element.created_at), 
                  created_at_date:toDate(element.created_at), 
                  created_at_time:toTime(element.created_at), 
                  local_created_at_:toDateTime(element.local_created_at), 
                  local_created_at_date:toDate(element.local_created_at), 
                  local_created_at_time:toTime(element.local_created_at), 
                  created_at_server_:toDateTime(element.created_at_server),
                  created_at_server_date:toDate(element.created_at_server),
                  created_at_server_time:toTime(element.created_at_server),
                  updated_at_:toDateTime(element.updated_at), 
                  updated_at_server_:toDateTime(element.updated_at_server), 
              }
              resolve({
                success:true, 
                data:newItem
              })
            }else{
              resolve({
                success:false, 
                data:null,
                error:'no data',
              })
              return
            }  
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 
    }
}