import Vue from 'vue'
import Vuex from 'vuex'

import app from './app'
import errors from './errors' 
import load from './load' 
import mbs from './mbs'
import users from './users'
import items from './items'
import locals from './locals'
import print from './print'
import lan from './lan'
import join from './join'
import calculate from './calculate'
import {conductor} from "../plugins/CONDUCTOR"

Vue.use(Vuex) 
export const store = new Vuex.Store({
 modules:{
  app:app,
  load:load, 
  mbs:mbs,
  users:users,
  items:items,
  locals:locals,
  lan:lan,
  print:print,
  join:join,
  calculate:calculate,
  errors:errors,
 },
 plugins:[conductor]
})
