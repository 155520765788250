
<template>
    <div>   
        <v-container grid-list-xl> 
            <div> 
                <v-alert v-if="ThisDayShift?ThisDayShift.total_closing_cash?true:false:false"
                    border="top" 
                    colored-border
                    color="warning"
                    elevation="2" >
                    <v-layout  class="ma-0 py-2"   align-center>
                        <v-icon color="warning">warning</v-icon>
                        <div class="px-5">
                            The selected Day shift has already been closed. Click Read Report Button to view the Sales Report.
                        </div>  
                    </v-layout> 
                    <v-layout row wrap class="ma-0 px">
                        <v-spacer></v-spacer>
                        <v-btn @click="READ_REPORT()" color="warning" outlined>Read Report</v-btn>
                    </v-layout>
                </v-alert>
                <br>
                <h1 class="grey--text text--darken-1">Manage Day Shift</h1>
                <br>
                <form id="form_order_details" @submit.prevent="CREATE_ORDER()" >
                    <v-row>
                        <v-col cols="12" md="4">
                            <div class="text-justify">
                                <h4 class="grey--text text--darken-1">Order Details</h4>
                                Add details for this delivery for ease identification of all your deliveries. Stock receiving is the function of checking items delivered to the business after an order. This includes inspecting the quality, condition, and quantity of any incoming goods, and allocating them to a space in the warehouse.
                            </div>
                        </v-col>
                        <v-col cols="12" md="8"  > 
                            <mbs-page-spinner v-if="!show_input"
                                height="500"/>  
                            <mbs-input-fields v-else
                                v-model="input"
                                :field_items="DATA.FIELDS.manage_day_shift"
                                :select_items="SelectItems"
                                :disabled_fields="DisabledFields"
                                />
                            
                            <v-layout row wrap>
                                <v-flex xs7>
                                    <v-btn :to="MBS.actions.COMPANY_LINK('/day-shift-report/'+day_shift_key,true)"      
                                    class="mt-5" width="100%" color="primary" :disabled="!day_shift_key">
                                        DAY SHIFT REPORT
                                        <v-icon class="ml-2">mdi-form-dropdown</v-icon>
                                    </v-btn>  
                                </v-flex>
                                <v-flex xs5 class="pl-0">
                                    <v-btn @click="dialog_shift_sales_report=!dialog_shift_sales_report"     
                                        class="mt-5" width="100%" color="secondary" :disabled="!day_shift_key">
                                        DETAILED REPORT
                                        <v-icon class="ml-2" size="32">mdi-clipboard-list-outline</v-icon>
                                    </v-btn> 
                                </v-flex> 
                            </v-layout>
                        </v-col> 
                    </v-row> 
                    <br><br><br>
                    <hr>
                    <br><br><br> 
                    <v-row  > 
                        <v-col cols="12" >
                            <!-- // USER LIST -->
                            <v-card tile>
                                <v-list > 
                                    <!-- //TILLS -->
                                    <v-list-group >
                                        <template v-slot:activator>
                                            <v-list-item-action><v-icon>mdi-cash-register</v-icon></v-list-item-action>
                                            <v-list-item-content>
                                                <div>{{MBS.actions.SIZE(DayShiftTills)}} Till(s) Assigned</div>  
                                            </v-list-item-content> 
                                        </template> 
                                        <v-card class="mx-5" outlined flat tile>
                                            <div v-for="(till,index) in DayShiftTills" :key="index" class="ml-2"> 
                                                <v-divider inset v-if="index!=0" ></v-divider> 
                                                <v-list-item dense @click="" >
                                                    <v-list-item-action > 
                                                        <v-avatar  tile 
                                                            size="35" rounded 
                                                            color="b2" class="white--text" >
                                                            <v-icon v-if="till.running">$printer-pos-play</v-icon>
                                                            <v-icon v-else>$printer-pos-pause</v-icon>
                                                        </v-avatar>   
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title >
                                                            <v-avatar class="mb-1"
                                                                size="10"  
                                                                :color="till.online?'green':till.closed?'orange':'red'" > 
                                                            </v-avatar>
                                                            {{till.name}}  
                                                            <span class="ml-5 font-weight-bold">({{ MBS.actions.TEXT_UP1(till.till_type_key) }})</span>
                                                        </v-list-item-title> 
                                                        <v-list-item-subtitle  >
                                                            Teller: {{till.teller_name?till.teller_name:'---' }} <strong class="mx-3" >|</strong> <strong>{{ till.status }}</strong>
                                                        </v-list-item-subtitle> 
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-card flat :outlined="till.running" :color="till.running?'secondary darken-2':''">
                                                            <v-btn-toggle  :loading="inputLoading"  > 
                                                                <v-btn  :loading="inputLoading"   class="primary--text" :disabled="!till.running" @click="CONFIRM_FORCE_AND_CLOSE_TILL(till.cashup)" 
                                                                 small>Force & Close</v-btn>    
                                                                <v-menu   
                                                                    :close-on-content-click="true" 
                                                                    offset-y  offset-overflow
                                                                    :nudge-width="90" rounded="xl"
                                                                    >
                                                                    <template v-slot:activator="{ on, attrs }">  
                                                                        <v-btn small  v-on="on"  :loading="inputLoading" 
                                                                            ref="btn_selling_mode" 
                                                                            color="secondary"> 
                                                                            <v-icon  class="ml- white--text">arrow_drop_down</v-icon>
                                                                        </v-btn> 
                                                                    </template> 
                                            
                                                                    <v-card  v-if="ud"  
                                                                        color="lighten-1" class="">  
                                                                        <v-card-title class="secondary white--text py-" dark>
                                                                            More Options 
                                                                        </v-card-title> 
                                                                        <v-list dense> 
                                                                            <div v-for="(action,index) in till.running?open_till_options:close_till_options" :key="index">
                                                                                <v-list-item @click="ON_TILL_ACTION(action,till)">{{action.name}}</v-list-item> 
                                                                            </div>
                                                                        </v-list>  
                                                                    </v-card>  
                                                                </v-menu>   
                                                            </v-btn-toggle> 

                                                        </v-card>
                                                    </v-list-item-action>
                                                </v-list-item>   
                                                
                                            </div>  
                                            <div  >
                                                <v-divider  ></v-divider> 
                                                <v-layout class="ma-0 mr- b1" align-center justify-center >
                                                    <!-- <v-spacer/>  -->
                                                    <v-btn :disabled="!UnusedTills" @click="ON_ADD_TILL()" class="mb-2 mt-4"    color="secondary" >Add another Till 
                                                        <v-icon class="ml-3">mdi-plus-circle</v-icon>
                                                    </v-btn>
                                                </v-layout> 
                                            </div>
                                        </v-card>
                                    </v-list-group>
                                    
                                    <!-- //USERS -->
                                    <v-list-group>
                                        <template v-slot:activator>
                                            <v-list-item-action><v-icon>group</v-icon></v-list-item-action>
                                            <v-list-item-content>
                                                <div>{{MBS.actions.SIZE(DayShiftUsers)}} User(s) Joined</div>  
                                            </v-list-item-content> 
                                        </template> 
                                        <v-card class="mx-5" outlined tile flat>
                                            <div v-for="(user,index) in DayShiftUsers" :key="index" class="ml-">
                                                <v-divider inset v-if="index!=0" ></v-divider>
                                                <v-list-item dense @click="">
                                                    <v-list-item-action >
                                                        <v-avatar v-if="user.photoURL"
                                                            size="35"  >
                                                            <v-img :src="user.photoURL" lazy-src="https://picsum.photos/10/6?image=3" >
                                                                <template v-slot:placeholder>
                                                                    <v-row
                                                                        class="fill-height ma-0"
                                                                        align="center"
                                                                        justify="center">
                                                                        <v-progress-circular
                                                                            indeterminate
                                                                            color="grey lighten-5"
                                                                            /> 
                                                                    </v-row>
                                                                </template>
                                                            </v-img>
                                                        </v-avatar> 
                                                        <v-avatar v-else
                                                            size="35" 
                                                            color="secondary" class="white--text" >
                                                            <div v-if="user">
                                                                {{MBS.actions.TEXT_UP(user.surname?user.surname.slice(0,1):'..')}} 
                                                            </div>
                                                        </v-avatar>   
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title >
                                                            <v-avatar class="mb-1"
                                                                size="10"  
                                                                :color="user.online?'green':'red'" > 
                                                            </v-avatar>
                                                        {{user.surname}} {{user.names}}  
                                                        </v-list-item-title>
                                                        <!-- <v-list-item-subtitle>
                                                            {{user.cashups_size}} cashup(s)
                                                        </v-list-item-subtitle> -->
                                                        <v-list-item-subtitle v-if="user.running_cashup">
                                                            Till: {{user.running_cashup.till_name}},
                                                            Open Time: {{ user.running_cashup.created_at_ }},
                                                            Open Cash: {{ user.running_cashup.opening_cash_}},
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle v-else>
                                                            No Running Till  <strong class="mx-2 font-weight-bold not-f5">|</strong>  <strong>{{user.cashups_size}} cashup(s)</strong>
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-btn color="secondary" :disabled="!user.running_cashup" @click="CONFIRM_FORCE_AND_CLOSE_TILL(user.running_cashup)" outlined small>Force & Close Till</v-btn>  
                                                    </v-list-item-action>
                                                </v-list-item>    
                                            </div>  
                                        </v-card>
                                    </v-list-group> 
                                    
                                    <!-- //CASHUP -->
                                    <v-list-group >
                                        <template v-slot:activator>
                                            <v-list-item-action><v-icon>mdi-cash-clock</v-icon></v-list-item-action>
                                            <v-list-item-content>
                                                <div>{{MBS.actions.SIZE(Cashups)}} Cashup(s) Created</div>  
                                            </v-list-item-content> 
                                        </template> 
                                        <v-card outlined tile flat  class="mx-5">
                                            <div v-for="(cashup,index) in Cashups" :key="index" class="ml-2"> 
                                                <v-divider inset v-if="index!=0"></v-divider> 
                                                <v-list-item dense @click="">
                                                    <v-list-item-action > 
                                                        <v-avatar tile
                                                            size="35" 
                                                            color="b2" class="white--text" >
                                                            <v-icon v-if="cashup.closed">$printer-pos-pause</v-icon>
                                                            <v-icon v-else>$printer-pos-play</v-icon>
                                                        </v-avatar>   
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title >
                                                            <v-avatar class="mb-1 mr-1"
                                                                size="10"  
                                                                :color="cashup.online?'green':'red'" > 
                                                            </v-avatar>
                                                            {{cashup.teller_name}} 
                                                            <strong class="mx-2 font-weight-bold not-f5">|</strong>
                                                            Till: {{ cashup.till_name }}
                                                        </v-list-item-title> 
                                                        <v-list-item-subtitle>
                                                            Opening Cash: {{ cashup.opening_cash_ }} , 
                                                            Closing Cash: {{ cashup.closing_cash_ }}   
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle class="primary--text">
                                                            Opened: {{ cashup.local_created_at_ }} , 
                                                            Closed: {{ cashup.closed?cashup.close_date_time_:'Running...' }}   
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-btn 
                                                            color="secondary" :disabled="cashup.closed" @click="CONFIRM_FORCE_AND_CLOSE_TILL(cashup)" outlined small>Force to Close
                                                        </v-btn>  
                                                    </v-list-item-action>
                                                </v-list-item>    
                                            </div>  
                                        </v-card>
                                    </v-list-group> 
                                </v-list> 
                            </v-card>

                            <v-card v-if="!(ThisDayShift?ThisDayShift.closed:false)" @click="SUBMIT()"  
                                :disabled="inputLoading" 
                                :flat="!input.closing_cash"  
                                class=" my-4 v-btn" :color="inputLoading?'grey darken-1':'primary'" dark > 
                                <v-layout class="ma-0 py-2 px-4" justify-center align-center v-if="!inputLoading">
                                    <!-- <div class="font-weight-bold not-f7">Close Day Shift</div> 
                                    <v-spacer></v-spacer> -->
                                    <div class="font-weight-bold not-f7">Z-READ & CLOSE SHIFT</div> 

                                    <v-icon size="35" class="ml-2">$printer-pos-remove</v-icon>
                                </v-layout> 
                                <v-layout v-else class="ma-0 pa-2" justify-center>
                                    <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                                </v-layout>
                            </v-card>
                        </v-col> 
                    </v-row> 
                </form>
            </div> 
        </v-container> 
        <div>
            <mbs-auto-print
                v-if="autoPrint"
                :show="true"  
                :auto_print="true"  
                :type="typePrint"  
                :data="itemPrint" /> 
            <!--MANAGE SHIFT-->
            <v-dialog
                v-model="dialog_add_till"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="600px"
                transition="dialog-transition" >
                <v-card rounded="lg">
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">ADD ANOTHER TILL</div> 
                        <v-spacer></v-spacer> 
                        <v-btn dark icon class="ml-5" @click="dialog_add_till=false" ><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <br> 
                        <form @submit.prevent="ADD_TILL()">
                            <div class="ma-5" v-if="dialog_add_till">
                                <!-- :disabled_fields="DisabledFields" -->
                                <mbs-input-fields 
                                    v-model="input_till"
                                    :field_items="DATA.FIELDS.add_till_day_shift"
                                    :select_items="SelectItems"  
                                    />   
                                <div class=" mt-5">Make sure you select Till before continue</div> 
                                <v-layout class="ma-0">
                                    <v-spacer></v-spacer>
                                    <v-btn :loading="inputLoading" type="submit" large color="primary">ADD TILL</v-btn>
                                </v-layout>
                            </div> 
                        </form>
                    </v-card-text> 
                </v-card> 
            </v-dialog>  
            <mbs-day-shift-sales-report
                v-model="dialog_shift_sales_report"
                :day_shift="ThisDayShift"
                /> 
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import DB from '../../../plugins/DB'
    import {mapState} from "vuex"
    let PAGE_NAME = "MANAGE-SHIFT"  
    export default {
        name:PAGE_NAME, 
        props:['id','day_key','type'], 
        data() { 
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10, 
                search_item:'', 
                dialog_add_till:false,
                selected_items:null,
                dialog_shift_sales_report:false, 
                inputLoading:false,
                input:{},
                input_till:{},
                search_input:null,  
                DATA:DATA, 
                day_shift_key:null, 
                show_input:true, 
                autoPrint:false,
                open_till_options:[
                    {type:'till_action',action:'ask_to_close',name:'Ask to Close'},
                    {type:'till_action',action:'view_history',name:'View History'},
                    {type:'till_action',action:'open_drawer',name:'Open Drawer'},
                    {type:'till_action',action:'print_duplicate',name:'Print Duplicate'},
                ],
                close_till_options:[
                    {type:'till_action',action:'view_history',name:'View History'},
                    {type:'till_action',action:'open_drawer',name:'Try Open Drawer'},
                    {type:'till_action',action:'print_duplicate',name:'Try Print Duplicate'},
                ],
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
                this.input.day_shift_key = this.day_key  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.MBS.actions.toolbar({
                    show:true, 
                }) 
                this.FEEDBACKS(1000) 
                this.ON_SELECT_SHIFT()
                this.input.day_shift_key = this.day_key  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
            /////////   

            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                Payments: state=> state.items[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],
                JoinedPayments: state=> state.join[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],  
                SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],  
                DayShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                JoinedDayShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],  
                LocalDaysShifts: state=> state.locals[(DATA.ITEMS.LOCAL_DAYS_SHIFTS.values).toUpperCase()], 
                Tills: state=> state.items[(DATA.ITEMS.TILLS.values).toUpperCase()],
                JoinedTills: state=> state.join[(DATA.ITEMS.TILLS.values).toUpperCase()],  
            }), 
            PaymentsData(){
                let Payments = this.Payments
                let JoinedPayments = this.JoinedPayments 
                return JoinedPayments?JoinedPayments:Payments
            },
            DayShiftsData(){
                let DayShifts = this.DayShifts
                let JoinedDayShifts = this.JoinedDayShifts 
                let items = null
                if (JoinedDayShifts) {
                    items = JoinedDayShifts
                }else{
                    if (DayShifts) {
                        items = this.MBS.actions.JOIN_DAY_SHIFTS(DayShifts)   
                    }
                }
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))   
                return list  
            }, 
            AllDaysShifts(){
                let locals = this.MBS.actions.JOIN_DAY_SHIFTS(this.LocalDaysShifts)
                let dayShifts = this.DayShiftsData

                let data = []
                if(!dayShifts && !locals){return null}
                
                if (dayShifts) {
                    data = [...dayShifts] 
                }if (locals) {
                    locals.forEach(local => {
                        let index = data.findIndex(item=>item.key == local.key)
                        if (index ==-1 ) {
                            data.push(local)
                        } else {
                            data[index] = {...data[index],...local}
                        }
                        
                    }); 
                 }
                return data
            }, 
            TillsData(){
                let Tills = this.Tills
                let JoinedTills = this.JoinedTills 
                let items = JoinedTills?JoinedTills:Tills 
                return items  
            },
            UnusedTills(){
                let all_tills = this.TillsData
                let used_tills = this.DayShiftTills
                if(!all_tills){return null}
                if(!used_tills){return all_tills}
                let tills = []
                let used = key=>{return used_tills.find(till=>till.till_key==key)}
                for (const till of all_tills) {
                    let used_t = used(till.key)
                    if (!used_t) {
                        tills.push({
                            ...till 
                        })
                    }
                }
                return this.MBS.actions.SIZE(tills)?tills:null
                 
            },

            SelectItems(){ 
                let DayShifts = this.AllDaysShifts
                let Tills = this.UnusedTills
                return {
                    DayShifts:DayShifts, 
                    Tills:Tills, 
                }
            },
            
            LocalCashup(){   
                let localCashup = this.MBS.actions.LocalCashup 
                return localCashup
            }, 
            selectedCompany(){ 
                return this.$store.getters.getSelectedCompany
            }, 
        
            ThisDayShift(){  
                let day_shift_key = this.day_shift_key
                let day_shifts = this.AllDaysShifts 
                let items = day_shifts?day_shifts.find(day=>day.key==day_shift_key):null
                return items
            },  
            DayShiftTills(){   
                let day = this.ThisDayShift 
                let tills = day?day.tills:null
                let joined_tills = day?day.joined_tills:null
                let items = joined_tills?joined_tills:tills 
                if(!items){return null}

                let new_till = []
                for (const item of items) {
                    let joined_teller = this.MBS.actions.JOIN_USER(item.teller?item.teller:item.uid)
                    new_till.push({
                        ...item,
                        online:joined_teller?joined_teller.online:false,
                        teller:joined_teller
                    })
                } 

                console.log(new_till,'new_till..............');
                
                return new_till
            },
            Cashups(){  
                let day = this.ThisDayShift 
                let cashups = day?day.cashups:null
                let joined_cashups = day?day.joined_cashups:null
                let items = joined_cashups?joined_cashups:cashups 
                if(!items){return null}
                let new_cashup = []
                for (const item of items) {
                    let joined_teller = this.MBS.actions.JOIN_USER(item.teller?item.teller:item.uid)
                    new_cashup.push({
                        ...item,
                        online:joined_teller?joined_teller.online:false,
                        teller:joined_teller
                    })
                } 
                return new_cashup
            },  
            DayShiftUsers(){   
                let cashups = this.Cashups 
                if(!cashups){return null}
                let users = []
                for (const cashup of cashups){
                    let uid = cashup.uid
                    let update_user = users.find(user=>user.uid==uid)
                    if (!update_user) {
                        let user = this.$store.getters.getUserByUid(uid)
                        if (user) {
                            let details = {
                                ...user,
                                cashups:[cashup],
                                cashups_size:1
                            }
                            if (!cashup.closed) {
                                details.running_cashup = cashup  
                            }
                            users.push(details) 
                        }
                    }else{
                        update_user.cashups.push(cashup)
                        update_user.cashups_size++  
                        if (!cashup.closed) {
                            update_user.running_cashup = cashup  
                        }
                    }  
                }  
                let joined_users = this.MBS.actions.JOIN_USERS(users)
                return joined_users
            },  
            DisabledFields(){
                let shift = this.ThisDayShift
                let settings = this.CompanySettings  
                let edit_closing_cash = settings?settings.edit_closing_cash:null
                let total_closing_cash = shift?shift.total_closing_cash:null
                
                if (!edit_closing_cash) {
                    return ['closing_cash','operating_date_','outlet_name','expected_cash']
                }
                if (total_closing_cash) {
                    return ['closing_cash','operating_date_','outlet_name','expected_cash']
                }
                return null
            },

            SaleOrders(){ 
                let items = this.$store.getters.getSaleOrders  
                if(!items){return null}
                let filtered_items = items.filter(item=>{
                    return !item.archive && item.day_shift_key == this.day_shift_key
                }) 
                if(!filtered_items){return null}
                let list = [...filtered_items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",true))  
                return list
            }, 
            Joined_SaleOrders(){   
                let orders = this.SaleOrders 
                let joined = this.MBS.actions.JOIN_SALE_ORDERS(orders)   
                return joined
            },
            DayShiftSaleOrders(){ 
                let sales = this.Joined_SaleOrders 
                let day_shift_key = this.day_shift_key 
                if(!day_shift_key||!sales){return null}
                let filtered_items = sales.filter(item=>{
                    return item.day_shift_key == day_shift_key
                })    
                return filtered_items  
            }, 
            DayShiftSaleOrdersItems(){
                let sales = this.DayShiftSaleOrders 
                let items = this.ORDER_ITEMS(sales)  
                return items
            },
            RetailDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                
                let retail_items = this.RETAIL_ITEMS(items)
                return retail_items 
            },
            WholesaleDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.WHOLESALE_ITEMS(items)
                return retail_items 
            },
            CashDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems 
                let retail_items = this.CASH_ITEMS(items) 
                return retail_items 
            },
            OnAccountDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.ON_ACCOUNT_ITEMS(items)
                return retail_items 
            },



            ThisInvoicePayments(){
                let payments = this.PaymentsData
                let day = this.ThisDayShift 
                let day_shift_key = this.day_shift_key
                if(!payments||!day_shift_key){return null}
                let filled_items = payments.filter(item=>{
                    return item.day_shift_key == day_shift_key
                })  
                return filled_items
            },
            InvoiceCashPayments(){
                let payments = this.ThisInvoicePayments 
                if(!payments){return null}
                let filled_items = payments.filter(item=>{
                    return !item.payment_option_code
                }) 
                return filled_items
            },
            OtherInvoicePayments(){
                let payments = this.ThisInvoicePayments 
                if(!payments){return null}
                let filled_items = payments.filter(item=>{
                    return item.payment_option_code
                }) 
                return filled_items
            },
            TotalInvoicePayments(){
                try { 
                    let items = this.ThisInvoicePayments
                    let total_amount = 0 
                    if (!items) {return 0}
                    items.forEach(item => {
                        let amount = this.toNumber(item.amount) 
                        total_amount = total_amount + amount
                    });   
                    return total_amount
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalInvoicePayments',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },  
            
        },
        methods: {   
            TOTAL_AMOUNT(items){
                try {  
                    let total_amount = 0 
                    if (!items) {return 0}
                    items.forEach(item => {
                        let amount = this.toNumber(item.amount) 
                        total_amount = total_amount + amount
                    });  
                    return total_amount
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TOTAL_AMOUNT',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },  
            TABLE_ACTION(action,item){
                try {
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            ON_TILL_ACTION(action,till){
                try {
                    this.CONFIRM_SENDING_TILL_ACTION(till,action)  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            ON_ADD_TILL(){
                try {
                    this.input_till = {}
                    this.dialog_add_till = true
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_ADD_TILL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            ADD_TILL(){
                try {
                    let input = this.input_till
                    let day_shift = this.ThisDayShift
                    let till_key = input?input.till_key:null
                    let floating_cash = input?input.floating_cash:null
                    let day_shift_key = day_shift?day_shift.key:null
                    if (!till_key || ! day_shift_key) {
                        let mbs_text = this.MBS.text.error0
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })
                        return 
                    }
                    let till_details = {
                        till_key:till_key,
                        floating_cash:this.toNumber(floating_cash)
                    }
                    let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.days_shifts+day_shift_key+"/tills/",true)
                    this.inputLoading = true
                    this.$store.dispatch("fi_add",{
                        action:DATA.ITEMS.DAYS_SHIFTS.values,
                        path:path,
                        data:till_details,
                    }).then(res=>{
                        this.inputLoading = false
                        this.dialog_add_till = false
                        console.log(res,'res.......');
                    }).catch(error=>{
                        this.inputLoading = false
                        this.dialog_add_till = false
                        console.log(error,'error.......');
                    }) 
                    console.log(input,'input_till...');
                    
                
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_TILL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CONFIRM_FORCE_AND_CLOSE_TILL(cashup){
                try {
                    // this.FORCE_AND_CLOSE_TILL(cashup)
                    // return 
                    this.MBS.actions.dialogConfirmPassword({
                        show:true,
                        fixed:true,
                        icon:"$printer-pos-pause",
                        title:"FORCE AND CLOSE TILL",
                        text:"Force closing, might disturb Z-Read, only use when necessary, You can ask to close instead. Enter your password to confirm force closing.",
                        btnYes:"FORCE CLOSE",
                        btnNo:"ASK TELL TO CLOSE",
                        action:{
                            code:PAGE_NAME+"=FORCE-AND-CLOSE",
                            data:cashup,
                            all_users:true,
                            phone_number_start:true,
                            get_access:{
                                page_name:"Start Day",  
                            }

                        }
                    })
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_FORCE_AND_CLOSE_TILL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            FORCE_AND_CLOSE_TILL(cashup){
                try {
                    let till_key = cashup?cashup.till_key:null
                    let cashup_key = cashup?cashup.key:null
                    let day_shift_key = cashup?cashup.day_shift_key:null
                    if (cashup_key && day_shift_key) {
                        let till_details = {
                            farce_closed:true, 
                            closed:true, 
                        }
                        let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.days_shifts+day_shift_key+"/cashups/"+cashup_key,true)
                        console.log(path,'-------');
                         
                        this.inputLoading = true
                        this.$store.dispatch("fi_update",{
                            action:DATA.ITEMS.DAYS_SHIFTS.values,
                            path:path,
                            data:till_details,
                        }).then(res=>{
                            this.inputLoading = false
                            this.dialog_add_till = false
                            console.log(res,'res.......');
                            this.SENDING_TILL_ACTION(null,{
                                name:"",
                                action:"for_close_register",
                                till_key:till_key,
                                cashup_key:cashup_key,
                                day_shift_key:day_shift_key,

                            }) 
                        }).catch(error=>{
                            this.inputLoading = false
                            this.dialog_add_till = false
                            console.log(error,'error.......');
                        }) 
                    }
                     console.log(till,'CLOSE');
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FORCE_AND_CLOSE_TILL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CONFIRM_ASK_TO_CLOSE_TILL(till){
                try {
                    let mbs_text = this.MBS.text.item_action("TILL","ASK TO CLOSE")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        icon:"$printer-pos-pause",
                        title:mbs_text.title,
                        text:mbs_text.text,  
                        btnYes:mbs_text.btnYes,   
                        btnNo:mbs_text.btnNo,   
                        action:{
                            code:PAGE_NAME+"=ASK-TO-CLOSE",
                            data:till

                        }
                    })
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_ASK_TO_CLOSE_TILL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ASK_TO_CLOSE_TILL(till){
                try {
                    let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.company_quick_actions,true)
                    this.inputLoading = true
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Loading..",
                    })
                    this.$store.dispatch("fi_add",{
                        action:DATA.ITEMS.COMPANY_QUICK_ACTIONS.values,
                        path:path,
                        data:{
                            type:'sale_action',
                            action:'ask_to_close',
                            till_key:till.key
                        }
                    }).then(res=>{
                        console.log(res,'r........');
                        let mbs_text = this.MBS.text.item_action_success("action","Sending")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,  
                        }) 
                        this.MBS.actions.progressDialog() 
                        this.inputLoading = false
                    }).catch(error=>{
                        console.log(error,'error.....');
                        let mbs_text = this.MBS.text.item_action_fail("action","Sending")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,  
                        }) 
                        this.MBS.actions.progressDialog()
                        this.inputLoading = false
                    })
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ASK_TO_CLOSE_TILL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CONFIRM_SENDING_TILL_ACTION(till,action){
                try {
                    if (till,action) {
                        let mbs_text = this.MBS.text.item_action(" ",action.name)
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            icon:"$printer-pos-pause",
                            title:mbs_text.title,
                            text:mbs_text.text,  
                            btnYes:mbs_text.btnYes,   
                            btnNo:mbs_text.btnNo,   
                            action:{
                                code:PAGE_NAME+"=SEND-TILL-ACTION",
                                data:{
                                    till:till,
                                    action:{
                                        ...action,
                                        till_key:till.key
                                    },
                                }
    
                            }
                        }) 
                    }
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_SENDING_TILL_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SENDING_TILL_ACTION(till,action){
                try {
                    let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.company_quick_actions,true)
                    this.inputLoading = true
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Loading..",
                    })
                    this.$store.dispatch("fi_add",{
                        action:DATA.ITEMS.COMPANY_QUICK_ACTIONS.values,
                        path:path,
                        data:action
                    }).then(res=>{
                        console.log(res,'r........');
                        let mbs_text = this.MBS.text.item_action_success("action","Sending")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,  
                        }) 
                        this.MBS.actions.progressDialog() 
                        this.inputLoading = false
                    }).catch(error=>{
                        console.log(error,'error.....');
                        let mbs_text = this.MBS.text.item_action_fail("action","Sending")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,  
                        }) 
                        this.MBS.actions.progressDialog()
                        this.inputLoading = false
                    })
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SENDING_TILL_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },



            SUBMIT(){
                try {
                    let link = "/z-read/"+this.day_shift_key
                    const path = this.MBS.actions.COMPANY_LINK(link,true) 
                    this.MBS.actions.dialog({
                        show:true,
                        title:"OPEN Z READY",
                        text:"You must open the z-read in order to close the day shift.",
                        btnYes:"OPEN Z-READ",
                        btnNo:"CANCEL",
                        action:{
                            code:"=GO",
                            to:path
                        }
                    })
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ADD_ITEM(item_key){
                try {
                    let items = this.JOINED_ITEMS
                    if (items) {
                        let item_selected = items.find(item=>{
                            return item.key == item_key
                        })
                        if (!item_selected) {
                            console.log(item_key,'not found');
                            return 
                        }
                        if (!this.selected_items) {
                            this.selected_items = []
                        }

                        let item = this.selected_items.find(item=>{
                            return item.key == item_key
                        }) 
                        if (item) {
                            item.quantity = (item.quantity*1)+1
                        } else {
                            this.selected_items.push({
                                ...item_selected, 
                                quantity:1,
                            })   
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            REMOVE_ITEM(selected_item){
                try {
                    let items = this.selected_items
                    if (items) {
                        let filtered_item = items.filter(item=>{
                            return item.key != selected_item.key
                        })
                        this.selected_items = filtered_item

                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let price = this.toNumber(item.price)
                    let discount = this.toNumber(item.discount)
                    let quantity = this.toNumber(item.quantity)
                    return quantity*(price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CREATE_ORDER(){
                try {
                    let input = this.input   
                    let localCashup = this.LocalCashup 
                    let day_shift = this.ThisDayShift   
                    // let cashups = day_shift?day_shift.joined_cashups:null
                    let cashups = this.MBS.actions.OBJECT_ITEMS(day_shift?day_shift.cashups:null) 
                    let total_cashups =  this.MBS.actions.CALCULATE_TOTAL_CASHUPS(cashups) 
                    let sales_retail = total_cashups?total_cashups.sales_retail:0
                    let sales_wholesales = total_cashups?total_cashups.sales_wholesales:0
                    let sales_on_account = total_cashups?total_cashups.sales_on_account:0
                    let sales_cash_payments = total_cashups?total_cashups.sales_cash_payments:0
                    let invoices_cash_payments = total_cashups?total_cashups.invoices_cash_payments:0
                    
                     
                    let closing_cash = input?this.toNumber(input.closing_cash):null 
                    let day_shift_key = input?input.day_shift_key:null 
                    let description = input?input.description:null  
                    let moment = this.MBS.date.moment
                    let time = moment?moment().valueOf():null
 
                    if(!day_shift){return null}
                    let order_details = {
                        ...day_shift,
                        total_closing_cash:closing_cash,  
                        totals_sales_retail:sales_retail,  
                        totals_sales_wholesales:sales_wholesales,  
                        totals_sales_on_account:sales_on_account,  
                        totals_sales_cash_payments:sales_cash_payments,  
                        totals_invoices_cash_payments:invoices_cash_payments,  
                        key:day_shift_key, 
                        local_close_at:time, 
                    } 
                    if (description) {
                        order_details.description = description
                    }    
                    if (localCashup) { 
                        this.MBS.events.$emit("ON_CLOSE_REGISTER") 
                        setTimeout(() => {
                            let mbs_text = this.MBS.text.close_register_before_z_read(DATA.ITEMS.DAYS_SHIFTS.name,"Close")
                            this.MBS.actions.dialog({
                                show:true,
                                title:mbs_text.title,
                                text:mbs_text.text,
                                btnYes:mbs_text.btnYes, 
                                action:{
                                    code:PAGE_NAME+"=CLOSE-REGISTER", 
                                    data:order_details
                                }
                            }) 
                        }, 500);
                        return
                    }
                    if (day_shift) { 
                        let mbs_text = this.MBS.text.item_action(DATA.ITEMS.DAYS_SHIFTS.name,"Close")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=CREATE-ORDER", 
                                data:order_details
                            }
                        })
                        
                    }
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_ORDER',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SUBMIT_ORDER(order){
                try {  
                    if (!order) {
                        console.log(order,'order error');
                    }  
                    this.inputLoading = true
                    this.MBS.actions.progressDialog({
                        show:true,
                        title:"Uploading..",
                    }) 
                    DB.addItems({
                        name:DATA.ITEMS.LOCAL_DAYS_SHIFTS.values,
                        data:order 
                    }).then(res=>{
                        this.MBS.events.$emit("SUBMIT_DAY_SHIFTS",PAGE_NAME)
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.DAYS_SHIFTS.value,"Closing")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                        this.ON_PRINTING()  
                        setTimeout(() => {
                            this.MBS.actions.go(-1)   
                        }, 500);
                    }).catch(error=>{
                        this.MBS.actions.error({
                            error:error,
                            from:'SUBMIT_ORDER',
                            page:PAGE_NAME, 
                        })  
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_failed(DATA.ITEMS.QUOTATIONS.value,"Submitted")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ON_SELECT_SHIFT(){
                try {  
                    let settings = this.CompanySettings  
                    let edit_closing_cash = settings?settings.edit_closing_cash:null
                    this.show_input = false 
                    let day_shift = this.ThisDayShift  
                    let cashups = day_shift?day_shift.joined_cashups:null
                    let Floating_cash = this.toNumber(day_shift?day_shift.total_floating_cash:0)

                    let InvoiceCashPayments = this.TOTAL_AMOUNT(this.InvoiceCashPayments)   
                    let SalesCashPayments = this.toNumber(this.TOTAL_SOLD(this.CashDayShiftItems)) 
                     
                    let total_cashups =  this.MBS.actions.CALCULATE_TOTAL_CASHUPS(cashups)
                    let sales_cash_payments = total_cashups.sales_cash_payments
                    let invoices_cash_payments = total_cashups.invoices_cash_payments
                    
                    let closing_cash1 = SalesCashPayments+InvoiceCashPayments
                    let closing_cash2 = sales_cash_payments+invoices_cash_payments

                    let closing_cash = closing_cash1>closing_cash2?closing_cash1:closing_cash2 
                    let total_cash = closing_cash +Floating_cash
                    if (day_shift) { 
                        this.input = {
                            ...day_shift,
                            ...this.input,
                            day_shift_key:day_shift.key,
                            expected_cash:total_cash,
                            closing_cash:day_shift.total_closing_cash?day_shift.total_closing_cash:closing_cash
                        }
                        setTimeout(() => {
                            this.show_input = true  
                        }, 1000);
                    }else{
                        this.show_input = true
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_SELECT_SHIFT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            READ_REPORT(){
                try {  
                    this.MBS.actions.go(-1)
                    setTimeout(() => {
                        let salesReport = this.MBS.actions.COMPANY_LINK("/day-shift-report/"+this.day_shift_key,true)
                        this.MBS.actions.go(salesReport) 
                    }, 5);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'READ_REPORT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            ON_PDF(){
                try { 
                     this.MBS.actions.dialog(null,true,false,
                        "PDF OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK") 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_PDF',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_EXCEL(){
                try { 
                    this.MBS.actions.dialog(null,true,false,
                        "EXCEL OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK")  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_EMAIL(){
                try { 
                    this.MBS.actions.dialog(null,true,false,
                        "EMAIL OPTION",
                        "Current not working. Try again later. For more info contact system admin",null,"OK") 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_PRINTING(){
                try { 
                    let lastSale = this.ThisDayShift 
                    this.autoPrint = false
                    setTimeout(() => {
                        this.itemPrint = lastSale
                        this.typePrint = 'z_read'
                        this.autoPrint = true   
                    }, 5);
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            //---------------[SALES CALCULATIONS]------------ 
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let sold_price = this.toNumber(item.sold_price)
                    let discount = this.toNumber(item.discount)
                    let quantity = this.toNumber(item.quantity)
                    return quantity*(sold_price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },   
            ORDER_ITEMS(orders){  
                try { 
                    if(!orders){return null} 
                    let items = []
                    orders.forEach(element => {
                        let joined_items = element.joined_items
                        if (joined_items) {
                            joined_items.forEach(item => {
                                items.push({
                                    ...item,
                                    account_type:element.account_type
                                }) 
                            });
                            
                        }
                    }); 
                    return items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ORDER_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            RETAIL_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(element.selling_model)) ==  
                        this.MBS.actions.TEXT_SPACE0("retail price")
                    });
                    return filtered_items
                } catch (error) { 
                    this.MBS.actions.error({
                        error:error,
                        from:'RETAIL_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            WHOLESALE_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(element.selling_model)) ==  
                        this.MBS.actions.TEXT_SPACE0("wholesale price")
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'WHOLESALE_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CASH_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return element.account_type == "cash"
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CASH_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_ACCOUNT_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return element.account_type == "on_account"
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_ACCOUNT_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            TOTAL_SOLD(items){
                try {  
                    let total = 0
                    if (items) {
                        items.forEach(element => {
                            let sold_price = this.toNumber(element.sold_price)
                            let quantity = this.toNumber(element.quantity)
                            let discount = this.toNumber(element.discount)
                            total = total+((sold_price-discount)*quantity)
                        }); 
                    }
                    return total
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CLOSE_REGISTER',
                        page:PAGE_NAME, 
                    })
                }  
            },  
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=CREATE-ORDER') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.SUBMIT_ORDER(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }  
                    } 
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=FORCE-AND-CLOSE') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.FORCE_AND_CLOSE_TILL(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);  
                            } else {
                                setTimeout(() => { 
                                    this.CONFIRM_ASK_TO_CLOSE_TILL(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=ASK-TO-CLOSE') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.ASK_TO_CLOSE_TILL(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);  
                            } else {   
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=SEND-TILL-ACTION') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.SENDING_TILL_ACTION(action.data.till,action.data.action)
                                }, this.MBS.data.ACTION_REFRESH_TIME);  
                            } else {   
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })  
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
        },
        watch: {
            search_item(value){ 
                if (value) {
                    this.ADD_ITEM(value) 
                }
            },
            input(value){   
                if (value) {
                    if (value.day_shift_key != this.day_shift_key) {
                        this.day_shift_key = value.day_shift_key
                        this.ON_SELECT_SHIFT(value)
                    }  
                } 
            },
            ThisDayShift(value){    
                this.ON_SELECT_SHIFT(value)
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
