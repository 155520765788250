import DATA from "./DATA" 
import formatNumber  from './formatNumber'   
import moment from 'moment'  
import WORKER from 'simple-web-worker'
let NAME = "Actions" 

const toNumber=formatNumber.toNumber
const toMoney=formatNumber.toMoney
const toSameLength=formatNumber.toSameLength


 
const toDateTime =(date_string)=>{ 
    let date = moment(date_string).format('yyyy/MM/DD HH:mm') 
    if (date == "Invalid date") {
        date = moment(toNumber(date_string)).format('yyyy/MM/DD HH:mm')  
    } 
    return  date  
}
const toDate = (date_string)=>{ 
    let date = moment(date_string).format('yyyy/MM/DD') 
    if (date == "Invalid date") {
        date = moment(toNumber(date_string)).format('yyyy/MM/DD HH:mm')  
    } 
    return  date  
}
const toTime = (date_string)=>{ 
    let time = moment(date_string).format('HH:mm') 
    if (time == "Invalid date") {
        time = moment(toNumber(date_string)).format('yyyy/MM/DD HH:mm')  
    } 
    return  time  
}
const TEXT_UP = (text)=>{ 
    if (!text) {return null}
    let tx = text.toUpperCase()
    return tx  
}
const TEXT_UP1 = (text)=>{ 
    if (!text) {return null}
    let tx = text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    return tx  
}
const TEXT_UP11 = (text)=>{ 
    if (!text) {return null}
    var splitStr = text.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) { 
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    } 
    return splitStr.join(' ');   
}
const TEXT_UP0 = (text)=>{ 
    if (!text) {return null} 
    if (typeof text != "string") {return null} 
    return text.toLowerCase();   
}
const TEXT_EQ = (text,text2)=>{ 
    if (TEXT_UP(text)===TEXT_UP(text2)) {
        return true
    } 
    return false   
}
const TEXT_SPLIT_N = (text)=>{ 
    if(!text){return null}
    return text.split('\n')  
}
const TEXT_SPACE0 = (text)=>{ 
    if(!text){return ''}
    return text.replace(/\s/g,'')  
}
const SIZE = (item)=>{ 
    if(!item) {return 0}
    return item.length  
}
const DEFINED_ONLY = (obj)=>{ 
    if(!obj) {return null}
    if (typeof obj === 'object' && obj !== null) {
        let newObj = [] 
        for (const key in obj) {
            let item = obj[key]
            if (item) { 
                newObj[key]=item 
            }
        }
        return newObj
    }
    return null 
}
const findUndefined = (obj, path = '')=>{
    const undefinedPaths = [];
    for (const key in obj) {
      const newPath = path ? `${path}.${key}` : key;
      const value = obj[key];
  
      if (typeof value === 'object' && value !== null) {
        undefinedPaths.push(...findUndefined(value, newPath));
      } else if (value === undefined) {
        undefinedPaths.push(newPath);
      }
    }
    return undefinedPaths;
} 
const hasUndefinedProperties = (obj)=>{
    const undefinedPaths = findUndefined(obj);
    return undefinedPaths.length > 0;
} 
const replaceUndefinedWithNull = (obj)=>{
    if (typeof obj === 'object' && obj !== null) {
      for (const key in obj) {
        obj[key] = replaceUndefinedWithNull(obj[key]);
      }
    } else if (obj === undefined) {
      obj = null;
    }
    return obj;
}
const mbs = {
        toDateTime:toDateTime, 
        toDate:toDate,
        toTime:toTime,
        TEXT_UP:TEXT_UP,
        TEXT_UP1:TEXT_UP1,
        TEXT_UP11:TEXT_UP11,
        TEXT_UP0:TEXT_UP0,
        TEXT_EQ:TEXT_EQ,
        TEXT_SPLIT_N:TEXT_SPLIT_N,
        TEXT_SPACE0:TEXT_SPACE0,
        DEFINED_ONLY:DEFINED_ONLY,
        findUndefined:findUndefined,
        hasUndefinedProperties:hasUndefinedProperties,
        replaceUndefinedWithNull:replaceUndefinedWithNull,
    }  
export { 
    toDateTime, 
    toDate,
    toTime,
    TEXT_UP,
    TEXT_UP1,
    TEXT_UP11,
    TEXT_UP0,
    TEXT_EQ,
    TEXT_SPLIT_N,
    TEXT_SPACE0, 
    SIZE, 
    DEFINED_ONLY,
    findUndefined,
    hasUndefinedProperties,
    replaceUndefinedWithNull
};