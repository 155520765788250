    
// import * as firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import "firebase/firestore"
import users from './../users'   
import app_store from './../app'   
import DATA from './../../plugins/DATA' 
import DB from './../../plugins/DB'  
import WORKER from 'simple-web-worker'
import  { 
  toDateTime, 
  toDate,
  toTime,
  TEXT_UP,
  TEXT_UP1,
  TEXT_UP11,
  TEXT_UP0,
  TEXT_EQ,
  TEXT_SPLIT_N,
  TEXT_SPACE0, 
  DEFINED_ONLY,
  findUndefined,
  hasUndefinedProperties,
  replaceUndefinedWithNull
} from './../../plugins/MBS_actions' 
import formatNumber  from '../../plugins/formatNumber'   
import moment from 'moment'     
const toNumber=formatNumber.toNumber
const toMoney=formatNumber.toMoney
const toSameLength=formatNumber.toSameLength
 
//ref
let REF_BILLING_PLANS = null
let REF_BILLING_PLAN_FEATURES = null
let REF_CURRENT_BILLING_PLANS = null
let REF_SUBSCRIPTIONS = null

let REF_ERRORS = null
let REF_MESSAGES = null
let REF_NOTIFICATIONS = null
let REF_COUNTRIES = null
let REF_REGIONS = null
let REF_DISTRICTS = null 

let REF_ATTENDANCES = null 
let REF_DESIGNATIONS = null 

let REF_ID_TYPES = null
let REF_DELIVERING_STEPS = null
let REF_ACTION_HOLDERS = null
let REF_BANK_ORDERS = null
let REF_CANCELLATION_FEES = null 

let REF_MEASUREMENT_UNITS = null
let REF_COMPANIES = null
let REF_COMPANY_TYPES = null
let REF_COMPANY_SIZES = null
let REF_COMPANY_USERS = null
let REF_COMPANY_USER_GROUPS = null
let REF_COMPANY_QUICK_ACTIONS = null

let REF_DEVICES = null
let REF_CARDS = null
let REF_PRICING_TIERS = null
let REF_TILLS = null
let REF_DAYS_SHIFTS = null
let REF_DAYS_SHIFTS_SALES = null
let REF_DAY_SALES = null
let REF_DAY_ORDERS = null
let REF_CASHUPS = null
let REF_STOCKS = null
let REF_STOCK_OUTLETS = null
let REF_STOCK_ORDERS = null
//let REF_STOCK_ORDER_ITEMS = null
//let REF_STOCKS_RETURN = null
let REF_SALE_ORDERS = null
// let REF_SALE_ORDER_ITEMS = null
let REF_RETURNED_SALES = null
let REF_QUOTATIONS_AND_INVOICES = null
let REF_QUOTATIONS = null
// let REF_INVOICES = null
let REF_BRANDS = null
let REF_MANUFACTURERS = null
let REF_SUPPLIERS = null
let REF_SUPPLIER_CATEGORIES = null
let REF_CUSTOMERS = null
let REF_CUSTOMER_GROUPS = null
let REF_ITEMS = null
let REF_ITEM_CATEGORIES = null
let REF_ITEM_DEPARTMENTS = null
let REF_ITEM_KITS = null
let REF_ITEM_ORDERS = null
let REF_ITEM_ORDER_OPTIONS = null
let REF_PATIENT_ORDERS = null
let REF_PATIENT_HISTORIES = null

let REF_ASSETS = null 
let REF_ASSET_TYPES = null 
let REF_PAYABLES = null 
let REF_RECEIVABLES = null 
let REF_PAID_INVOICES = null 
let REF_UNPAID_INVOICES = null 
let REF_CREDITORS = null 
let REF_DEBTORS = null 
let REF_PAYMENTS = null 
let REF_EXPENSES = null 
let REF_EXPENSE_CATEGORIES = null 
let REF_EXPENSE_ACCOUNTS = null 
let REF_TRACKING_UPDATES = null 
let REF_TRACKING_KEYS = null 
let REF_COMPANY_SETTINGS = null 
let REF_SELECTED_COMPANY = null 
let REF_EXPIRIES = null 
let REF_PAYMENT_METHODS = null 
let REF_MONEY_ACCOUNTS= null 
let REF_MONEY_ACCOUNT_TRANSACTIONS= null 
let REF_STOCK_TAKING_ORDERS= null 
let REF_STOCK_TAKINGS= null 
 

let NAME = "STORE-ITEM"

export default{
    state: {  
      BILLING_PLANS : null,
      BILLING_PLAN_FEATURES : null,
      CURRENT_BILLING_PLANS : null,
      SUBSCRIPTIONS : null,


      ERRORS:null, 
      K_CONNECTOR:null, 
      MESSAGES:null, 
      NOTIFICATIONS:null,
      SERVICES:null, 
      COUNTRIES:null,  
      REGIONS:null,  
      DISTRICTS:null, 

      ATTENDANCES:null,  
      DESIGNATIONS:null,  

      ID_TYPES:null, 
      DELIVERING_STEPS:null, 
      ACTION_HOLDERS:null, 
      BANK_ORDERS:null, 
      CANCELLATION_FEES:null,

      MEASUREMENT_UNITS:null, 
      SELECTED_COMPANY:null, 
      SELECTED_COMPANY_KEY:null, 
      COMPANIES:null, 
      COMPANY_QUICK_ACTIONS:null, 
      COMPANY_TYPES:null, 
      COMPANY_SIZES:null, 
      COMPANY_USERS:null, 
      COMPANY_USER_GROUPS:null, 
      
      
      DEVICES:null, 
      CONNECTED_PRINTERS:null, 
      CARDS:null, 
      PRICING_TIERS:null, 
      TILLS:null, 
      DAYS_SHIFTS:null, 
      DAYS_SHIFTS_SALES:null, 
      DAY_SALES:null, 
      DAY_ORDERS:null, 
      CASHUPS:null, 
      STOCKS:null, 
      STOCK_OUTLETS:null, 
      STOCK_ORDERS:null, 
      STOCK_ORDER_ITEMS:null, 
      STOCKS_RETURN:null, 
      SALE_ORDERS:null, 
      LOCAL_SALE_ORDERS:null, 
      SALE_ORDER_ITEMS:null, 
      RETURNED_SALES:null, 
      QUOTATIONS_AND_INVOICES:null, 
      QUOTATIONS:null, 
      INVOICES:null, 
      BRANDS:null, 
      MANUFACTURERS:null, 
      SUPPLIERS:null, 
      SUPPLIER_CATEGORIES:null, 
      CUSTOMERS:null, 
      CUSTOMER_GROUPS:null, 
      ITEMS:null, 
      ARCHIVED_ITEMS:null, 
      ITEM_CATEGORIES:null, 
      ITEM_DEPARTMENTS:null, 
      ITEM_KITS:null, 
      ITEM_ORDERS:null, 
      ITEM_ORDER_OPTIONS:null, 
      LOCAL_ITEM_ORDERS:null, 
      PATIENT_ORDERS:null, 
      PATIENT_HISTORIES:null, 
      
      ASSETS:null, 
      ASSET_TYPES:null, 
      PAYABLES:null, 
      RECEIVABLES:null, 
      PAID_INVOICES:null, 
      UNPAID_INVOICES:null, 
      CREDITORS:null, 
      DEBTORS:null, 
      PAYMENTS:null, 
      EXPENSES:null, 
      EXPENSE_CATEGORIES:null, 
      EXPENSE_ACCOUNTS:null, 
      
      TRACKING_UPDATES:null, 
      TRACKING_KEYS:null, 
      COMPANY_SETTINGS:null, 
      EXPIRIES:null, 
      PAYMENT_METHODS:null, 
      MONEY_ACCOUNTS:null, 
      MONEY_ACCOUNT_TRANSACTIONS:null, 
      STOCK_TAKING_ORDERS:null, 
      STOCK_TAKINGS:null, 
      
      LOCAL_CASHUPS:null, 
      LOCAL_STOCK_TAKING_ITEMS:null,  
      LOCAL_JOINED_STOCK_TAKINGS:null,  
      PAGE_HISTORIES:null,  
      LOCAL_WAREHOUSE_SESSIONS:null,  
      LOCAL_WAREHOUSE_ORDERS:null,  
       
      
      ALL_OPENING_STOCKS_ITEMS:null,  

      USER_ROLES:[
        'administrator','editor','advertiser'
      ],  
      DEFAULT_PAYMENT_METHODS : [
        'Bill','Cash','Check','Credit Card'
      ],
      BILL_TERMS: [
        'Due on receipt','7 Days','15 Days','30 Days','60 Days','3 Months','6 Months','Year'
      ],
      DOWNLOAD_REQUESTS:[],
      SHOW_OFFLINE:false,
      TEXT_CHANGE:null
    },
    //___________________-GETTERS-_________________
    getters: {  
      //billing
      getBillingPlans(state) {
        return state.BILLING_PLANS 
      }, 
      getBillingPlanByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.BILLING_PLANS){return null}
          return state.BILLING_PLANS.find(ct=>{
            return ct.key == key
          }) 
        }
      }, 
      getBillingPlanCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.BILLING_PLANS){return null}
          return state.BILLING_PLANS.find(ct=>{
            return ct.code == code
          }) 
        }
      },

      getBillingPlanFeatures(state) {
        return state.BILLING_PLAN_FEATURES 
      }, 
      getBillingPlanFeatureByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.BILLING_PLAN_FEATURES){return null}
          return state.BILLING_PLAN_FEATURES.find(ct=>{
            return ct.key == key
          }) 
        }
      }, 
      getBillingPlanFeatureByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.BILLING_PLAN_FEATURES){return null}
          return state.BILLING_PLAN_FEATURES.find(ct=>{
            return ct.code == code
          }) 
        }
      },
      
      getSubscriptions(state) {
        return state.SUBSCRIPTIONS 
      }, 
      getSubscriptionByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.SUBSCRIPTIONS){return null}
          return state.SUBSCRIPTIONS.find(ct=>{
            return ct.key == key
          }) 
        }
      }, 
      getSubscriptionCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.SUBSCRIPTIONS){return null}
          return state.SUBSCRIPTIONS.find(ct=>{
            return ct.code == code
          }) 
        }
      },
      //
      getItemsState(state) { 
        return state
      }, 
      getLocalSaleOrders(state) { 
        return state.LOCAL_SALE_ORDERS
      }, 
      getAppErrors(state) {
        return state.ERRORS 
      }, 
      getAppErrorByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.ERRORS){return null}
          return state.ERRORS.find(ct=>{
            return ct.key == key
          }) 
        }
      },  
      getMessages(state) {
        return state.MESSAGES
      }, 
      getNotification(state) {
        return state.NOTIFICATIONS
      }, 
      getLiveServices(state) {
        return state.SERVICES
      },   
      getMyCountries(state) {
        return state.COUNTRIES 
      }, 
      getMyCountryByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.COUNTRIES){return null}
          return state.COUNTRIES.find(ct=>{
            return ct.key == key
          }) 
        }
      }, 
      getMyCountryByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.COUNTRIES){return null}
          return state.COUNTRIES.find(ct=>{
            return ct.code == code
          }) 
        }
      }, 
      getRegions(state) {
        return state.REGIONS 
      }, 
      getRegionByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.REGIONS){return null}
          return state.REGIONS.find(ct=>{
            return ct.key == key
          }) 
        }
      }, 
      getRegionByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.REGIONS){return null}
          return state.REGIONS.find(ct=>{
            return ct.code == code
          }) 
        }
      }, 
      getDistricts(state) {
        return state.DISTRICTS 
      }, 
      getDistrictByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.DISTRICTS){return null}
          return state.DISTRICTS.find(ct=>{
            return ct.key == key
          }) 
        }
      }, 
      getDistrictByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.DISTRICTS){return null}
          return state.DISTRICTS.find(ct=>{
            return ct.code == code
          }) 
        }
      }, 
      
      getAttendances(state) {
        return state.ATTENDANCES 
      }, 
      getAttendanceByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.ATTENDANCES){return null}
          return state.ATTENDANCES.find(ct=>{
            return ct.key == key
          }) 
        }
      }, 
      getAttendanceByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.ATTENDANCES){return null}
          return state.ATTENDANCES.find(ct=>{
            return ct.code == code
          }) 
        }
      }, 
      getDesignations(state) {
        return state.DESIGNATIONS 
      }, 
      getDesignationByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.DESIGNATIONS){return null}
          return state.DESIGNATIONS.find(ct=>{
            return ct.key == key
          }) 
        }
      }, 
      getDesignationByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.DESIGNATIONS){return null}
          return state.DESIGNATIONS.find(ct=>{
            return ct.code == code
          }) 
        }
      }, 
      getIdTypes(state) {
        return state.ID_TYPES 
      },  
      getIdTypeByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.ID_TYPES){return null}
          return state.ID_TYPES.find(ct=>{
            return ct.key == key
          }) 
        }
      }, 
      getIdTypeByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.ID_TYPES){return null}
          return state.ID_TYPES.find(ct=>{
            return ct.code == code
          }) 
        }
      }, 
      getActionHolders(state) {
        return state.ACTION_HOLDERS 
      },  
      getActionHolderByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.ACTION_HOLDERS){return null}
          return state.ACTION_HOLDERS.find(ct=>{
            return ct.key == key
          }) 
        }
      }, 
      getBankOrders(state) {
        return state.BANK_ORDERS 
      },  
      getBankOrderByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.BANK_ORDERS){return null}
          return state.BANK_ORDERS.find(ct=>{
            return ct.key == key
          }) 
        }
      }, 
      getBankOrderByUid(state) {
        return uid=>{
          if(!uid){return null}
          if(!state.BANK_ORDERS){return null}
          return state.BANK_ORDERS.find(ct=>{
            return ct.uid == uid
          }) 
        }
      }, 
      getCancellationFees(state) {
        return state.CANCELLATION_FEES 
      },  
      getCancellationFeeByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.CANCELLATION_FEES){return null}
          return state.CANCELLATION_FEES.find(ct=>{
            return ct.key == key
          }) 
        }
      }, 
      
      getMeasurementUnities(state) {
        return state.MEASUREMENT_UNITS 
      },  
      getMeasurementUnitByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.MEASUREMENT_UNITS){return null}
          return state.MEASUREMENT_UNITS.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getCompanies(state) {
        return state.COMPANIES 
      },  
      getCompanyByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.COMPANIES){return null}
          return state.COMPANIES.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getCompanyTypes(state) {
        return state.COMPANY_TYPES 
      },  
      getCompanyTypeByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.COMPANY_TYPES){return null}
          return state.COMPANY_TYPES.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getCompanySizes(state) {
        return state.COMPANY_SIZES 
      },  
      getCompanySizeByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.COMPANY_SIZES){return null}
          return state.COMPANY_SIZES.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getCompanyUsers(state) {
        return state.COMPANY_USERS 
      },  
      getCompanyUserByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.COMPANY_USERS){return null}
          return state.COMPANY_USERS.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getCompanyUserByPhoneNumber(state) {
        return phone_number=>{
          if(!phone_number){return null}
          if(!state.COMPANY_USERS){return null}
          return state.COMPANY_USERS.find(item=>{
            return item.phone_number == phone_number
          }) 
        }
      }, 
      getCompanyUserGroups(state) {
        return state.COMPANY_USER_GROUPS 
      },  
      getCompanyUserGroupByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.COMPANY_USER_GROUPS){return null}
          return state.COMPANY_USER_GROUPS.find(item=>{
            return item.key == key
          }) 
        }
      },
      getCompanyUserGroupByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.COMPANY_USER_GROUPS){return null}
          return state.COMPANY_USER_GROUPS.find(item=>{
            return item.code == code
          }) 
        }
      }, 
      
      getCompanyQuickActions(state) {
        return state.COMPANY_QUICK_ACTIONS
      },  
      getCompanyQuickActionByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.COMPANY_QUICK_ACTIONS){return null}
          return state.COMPANY_QUICK_ACTIONS.find(item=>{
            return item.key == key
          }) 
        }
      },
      getCompanyQuickActionByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.COMPANY_QUICK_ACTIONS){return null}
          return state.COMPANY_QUICK_ACTIONS.find(item=>{
            return item.code == code
          }) 
        }
      },
      getDeviceByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.DEVICES){return null}
          return state.DEVICES.find(item=>{
            return item.key == key
          }) 
        }
      },
      
      getTills(state) {
        return state.TILLS
      },  
      getTillByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.TILLS){return null}
          return state.TILLS.find(item=>{
            return item.key == key
          }) 
        }
      },
      getTillByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.TILLS){return null}
          return state.TILLS.find(item=>{
            return item.code == code
          }) 
        }
      },
      getPricingTiers(state) {
        const app_state = app_store.state
        const defaults = app_state.DEFAULT_PRICING_TIERS
        const state_data = state.PRICING_TIERS
        let new_data = [
          ...defaults, 
        ] 
        if (state_data) {
          state_data.forEach(data => {
            let index = new_data.findIndex(item=>item.key==data.key)
            if (index==-1) {
              new_data.push(data) 
            } else {
              new_data[index] = {
                ...new_data[index],
                data
              }
            }
            
          });
        }
        return new_data
      },  
      getPricingTierByKey(state) {
        const app_state = app_store.state
        const defaults = app_state.DEFAULT_PRICING_TIERS
        const state_data = state.PRICING_TIERS
        let new_data = [
          ...defaults, 
        ] 
        if (state_data) {
          state_data.forEach(data => {
            let index = new_data.findIndex(item=>item.key==data.key)
            if (index==-1) {
              new_data.push(data) 
            } else {
              new_data[index] = {
                ...new_data[index],
                data
              }
            }
            
          });
        }
        return key=>{
          if(!key){return null}
          if(!new_data){return null}
          return new_data.find(item=>{
            return item.key == key
          }) 
        }
      },
      getPricingTierByCode(state) {
        const app_state = app_store.state
        const defaults = app_state.DEFAULT_PRICING_TIERS
        const state_data = state.PRICING_TIERS
        let new_data = [
          ...defaults, 
        ] 
        if (state_data) {
          state_data.forEach(data => {
            let index = new_data.findIndex(item=>item.key==data.key)
            if (index==-1) {
              new_data.push(data) 
            } else {
              new_data[index] = {
                ...new_data[index],
                data
              }
            }
            
          });
        }
        return code=>{ 
          if(!code){return null}
          if(!new_data){return null}
          return new_data.find(item=>{
            return item.code == code
          }) 
        }
      },
      getDeviceByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.DEVICES){return null}
          return state.DEVICES.find(item=>{
            return item.key == key
          }) 
        }
      },
      getDeviceByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.DEVICES){return null}
          return state.DEVICES.find(item=>{
            return item.code == code
          }) 
        }
      },
      getCardByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.CARDS){return null}
          return state.CARDS.find(item=>{
            return item.key == key
          }) 
        }
      },
      getCardByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.CARDS){return null}
          return state.CARDS.find(item=>{
            return item.code == code
          }) 
        }
      },
      getCashups(state) {
        return state.CASHUPS
      },  
      getCashupByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.CASHUPS){return null}
          return state.CASHUPS.find(item=>{
            return item.key == key
          }) 
        }
      },
      getCashupByDayShiftKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.CASHUPS){return null}
          return state.CASHUPS.filter(item=>{
            return item.day_shift_key == key
          }) 
        }
      },
      getDayShifts(state) {
        return state.DAYS_SHIFTS
      },  
      getDayShiftByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.DAYS_SHIFTS){return null}
          return state.DAYS_SHIFTS.find(item=>{
            return item.key == key
          }) 
        }
      },
      getStockOutlets(state) {
        return state.STOCK_OUTLETS 
      },  
      getStockOutletByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.STOCK_OUTLETS){return null}
          return state.STOCK_OUTLETS.find(item=>{
            return item.key == key
          }) 
        }
      },
      getStockOutletByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.STOCK_OUTLETS){return null}
          return state.STOCK_OUTLETS.find(item=>{
            return item.code == code
          }) 
        }
      }, 
      getStockOrders(state) {
        return state.STOCK_ORDERS 
      },  
      getStockOrderByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.STOCK_ORDERS){return null}
          return state.STOCK_ORDERS.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getStockOrderByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.STOCK_ORDERS){return null}
          return state.STOCK_ORDERS.find(item=>{
            return item.code == code
          }) 
        }
      }, 
      
      getStockOrderItems(state) {
        return state.STOCK_ORDER_ITEMS 
      },  
      getStockOrderItemByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.STOCK_ORDER_ITEMS){return null}
          return state.STOCK_ORDER_ITEMS.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getStockOrderItemByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.STOCK_ORDER_ITEMS){return null}
          return state.STOCK_ORDER_ITEMS.find(item=>{
            return item.code == code
          }) 
        }
      }, 
      getStockOrderItemsByOrderKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.STOCK_ORDER_ITEMS){return null}
          return state.STOCK_ORDER_ITEMS.filter(item=>{
            return item.order_key == key
          }) 
        }
      }, 
      getStockOrderItemsByItemKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.STOCK_ORDER_ITEMS){return null}
          return state.STOCK_ORDER_ITEMS.filter(item=>{
            return item.item_key == key
          }) 
        }
      }, 
      getStocksReturn(state) {
        return state.STOCKS_RETURN 
      },  
      getStockByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.STOCKS_RETURN){return null}
          return state.STOCKS_RETURN.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getSaleOrders(state) {
        return state.SALE_ORDERS 
      },  
      getSaleOrderByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.SALE_ORDERS){return null}
          return state.SALE_ORDERS.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getSaleOrderByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.SALE_ORDERS){return null}
          return state.SALE_ORDERS.find(item=>{
            return item.code == code
          }) 
        }
      }, 
      getSaleOrderItems(state) {
        return state.SALE_ORDER_ITEMS 
      },  
      getSaleOrderItemByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.SALE_ORDER_ITEMS){return null}
          return state.SALE_ORDER_ITEMS.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getSaleOrderItemByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.SALE_ORDER_ITEMS){return null}
          return state.SALE_ORDER_ITEMS.find(item=>{
            return item.code == code
          }) 
        }
      }, 
      getSaleOrderItemsByOrderKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.SALE_ORDER_ITEMS){return null}
          return state.SALE_ORDER_ITEMS.filter(item=>{
            return item.order_key == key
          }) 
        }
      }, 
      getSaleOrderItemsByItemKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.SALE_ORDER_ITEMS){return null}
          return state.SALE_ORDER_ITEMS.filter(item=>{
            return item.item_key == key
          }) 
        }
      }, 
      getSalesReturn(state) {
        return state.RETURNED_SALES 
      },  
      getSaleReturnByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.RETURNED_SALES){return null}
          return state.RETURNED_SALES.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getQuotationsAndInvoices(state) {
        return state.QUOTATIONS_AND_INVOICES 
      },  
      getQuotationAndInvoiceByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.QUOTATIONS_AND_INVOICES){return null}
          return state.QUOTATIONS_AND_INVOICES.find(item=>{
            return item.key == key
          }) 
        }
      },
      getQuotationAndInvoiceByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.QUOTATIONS_AND_INVOICES){return null}
          return state.QUOTATIONS_AND_INVOICES.find(item=>{
            return item.code == code
          }) 
        }
      },
      getQuotations(state) {
        return state.QUOTATIONS 
      },  
      getQuotationByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.QUOTATIONS){return null}
          return state.QUOTATIONS.find(item=>{
            return item.key == key
          }) 
        }
      },
      getQuotationByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.QUOTATIONS){return null}
          return state.QUOTATIONS.find(item=>{
            return item.code == code
          }) 
        }
      },
      getInvoice(state) {
        return state.INVOICES 
      },  
      getInvoicesByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.INVOICES){return null}
          return state.INVOICES.find(item=>{
            return item.key == key
          }) 
        }
      },
      getInvoicesByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.INVOICES){return null}
          return state.INVOICES.find(item=>{
            return item.code == code
          }) 
        }
      },
      getBrands(state) {
        return state.BRANDS 
      },  
      getBrandByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.BRANDS){return null}
          return state.BRANDS.find(item=>{
            return item.key == key
          }) 
        }
      },
      getBrandByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.BRANDS){return null}
          return state.BRANDS.find(item=>{
            return item.code == code
          }) 
        }
      },
      getManufacturers(state) {
        return state.MANUFACTURERS 
      },  
      getManufacturerByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.MANUFACTURERS){return null}
          return state.MANUFACTURERS.find(item=>{
            return item.key == key
          }) 
        }
      },
      getManufacturerByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.MANUFACTURERS){return null}
          return state.MANUFACTURERS.find(item=>{
            return item.code == code
          }) 
        }
      },
      getSuppliers(state) {
        return state.SUPPLIERS 
      },  
      getSupplierByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.SUPPLIERS){return null}
          return state.SUPPLIERS.find(item=>{
            return item.key == key
          }) 
        }
      },
      getSupplierByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.SUPPLIERS){return null}
          return state.SUPPLIERS.find(item=>{
            return item.code == code
          }) 
        }
      },
      getSupplierCategories(state) {
        return state.SUPPLIER_CATEGORIES 
      },  
      getSupplierCategoryByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.SUPPLIER_CATEGORIES){return null}
          return state.SUPPLIER_CATEGORIES.find(item=>{
            return item.key == key
          }) 
        }
      },
      getSupplierCategoryByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.SUPPLIER_CATEGORIES){return null}
          return state.SUPPLIER_CATEGORIES.find(item=>{
            return item.code == code
          }) 
        }
      },
      getCustomers(state) {
        return state.CUSTOMERS 
      },  
      getCustomerByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.CUSTOMERS){return null}
          return state.CUSTOMERS.find(item=>{
            return item.key == key
          }) 
        }
      },
      getCustomerByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.CUSTOMERS){return null}
          return state.CUSTOMERS.find(item=>{
            return item.code == code
          }) 
        }
      },
      getCustomerGroups(state) {
        return state.CUSTOMER_GROUPS 
      },  
      getCustomerGroupByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.CUSTOMER_GROUPS){return null}
          return state.CUSTOMER_GROUPS.find(item=>{
            return item.key == key
          }) 
        }
      },
      getCustomerGroupByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.CUSTOMER_GROUPS){return null}
          return state.CUSTOMER_GROUPS.find(item=>{
            return item.code == code
          }) 
        }
      },
      getItems(state) {
        return state.ITEMS 
      },  
      getItemByKey(state) {
        return key=>{
          if(!key){return null}
          // if(key=="tip"){return {
          //   name:"Tip",
          //   key:'tip',
          //   code:'tip',
          // }}
          if(!state.ITEMS){return null}
          return state.ITEMS.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getItemByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.ITEMS){return null}
          return state.ITEMS.find(item=>{
            return item.code == code
          }) 
        }
      }, 
      getParentItems(state) {
        return parent_item_code=>{
          if(!parent_item_code){return null}
          if(!state.ITEMS){return null}
          return state.ITEMS.filter(item=>{
            return item.parent_item_code == parent_item_code
          }) 
        }
      }, 
      getItemCategories(state) {
        return state.ITEM_CATEGORIES 
      },  
      getItemCategoryByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.ITEM_CATEGORIES){return null}
          return state.ITEM_CATEGORIES.find(item=>{
            return item.key == key
          }) 
        }
      },
      getItemCategoryByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.ITEM_CATEGORIES){return null}
          return state.ITEM_CATEGORIES.find(item=>{
            return item.code == code
          }) 
        }
      },
      getItemDepartments(state) {
        return state.ITEM_DEPARTMENTS 
      },  
      getItemDepartmentByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.ITEM_DEPARTMENTS){return null}
          return state.ITEM_DEPARTMENTS.find(item=>{
            return item.key == key
          }) 
        }
      },
      getItemDepartmentByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.ITEM_DEPARTMENTS){return null}
          return state.ITEM_DEPARTMENTS.find(item=>{
            return item.code == code
          }) 
        }
      },
      getItemOrders(state) {
        return state.ITEM_ORDERS 
      },  
      getItemOrderByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.ITEM_ORDERS){return null}
          return state.ITEM_ORDERS.find(item=>{
            return item.key == key
          }) 
        }
      },
      getItemOrderByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.ITEM_ORDERS){return null}
          return state.ITEM_ORDERS.find(item=>{
            return item.code == code
          }) 
        }
      },

      getPatientOrders(state) {
        return state.PATIENT_ORDERS 
      },  
      getPatientOrderByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.PATIENT_ORDERS){return null}
          return state.PATIENT_ORDERS.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getPatientOrderByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.PATIENT_ORDERS){return null}
          return state.PATIENT_ORDERS.find(item=>{
            return item.code == code
          }) 
        }
      },

      getPatientHistories(state) {
        return state.PATIENT_HISTORIES 
      },  
      getPatientHistoryByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.PATIENT_HISTORIES){return null}
          return state.PATIENT_HISTORIES.find(item=>{
            return item.key == key
          }) 
        }
      },
      getPatientHistoryByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.PATIENT_HISTORIES){return null}
          return state.PATIENT_HISTORIES.find(item=>{
            return item.code == code
          }) 
        }
      },
      getItemOrderOptions(state) {
        return state.ITEM_ORDER_OPTIONS 
      },  
      getItemOrderOptionByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.ITEM_ORDER_OPTIONS){return null}
          return state.ITEM_ORDER_OPTIONS.find(item=>{
            return item.key == key
          }) 
        }
      },
      getItemOrderOptionByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.ITEM_ORDER_OPTIONS){return null}
          return state.ITEM_ORDER_OPTIONS.find(item=>{
            return item.code == code
          }) 
        }
      }, 
      getLocalItemOrders(state) {
        return state.LOCAL_ITEM_ORDERS 
      },  
      getLocalItemOrderByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.LOCAL_ITEM_ORDERS){return null}
          return state.LOCAL_ITEM_ORDERS.find(item=>{
            return item.key == key
          }) 
        }
      },
      getLocalItemOrderByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.LOCAL_ITEM_ORDERS){return null}
          return state.LOCAL_ITEM_ORDERS.find(item=>{
            return item.code == code
          }) 
        }
      },
      getItemKits(state) {
        return state.ITEM_KITS 
      },  
      getItemKitByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.ITEM_KITS){return null}
          return state.ITEM_KITS.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getItemKitByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.ITEM_KITS){return null}
          return state.ITEM_KITS.find(item=>{
            return item.code == code
          }) 
        }
      }, 
      
      getAssets(state) {
        return state.ASSETS 
      },  
      getAssetByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.ASSETS){return null}
          return state.ASSETS.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getAssetByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.ASSETS){return null}
          return state.ASSETS.find(item=>{
            return item.code == code
          }) 
        }
      }, 
      getAssetTypes(state) {
        return state.ASSET_TYPES 
      },  
      getAssetTypeByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.ASSET_TYPES){return null}
          return state.ASSET_TYPES.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getAssetTypeByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.ASSET_TYPES){return null}
          return state.ASSET_TYPES.find(item=>{
            return item.code == code
          }) 
        }
      }, 
      getPayables(state) {
        return state.PAYABLES 
      },  
      getPayableByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.PAYABLES){return null}
          return state.PAYABLES.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getPayableByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.PAYABLES){return null}
          return state.PAYABLES.find(item=>{
            return item.code == code
          }) 
        }
      }, 
      getReceivables(state) {
        return state.RECEIVABLES 
      },  
      getReceivableByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.RECEIVABLES){return null}
          return state.RECEIVABLES.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getReceivableByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.RECEIVABLES){return null}
          return state.RECEIVABLES.find(item=>{
            return item.code == code
          }) 
        }
      }, 
      getPaidInvoices(state) {
        return state.PAID_INVOICES 
      },  
      getPaidInvoiceByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.PAID_INVOICES){return null}
          return state.PAID_INVOICES.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getPaidInvoiceByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.PAID_INVOICES){return null}
          return state.PAID_INVOICES.find(item=>{
            return item.code == code
          }) 
        }
      }, 
      getUnpaidInvoices(state) {
        return state.UNPAID_INVOICES 
      },  
      getUnpaidInvoiceByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.UNPAID_INVOICES){return null}
          return state.UNPAID_INVOICES.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getUnpaidInvoiceByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.UNPAID_INVOICES){return null}
          return state.UNPAID_INVOICES.find(item=>{
            return item.code == code
          }) 
        }
      }, 
      getCreditors(state) {
        return state.CREDITORS 
      },  
      getCreditorByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.CREDITORS){return null}
          return state.CREDITORS.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getCreditorByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.CREDITORS){return null}
          return state.CREDITORS.find(item=>{
            return item.code == code
          }) 
        }
      }, 
      getDebtors(state) {
        return state.DEBTORS 
      },  
      getDebtorByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.DEBTORS){return null}
          return state.DEBTORS.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getDebtorByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.DEBTORS){return null}
          return state.DEBTORS.find(item=>{
            return item.code == code
          }) 
        }
      }, 
      getPayments(state) {
        return state.PAYMENTS 
      },  
      getPaymentByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.PAYMENTS){return null}
          return state.PAYMENTS.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getExpenses(state) {
        return state.EXPENSES 
      },  
      getExpenseByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.EXPENSES){return null}
          return state.EXPENSES.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getExpenseByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.EXPENSES){return null}
          return state.EXPENSES.find(item=>{
            return item.code == code
          }) 
        }
      }, 
      getExpenseCategories(state) {
        return state.EXPENSE_CATEGORIES 
      },  
      getExpenseCategoryByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.EXPENSE_CATEGORIES){return null}
          return state.EXPENSE_CATEGORIES.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getExpenseCategoryByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.EXPENSE_CATEGORIES){return null}
          return state.EXPENSE_CATEGORIES.find(item=>{
            return item.code == code
          }) 
        }
      }, 
      getExpenseAccounts(state) {
        return state.EXPENSE_ACCOUNTS 
      },  
      getExpenseAccountByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.EXPENSE_ACCOUNTS){return null}
          return state.EXPENSE_ACCOUNTS.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getExpenseAccountByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.EXPENSE_ACCOUNTS){return null}
          return state.EXPENSE_ACCOUNTS.find(item=>{
            return item.code == code
          }) 
        }
      }, 
 
      getOpeningStockItems(state) {
        return payload=>{
          let item_key = payload?payload.item_key:null
          let archive = payload?payload.archive:null
          let all = payload?payload.all:null
          let items = null
          if (all) {
            items = state[TEXT_UP("ALL_"+DATA.ITEMS.OPENING_STOCK.values+"_ITEMS")]
          }else if (archive) {
            items = state[TEXT_UP("ARCHIVED_"+DATA.ITEMS.OPENING_STOCK.values+"_ITEMS")]
          }else{
            items = state[TEXT_UP(DATA.ITEMS.OPENING_STOCK.values+"_ITEMS")]
          }  
          if(!item_key||!items){return null}  
          return items.filter(item=>{
            return item.item_key == item_key
          }) 
        }
      }, 
      getReceivedStockItems(state) {
        return payload=>{
          let item_key = payload?payload.item_key:null
          let archive = payload?payload.archive:null
          let all = payload?payload.all:null
          let items = null
          if (all) {
            items = state[TEXT_UP("ALL_"+DATA.ITEMS.STOCK_RECEIVES.values+"_ITEMS")]
          }else if (archive) {
            items = state[TEXT_UP("ARCHIVED_"+DATA.ITEMS.STOCK_RECEIVES.values+"_ITEMS")]
          }else{
            items = state[TEXT_UP(DATA.ITEMS.STOCK_RECEIVES.values+"_ITEMS")]
          }  
          if(!item_key||!items){return null}  
          return items.filter(item=>{
            return item.item_key == item_key
          }) 
        }
      }, 
      getReturnedStockItems(state) {
        return payload=>{
          let item_key = payload?payload.item_key:null
          let archive = payload?payload.archive:null
          let all = payload?payload.all:null
          let items = null
          if (all) {
            items = state[TEXT_UP("ALL_"+DATA.ITEMS.STOCKS_RETURN.values+"_ITEMS")]
          }else if (archive) {
            items = state[TEXT_UP("ARCHIVED_"+DATA.ITEMS.STOCKS_RETURN.values+"_ITEMS")]
          }else{
            items = state[TEXT_UP(DATA.ITEMS.STOCKS_RETURN.values+"_ITEMS")]
          }  
          if(!item_key||!items){return null}  
          return items.filter(item=>{
            return item.item_key == item_key
          }) 
        }
      }, 
      getAdjustedStockItems(state) {
        return payload=>{
          let item_key = payload?payload.item_key:null
          let archive = payload?payload.archive:null
          let all = payload?payload.all:null
          let items = null
          if (all) {
            items = state[TEXT_UP("ALL_"+DATA.ITEMS.STOCK_ADJUSTMENTS.values+"_ITEMS")]
          }else if (archive) {
            items = state[TEXT_UP("ARCHIVED_"+DATA.ITEMS.STOCK_ADJUSTMENTS.values+"_ITEMS")]
          }else{
            items = state[TEXT_UP(DATA.ITEMS.STOCK_ADJUSTMENTS.values+"_ITEMS")]
          }  
          if(!item_key||!items){return null}  
          return items.filter(item=>{
            return item.item_key == item_key
          }) 
        }
      }, 
      getSoldItems(state) {
        return payload=>{
          let item_key = payload?payload.item_key:null
          let archive = payload?payload.archive:null
          let all = payload?payload.all:null
          let items = null
          if (all) {
            items = state[TEXT_UP("ALL_"+DATA.ITEMS.SALES.values+"_ITEMS")]
          }else if (archive) {
            items = state[TEXT_UP("ARCHIVED_"+DATA.ITEMS.SALES.values+"_ITEMS")]
          }else{
            items = state[TEXT_UP(DATA.ITEMS.SALES.values+"_ITEMS")]
          }   
          if(!item_key||!items){return null}  
          return items.filter(item=>{
            return item.item_key == item_key
          }) 
        }
      }, 
      getReturnedSoldItems(state) {
        return payload=>{
          let item_key = payload?payload.item_key:null
          let archive = payload?payload.archive:null
          let all = payload?payload.all:null
          let items = null
          if (all) {
            items = state[TEXT_UP("ALL_"+DATA.ITEMS.RETURNED_SALES.values+"_ITEMS")]
          }else if (archive) {
            items = state[TEXT_UP("ARCHIVED_"+DATA.ITEMS.RETURNED_SALES.values+"_ITEMS")]
          }else{
            items = state[TEXT_UP(DATA.ITEMS.RETURNED_SALES.values+"_ITEMS")]
          }  
          if(!item_key||!items){return null}  
          return items.filter(item=>{
            return item.item_key == item_key
          }) 
        }
      }, 
      getReturnedSoldItems(state) {
        return payload=>{
          let item_key = payload?payload.item_key:null
          let archive = payload?payload.archive:null
          let all = payload?payload.all:null
          let items = null
          if (all) {
            items = state[TEXT_UP("ALL_"+DATA.ITEMS.RETURNED_SALES.values+"_ITEMS")]
          }else if (archive) {
            items = state[TEXT_UP("ARCHIVED_"+DATA.ITEMS.RETURNED_SALES.values+"_ITEMS")]
          }else{
            items = state[TEXT_UP(DATA.ITEMS.RETURNED_SALES.values+"_ITEMS")]
          }  
          if(!item_key||!items){return null}  
          return items.filter(item=>{
            return item.item_key == item_key
          }) 
        }
      }, 
      
      getSelectedCompany(state) {
        return state.SELECTED_COMPANY 
      }, 
      getDefaultPaymentMethods(state) {
        return state.DEFAULT_PAYMENT_METHODS
      },  
      getPaymentMethods(state) {
        return state.PAYMENT_METHODS 
      },  
      getPaymentMethodByKey(state) {
        let app_state = app_store.state
        let default_payments = app_state.DEFAULT_PAYMENT_METHODS
        let PAYMENT_METHODS = [
          ...default_payments, 
        ]
        if (state.PAYMENT_METHODS) {
          PAYMENT_METHODS.push(...state.PAYMENT_METHODS)
        }
        return key=>{
          if(!key){return null}
          if(!PAYMENT_METHODS){return null}
          return PAYMENT_METHODS.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getPaymentMethodByCode(state) {
        let app_state = app_store.state
        let default_payments = app_state.DEFAULT_PAYMENT_METHODS
        let PAYMENT_METHODS = [
          ...default_payments, 
        ]
        if (state.PAYMENT_METHODS) {
          PAYMENT_METHODS.push(...state.PAYMENT_METHODS)
        }
        return code=>{
          if(!code){return null}
          if(!PAYMENT_METHODS){return null}
          return PAYMENT_METHODS.find(item=>{
            return item.code == code
          }) 
        }
      }, 
      getMoneyAccounts(state) { 
        return state.MONEY_ACCOUNTS 
      },  
      getMoneyAccountByKey(state) {
        let app_state = app_store.state
        let default_account = app_state.DEFAULT_MONEY_ACCOUNTS
        let MONEY_ACCOUNTS = [
          ...default_account, 
        ]
        if (state.MONEY_ACCOUNTS) {
          MONEY_ACCOUNTS.push(...state.MONEY_ACCOUNTS)
        }
        return key=>{  
          if(!key){return null}
          if(!MONEY_ACCOUNTS){return null}
          return MONEY_ACCOUNTS.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getMoneyAccountByCode(state) {
        let app_state = app_store.state
        let default_account = app_state.DEFAULT_MONEY_ACCOUNTS
        let MONEY_ACCOUNTS = [
          ...default_account, 
        ]
        if (state.MONEY_ACCOUNTS) {
          MONEY_ACCOUNTS.push(...state.MONEY_ACCOUNTS)
        }
        return code=>{
          if(!code){return null}
          if(!MONEY_ACCOUNTS){return null}
          return MONEY_ACCOUNTS.find(item=>{
            return item.code == code
          }) 
        }
      }, 
      getMoneyAccountTransactions(state) {
        return state.MONEY_ACCOUNT_TRANSACTIONS 
      },  
      getMoneyAccountTransactionByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.MONEY_ACCOUNT_TRANSACTIONS){return null}
          return state.MONEY_ACCOUNT_TRANSACTIONS.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getMoneyAccountTransactionByCode(state) {
        return code=>{
          if(!code){return null}
          if(!state.MONEY_ACCOUNT_TRANSACTIONS){return null}
          return state.MONEY_ACCOUNT_TRANSACTIONS.find(item=>{
            return item.code == code
          }) 
        }
      }, 

      getStockTakingOrders(state) {
        return state.STOCK_TAKING_ORDERS 
      },  
      getStockTakingOrderByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.STOCK_TAKING_ORDERS){return null}
          return state.STOCK_TAKING_ORDERS.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getStockTakings(state) {
        return state.STOCK_TAKINGS 
      },  
      getStockTakingByKey(state) {
        return key=>{
          if(!key){return null}
          if(!state.STOCK_TAKINGS){return null}
          return state.STOCK_TAKINGS.find(item=>{
            return item.key == key
          }) 
        }
      }, 
      getBillTerms(state) {
        return state.BILL_TERMS
      },  
      getUserRoles(state) {
        return state.USER_ROLES
      },  
      getDownloadRequests(state) {
        return state.DOWNLOAD_REQUESTS
      },    
    },
    //___________________-MUTATIONS-___________________
    mutations:{  
      setItems(state, payload) {
        try {
          let name = payload?payload.name:null
          let data = payload?payload.data:null
          state[name]=data  
        } catch (error) {
          console.log(error);
        }
      },  
      updateItems(state, payload) {
        try {
          let name = payload?payload.name:null
          let data = payload?payload.data:null
          let stateData = state[name]
          if (stateData && data) {
            data = [
              ...stateData,
              ...data
            ]
          }
          state[name]=data  
        } catch (error) {
          console.log(error);
        }
      }, 
      updateDownloadRequest(state, payload) {
        try {
          let downloads = state.DOWNLOAD_REQUESTS
          if(!downloads){state.DOWNLOAD_REQUESTS=[]}
          let name = payload?payload.name:null
          let status = payload?payload.status:null
          let initiate_size = 0
          let ref_remove_size = 0
          let start_size = 0
          let run_size = 0
          let download_size = 0
          if (status == "initiated") {
            initiate_size = 1
          }else if (status == "ref_removed") {
            ref_remove_size = 1
          }else if (status == "started") {
            start_size = 1
          }else if (status == "running") {
            run_size = 1
          }else if (status == "downloaded") {
            download_size = 1
          }
          let index = downloads.findIndex(item=>TEXT_UP(item.name)==TEXT_UP(name))
          if (index==-1) {
            state.DOWNLOAD_REQUESTS.push({
              ...payload,
              initiate_size:initiate_size,
              ref_remove_size:ref_remove_size,
              start_size:start_size,
              run_size:run_size,
              download_size:download_size,
            })
          } else { 
            let old = status == "ref_removed"?{}:state.DOWNLOAD_REQUESTS[index]
            let details ={
              ...old,
              ...payload,
              initiate_size:state.DOWNLOAD_REQUESTS[index].initiate_size+initiate_size,
              ref_remove_size:state.DOWNLOAD_REQUESTS[index].ref_remove_size+ref_remove_size,
              start_size:state.DOWNLOAD_REQUESTS[index].start_size+start_size,
              run_size:state.DOWNLOAD_REQUESTS[index].run_size+run_size,
              download_size:state.DOWNLOAD_REQUESTS[index].download_size+download_size,
            }
            state.DOWNLOAD_REQUESTS.splice(index,1) 
            state.DOWNLOAD_REQUESTS.push(details) 
          } 
        } catch (error) {
          console.log(error);
        }
      }, 
      updateItemsByKey(state, payload) {
        try {
          let name = payload?payload.name:null
          let data = payload?payload.data:null
          let stateData =[]
          if (state[name]) {stateData = [...state[name] ]}  
          if (data) {  
            for (const element of data) {
              var foundIndex = stateData.findIndex(x => x.key == element.key); 
              if(foundIndex != -1) { 
                stateData[foundIndex] = element;
              }else{
                stateData.push(element)
              } 
            }  
            state[name]=stateData
          }
        } catch (error) {
          console.log(error);
        }
      }, 
      restItems(state, payload) {
        try {
          let name = payload?payload.name:null 
          state[name]=null  
        } catch (error) {
          console.log(error);
        }
      },  
    },
    //____________________[ACTIONS]_____________________
    actions: { 
      company_link({commit,dispatch},payload) { 
        try {  
          let link = payload?payload.link:null
          let company_key = payload?payload.company_key:null

          let index1 = link?link.includes("xxxx"):null
          let index2 = link?link.includes("XXXX"):null
          if(!link){return null} 
          if (!company_key) {return null} 

          if (index1) {
              const new_link = link.replace("xxxx",company_key)
              return new_link  
          }else if (index2) {
              const new_link = link.replace("XXXX",company_key)
              return new_link  
          }else{
              const new_link = "/"+company_key+link 
              return new_link 
          } 
        } catch (error) { 
          commit("ERROR",{
            error:{
              error:error,
              payload:payload
            },
            from:'company_link',
            page:NAME, 
          }) 
        } 
      },   
      initialize_items_ref({commit,dispatch},payload) {
        return new Promise(async(resolve,reject)=>{
          let response = {
            from:'initialize_items_ref',
            page:NAME,
            payload:payload 
          }
          try {  
            //create path
            let P_SERVER = (DATA.APP.SERVER+'/').toUpperCase()
            let P_SETTINGS = (DATA.APP.SERVER+'/SETTINGS/').toUpperCase() 
            let P_BILLING_PLANS = DATA.PATHS.billing_plans
            let P_BILLING_PLAN_FEATURES = DATA.PATHS.billing_plan_features 

            let P_ERRORS = DATA.PATHS.errors
            let P_MESSAGES = P_SERVER+DATA.ITEMS.MESSAGES.values.toUpperCase()+'/'
            let P_COUNTRIES =DATA.PATHS.countries
            let P_REGIONS = DATA.PATHS.regions
            let P_DISTRICTS = DATA.PATHS.districts 

            let P_ID_TYPES = P_SETTINGS+DATA.ITEMS.ID_TYPES.values.toUpperCase()+'/' 
            let P_ACTION_HOLDERS = P_SERVER+DATA.ITEMS.ACTION_HOLDERS.values.toUpperCase()+'/' 

            let P_MEASUREMENT_UNITS = P_SETTINGS+DATA.ITEMS.MEASUREMENT_UNITS.values.toUpperCase()+'/' 
            let P_COMPANIES = DATA.PATHS.companies
            let P_COMPANY_TYPES= DATA.PATHS.company_types
            let P_COMPANY_SIZES= DATA.PATHS.company_sizes 

            //initiate path
            let initiate_path = async name=>{
              if (name) {
                await dispatch("off_refs",{
                  name:name,
                  from:"initialize_items_ref"
                })
                commit("updateDownloadRequest",{
                  name:name,
                  status:'initiated', 
                })  
              }
              
              if (name == DATA.ITEMS.BILLING_PLANS.values) {
                REF_BILLING_PLANS= firebase.database().ref(P_BILLING_PLANS) 
              }else if (name == DATA.ITEMS.BILLING_PLAN_FEATURES.values) {
                REF_BILLING_PLAN_FEATURES= firebase.database().ref(P_BILLING_PLAN_FEATURES) 
              }
              
              else if (name == DATA.ITEMS.ERRORS.values) {
                REF_ERRORS= firebase.database().ref(P_ERRORS) 
              }else if (name == DATA.ITEMS.MESSAGES.values) {
                REF_MESSAGES = firebase.database().ref(P_MESSAGES) 
              }else if (name == DATA.ITEMS.COMPANIES.values) {
                REF_COMPANIES = firebase.database().ref(P_COMPANIES)  
              }else if (name == DATA.ITEMS.COUNTRIES.values) {
                REF_COUNTRIES = firebase.database().ref(P_COMPANIES)  
              }else if (name == DATA.ITEMS.COUNTRIES.values) {
                REF_COUNTRIES = firebase.database().ref(P_COUNTRIES)  
              }else if (name == DATA.ITEMS.REGIONS.values) {
                REF_REGIONS = firebase.database().ref(P_REGIONS)  
              }else if (name == DATA.ITEMS.DISTRICTS.values) {
                REF_DISTRICTS = firebase.database().ref(P_DISTRICTS)
              } 
              else if (name == DATA.ITEMS.ID_TYPES.values) {
                REF_ID_TYPES = firebase.database().ref(P_ID_TYPES) 
              }else if (name == DATA.ITEMS.ACTION_HOLDERS.values) {
                REF_ACTION_HOLDERS = firebase.database().ref(P_ACTION_HOLDERS)  
              }
              
              else if (name == DATA.ITEMS.MEASUREMENT_UNITS.values) {
                REF_MEASUREMENT_UNITS = firebase.database().ref(P_MEASUREMENT_UNITS) 
              }else if (name == DATA.ITEMS.COMPANIES.values) {
                REF_COMPANIES = firebase.database().ref(P_COMPANIES)
              }else if (name == DATA.ITEMS.COMPANY_TYPES.values) {
                REF_COMPANY_TYPES = firebase.database().ref(P_COMPANY_TYPES)
              }else if (name == DATA.ITEMS.COMPANY_SIZES.values) {
                REF_COMPANY_SIZES = firebase.database().ref(P_COMPANY_SIZES) 
              } else {
                response.error = "name not found"
                response.name = name
                reject(response)
              } 
            }
 
            //set names
            let name = payload?payload.name:null
            let initiate_names = [] 
            if (name) {
              initiate_names.push(name)
            }else {
              initiate_names.push(DATA.ITEMS.BILLING_PLANS.values) 
              initiate_names.push(DATA.ITEMS.BILLING_PLAN_FEATURES.values)   
              initiate_names.push(DATA.ITEMS.ERRORS.values)   
              initiate_names.push(DATA.ITEMS.MESSAGES.values)    
              initiate_names.push(DATA.ITEMS.COUNTRIES.values)    
              initiate_names.push(DATA.ITEMS.REGIONS.values)     
              initiate_names.push(DATA.ITEMS.DISTRICTS.values) 

              initiate_names.push(DATA.ITEMS.ID_TYPES.values)     
              initiate_names.push(DATA.ITEMS.ACTION_HOLDERS.values)   
              initiate_names.push(DATA.ITEMS.MEASUREMENT_UNITS.values)    
              initiate_names.push(DATA.ITEMS.MEASUREMENT_UNITS.values)    
              initiate_names.push(DATA.ITEMS.COMPANIES.values)    
              initiate_names.push(DATA.ITEMS.COMPANY_TYPES.values)    
              initiate_names.push(DATA.ITEMS.COMPANY_SIZES.values)    
            }
            
            //initiate 
            for (const name of initiate_names) { 
              initiate_path(name)
            } 
            resolve({success:true}) 
          } catch (error) {
            commit("ERROR",{
              error:error,
              from:'initialize_items_ref',
              page:NAME, 
            })
            response.error = error
            reject(response)
          } 
        })
      },  
      initialize_company_data_ref({commit,dispatch},payload) {
        return new Promise(async(resolve,reject)=>{
          let response = {
            from:'initialize_company_data_ref',
            page:NAME,
            payload:payload 
          }
          try {   
            let company_key = payload?payload.company_key:null
            if (!company_key) {
              response.error = "no company key"
              reject(response)
              return
            } 
            let linkPath =async (link)=>{
              return await dispatch("company_link",{link:link,company_key:company_key})
            } 
            
            //INITIALIZE SELECTED COMPANY
            let P_SELECTED_COMPANY = DATA.PATHS.companies+company_key

            let P_CURRENT_BILLING_PLANS =  await linkPath(DATA.PATHS.current_billing_plans)
            let P_SUBSCRIPTIONS =  await linkPath(DATA.PATHS.subscriptions)

            //OTHER COMPANY DATA
            let P_COMPANY_QUICK_ACTIONS = await linkPath(DATA.PATHS.company_quick_actions)
            let P_DEVICES = await linkPath(DATA.PATHS.devices)
            let P_CARDS = await linkPath(DATA.PATHS.cards)
            let P_TILLS = await linkPath(DATA.PATHS.tills)
            let P_PRICING_TIERS = await linkPath(DATA.PATHS.pricing_tiers)
            let P_DAYS_SHIFTS = await linkPath(DATA.PATHS.days_shifts)
            let P_DAYS_SHIFTS_SALES = await linkPath(DATA.PATHS.days_shifts_sales)
            let P_CASHUPS = await linkPath(DATA.PATHS.cashups)
            let P_STOCK_OUTLETS = await linkPath(DATA.PATHS.stock_outlets)
            let P_STOCK_ORDERS = await linkPath(DATA.PATHS.stock_orders)
            let P_STOCK_ORDER_ITEMS = await linkPath(DATA.PATHS.stock_order_items)
            let P_STOCKS_RETURN = await linkPath(DATA.PATHS.stocks_return)
            let P_SALE_ORDERS = await linkPath(DATA.PATHS.sale_orders) 
            // let P_SALE_ORDER_ITEMS = await linkPath(DATA.PATHS.sale_order_items)
            let P_QUOTATIONS_AND_INVOICES = await linkPath(DATA.PATHS.quotations_and_invoices)
            let P_QUOTATIONS = await linkPath(DATA.PATHS.issued_quotations)
            let P_RETURNED_SALES = await linkPath(DATA.PATHS.sales_return)
            // let P_INVOICES = await linkPath(DATA.PATHS.invoices)
            let P_BRANDS = await linkPath(DATA.PATHS.brands)
            let P_MANUFACTURERS = await linkPath(DATA.PATHS.manufacturers)
            let P_SUPPLIERS = await linkPath(DATA.PATHS.suppliers)
            let P_SUPPLIER_CATEGORIES = await linkPath(DATA.PATHS.supplier_categories)
            let P_CUSTOMERS = await linkPath(DATA.PATHS.customers)
            let P_CUSTOMER_GROUPS = await linkPath(DATA.PATHS.customer_groups)
            let P_ITEMS = await linkPath(DATA.PATHS.items)
            let P_ITEM_CATEGORIES = await linkPath(DATA.PATHS.item_categories)
            let P_ITEM_DEPARTMENTS = await linkPath(DATA.PATHS.item_departments)
            let P_ITEM_ORDERS = await linkPath(DATA.PATHS.item_orders)
            let P_PATIENT_ORDERS = await linkPath(DATA.PATHS.patient_orders)
            let P_PATIENT_HISTORIES = await linkPath(DATA.PATHS.patient_histories)
            let P_ITEM_ORDER_OPTIONS = await linkPath(DATA.PATHS.item_order_options)
            let P_ITEM_KITS = await linkPath(DATA.PATHS.item_kits)
            let P_COMPANY_USERS = await linkPath(DATA.PATHS.company_users)
            let P_COMPANY_USER_GROUPS = await linkPath(DATA.PATHS.company_user_groups)

            let P_ASSETS= await linkPath(DATA.PATHS.assets)
            let P_ASSET_TYPES= await linkPath(DATA.PATHS.asset_types)
            let P_PAYABLES= await linkPath(DATA.PATHS.payables)
            let P_RECEIVABLES= await linkPath(DATA.PATHS.receivables)
            let P_PAID_INVOICES= await linkPath(DATA.PATHS.paid_invoices)
            let P_UNPAID_INVOICES= await linkPath(DATA.PATHS.unpaid_invoices)
            let P_CREDITORS= await linkPath(DATA.PATHS.creditors)
            let P_DEBTORS= await linkPath(DATA.PATHS.debtors)
            let P_PAYMENTS= await linkPath(DATA.PATHS.payments)
            let P_EXPENSES= await linkPath(DATA.PATHS.expenses)
            let P_EXPENSE_CATEGORIES= await linkPath(DATA.PATHS.expense_categories)
            let P_EXPENSE_ACCOUNTS= await linkPath(DATA.PATHS.expense_accounts)
            let P_TRACKING_UPDATES = await linkPath(DATA.PATHS.tracking_updates)
            let P_COMPANY_SETTINGS = await linkPath(DATA.PATHS.company_settings)
            let P_EXPIRIES = await linkPath(DATA.PATHS.expiries)
            let P_PAYMENT_METHODS = await linkPath(DATA.PATHS.payment_methods)
            let P_MONEY_ACCOUNTS = await linkPath(DATA.PATHS.money_accounts)
            let P_MONEY_ACCOUNT_TRANSACTIONS = await linkPath(DATA.PATHS.money_account_transact)
            let P_STOCK_TAKING_ORDERS = await linkPath(DATA.PATHS.stock_taking_orders)
            let P_STOCK_TAKINGS = await linkPath(DATA.PATHS.stock_takings)

            let P_ATTENDANCES = await linkPath(DATA.PATHS.attendances)
            let P_DESIGNATIONS = await linkPath(DATA.PATHS.designations)

            //initiate path
            let initiate_path = async name=>{
              if (name) {
                await dispatch("off_refs",{
                  name:name,
                  from:"initialize_company_data_ref"
                })
                
                commit("updateDownloadRequest",{
                  name:name,
                  company_key:company_key,
                  status:'initiated', 
                })
              }
              
              if (name == DATA.ITEMS.SELECTED_COMPANIES.values) {
                REF_SELECTED_COMPANY = firebase.database().ref(P_SELECTED_COMPANY)  
              }else if (name == DATA.ITEMS.CURRENT_BILLING_PLANS.values) {
                REF_CURRENT_BILLING_PLANS = firebase.database().ref(P_CURRENT_BILLING_PLANS)  
              }else if (name == DATA.ITEMS.SUBSCRIPTIONS.values) {
                REF_SUBSCRIPTIONS = firebase.database().ref(P_SUBSCRIPTIONS)  
              }else if (name == DATA.ITEMS.COMPANY_QUICK_ACTIONS.values) {
                REF_COMPANY_QUICK_ACTIONS = firebase.database().ref(P_COMPANY_QUICK_ACTIONS)  
              }else if (name == DATA.ITEMS.DEVICES.values) {
                REF_DEVICES = firebase.database().ref(P_DEVICES)  
              }else if (name == DATA.ITEMS.CARDS.values) {
                REF_CARDS = firebase.database().ref(P_CARDS)  
              }else if (name == DATA.ITEMS.TILLS.values) {
                REF_TILLS = firebase.database().ref(P_TILLS)  
              }else if (name == DATA.ITEMS.PRICING_TIERS.values) {
                REF_PRICING_TIERS = firebase.database().ref(P_PRICING_TIERS)  
              }else if (name == DATA.ITEMS.DAYS_SHIFTS.values) {
                REF_DAYS_SHIFTS = firebase.database().ref(P_DAYS_SHIFTS)  
              }else if (name == DATA.ITEMS.DAYS_SHIFTS_SALES.values) {
                REF_DAYS_SHIFTS_SALES = firebase.database().ref(P_DAYS_SHIFTS_SALES)  
              }else if (name == DATA.ITEMS.CASHUPS.values) {
                REF_CASHUPS = firebase.database().ref(P_CASHUPS)  
              }else if (name == DATA.ITEMS.STOCK_OUTLETS.values) {
                REF_STOCK_OUTLETS = firebase.database().ref(P_STOCK_OUTLETS)  
              }else if (name == DATA.ITEMS.STOCK_ORDERS.values) {
                REF_STOCK_ORDERS = firebase.database().ref(P_STOCK_ORDERS)  
              }else if (name == DATA.ITEMS.STOCK_ORDER_ITEMS) {
                REF_STOCK_ORDER_ITEMS = firebase.database().ref(P_STOCK_ORDER_ITEMS)  
              }else if (name == DATA.ITEMS.STOCKS_RETURN.values) {
                REF_STOCKS_RETURN = firebase.database().ref(P_STOCKS_RETURN)  
              }else if (name == DATA.ITEMS.SALE_ORDERS.values) {
                REF_SALE_ORDERS = firebase.database().ref(P_SALE_ORDERS)  
              }else if (name == DATA.ITEMS.QUOTATIONS_AND_INVOICES.values) {
                REF_QUOTATIONS_AND_INVOICES = firebase.database().ref(P_QUOTATIONS_AND_INVOICES)  
              }else if (name == DATA.ITEMS.RETURNED_SALES.values) {
                REF_RETURNED_SALES = firebase.database().ref(P_RETURNED_SALES)  
              }else if (name == DATA.ITEMS.QUOTATIONS.values) {
                REF_QUOTATIONS = firebase.database().ref(P_QUOTATIONS)  
              }else if (name == DATA.ITEMS.BRANDS.values) {
                REF_BRANDS = firebase.database().ref(P_BRANDS)  
              }else if (name == DATA.ITEMS.MANUFACTURERS.values) {
                REF_MANUFACTURERS = firebase.database().ref(P_MANUFACTURERS)  
              }else if (name == DATA.ITEMS.SUPPLIERS.values) {
                REF_SUPPLIERS = firebase.database().ref(P_SUPPLIERS)  
              }else if (name == DATA.ITEMS.SUPPLIER_CATEGORIES.values) {
                REF_SUPPLIER_CATEGORIES = firebase.database().ref(P_SUPPLIER_CATEGORIES)  
              }else if (name == DATA.ITEMS.CUSTOMERS.values) {
                REF_CUSTOMERS = firebase.database().ref(P_CUSTOMERS)  
              }else if (name == DATA.ITEMS.CUSTOMER_GROUPS.values) {
                REF_CUSTOMER_GROUPS = firebase.database().ref(P_CUSTOMER_GROUPS)  
              }else if (name == DATA.ITEMS.ITEMS.values) {
                REF_ITEMS = firebase.database().ref(P_ITEMS)  
              }else if (name == DATA.ITEMS.ITEM_CATEGORIES.values) {
                REF_ITEM_CATEGORIES = firebase.database().ref(P_ITEM_CATEGORIES)  
              }else if (name == DATA.ITEMS.ITEM_DEPARTMENTS.values) {
                REF_ITEM_DEPARTMENTS = firebase.database().ref(P_ITEM_DEPARTMENTS)  
              }else if (name == DATA.ITEMS.ITEM_ORDERS.values) {
                REF_ITEM_ORDERS = firebase.database().ref(P_ITEM_ORDERS)  
              }else if (name == DATA.ITEMS.PATIENT_ORDERS.values) {
                REF_PATIENT_ORDERS = firebase.database().ref(P_PATIENT_ORDERS)  
              }else if (name == DATA.ITEMS.PATIENT_HISTORIES.values) {
                REF_PATIENT_HISTORIES = firebase.database().ref(P_PATIENT_HISTORIES)  
              }else if (name == DATA.ITEMS.ITEM_ORDER_OPTIONS.values) {
                REF_ITEM_ORDER_OPTIONS = firebase.database().ref(P_ITEM_ORDER_OPTIONS)  
              }else if (name == DATA.ITEMS.ITEM_KITS.values) {
                REF_ITEM_KITS = firebase.database().ref(P_ITEM_KITS)  
              }else if (name == DATA.ITEMS.COMPANY_USERS.values) {
                REF_COMPANY_USERS = firebase.database().ref(P_COMPANY_USERS)  
              }else if (name == DATA.ITEMS.COMPANY_USER_GROUPS.values) {
                REF_COMPANY_USER_GROUPS = firebase.database().ref(P_COMPANY_USER_GROUPS)  
              }
              else if (name == DATA.ITEMS.ASSETS.values) {
                REF_ASSETS = firebase.database().ref(P_ASSETS)  
              }else if (name == DATA.ITEMS.ASSET_TYPES.values) {
                REF_ASSET_TYPES = firebase.database().ref(P_ASSET_TYPES)  
              }else if (name == DATA.ITEMS.PAYABLES.values) {
                REF_PAYABLES = firebase.database().ref(P_PAYABLES)  
              }else if (name == DATA.ITEMS.RECEIVABLES.values) {
                REF_RECEIVABLES = firebase.database().ref(P_RECEIVABLES)  
              }else if (name == DATA.ITEMS.PAID_INVOICES.values) {
                REF_PAID_INVOICES = firebase.database().ref(P_PAID_INVOICES)  
              }else if (name == DATA.ITEMS.UNPAID_INVOICES.values) {
                REF_UNPAID_INVOICES = firebase.database().ref(P_UNPAID_INVOICES)  
              }else if (name == DATA.ITEMS.CREDITORS.values) {
                REF_CREDITORS = firebase.database().ref(P_CREDITORS)  
              }else if (name == DATA.ITEMS.DEBTORS.values) {
                REF_DEBTORS = firebase.database().ref(P_DEBTORS)  
              }else if (name == DATA.ITEMS.PAYMENTS.values) {
                REF_PAYMENTS = firebase.database().ref(P_PAYMENTS)  
              }else if (name == DATA.ITEMS.EXPENSES.values) {
                REF_EXPENSES = firebase.database().ref(P_EXPENSES)  
              }else if (name == DATA.ITEMS.EXPENSE_CATEGORIES.values) {
                REF_EXPENSE_CATEGORIES = firebase.database().ref(P_EXPENSE_CATEGORIES)  
              }else if (name == DATA.ITEMS.EXPENSE_ACCOUNTS.values) {
                REF_EXPENSE_ACCOUNTS = firebase.database().ref(P_EXPENSE_ACCOUNTS)  
              }else if (name == DATA.ITEMS.TRACKING_UPDATES.values) {
                REF_TRACKING_UPDATES = firebase.database().ref(P_TRACKING_UPDATES)  
              }else if (name == DATA.ITEMS.COMPANY_SETTINGS.values) {
                REF_COMPANY_SETTINGS = firebase.database().ref(P_COMPANY_SETTINGS)  
              }else if (name == DATA.ITEMS.EXPIRIES.values) {
                REF_EXPIRIES = firebase.database().ref(P_EXPIRIES)  
              }else if (name == DATA.ITEMS.PAYMENT_METHODS.values) {
                REF_PAYMENT_METHODS = firebase.database().ref(P_PAYMENT_METHODS)  
              }else if (name == DATA.ITEMS.MONEY_ACCOUNTS.values) {
                REF_MONEY_ACCOUNTS = firebase.database().ref(P_MONEY_ACCOUNTS)  
              }else if (name == DATA.ITEMS.MONEY_ACCOUNT_TRANSACTIONS.values) {
                REF_MONEY_ACCOUNT_TRANSACTIONS = firebase.database().ref(P_MONEY_ACCOUNT_TRANSACTIONS)  
              }else if (name == DATA.ITEMS.STOCK_TAKINGS.values) {
                REF_STOCK_TAKINGS = firebase.database().ref(P_STOCK_TAKINGS)  
              }else if (name == DATA.ITEMS.STOCK_TAKING_ORDERS.values) {
                REF_STOCK_TAKING_ORDERS = firebase.database().ref(P_STOCK_TAKING_ORDERS)  
              }
              else if (name == DATA.ITEMS.ATTENDANCES.values) {
                REF_ATTENDANCES = firebase.database().ref(P_ATTENDANCES)
              }else if (name == DATA.ITEMS.DESIGNATIONS.values) {
                REF_DESIGNATIONS = firebase.database().ref(P_DESIGNATIONS)
              }
              else {
                response.error = "name not found"
                response.name = name
                console.log(response,'e..........');
                reject(response)
              } 
            }
 
            //set names
            let name = payload?payload.name:null
            let initiate_names = [] 
            if (name) {
              initiate_names.push(name)
            }else {
              initiate_names.push(DATA.ITEMS.SELECTED_COMPANIES.values) 
              initiate_names.push(DATA.ITEMS.CURRENT_BILLING_PLANS.values)   
              initiate_names.push(DATA.ITEMS.SUBSCRIPTIONS.values)   
              initiate_names.push(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values)    
              initiate_names.push(DATA.ITEMS.DEVICES.values)    
              initiate_names.push(DATA.ITEMS.CARDS.values)     
              initiate_names.push(DATA.ITEMS.TILLS.values)     
              initiate_names.push(DATA.ITEMS.PRICING_TIERS.values)     
              initiate_names.push(DATA.ITEMS.DAYS_SHIFTS.values)   
              initiate_names.push(DATA.ITEMS.DAYS_SHIFTS_SALES.values)    
              initiate_names.push(DATA.ITEMS.CASHUPS.values)    
              initiate_names.push(DATA.ITEMS.STOCK_OUTLETS.values)    
              initiate_names.push(DATA.ITEMS.STOCK_ORDERS.values)    
              initiate_names.push(DATA.ITEMS.SALE_ORDERS.values)    
              initiate_names.push(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values)    
              initiate_names.push(DATA.ITEMS.QUOTATIONS.values)    
              initiate_names.push(DATA.ITEMS.RETURNED_SALES.values)    
              initiate_names.push(DATA.ITEMS.BRANDS.values)    
              initiate_names.push(DATA.ITEMS.MANUFACTURERS.values)    
              initiate_names.push(DATA.ITEMS.SUPPLIERS.values)    
              initiate_names.push(DATA.ITEMS.SUPPLIER_CATEGORIES.values)    
              initiate_names.push(DATA.ITEMS.CUSTOMERS.values)    
              initiate_names.push(DATA.ITEMS.CUSTOMER_GROUPS.values)    
              initiate_names.push(DATA.ITEMS.ITEMS.values)    
              initiate_names.push(DATA.ITEMS.ITEM_CATEGORIES.values)    
              initiate_names.push(DATA.ITEMS.ITEM_DEPARTMENTS.values)    
              initiate_names.push(DATA.ITEMS.ITEM_ORDERS.values)    
              initiate_names.push(DATA.ITEMS.ITEM_ORDER_OPTIONS.values)    
              initiate_names.push(DATA.ITEMS.PATIENT_ORDERS.values)    
              initiate_names.push(DATA.ITEMS.PATIENT_HISTORIES.values)    
              initiate_names.push(DATA.ITEMS.ITEM_KITS.values)    
              initiate_names.push(DATA.ITEMS.COMPANY_USERS.values)    
              initiate_names.push(DATA.ITEMS.COMPANY_USER_GROUPS.values)    
              initiate_names.push(DATA.ITEMS.ASSETS.values)    
              initiate_names.push(DATA.ITEMS.ASSET_TYPES.values)    
              initiate_names.push(DATA.ITEMS.PAYABLES.values)    
              initiate_names.push(DATA.ITEMS.RECEIVABLES.values)    
              initiate_names.push(DATA.ITEMS.PAID_INVOICES.values)    
              initiate_names.push(DATA.ITEMS.UNPAID_INVOICES.values)    
              initiate_names.push(DATA.ITEMS.CREDITORS.values)    
              initiate_names.push(DATA.ITEMS.DEBTORS.values)    
              initiate_names.push(DATA.ITEMS.PAYMENTS.values)    
              initiate_names.push(DATA.ITEMS.EXPENSES.values)    
              initiate_names.push(DATA.ITEMS.EXPENSE_CATEGORIES.values)    
              initiate_names.push(DATA.ITEMS.EXPENSE_ACCOUNTS.values)    
              initiate_names.push(DATA.ITEMS.TRACKING_UPDATES.values)    
              initiate_names.push(DATA.ITEMS.COMPANY_SETTINGS.values)    
              initiate_names.push(DATA.ITEMS.EXPIRIES.values)    
              initiate_names.push(DATA.ITEMS.PAYMENT_METHODS.values)    
              initiate_names.push(DATA.ITEMS.MONEY_ACCOUNTS.values)    
              initiate_names.push(DATA.ITEMS.MONEY_ACCOUNT_TRANSACTIONS.values)    
              initiate_names.push(DATA.ITEMS.STOCK_TAKINGS.values)    
              initiate_names.push(DATA.ITEMS.STOCK_TAKING_ORDERS.values)   
              
              initiate_names.push(DATA.ITEMS.ATTENDANCES.values)    
              initiate_names.push(DATA.ITEMS.DESIGNATIONS.values)  
            }
            
            //initiate 
            for (const name of initiate_names) { 
              initiate_path(name)
            } 
  
 

            resolve({
              success:true
            }) 
          } catch (error) {
            response.error = error
            commit("ERROR",response)
            reject(response)
          } 
        })
      }, 
      off_refs({commit},payload) {
        return new Promise(async (resolve,reject)=>{
          let response = {
            from:'off_refs',
            page:NAME,
            payload:payload
          }
          try {   
            let name = payload?payload.name:null
            if (name == DATA.ITEMS.ERRORS.values) {
              if (REF_ERRORS) { 
                REF_ERRORS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })
              }
            }else if (name == DATA.ITEMS.MESSAGES.values) {
              if (REF_MESSAGES) { 
                REF_MESSAGES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })
              }
            }else if (name == DATA.ITEMS.NOTIFICATIONS.values) {
              if (REF_NOTIFICATIONS) { REF_NOTIFICATIONS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.COUNTRIES.values) {
              if (REF_COUNTRIES) { REF_COUNTRIES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.REGIONS.values) {
              if (REF_REGIONS) { REF_REGIONS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.DISTRICTS.values) {
              if (REF_DISTRICTS) { REF_DISTRICTS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.ATTENDANCES.values) {
              if (REF_ATTENDANCES) { REF_ATTENDANCES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.DESIGNATIONS.values) {
              if (REF_DESIGNATIONS) { REF_DESIGNATIONS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.ID_TYPES.values) {
              if (REF_ID_TYPES) { REF_ID_TYPES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.DELIVERING_STEPS.values) {
              if (REF_DELIVERING_STEPS) { REF_DELIVERING_STEPS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.ACTION_HOLDERS.values) {
              if (REF_ACTION_HOLDERS) { REF_ACTION_HOLDERS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.ORDERS.values) {
              if (REF_BANK_ORDERS) { REF_BANK_ORDERS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.CANCELLATION_FEES.values) {
              if (REF_CANCELLATION_FEES) { REF_CANCELLATION_FEES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })} 
            }
            
            else if (name == DATA.ITEMS.MEASUREMENT_UNITS.values) {
              if (REF_MEASUREMENT_UNITS) { REF_MEASUREMENT_UNITS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.SELECTED_COMPANIES.values) {
              if (REF_SELECTED_COMPANY) { REF_SELECTED_COMPANY.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.COMPANIES.values) {
              if (REF_COMPANIES) { REF_COMPANIES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.COMPANY_TYPES.values) {
              if (REF_COMPANY_TYPES) { REF_COMPANY_TYPES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.COMPANY_SIZES.values) {
              if (REF_COMPANY_SIZES) { REF_COMPANY_SIZES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            } 
            
            else if (name == DATA.ITEMS.BILLING_PLANS.values) {
              if (REF_BILLING_PLANS) { REF_BILLING_PLANS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })} 
            }else if (name == DATA.ITEMS.BILLING_PLAN_FEATURES.values) {
              if (REF_BILLING_PLAN_FEATURES) { REF_BILLING_PLAN_FEATURES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.CURRENT_BILLING_PLANS.values) {
              if (REF_CURRENT_BILLING_PLANS) { REF_CURRENT_BILLING_PLANS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.SUBSCRIPTIONS.values) {
              if (REF_SUBSCRIPTIONS) { REF_SUBSCRIPTIONS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.SELECTED_COMPANIES.values) {
              if (REF_SELECTED_COMPANY) { REF_SELECTED_COMPANY.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.COMPANY_QUICK_ACTIONS.values) {
              if (REF_COMPANY_QUICK_ACTIONS) { REF_COMPANY_QUICK_ACTIONS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.DEVICES.values) {
              if (REF_DEVICES) { REF_DEVICES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.CARDS.values) {
              if (REF_CARDS) { REF_CARDS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.TILLS.values) {
              if (REF_TILLS) { REF_TILLS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.PRICING_TIERS.values) {
              if (REF_PRICING_TIERS) { REF_PRICING_TIERS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.DAYS_SHIFTS.values) {
              if (REF_DAYS_SHIFTS) { REF_DAYS_SHIFTS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.DAYS_SHIFTS_SALES.values) {
              if (REF_DAYS_SHIFTS_SALES) { REF_DAYS_SHIFTS_SALES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.DAY_SALES.values) {
              if (REF_DAY_SALES) { REF_DAY_SALES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.DAY_ORDERS.values) {
              if (REF_DAY_ORDERS) { REF_DAY_ORDERS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.CASHUPS.values) {
              if (REF_CASHUPS) { REF_CASHUPS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.STOCKS.values) {
              if (REF_STOCKS) { REF_STOCKS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.STOCK_OUTLETS.values) {
              if (REF_STOCK_OUTLETS) { REF_STOCK_OUTLETS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.STOCK_ORDERS.values) {
              if (REF_STOCK_ORDERS) { REF_STOCK_ORDERS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.STOCKS_RETURN.values) {
              if (REF_STOCKS_RETURN) { REF_STOCKS_RETURN.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.SALE_ORDERS.values) {
              if (REF_SALE_ORDERS) { REF_SALE_ORDERS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.QUOTATIONS_AND_INVOICES.values) {
              if (REF_QUOTATIONS_AND_INVOICES) { REF_QUOTATIONS_AND_INVOICES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.QUOTATIONS.values) {
              if (REF_QUOTATIONS) { REF_QUOTATIONS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.RETURNED_SALES.values) {
              if (REF_RETURNED_SALES) { REF_RETURNED_SALES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.BRANDS.values) {
              if (REF_BRANDS) { REF_BRANDS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.MANUFACTURERS.values) {
              if (REF_MANUFACTURERS) { REF_MANUFACTURERS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.SUPPLIERS.values) {
              if (REF_SUPPLIERS) { REF_SUPPLIERS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.SUPPLIER_CATEGORIES.values) {
              if (REF_SUPPLIER_CATEGORIES) { REF_SUPPLIER_CATEGORIES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.CUSTOMERS.values) {
              if (REF_CUSTOMERS) { REF_CUSTOMERS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.CUSTOMER_GROUPS.values) {
              if (REF_CUSTOMER_GROUPS) { REF_CUSTOMER_GROUPS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.ITEMS.values) {
              if (REF_ITEMS) { 
                REF_ITEMS.off()
                commit("updateDownloadRequest",{
                name:name,
                status:'ref_removed', 
                })
              }
            }else if (name == DATA.ITEMS.ITEM_CATEGORIES.values) {
              if (REF_ITEM_CATEGORIES) { REF_ITEM_CATEGORIES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.ITEM_DEPARTMENTS.values) {
              if (REF_ITEM_DEPARTMENTS) { REF_ITEM_DEPARTMENTS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.ITEM_ORDERS.values) {
              if (REF_ITEM_ORDERS) { REF_ITEM_ORDERS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.PATIENT_ORDERS.values) {
              if (REF_PATIENT_ORDERS) { REF_PATIENT_ORDERS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.PATIENT_HISTORIES.values) {
              if (REF_PATIENT_HISTORIES) { REF_PATIENT_HISTORIES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.ITEM_ORDER_OPTIONS.values) {
              if (REF_ITEM_ORDER_OPTIONS) { REF_ITEM_ORDER_OPTIONS.off() 
                commit("updateDownloadRequest",{
                name:name,
                status:'ref_removed', 
              })}
            }else if (name == DATA.ITEMS.ITEM_KITS.values) {
              if (REF_ITEM_KITS) { REF_ITEM_KITS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.COMPANY_USERS.values) {
              if (REF_COMPANY_USERS) { REF_COMPANY_USERS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.COMPANY_USER_GROUPS.values) {
              if (REF_COMPANY_USER_GROUPS) { REF_COMPANY_USER_GROUPS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }
            
            else if (name == DATA.ITEMS.ASSETS.values) {
              if (REF_ASSETS) { REF_ASSETS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.ASSET_TYPES.values) {
              if (REF_ASSET_TYPES) { REF_ASSET_TYPES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.PAYABLES.values) {
              if (REF_PAYABLES) { REF_PAYABLES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.RECEIVABLES.values) {
              if (REF_RECEIVABLES) { REF_RECEIVABLES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.PAID_INVOICES.values) {
              if (REF_PAID_INVOICES) { REF_PAID_INVOICES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.UNPAID_INVOICES.values) {
              if (REF_UNPAID_INVOICES) { REF_UNPAID_INVOICES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.CREDITORS.values) {
              if (REF_CREDITORS) { REF_CREDITORS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.DEBTORS.values) {
              if (REF_DEBTORS) { REF_DEBTORS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.EXPENSES.values) {
              if (REF_EXPENSES) { REF_EXPENSES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.EXPENSE_CATEGORIES.values) {
              if (REF_EXPENSE_CATEGORIES) { REF_EXPENSE_CATEGORIES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.PAYMENTS.values) {
              if (REF_PAYMENTS) { REF_PAYMENTS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.EXPENSE_ACCOUNTS.values) {
              if (REF_EXPENSE_ACCOUNTS) { REF_EXPENSE_ACCOUNTS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.TRACKING_UPDATES.values) {
              if (REF_TRACKING_UPDATES) { REF_TRACKING_UPDATES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.COMPANY_SETTINGS.values) {
              if (REF_COMPANY_SETTINGS) { REF_COMPANY_SETTINGS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.EXPIRIES.values) {
              if (REF_EXPIRIES) { REF_EXPIRIES.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.PAYMENT_METHODS.values) {
              if (REF_PAYMENT_METHODS) { REF_PAYMENT_METHODS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.MONEY_ACCOUNTS.values) {
              if (REF_MONEY_ACCOUNTS) { REF_MONEY_ACCOUNTS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.MONEY_ACCOUNT_TRANSACTIONS.values) {
              if (REF_MONEY_ACCOUNT_TRANSACTIONS) { REF_MONEY_ACCOUNT_TRANSACTIONS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.STOCK_TAKINGS.values) {
              if (REF_STOCK_TAKINGS) { REF_STOCK_TAKINGS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else if (name == DATA.ITEMS.STOCK_TAKING_ORDERS.values) {
              if (REF_STOCK_TAKING_ORDERS) { REF_STOCK_TAKING_ORDERS.off() 
                commit("updateDownloadRequest",{
                  name:name,
                  status:'ref_removed', 
                })}
            }else {   
              response.error = "name not found"
              console.log(response,'e..................*');
              reject(response)
            } 
            resolve({
              success:true
            }) 
          } catch (error) {
            commit("ERROR",{
              error:error,
              from:'off_refs',
              page:NAME, 
            })
            response.error = error
            reject(response)
          } 
        })
      }, 
      fi_tracker({ commit,dispatch},payload){
        return new Promise(async(resolve,reject)=>{
          let response = {
            from:'fi_tracker',
            page:NAME,
            payload:payload,
          }
          try { 
            let names = payload?payload.names:null
            let company = payload?payload.company:null 
            let company_key = payload?payload.company_key:null 
            if(company){
              company_key =company?company.company_key:null
            }
            let max_records = payload?payload.max_records:null
            max_records = max_records?max_records:50
            if (!names) {
              reject({...response,error:"payload required"})
              return
            } 

            //----GET LOCALS AND DATA SAVE
            for (const item of names) { 
              if (!item.data_save) { 
                dispatch('fi_get_item',{
                  company_key:company_key,
                  ...item
                }) 
              } else {
                if (item.get) {
                  let name = item.name
                  commit('updateProcesses', {
                    add: true,
                    name: 'local_getting/'+name
                  }) 
                  let db_res = await DB.getItems({
                    name:name,
                    company_key:company_key, 
                  })
                  let data = db_res?db_res.data:null
                  commit('updateProcesses', { 
                    name: 'local_getting/'+name
                  }) 
                  dispatch("f_save_items",{
                    name:name,
                    data:data,
                    db:true
                  })  
                }    
              }
            }

            //-----START TRACKING
            setTimeout(() => {
              if (REF_TRACKING_UPDATES) {
                REF_TRACKING_UPDATES.on('value', async data => {
                  let obj = data.val()
                  let i = 1
                  let updates = []
                  for (const key in obj) {
                    let item = obj[key]  
                    item = {
                      i:i,
                      key:key,
                      number : item
                    }  
                    updates.push(item)  
                    i++
                  } 
                  if (updates.length>0) {
                    //---------[GET UPDATES]------
                    let db_res = await DB.getItems({
                      name:DATA.ITEMS.TRACKING_UPDATES.values,
                      company_key:company_key, 
                    }) 
                    let local_updates = db_res?db_res.data:null
                    let findUpdate = (records,name)=>{
                      if(!records||!name){return null}
                      return records.find(item=>item.key.toUpperCase() == name.toUpperCase())
                    }
  
  
                    //---------[CHECK DATA]------
                    names.forEach(async element => {
                      // RUN DATA SAVE
                      if (element.data_save) {
                        let name = element.name
                        if (!name) {
                          commit("ERROR",{...response,error:"no name"})
                          return
                        }
                        let link_key = DATA.PATHS.tracking_keys+(name.toUpperCase())+"/"
                        let path_key = await dispatch("company_link",{link:link_key,company_key:company_key})
                        let ref_key = firebase.database().ref(path_key)  
                        let this_update = findUpdate(updates,name)
                        let this_local_update = findUpdate(local_updates,name)
                        let update_number = toNumber(this_update?this_update.number:0)
                        let local_update_number = toNumber(this_local_update?this_local_update.number:0)
                        let db_res = await DB.getItems({
                          name:DATA.ITEMS.TRACKING_KEYS.values, 
                          key:company_key+"/"+name, 
                        })
                        let local_key = db_res?db_res.data:null
                        let local_keys = local_key?local_key.keys:null 
  
  
                        let download_key = false
                        if (update_number == 0) {
                          download_key = false
                        }else if (update_number == local_update_number) {
                          download_key = false
                        }else{
                          download_key = true
                        } 
      
                        if (download_key) {
                          // console.log(name,'start....');
                          // await dispatch('fi_get_item',{
                          //   company_key:company_key,
                          //   name:name,
                          //   cash:true,
                          // }) 
                          
                          // console.log(name,'updated....');
                          ref_key.once('value', async data => {
                            let obj = data.val()
                            let i = 1
                            let keys = []
                            for (const key in obj) {
                              let item = obj[key]  
                              item = {
                                i:i,
                                key:key,
                                ...item
                              }  
                              keys.push(item)  
                              i++
                            } 
                            
    
                            if (keys) { 
                              await dispatch('fi_get_item',{
                                company_key:company_key,
                                name:name,
                                cash:true,
                                child_keys:keys,
                                local_child_keys:local_keys
                              }) 
                            } 
                            //---- UPDATE KEYS
                            DB.addItems({
                              name:DATA.ITEMS.TRACKING_KEYS.values, 
                              data:{
                                key:company_key+"/"+name, 
                                keys:keys
                              }, 
                            })
                          }) 
                        }else{
                          console.log(update_number,local_update_number,name,'same key......',name);
                        }  
                      } else {
                        
                      }
                    }); 
                  }
  
                  DB.addItems({
                    name:DATA.ITEMS.TRACKING_UPDATES.values,
                    company_key:company_key, 
                    data:updates
                  }) 
                  resolve({success:true,data:updates})
                  // await dispatch('f_save_items',{...ref_action,data:data})
                })   
              }
            }, 100);
          } catch (error) {
            response = {...response,error:error}
            commit("ERROR",response)
            reject(response)
          } 
        }) 
      },
      combine_data({},payload){
        let response = {
          from:'combine_data',
          page:NAME,
          payload:payload,
        }
        try {
          let data1 = payload?payload.data1:null
          let data2 = payload?payload.data2:null 
          if (!data1) {data1 = []}  
          if (data2) {  
            for (const element of data2) {
              var foundIndex = data1.findIndex(x => x.key == element.key); 
              if(foundIndex != -1) { 
                data1[foundIndex] = element;
              }else{
                data1.push(element)
              } 
            }  
          }
          return data1 
        } catch (error) {
          response = {...response,error:error}
          commit("ERROR",response)
          reject(response)
        }
      },
      //-----------------[LOCAL UPDATE]
      update_from_db({commit,state},payload) {
        return new Promise((resolve,reject)=>{
          let response = {
            from:'update_from_db',
            page:NAME,
            payload:payload
          }
          try {   
            let name = payload?payload.name:''
            
            if (name.toUpperCase()===DATA.ITEMS.LOCAL_SALE_ORDERS.values.toUpperCase()) {
              // DB.getItems({
              //   name:DATA.ITEMS.LOCAL_SALE_ORDERS.values,
              //   update_from_db:true
              // }).then(async res=>{ 
              //   let orders = res?res.data:null
              //   if (orders) {
              //     let sale_orders = state.SALE_ORDERS
              //     sale_orders = sale_orders?sale_orders.filter(item=>{
              //       return !item.offline_version 
              //     }):[]
              //     orders.forEach(element => {
              //       sale_orders.push({
              //         ...element,
              //         offline_version:true
              //       })
              //     }); 
              //     state.SALE_ORDERS = sale_orders
              //   }
              //   resolve({
              //     success:true,
              //     updated:true
              //   }) 
              // }).catch(error=>{
              //   response.error = error
              //   commit("ERROR",response)
              //   reject(response)
              // })
            } else {
              resolve({
                success:true
              }) 
            } 
          } catch (error) {
            response.error = error
            commit("ERROR",response)
            reject(response)
          } 
        })
      },  
      update_local_item({ commit,state}, payload) {
        try {
          let key = payload.key
          let value_item = payload.value_item
          let data = payload.data 
          let items = null
          
          if (!key) {
            commit("ERROR",{
              error:{
                error:'updating/local_item/error',
                data:payload
              },
              from:'update_local_item',
              page:NAME, 
            })
            return
          }if (!value_item) {
            commit("ERROR",{
              error:{
                error:'updating/local_item/error',
                data:payload
              },
              from:'update_local_item',
              page:NAME, 
            })
            return
          }if (!data) {
            commit("ERROR",{
              error:{
                error:'updating/local_item/error',
                data:payload
              },
              from:'update_local_item',
              page:NAME, 
            })
            return
          } 
          value_item = value_item.toUpperCase()

          if (value_item==='PROVINCES'||value_item==='PROVINCE') {
            items = [...state.PROVINCES]
          }else if(value_item==='DISTRICTS'||value_item==='DISTRICT') {
            items = [...state.DISTRICTS]
          }else if(value_item==='CONSTITUENCIES'||value_item==='CONSTITUENCY') {
            items = [...state.CONSTITUENCIES]
          }else if(value_item==='POLLING_STATIONS'||value_item==='POLLING_STATION') {
            items = [...state.POLLING_STATIONS]
          }else if(value_item==='REPORTS'||value_item==='REPORT') {
            items = [...state.REPORTS]
          }else if(value_item==='SMS'||value_item==='sms') {
            items = [...state.SMS]
          }else if(value_item==='REPORT_CATEGORIES'||value_item==='REPORT_CATEGORY') {
            items = [...state.REPORT_CATEGORIES]
          }else if(value_item==='POLLS'||value_item==='POLL') {
            items = [...state.POLLS]
          }else if(value_item==='NEWS'||value_item==='NEWS') {
            items = [...state.NEWS]
          } 

          let item_size = items?items.length:0
          if (item_size>0) {
            var foundIndex = items.findIndex(x => x.key == key);
             
            items[foundIndex] = {
              ...items[foundIndex],
              ...data
            }

            if (value_item==='PROVINCES'||value_item==='PROVINCE') {
              state.PROVINCES=items 
            }else if(value_item==='DISTRICTS'||value_item==='DISTRICT') {
              state.DISTRICTS=items 
            }else if(value_item==='CONSTITUENCIES'||value_item==='CONSTITUENCY') {
              state.CONSTITUENCIES=items 
            }else if(value_item==='POLLING_STATIONS'||value_item==='POLLING_STATION') {
              state.POLLING_STATIONS=items 
            }else if(value_item==='REPORTS'||value_item==='REPORT') {
              state.REPORTS=items 
            }else if(value_item==='SMS'||value_item==='sms') {
              state.SMS=items 
            }else if(value_item==='REPORT_CATEGORIES'||value_item==='REPORT_CATEGORY') {
              state.REPORT_CATEGORIES=items 
            }else if(value_item==='POLLS'||value_item==='POLL') {
              state.POLLS=items 
            }else if(value_item==='NEWS'||value_item==='NEWS') {
              state.NEWS=items 
            }  

          }else { 
            commit("ERROR",{
              error:{
                error:"No reports",
                data:payload,
                items:items
              },
              from:'update_local_item',
              page:NAME, 
            })
          } 
        } catch (error) {
          commit("ERROR",{
            error:{
              error:error,
              data:payload
            },
            from:'update_local_item',
            page:NAME, 
          })
        }
      }, 
      add_local_item({ commit,state}, payload) {
        try {
          let key = payload.key
          let value_item = payload.value_item
          let data = payload.data 
          let items = []
 
          
          if (!key) {
            commit("ERROR",{
              error:{
                error:'No key',
                data:payload
              },
              from:'add_local_item',
              page:NAME, 
            })
            return
          }if (!value_item) {
            commit("ERROR",{
              error:{
                error:'no value',
                data:payload
              },
              from:'add_local_item',
              page:NAME, 
            })
            return
          }if (!data) {
            commit("ERROR",{
              error:{
                error:'no data',
                data:payload
              },
              from:'add_local_item',
              page:NAME, 
            })
            return
          } 
          value_item = value_item.toUpperCase()

          if (value_item==='PROVINCES'||value_item==='PROVINCE') {
            items = [...state.PROVINCES]
          }else if(value_item==='DISTRICTS'||value_item==='DISTRICT') {
            items = [...state.DISTRICTS]
          }else if(value_item==='CONSTITUENCIES'||value_item==='CONSTITUENCY') {
            items = [...state.CONSTITUENCIES]
          }else if(value_item==='POLLING_STATIONS'||value_item==='POLLING_STATION') {
            items = [...state.POLLING_STATIONS]
          }else if(value_item==='REPORTS'||value_item==='REPORT') {
            items = [...state.REPORTS]
          }else if(value_item==='SMS'||value_item==='sms') {
            items = [...state.SMS]
          }else if(value_item==='REPORT_CATEGORIES'||value_item==='REPORT_CATEGORY') {
            items = [...state.REPORT_CATEGORIES]
          }else if(value_item==='POLLS'||value_item==='POLL') {
            items = [...state.POLLS]
          }else if(value_item==='NEWS'||value_item==='NEWS') {
            items = [...state.NEWS]
          } 

          let item_size = items?items.length:0
          if (item_size===0) { 
             item = []
          }

          items.push({
            key:key,
            ...data
          })

          if (value_item==='PROVINCES'||value_item==='PROVINCE') {
            state.PROVINCES=items 
          }else if(value_item==='DISTRICTS'||value_item==='DISTRICT') {
            state.DISTRICTS=items 
          }else if(value_item==='CONSTITUENCIES'||value_item==='CONSTITUENCY') {
            state.CONSTITUENCIES=items 
          }else if(value_item==='POLLING_STATIONS'||value_item==='POLLING_STATION') {
            state.POLLING_STATIONS=items 
          }else if(value_item==='REPORTS'||value_item==='REPORT') {
            state.REPORTS=items 
          }else if(value_item==='SMS'||value_item==='sms') {
            state.SMS=items 
          }else if(value_item==='REPORT_CATEGORIES'||value_item==='REPORT_CATEGORY') {
            state.REPORT_CATEGORIES=items 
          }else if(value_item==='POLLS'||value_item==='POLL') {
            state.POLLS=items 
          }else if(value_item==='NEWS'||value_item==='NEWS') {
            state.NEWS=items 
          }  
            
        } catch (error) {
          commit("ERROR",{
            error:{
              error:error,
              data:payload
            },
            from:'update_local_item',
            page:NAME, 
          })
        }
      }, 
      //-----------------[UPLOADING]
      fi_uploadImage({commit,state},payload){
        try {
          // create image from base64
          function dataURItoBlob(dataURI) {
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
  
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
              byteString = atob(dataURI.split(',')[1]);
            else
              byteString = unescape(dataURI.split(',')[1]);
  
            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  
            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
  
            return new Blob([ia], { type: mimeString });
          } 
          let image = payload.image
          let action = payload.action
          let path = payload.path 
  
          let newImage = dataURItoBlob(image);
          let uid = users.state.USER.uid 
          let ext = 'jpeg'
          let storeRef = firebase.storage().ref()
          let uploadTask = null
          let pNum =10
          let photoURL=''
          let dt = new Date
          let date = dt.toISOString()
  
          //not
          commit('updateLoading', true)
          commit('updateProcesses', {
            add: true,
            name: 'uploading/image/'+action,
            val: 5
          })
          if (uid!=null) {
            // Create the file metadata
            let metadata={
              contentType:'image/'+ext,
              created_uid:uid,
              created_at:date
            }
            //upload image and metadata extension  
            uploadTask = storeRef.child("FUTURE_TOURS/IMAGES/"+path+"."+ext).put(newImage,metadata)
  
            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,snapshot=>{
              //TASK PROGRESS, INCLUDING THE NUMBER OF BYTES UPLOADED
              let progress = (snapshot.bytesTransferred / snapshot.totalBytes)*100
              pNum= 0 + progress 
              commit('updateProcesses', {
                add: true,
                name: 'uploading/image/'+action,
                val: pNum
              })
              switch(snapshot.state){
                case firebase.storage.TaskState.PAUSED:  // or 'paused'
                  commit('updateResponses', {
                    res: 'uploading/image/'+action+'/state',
                    val: 'paused'
                  }) 
                  break
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  commit('updateResponses', {
                    res: 'uploading/image/'+action+'/state',
                    val: 'running'
                  }) 
                  break; 
              } 
            },error=>{
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              commit('updateLoading', false)
              commit('updateProcesses', {
                remove: true,
                name: 'uploading/image/'+action, 
              })
              commit('updateResponses', {
                res: 'uploading/image/'+action+'/error',
                val: error
              })
              commit("ERROR",{
                page:NAME,
                from:"fi_uploadImage",
                error:error
              })
              switch (error.code) {
                case 'storage/unauthorized':
                  // User doesn't have permission to access the object 
                  break 
                case 'storage/canceled':
                  // User canceled the upload 
                  break 
                case 'storage/unknown':
                  // Unknown error occurred, inspect error.serverResponse 
                  break;
              }
            },()=>{
              // Upload completed successfully, now we can get the download URL 
              uploadTask.snapshot.ref.getDownloadURL() 
              .then(function (downloadURL) { 
                photoURL = downloadURL 
                return firebase.database().ref("FUTURE_TOURS/"+path).update({ 
                  image:{
                    link: photoURL,
                    path:"FUTURE_TOURS/IMAGES/"+path 
                  },
                  updated_at: date,
                  updated_uid:uid
                })
              }) 
              .then(data=>{
                commit('updateLoading', false)
                commit('updateProcesses', {
                  remove: true,
                  name: 'uploading/image/'+action 
                })
                commit('updateResponses', {
                  res: 'uploading/image/'+action+'/successful',
                  val: data
                })
              }).catch(error=>{
                commit('updateLoading', false)
                commit('updateProcesses', {
                  remove: true,
                  name: 'uploading/image/'+action 
                })
                commit('updateResponses', {
                  res: 'uploading/image/'+action+'/error',
                  val: error
                })
                commit("ERROR",{
                  page:NAME,
                  from:"fi_uploadImage",
                  error:error
                })
              })
            }) 
          }else{ 
            commit('updateLoading', false) 
            commit('updateProcesses', {
              remove: true,
              name: 'uploading/image/'+action 
            })
            commit('updateResponses', {
              res: 'uploading/image/'+action+'/error',
              val: 'no user'
            })
            commit("ERROR",{
              page:NAME,
              from:"fi_uploadImage",
              error:'no user'
            })
          } 
        } catch (error) {
          commit("ERROR",{
            error:error,
            from:'fi_uploadImage',
            page:NAME, 
          })
        }
      }, 
      fs_uploadImage({commit,state},payload){
        try {
          // create image from base64
          function dataURItoBlob(dataURI) {
            // convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
  
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
              byteString = atob(dataURI.split(',')[1]);
            else
              byteString = unescape(dataURI.split(',')[1]);
  
            // separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  
            // write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
  
            return new Blob([ia], { type: mimeString });
          } 
          let image = payload.image
          let action = payload.action
          let path = payload.path
          let child = payload.child
          let array = payload.array
          let image_name = payload.image_name
          let key = payload.key
  
          let newImage = dataURItoBlob(image);
          let uid = users.state.USER?users.state.USER.uid:null 
          let ext = 'jpeg'
          let storeRef = firebase.storage().ref()
          let uploadTask = null
          let pNum =10
          let photoURL=''
          let dt = new Date
          let date = dt.toISOString() 
          //not
          commit('updateLoading', true)
          commit('updateProcesses', {
            add: true,
            name: 'uploading/image/'+action,
            val: 5
          })
          if (uid) { 
            // Create the file metadata
            let metadata={
              contentType:'image/'+ext,
              created_uid:uid,
              created_at:date
            }
            //upload image and metadata extension  
            uploadTask = storeRef.child("FUTURE_TOURS/IMAGES/"+path+'/'+child+'/'+key+'__'+image_name+"."+ext).put(newImage,metadata)
  
            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,snapshot=>{
              //TASK PROGRESS, INCLUDING THE NUMBER OF BYTES UPLOADED
              let progress = (snapshot.bytesTransferred / snapshot.totalBytes)*100
              pNum= 0 + progress 
              commit('updateProcesses', {
                add: true,
                name: 'uploading/image/'+action,
                val: pNum
              })
              switch(snapshot.state){
                case firebase.storage.TaskState.PAUSED:  // or 'paused'
                  commit('updateResponses', {
                    res: 'uploading/image/'+action+'/state',
                    val: 'paused'
                  }) 
                  break
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  commit('updateResponses', {
                    res: 'uploading/image/'+action+'/state',
                    val: 'running'
                  }) 
                  break; 
              } 
            },error=>{
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors 
              commit('updateLoading', false)
              commit('updateProcesses', {
                remove: true,
                name: 'uploading/image/'+action, 
              })
              commit('updateResponses', {
                res: 'uploading/image/'+action+'/error',
                val: error
              })
              commit("ERROR",{
                page:NAME,
                from:"fs_uploadImage",
                error:error
              })
              switch (error.code) {
                case 'storage/unauthorized':
                  // User doesn't have permission to access the object 
                  break 
                case 'storage/canceled':
                  // User canceled the upload 
                  break 
                case 'storage/unknown':
                  // Unknown error occurred, inspect error.serverResponse 
                  break;
              }
            },()=>{
              // Upload completed successfully, now we can get the download URL 
              uploadTask.snapshot.ref.getDownloadURL() 
              .then(function (downloadURL) {  
                photoURL = downloadURL 
                let storeRef = firebase.firestore().doc(""+path)
                let updateData = {} 
                if(array){
                  if (child) {
                    array.push({
                      name:image_name?image_name:'.',
                      link: photoURL,
                      path:"FUTURE_TOURS/IMAGES/"+path 
                    })
                    updateData[child] = array  
                  }else{
                    array.push({
                      name:image_name?image_name:'.',
                      link: photoURL,
                      path:"FUTURE_TOURS/IMAGES/"+path 
                    })
                    updateData = array
                     
                  }
                }else{
                  if (child) {
                    updateData[child] = {
                      name:image_name?image_name:'.',
                      link: photoURL,
                      path:"FUTURE_TOURS/IMAGES/"+path 
                    }
                  }else{
                    updateData = {
                      name:image_name?image_name:'.',
                      link: photoURL,
                      path:"FUTURE_TOURS/IMAGES/"+path 
                    }
                  }
                }
                
  
                return storeRef.update(updateData)
              }) 
              .then(data=>{ 
                commit('updateLoading', false)
                commit('updateProcesses', {
                  remove: true,
                  name: 'uploading/image/'+action 
                })
                commit('updateResponses', {
                  res: 'uploading/image/'+action+'/successful',
                  val: data
                })
              }).catch(error=>{ 
                commit('updateLoading', false)
                commit('updateProcesses', {
                  remove: true,
                  name: 'uploading/image/'+action 
                })
                commit('updateResponses', {
                  res: 'uploading/image/'+action+'/error',
                  val: error
                })
                commit("ERROR",{
                  page:NAME,
                  from:"fs_uploadImage",
                  error:error
                })
              })
            }) 
          }else{ 
            commit('updateLoading', false) 
            commit('updateProcesses', {
              remove: true,
              name: 'uploading/image/'+action 
            })
            commit('updateResponses', {
              res: 'uploading/image/'+action+'/error',
              val: 'no user'
            })
            commit("ERROR",{
              page:NAME,
              from:"fs_uploadImage",
              error:'no user'
            })
          } 
        } catch (error) {
          commit("ERROR",{
            error:error,
            from:'fi_uploadImage',
            page:NAME, 
          })
        }
      }, 
      fi_uploadSales({ commit,dispatch}, payload) {
        return new Promise((resolve,reject)=>{
          let response = {
            from:'fi_uploadSales',
            page:NAME, 
            payload:payload
          }
          try { 
            commit('updateLoading', true)
            commit('updateProcesses', {
              add: true,
              name: 'uploading/sales' 
            })  
            DB.getItems({
              name:DATA.ITEMS.LOCAL_SALE_ORDERS.values
            }).then(async res=>{ 
              let orders = res?res.data:null 

              // console.log(orders,'orders......**');
              let submit_errors = false
              let uploaded_orders = []
              let successes = []
              let errors = []
              if (!orders) {
                resolve({
                  error:"no orders"
                })
                return
              } 
              for (const order of orders) {
                let order_key = order.key
                let day_shift_key = order.day_shift_key
                let company_key = order.company_key
                let created_uid = order.created_uid

                if (!order.uploaded) {
                // if (true) {
                  // delete joined items
                  delete order.joined_items 
                  let path = await dispatch("company_link",{
                    link:DATA.PATHS.days_shifts_sales+day_shift_key+"/"+order_key,
                    company_key:company_key
                  }) 
                  if (order_key && path && company_key) {
                    try {
                      let upRes = await dispatch('fi_update',{
                        action:DATA.ITEMS.SALE_ORDERS.values,
                        data:{...order,uploaded:true},
                        uid:created_uid,
                        path:path,
                      })  
                      //-------UPDATE ORDER AFTER UPLOAD
                      await DB.addItems({
                        name:DATA.ITEMS.LOCAL_SALE_ORDERS.values, 
                        data:{...order,uploaded:true}
                      }) 
                      successes.push(upRes)
                      uploaded_orders.push(order)
                    } catch (error) {
                      errors.push(error)
                      submit_errors = true
                    }
                  }else{
                    submit_errors = true
                    errors.push({
                      order:order,
                      error:"payload required"
                    })
                  }   
                }else{  
                }
              } 
              let res_localSales = await DB.getItems({
                name:DATA.ITEMS.LOCAL_SALE_ORDERS.values
              }) 
              await dispatch("add_items",{
                name:DATA.ITEMS.LOCAL_SALE_ORDERS.values,
                data:res_localSales?res_localSales.data:null
              })

              if (submit_errors) {
                reject({
                  error:"finish with error",
                  errors:errors,
                  successes:successes,
                  uploaded_orders:uploaded_orders,
                  local_sale_orders:res_localSales?res_localSales.data:null
                })
              } else {
                resolve({
                  success:true,
                  errors:errors,
                  successes:successes,
                  uploaded_orders:uploaded_orders,
                  local_sale_orders:res_localSales?res_localSales.data:null
                })
              } 
              commit('updateLoading', false)
            }).catch(error=>{
              commit('updateLoading', false) 
              reject({
                ...response,
                error:error
              })
            })  
          } catch (error) {
            commit('updateLoading', false)
            commit("ERROR",{
              ...response,
              error:error
            })
            reject({
              ...response,
              error:error
            })
          }
        })
      }, 
      fi_uploadItemOrders({ commit,dispatch}, payload) {
        return new Promise((resolve,reject)=>{
          let response = {
            from:'fi_uploadItemOrders',
            page:NAME, 
            payload:payload
          }
          try { 
            commit('updateLoading', true)
            commit('updateProcesses', {
              add: true,
              name: 'uploading/item_orders' 
            })  
            DB.getItems({
              name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values
            }).then(async res=>{ 
              let orders = res?res.data:null 
              let submit_errors = false
              let uploaded_orders = []
              let successes = []
              let errors = []
              if (!orders) {
                resolve({
                  error:"no orders"
                })
                return
              } 
              for (const order of orders) {
                let order_key = order.key
                let day_shift_key = order.day_shift_key
                let company_key = order.company_key
                let created_uid = order.created_uid
                let path = await dispatch("company_link",{
                  link:DATA.PATHS.item_orders+day_shift_key+"/"+order_key,
                  company_key:company_key
                }) 


                if (order.deleted) {
                  // await dispatch("fi_delete",{
                  //   path:path,
                  //   action:DATA.ITEMS.ITEM_ORDERS.values
                  // })
                  // await DB.deleteItems({
                  //   name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values,
                  //   key:order.key,
                  //   update_locals:true
                  // })
                }
                if (!order.uploaded) {
                  if (order_key && path && company_key) {
                    try {
                      let upRes = await dispatch('fi_update',{
                        action:DATA.ITEMS.ITEM_ORDERS.values,
                        data:{...order,uploaded:true},
                        uid:created_uid,
                        path:path,
                      })  
                      //-------UPDATE ORDER AFTER UPLOAD
                      await DB.addItems({
                        name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values, 
                        data:{...order,uploaded:true},
                        update_locals:true
                      }) 
                      successes.push(upRes)
                      uploaded_orders.push(order)
                    } catch (error) {
                      errors.push(error)
                      submit_errors = true
                    }
                  }else{
                    submit_errors = true
                    errors.push({
                      order:order,
                      error:"payload required"
                    })
                  }   
                }else{  
                }
              } 
              let res_local_item_orders = await DB.getItems({
                name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values
              }) 
              // await dispatch("add_items",{
              //   name:DATA.ITEMS.LOCAL_ITEM_ORDERS.values,
              //   data:res_local_item_orders?res_local_item_orders.data:null
              // })

              if (submit_errors) {
                reject({
                  error:"finish with error",
                  errors:errors,
                  successes:successes,
                  uploaded_orders:uploaded_orders,
                  local_sale_orders:res_local_item_orders?res_local_item_orders.data:null
                })
              } else {
                resolve({
                  success:true,
                  errors:errors,
                  successes:successes,
                  uploaded_orders:uploaded_orders,
                  local_sale_orders:res_local_item_orders?res_local_item_orders.data:null
                })
              } 
              commit('updateLoading', false)
            }).catch(error=>{
              commit('updateLoading', false) 
              reject({
                ...response,
                error:error
              })
            })  
          } catch (error) {
            commit('updateLoading', false)
            commit("ERROR",{
              ...response,
              error:error
            })
            reject({
              ...response,
              error:error
            })
          }
        })
      },  
      //-----------------[ FI SEND]---------------
      fi_add_Message({commit},payload){
        try {
          //not
          commit('updateLoading', true)
          commit('updateProcesses', {
            add: true,
            name: 'adding/message',
            val:'start'
          })
          let dt = new Date
          let date = dt.toISOString()
          let message ={ 
            ...payload,
            created_at:date,
            created_at_server:firebase.database.ServerValue.TIMESTAMP
          }
          let uid = users.state.USER?users.state.USER.uid:null 
          if (uid) {
            message.created_uid = uid
          }
          let ref = firebase.database().ref('FUTURE_TOURS/MESSAGES').push(message)
          ref.then(res => {
            //not
            commit('updateLoading', false)
            commit('updateProcesses', {
              remove: true,
              name: 'adding/message'
            })
            commit('updateResponses', {
              res: 'adding/message/successful',
              val: res
            })
          }).catch(error => {
            //not
            commit('updateLoading', false) 
            commit('updateResponses', {
              res: 'adding/message/error',
              val: error
            })
            commit("ERROR",{
              page:NAME,
              from:"fi_add_Message",
              error:error
            })
          }) 
        } catch (error) {
          commit("ERROR",{
            error:error,
            from:'fi_add_Message',
            page:NAME, 
          })
        }
      }, 
      //-----------------[ EXIST]---------------
      fs_exist({ commit, state },payload) {
        try {
          let action = payload.action
          let path = payload.path
          let exist = payload.exist 
          let exRef = firebase.firestore().collection(path).where(exist.path,"==",exist.value)
          exRef.get().then(snapshot=>{
            var newData = [];
            snapshot.forEach(function(doc) { 
                newData.push({
                  ...doc.data(),
                  key:doc.id
                });
            }); 
 
          }).catch(error=>{ 
          }) 
        } catch (error) {
          commit("ERROR",{
            error:error,
            from:'fs_exist',
            page:NAME, 
          })
        } 
      },
      //-----------------[ FI ADD]---------------
      fi_add({ commit}, payload) {
        return new Promise((resolve,reject)=>{
          let response = {
            from:'fi_add',
            page:NAME, 
            payload:payload
          }
          try {
            let action = payload.action
            let path = payload.path
            let data = payload.data
            let no_frag = payload.multiple
            let us = users?users.state.USER:null
            let uid = us?us.uid:null 
            if (!uid) {
              commit('updateResponses', {
                res: 'adding/'+action+'/error',
                val: "no action"
              })
              reject({
                ...response,
                error:"no uid"
              })
              return
            } 
            //not
            commit('updateLoading', true)
            commit('updateProcesses', {
              add: true,
              name: 'adding/'+action
            }) 
            if (action) { 
              let dt = new Date
              let date = dt.toISOString()
              let record = data
              if (!no_frag) {
                record = {
                  ...data,
                  created_uid:uid,
                  created_at:date,
                  created_at_server:firebase.database.ServerValue.TIMESTAMP
                } 
              } 
              let ref = firebase.database().ref(path).push(record)
              ref.then(res => {
                //not
                commit('updateLoading', false)
                commit('updateProcesses', { 
                  name: 'adding/'+action
                })
                commit('updateResponses', {
                  res: 'adding/'+action+'/successful',
                  val: res
                })
                resolve({
                  success:true,
                  data:res
                })
              }).catch(error => {
                //not
                commit('updateLoading', false)
                commit('updateProcesses', { 
                  name: 'adding/'+action
                })
                commit('updateResponses', {
                  res: 'adding/'+action+'/error',
                  val: error
                })
                commit("ERROR",{
                  page:NAME,
                  from:"fi_add",
                  error:error
                })
                reject({
                  ...response,
                  error:error
                })
              })
            } else {
              //not
              commit('updateLoading', false)
              commit('updateProcesses', { 
                value: 'adding/'+action
              })
              commit('updateResponses', {
                res: 'adding/'+action+'/error',
                val: "no action"
              })
              commit("ERROR",{
                page:NAME,
                from:"fi_add",
                error:'no user'
              })
              reject({
                ...response,
                error:"no user"
              })
            } 
          } catch (error) {
            commit("ERROR",{
              error:error,
              from:'fi_add',
              page:NAME, 
            })
            reject({
              ...response,
              error:error
            })
          }
        })
      }, 
      fi_add_array({ commit}, payload) {
        return new Promise((resolve,reject)=>{
          let response = {
            from:'fi_add_array',
            page:NAME, 
            payload:payload
          }
          try {
            let action = payload.action
            let path = payload.path
            let items = payload.items
            let us = users?users.state.USER:null
            let uid = us?us.uid:null 
            if (!uid) {
              commit('updateResponses', {
                res: 'adding/'+action+'/error',
                val: "no action"
              })
              reject({
                ...response,
                error:"no uid"
              })
              return
            } 
            //not
            commit('updateLoading', true)
            commit('updateProcesses', {
              add: true,
              name: 'adding/'+action
            }) 
            if (action) { 
              let dt = new Date
              let date = dt.toISOString()
              let ref = firebase.database().ref(path)
              let res = []
              let res_error = []
              let index = 0
              items.forEach(async data => {
                index = index+1
                try {
                  let record = {
                    ...data,
                    created_uid:uid,
                    created_at:date,
                    created_at_server:firebase.database.ServerValue.TIMESTAMP
                  }
                  res.push(await ref.push(record)) 
                  
                } catch (error) {
                  res_error.push(error)
                }
                if (items.length == index) {
                  if (res_error.length == 0) {
                    //not
                    commit('updateLoading', false)
                    commit('updateProcesses', { 
                      name: 'adding/'+action
                    })
                    commit('updateResponses', {
                      res: 'adding/'+action+'/successful',
                      val: res
                    })
                    resolve({
                      success:true,
                      data:res
                    }) 
                  } else {
                    //not
                    commit('updateLoading', false)
                    commit('updateProcesses', { 
                      value: 'adding/'+action
                    })
                    commit('updateResponses', {
                      res: 'adding/'+action+'/error',
                      val: "no action"
                    })
                    commit("ERROR",{
                      page:NAME,
                      from:"fi_add",
                      error:{
                        errors:res_error,
                        error:"some item not added"
                      }
                    })
                    reject({
                      ...response,
                      error:res_error
                    })
                  }  
                }
                
              });

            } else {
              //not
              commit('updateLoading', false)
              commit('updateProcesses', { 
                value: 'adding/'+action
              })
              commit('updateResponses', {
                res: 'adding/'+action+'/error',
                val: "no action"
              })
              commit("ERROR",{
                page:NAME,
                from:"fi_add",
                error:'no user'
              })
              reject({
                ...response,
                error:"no user"
              })
            } 
          } catch (error) {
            commit("ERROR",{
              error:error,
              from:'fi_add',
              page:NAME, 
            })
            reject({
              ...response,
              error:error
            })
          }
        })
      }, 
      fs_add({ commit}, payload) {
        try {
          let action = payload.action
          let path = payload.path
          let data = payload.data
          let us = users?users.state.USER:null
          let uid = us?us.uid:null 
          if (!uid) {
            commit('updateResponses', {
              res: 'adding/'+action+'/error',
              val: "no action"
            }) 
            return
          }
  
          //not
          commit('updateLoading', true)
          commit('updateProcesses', {
            add: true,
            name: 'adding/'+action
          }) 
          if (action) { 
            let dt = new Date
            let date = dt.toISOString()
            let record = {
              ...data,
              created_uid:uid,
              created_at:date,
              created_at_server:firebase.firestore.FieldValue.serverTimestamp()
            }
            let fsRef = firebase.firestore().collection(path).add(record)
            
            fsRef.then(res => {
              //not
              commit('updateLoading', false)
              commit('updateProcesses', { 
                name: 'adding/'+action
              })
              commit('updateResponses', {
                res: 'adding/'+action+'/successful',
                val: res
              })
            }).catch(error => {
              //not
              commit('updateLoading', false)
              commit('updateProcesses', { 
                name: 'adding/'+action
              })
              commit('updateResponses', {
                res: 'adding/'+action+'/error',
                val: error
              })
              commit("ERROR",{
                page:NAME,
                from:"fs_add",
                error:error
              })
            })
          } else {
            //not
            commit('updateLoading', false)
            commit('updateProcesses', { 
              value: 'adding/'+action
            })
            commit('updateResponses', {
              res: 'adding/'+action+'/error',
              val: "no action"
            })
            commit("ERROR",{
              page:NAME,
              from:"fs_add",
              error:'no user'
            })
          } 
        } catch (error) {
          commit("ERROR",{
            error:error,
            from:'fs_add',
            page:NAME, 
          })
        }
      },
      //-----------------[ FI UPDATE]---------------
      fi_update({ commit}, payload) {
        return new Promise(async (resolve,reject)=>{
          let response = {
            from:'fi_update',
            page:NAME, 
            payload:payload
          }
          try {
            let action = payload.action
            let path = payload.path
            let data = payload.data
            let uid = payload.uid
            let no_frag = payload.no_frag
            let multiple = payload.multiple
            if (!uid) {
              let us = users?users.state.USER:null
              uid = us?us.uid:null  
            }

            if (!uid) {
              commit('updateResponses', {
                res: 'updating/'+action+'/error',
                val: "no action"
              })
              reject({
                ...response,
                error:"no uid"
              })
              return
            } 
 
            //not
            commit('updateLoading', true)
            commit('updateProcesses', {
              add: true,
              name: 'updating/'+action
            }) 
            if (action) { 
              let dt = new Date
              let date = dt.toISOString()
              let ref = null
              let record = {}
              if (multiple) { 
                function groupData(array, groupSize) {
                  const result = [];
                  for (let i = 0; i < array.length; i += groupSize) {
                    result.push(array.slice(i, i + groupSize));
                  }
                  return result;
                }  
                const groupedData = groupData(data, 900);  
                try {
                  let responses = []
                  for (const gData of groupedData) { 
                    let dataItem = gData
                    if (hasUndefinedProperties(gData)) {
                      dataItem = replaceUndefinedWithNull(gData)
                    } 
                    record = {}
                    dataItem.forEach(item => { 
                      let item_path = item.path
                      let has = item_path?item_path.slice(-1)==="/":false
                      item_path = item_path+(has?"":"/")
                      for (const key in item) {
                        if (Object.hasOwnProperty.call(item, key) && key != "path") { 
                          record[item_path+key] =  item[key];
                        }
                      }
                      if (!no_frag) {
                        record[item_path+"updated_uid"] =  uid
                        record[item_path+"updated_at"] =  date
                        record[item_path+"updated_at_server"] =  firebase.database.ServerValue.TIMESTAMP 
                      } 
                    });  
                    let res = await firebase.database().ref().update(record)
                    responses.push(res)
                  } 
                   //not
                   commit('updateLoading', false)
                   commit('updateProcesses', { 
                     name: 'updating/'+action
                   })
                   commit('updateResponses', {
                     res: 'updating/'+action+'/successful',
                     val: responses
                   })
                   resolve({
                     success:true,
                     data:responses
                   })
                } catch (error) {
                  console.log({
                    ...response,
                    error:error
                   },'///////////////////////////////error');
                   //not
                   commit('updateLoading', false)
                   commit('updateProcesses', { 
                     name: 'updating/'+action
                   })
                   commit('updateResponses', {
                     res: 'updating/'+action+'/error',
                     val: error
                   })
                   commit("ERROR",{
                    ...response,
                    error:error
                   })
                   reject({
                     ...response,
                     error:error
                   })
                } 
              } else {

                //to do UNDEFINED PROPERTIES
                if (hasUndefinedProperties(data)) {
                  record = replaceUndefinedWithNull(data)
                } else {
                  record = data 
                }


                if (!no_frag) {
                  record = {
                    ...record,
                    updated_uid:uid,
                    updated_at:date,
                    updated_at_server:firebase.database.ServerValue.TIMESTAMP
                  }
                }
                ref = firebase.database().ref(path).update(record)   
                ref.then(res => {
                  //not
                  commit('updateLoading', false)
                  commit('updateProcesses', { 
                    name: 'updating/'+action
                  })
                  commit('updateResponses', {
                    res: 'updating/'+action+'/successful',
                    val: res
                  })
                  resolve({
                    success:true,
                    data:res
                  })
                }).catch(error => {
                  //not
                  commit('updateLoading', false)
                  commit('updateProcesses', { 
                    name: 'updating/'+action
                  })
                  commit('updateResponses', {
                    res: 'updating/'+action+'/error',
                    val: error
                  })
                  commit("ERROR",{
                    ...response,
                    error:error
                  })
                  reject({
                    ...response,
                    error:error
                  })
                })
              }

            } else {
              //not
              commit('updateLoading', false)
              commit('updateProcesses', { 
                name: 'updating/'+action
              })
              commit('updateResponses', {
                res: 'updating/'+action+'/error',
                val: "no action"
              })
              commit("ERROR",{
                ...response,
                error:error
              })
              reject({
                ...response,
                error:"no action"
              })
            } 
          } catch (error) {
            commit("ERROR",{
              ...response,
              error:error
            })
            reject({
              ...response,
              error:error
            })
          }
        })
      }, 
      fs_update({ commit}, payload) {
        try {
          let action = payload.action
          let path = payload.path
          let data = payload.data
          let us = users?users.state.USER:null
          let uid = us?us.uid:null 
          if (!uid) {
            commit('updateResponses', {
              res: 'adding/'+action+'/error',
              val: "no action"
            }) 
            return
          } 
          //not
          commit('updateLoading', true)
          commit('updateProcesses', {
            add: true,
            name: 'updating/'+action
          }) 
          if (action) { 
            let dt = new Date
            let date = dt.toISOString()
            let record = {...data,updated_uid:uid,updated_at:date,updated_at_server:firebase.firestore.FieldValue.serverTimestamp()} 
            let ref = firebase.firestore().doc(path).update(record)
            ref.then(res => {
              //not
              commit('updateLoading', false)
              commit('updateProcesses', { 
                name: 'updating/'+action
              })
              commit('updateResponses', {
                res: 'updating/'+action+'/successful',
                val: res
              })
            }).catch(error => {
              //not
              commit('updateLoading', false)
              commit('updateProcesses', { 
                name: 'updating/'+action
              })
              commit('updateResponses', {
                res: 'updating/'+action+'/error',
                val: error
              })
              commit("ERROR",{
                page:NAME,
                from:"fs_update",
                error:error
              })
            })
          } else {
            //not
            commit('updateLoading', false)
            commit('updateProcesses', { 
              name: 'updating/'+action
            })
            commit('updateResponses', {
              res: 'updating/'+action+'/error',
              val: "no action"
            })
            commit("ERROR",{
              page:NAME,
              from:"fs_update",
              error:'no user'
            })
          } 
        } catch (error) {
          commit("ERROR",{
            error:error,
            from:'fs_update',
            page:NAME, 
          })
        }
      }, 
      fi_set({ commit, dispatch}, payload) {
        return new Promise(async(resolve,reject)=>{
          let response = {
            from:'fi_set',
            page:NAME, 
          }
          let action = payload.action
          let path = payload.path
          let path_invalid = payload.path_invalid
          let data = payload.data
          let us = users?users.state.USER:null
          let uid = us?us.uid:null  
          let array = payload.array   
          let download_data = payload.download_data   
          let PATH_REF = firebase.database().ref(path)
          let check_key = null
          try { 
            commit('updateLoading', true)
            commit('updateProcesses', {
              add: true,
              name: 'setting/'+action
            }) 
            if (action && uid && path && data) { 
              let dt = new Date
              let date = dt.toISOString()
              let record =array?data:{
                ...data,
                created_uid:uid,
                created_at:date,
                created_at_server:firebase.database.ServerValue.TIMESTAMP
              }
              let available = path_invalid?true:false
              if (!available) {
                check_key = await dispatch('fi_check_key',{path:path})
                available = check_key?check_key.available:null 
              }
              if (available) {
                let res = await PATH_REF.set(record)
                response.success = true,
                response.data = res
                if (!download_data) {
                  commit('updateLoading', false)
                  commit('updateProcesses', { 
                    name: 'setting/'+action
                  })
                  commit('updateResponses', {
                    res: 'setting/'+action+'/successful',
                    val: response
                  }) 
                  resolve(response) 
                } else {
                  let downloaded_data = null
                  await PATH_REF.once('value', async(snapshot) => {
                    downloaded_data = await snapshot.val() 
                  }) 
                  response.downloaded_data = downloaded_data 
                  commit('updateLoading', false)
                  commit('updateProcesses', { 
                    name: 'setting/'+action
                  })
                  commit('updateResponses', {
                    res: 'setting/'+action+'/successful',
                    val: response
                  }) 
                  resolve(response) 
                }
              } else {
                response.error="path not available"
                response.payload=payload
                response.uid=uid
                response.data=check_key
                commit('updateLoading', false)
                commit('updateProcesses', { 
                  name: 'setting/'+action
                })
                commit('updateResponses', {
                  res: 'setting/'+action+'/error',
                  val: "no action"
                })
                commit("ERROR",response)
                reject(response)
              } 
            } else {
              response.error="payload required"
              response.payload=payload
              response.uid=uid
              commit('updateLoading', false)
              commit('updateProcesses', { 
                name: 'setting/'+action
              })
              commit('updateResponses', {
                res: 'setting/'+action+'/error',
                val: "no action"
              })
              commit("ERROR",response)
              reject(response)
            } 
          } catch (error) {
            response.error = error
            response.payload = payload
            commit('updateLoading', false)
            commit('updateProcesses', { 
              name: 'setting/'+action
            })
            commit('updateResponses', {
              res: 'setting/'+action+'/error',
              val: response
            }) 
            commit("ERROR",response)
            reject(response)
          }  
        })
      },
      fs_set({ commit}, payload) {
        try {
          let action = payload.action
          let path = payload.path
          let data = payload.data
          let us = users?users.state.USER:null
          let uid = us?us.uid:null 
          if (!uid) {
            commit('updateResponses', {
              res: 'adding/'+action+'/error',
              val: "no action"
            }) 
            return
          }
          let array = payload.array 
          
          //not
          commit('updateLoading', true)
          commit('updateProcesses', {
            add: true,
            name: 'setting/'+action
          }) 
          if (action) { 
            let dt = new Date
            let date = dt.toISOString()
            let record =array?data:{
              ...data,
              created_uid:uid,
              updated_at:date,
              created_at_server:firebase.firestore.FieldValue.serverTimestamp()
            }
            let ref = firebase.firestore().collection(path).set(record)
            ref.then(res => {
              //not
              commit('updateLoading', false)
              commit('updateProcesses', { 
                name: 'setting/'+action
              })
              commit('updateResponses', {
                res: 'setting/'+action+'/successful',
                val: res
              })
            }).catch(error => {
              //not
              commit('updateLoading', false)
              commit('updateProcesses', { 
                name: 'setting/'+action
              })
              commit('updateResponses', {
                res: 'setting/'+action+'/error',
                val: error
              })
              commit("ERROR",{
                page:NAME,
                from:"fi_set",
                error:error
              })
            })
          } else {
            //not
            commit('updateLoading', false)
            commit('updateProcesses', { 
              name: 'setting/'+action
            })
            commit('updateResponses', {
              res: 'setting/'+action+'/error',
              val: "no action"
            })
            commit("ERROR",{
              page:NAME,
              from:"fs_set",
              error:'no user'
            })
          } 
        } catch (error) {
          commit("ERROR",{
            error:error,
            from:'fs_set',
            page:NAME, 
          })
        }
      },

      //-----------------[ FI DELETE]------------
      fi_delete({ commit}, payload) {
        return new Promise(async (resolve,reject)=>{
          let response = {
            from:'fi_delete',
            page:NAME, 
            payload:payload, 
          }
          try {
            let action = payload.action
            let path = payload.path  
            let multiple = payload.multiple  
            let data = payload.data?payload.data:payload.paths  
    
            //not
            commit('updateLoading', true)
            commit('updateProcesses', {
              add: true,
              name: 'deleting/'+action
            }) 
            if (action) {  
              let ref = null
              if (multiple) { 
                function groupData(array, groupSize) {
                  const result = [];
                  for (let i = 0; i < array.length; i += groupSize) {
                    result.push(array.slice(i, i + groupSize));
                  }
                  return result;
                }  
                const groupedData = groupData(data, 900);  
                try {
                  let responses = []
                  for (const dataItem of groupedData) { 
                    let record = {}
                    dataItem.forEach(item => { 
                      let item_path = item.path
                      record[item_path] =  null 
                    });  
                    let res = await firebase.database().ref().update(record)
                    responses.push(res)
                  } 
                   //not
                   commit('updateLoading', false)
                   commit('updateProcesses', { 
                     name: 'deleting/'+action
                   })
                   commit('updateResponses', {
                     res: 'deleting/'+action+'/successful',
                     val: responses
                   })
                   resolve({
                     success:true,
                     data:responses
                   })
                } catch (error) {
                   //not
                   commit('updateLoading', false)
                   commit('updateProcesses', { 
                     name: 'deleting/'+action
                   })
                   commit('updateResponses', {
                     res: 'deleting/'+action+'/error',
                     val: error
                   })
                   commit("ERROR",{
                     page:NAME,
                     from:"fi_delete",
                     error:error
                   })
                   reject({
                     ...response,
                     error:error
                   })
                } 
              }else{
                ref = firebase.database().ref(path).set(null)
                ref.then(res => {
                  //not
                  commit('updateLoading', false)
                  commit('updateProcesses', { 
                    name: 'deleting/'+action
                  })
                  commit('updateResponses', {
                    res: 'deleting/'+action+'/successful',
                    val: res
                  })
                  resolve({
                    response:'success',
                    deleted:true
                  })
                }).catch(error => {
                  //not
                  commit('updateLoading', false)
                  commit('updateProcesses', { 
                    name: 'deleting/'+action
                  })
                  commit('updateResponses', {
                    res: 'deleting/'+action+'/error',
                    val: error
                  })
                  commit("ERROR",{
                    page:NAME,
                    from:"fi_delete",
                    error:error
                  })
                  reject({
                    response:'failed',
                    error:error
                  })
                })

              }
            } else {
              //not
              commit('updateLoading', false)
              commit('updateProcesses', { 
                name: 'deleting/'+action
              })
              commit('updateResponses', {
                res: 'deleting/'+action+'/error',
                val: "no action"
              })
              commit("ERROR",{
                page:NAME,
                from:"fi_delete",
                error:'no user'
              })
              reject({
                response:'failed',
                error:'no user'
              })
            } 
          } catch (error) {
            commit("ERROR",{
              error:error,
              from:'fi_delete',
              page:NAME, 
            })
            reject({
              response:'failed',
              error:error
            })
          } 
        })
      },
      fs_delete({ commit}, payload) {
        try {
          let action = payload.action
          let path = payload.path  
        
          //not
          commit('updateLoading', true)
          commit('updateProcesses', {
            add: true,
            name: 'deleting/'+action
          }) 
          if (action) {  
          
            let ref = firebase.firestore().doc(path).delete()
            ref.then(res => {
            
              //not
              commit('updateLoading', false)
              commit('updateProcesses', { 
                name: 'deleting/'+action
              })
              commit('updateResponses', {
                res: 'deleting/'+action+'/successful',
                val: res
              })
            }).catch(error => {
            
              //not
              commit('updateLoading', false)
              commit('updateProcesses', { 
                name: 'deleting/'+action
              })
              commit('updateResponses', {
                res: 'deleting/'+action+'/error',
                val: error
              })
              commit("ERROR",{
                page:NAME,
                from:"fi_delete",
                error:error
              })
            })
          } else {
            //not
            commit('updateLoading', false)
            commit('updateProcesses', { 
              name: 'deleting/'+action
            })
            commit('updateResponses', {
              res: 'deleting/'+action+'/error',
              val: "no action"
            })
            commit("ERROR",{
              page:NAME,
              from:"fi_delete",
              error:'no user'
            })
          } 
        } catch (error) {
          commit("ERROR",{
            error:error,
            from:'fs_delete',
            page:NAME, 
          })
        }
      },
      fi_deleteImage({commit},payload){ 
        try {
          let link = payload.link
          let action = payload.action
          let path = payload.path
          let uid = users.state.user.uid
          let ext = 'jpeg'
          let storeRef = firebase.storage().refFromURL(link)
          //not
          commit('updateLoading', true)
          commit('updateProcesses', {
            add: true,
            name: 'deleting/image/'+action 
          })
          if (uid!=null) {   
            storeRef.delete() 
            .then( ()=> {  
              return firebase.database().ref(path).update({ 
                image:null
              })
            }) 
            .then(data => {
              commit('updateLoading', false)
              commit('updateProcesses', { 
                name: 'deleting/image/'+action 
              })
              commit('updateResponses', {
                res: 'deleting/image/'+action+'/successful',
                val: data
              }) 
            }).catch(error => {
              
              if (error.code ==="storage/object-not-found") { 
                firebase.database().ref(path).update({ 
                  image:null
                })
                .then(data => {
                  commit('updateLoading', false)
                  commit('updateProcesses', { 
                    name: 'deleting/image/'+action 
                  })
                  commit('updateResponses', {
                    res: 'deleting/image/'+action+'/successful',
                    val: data
                  }) 
                })
                .catch(error2=>{
                  commit('updateLoading', false)
                  commit('updateProcesses', { 
                    value: 'deleting/image/'+action 
                  })
                  commit('updateResponses', {
                    res: 'deleting/image/'+action+'/error',
                    val: error2
                  }) 
                  commit("ERROR",{
                    page:NAME,
                    from:"fi_deleteImage",
                    error:error2
                  })
                })
              } else { 
                commit('updateLoading', false)
                commit('updateProcesses', { 
                  value: 'deleting/image/'+action 
                })
                commit('updateResponses', {
                  res: 'deleting/image/'+action+'/error',
                  val: error
                }) 
                commit("ERROR",{
                  page:NAME,
                  from:"fi_deleteImage",
                  error:error
                })
              }
              
            }) 
          }else{ 
            commit('updateLoading', false) 
            commit('updateProcesses', { 
              name: 'deleting/image/'+action 
            })
            commit('updateResponses', {
              res: 'deleting/image/'+action+'/error',
              val: 'no user'
            })
            commit("ERROR",{
              page:NAME,
              from:"fi_deleteImage",
              error:'no user'
            })
          } 
        } catch (error) {
          commit("ERROR",{
            error:error,
            from:'fi_uploadImage',
            page:NAME, 
          })
        }
      },
      fs_deleteImage({commit},payload){ 
        try {
          let link = payload.link
          let action = payload.action
          let path = payload.path
          let child = payload.child
          let array = payload.array
          let uid = users.state.USER.uid
          let ext = 'jpeg'
          let storeRef = firebase.storage().refFromURL(link)
          //not
          commit('updateLoading', true)
          commit('updateProcesses', {
            add: true,
            name: 'deleting/image/'+action 
          })
          if (uid!=null) {   
            storeRef.delete() 
            .then( ()=> {  
              let imgRef = firebase.firestore().doc(""+path)
              let updateData = {}
              updateData[child] = null
              if (array) {
                updateData[child] = array
              }
              return imgRef.update(updateData)
               
            }) 
            .then(data => {
              commit('updateLoading', false)
              commit('updateProcesses', { 
                name: 'deleting/image/'+action 
              })
              commit('updateResponses', {
                res: 'deleting/image/'+action+'/successful',
                val: data
              }) 
            }).catch(error => {
              
              if (error.code ==="storage/object-not-found") { 
                firebase.database().ref(path).update({ 
                  image:null
                })
                .then(data => {
                  commit('updateLoading', false)
                  commit('updateProcesses', { 
                    name: 'deleting/image/'+action 
                  })
                  commit('updateResponses', {
                    res: 'deleting/image/'+action+'/successful',
                    val: data
                  }) 
                })
                .catch(error2=>{
                  commit('updateLoading', false)
                  commit('updateProcesses', { 
                    value: 'deleting/image/'+action 
                  })
                  commit('updateResponses', {
                    res: 'deleting/image/'+action+'/error',
                    val: error2
                  }) 
                  commit("ERROR",{
                    page:NAME,
                    from:"fs_deleteImage",
                    error:error2
                  })
                })
              } else { 
                commit('updateLoading', false)
                commit('updateProcesses', { 
                  value: 'deleting/image/'+action 
                })
                commit('updateResponses', {
                  res: 'deleting/image/'+action+'/error',
                  val: error
                }) 
                commit("ERROR",{
                  page:NAME,
                  from:"fs_deleteImage",
                  error:error
                })
              }
              
            }) 
          }else{ 
            commit('updateLoading', false) 
            commit('updateProcesses', { 
              name: 'deleting/image/'+action 
            })
            commit('updateResponses', {
              res: 'deleting/image/'+action+'/error',
              val: 'no user'
            })
            commit("ERROR",{
              page:NAME,
              from:"fs_deleteImage",
              error:'no user'
            })
          } 
        } catch (error) {
          commit("ERROR",{
            error:error,
            from:'fs_deleteImage',
            page:NAME, 
          })
        }
      },
      fi_deleteAllImages({commit,dispatch},payload){ 
        try {
          let action = payload.action
          let path = payload.path
          let uid = users.state.user.uid 
          let disp = payload.dispatch
          let dispData = payload.dispatchData
  
          let storeRef = firebase.storage().ref(path) 
          //not
          commit('updateLoading', true)
          commit('updateProcesses', {
            add: true,
            name: 'deleting/image/'+action 
          })
          if (uid!=null) {  
            //IF USER 
            storeRef.listAll()
            .then(RESULTS=>{
              let itemSize = RESULTS.items.length
              let NUMBER = 0  
              RESULTS.items.forEach(item => {
                NUMBER = NUMBER+1
                let imageRef = firebase.storage().ref(item.fullPath)
                if (NUMBER===itemSize) { 
                  return imageRef.delete() 
                } else { 
                  imageRef.delete() 
                } 
              });
            })
            .then(data => {
              if (disp) {
                dispatch(disp,dispData)
              }
              commit('updateLoading', false)
              commit('updateProcesses', { 
                name: 'deleting/image/'+action 
              })
              commit('updateResponses', {
                res: 'deleting/image/'+action+'/successful',
                val: data
              }) 
            })
            .catch(error=>{
              commit('updateLoading', false)
              commit('updateProcesses', { 
                value: 'deleting/image/'+action 
              })
              commit('updateResponses', {
                res: 'deleting/image/'+action+'/error',
                val: error
              }) 
              commit("ERROR",{
                page:NAME,
                from:"fi_deleteAllImages",
                error:error
              })
            })
            
          }else{ 
            commit('updateLoading', false) 
            commit('updateProcesses', { 
              name: 'deleting/image/'+action 
            })
            commit('updateResponses', {
              res: 'deleting/image/'+action+'/error',
              val: 'no user'
            })
            commit("ERROR",{
              page:NAME,
              from:"fi_deleteAllImages",
              error:'no user'
            })
          } 
        } catch (error) {
          commit("ERROR",{
            error:error,
            from:'fi_deleteAllImages',
            page:NAME, 
          })
        } 
      }, 

      //-----------------[ SAVE ]--------------\ 
      join_items({commit,dispatch},payload){
        return new Promise((resolve,reject)=>{
          let response = {
            from:'join_items',
            page:NAME, 
            payload:payload
          }
          try {
            let name = payload.name
            let item = payload.item
            let items = payload.items  
            if (!name) {
              commit("ERROR",{
                ...response,
                error:{
                  error:'no name',
                  data:payload
                },
               
              })
              response.error = "no name or data"
              reject(response)
              return
            }   
 
            //join 
            // let joined = JOIN({
            //   name:name,
            //   items:items,
            //   item:item,
            // }) 
            // commit("setItems",{
            //   name:"JOINED_"+name.toUpperCase(),
            //   data:joined
            // }) 
            resolve({success:true}) 
          } catch (error) {
            response.error = error
            commit("ERROR",{
              ...response,
              error:error,
            })
            reject.apply(response)
          }
        })
      }, 
      f_save_items({commit,dispatch,state},payload){
        return new Promise(async (resolve,reject)=>{
          let response = {
            from:'f_save_items',
            page:NAME, 
            payload:payload
          }
          try {
            let name = payload.name
            let action = payload.action
            let data = payload.data 
            let fi_data = payload.fi_data 
            let cash = payload.cash 
            let db = payload.db 
            let company_key = payload.company_key 
            let child_keys = payload.child_keys 
            let is_array_data = payload.is_array_data 
            let isChild = child_keys?true:false
            let isCompany = company_key?true:false
            if (!name) {
              response.error = "no name"
              commit("ERROR",response)
              reject(response)
              return
            } 
            if (!action) {
              action = name
            } 
            commit('updateProcesses', {name: 'getting/'+action}) 
  
            if (name.toUpperCase()===DATA.ITEMS.ACTION_HOLDERS.values.toUpperCase()) { 
              let holderObject = data
              let ACTIONS = []
              let HOLDERS = [] 
              for (const key in holderObject) {
                  let i = 1
                  let actions = holderObject[key]
                  HOLDERS.push({
                      i:i,
                      key:key,
                      actions:actions
                  })
                  i++  
                  for (const key2 in actions) {
                      let i2 = 1
                      let item = actions[key2]
                      ACTIONS.push({
                          i2:i2,
                          key:key2,
                          holder_key:key,
                          ...item
                      })
                      i2++ 
                  } 
              } 
              state.ACTION_HOLDERS = ACTIONS   
              return
            }else if (name.toUpperCase()===DATA.ITEMS.COMPANY_SETTINGS.values.toUpperCase()) { 
              let settings = db||isChild? data:data
              if (cash) { 
                if (!settings) {
                  await DB.deleteItems({
                    name:name, 
                    key:company_key,
                  })
                } else {
                  await DB.addItems({
                    name:name,
                    data:settings,
                    company_key:company_key,
                    clear:isCompany||isChild?false:true,
                  })
                   
                }
              }
              commit("setItems",{
                name:name.toUpperCase(),
                data:settings?settings:null
              })
              return
            } else {  

              let items = []
              let all_items = []
              let archived_items = []
              let i = 1

              if (name.toUpperCase()!=DATA.ITEMS.DAYS_SHIFTS_SALES.values.toUpperCase() && (db || isChild || is_array_data) ) {   
                if (typeof data == "object") {
                  for (const item of data) { 
                    all_items.push(item)
                    if (item.archive) {
                      archived_items.push(item)
                    }else{
                      items.push(item)
                    }   
                  }   
                }

              }else{ 
                // -------------[CHANGE OBJECT TO ARRAY]------------
                let obj = data   
                if (TEXT_UP(name) == TEXT_UP(DATA.ITEMS.EXPIRIES.values)) {
                  for (const key in obj) {
                    let item_expiries = obj[key]
                    if (item_expiries) {
                      for (const expiry_key in item_expiries) {
                        let item = {
                          i:i,
                          key:key,
                          ...item_expiries[expiry_key]
                        } 
                        all_items.push(item)
                        if (item.archive) {
                          archived_items.push(item)
                        }else{
                          items.push(item)
                        }  
                        i++
                      }
                    } 
                  }
                }else{
                  for (const key in obj) {
                    let item = obj[key] 
                    let item_type = typeof item  
                    if (item_type == "number") {
                      item = {
                        i:i,
                        key:key,
                        number : item
                      } 
                    }else if (TEXT_UP(name) == TEXT_UP(DATA.ITEMS.DAYS_SHIFTS_SALES.values)) {
                      // let saleObj = item 
                      // item =  item
                    }else if (TEXT_UP(name) == TEXT_UP(DATA.ITEMS.ITEM_ORDERS.values)) { 
                      console.log(item,'');
                      item =  item
                    } else {
                      item = {
                        i:i,
                        key:key,
                        ...item
                      } 
                    }
                    all_items.push(item)
                    if (item.archive) {
                      archived_items.push(item)
                    }else{
                      items.push(item)
                    }  
                    i++
                  }    
                }
              }
              
              if (cash) { 
                if (name.toUpperCase()===DATA.ITEMS.DAYS_SHIFTS_SALES.values.toUpperCase()){ 
                  await DB.addItems({
                    name:name,
                    // data:data,
                    data:all_items,
                    company_key:company_key,
                    clear:isCompany||isChild?false:true,
                  })
                   
                }else{
                  await DB.addItems({
                    name:name,
                    data:all_items,
                    company_key:company_key,
                    clear:isCompany||isChild?false:true,
                  })
                   
                }
              }

              
              if (name.toUpperCase()===DATA.ITEMS.ERRORS.values.toUpperCase()) { 
                state.ERRORS = all_items     
              }else if (name.toUpperCase()===DATA.ITEMS.DAYS_SHIFTS_SALES.values.toUpperCase()) { 
                commit("setItems",{
                  name:name.toUpperCase(),
                  data:items
                })
                commit("setItems",{
                  name:"ARCHIVED_"+name.toUpperCase(),
                  data:archived_items
                })
                commit("setItems",{
                  name:"ALL_"+name.toUpperCase(),
                  data:all_items
                })

                if (all_items) {
                  let order_name = DATA.ITEMS.SALE_ORDERS.values
                  let sale_orders = []
                  let all_sale_orders = []
                  let archive_sale_orders = []
                  for (const saleOrders of all_items) {
                    if (saleOrders) { 
                      for (const k in saleOrders) {
                        if (k == "key") {
                          console.log(saleOrders,k,'----------');
                        }
                        let order = {
                          key:k,
                          ...saleOrders[k]
                        }
                        all_sale_orders.push(order)
                        if (order.archive) {
                          archive_sale_orders.push(order)
                        } else {
                          sale_orders.push(order)
                        }
                      }
                    }
                  } 
                  commit("setItems",{
                    name:order_name.toUpperCase(),
                    data:sale_orders
                  })
                  commit("setItems",{
                    name:"ARCHIVED_"+order_name.toUpperCase(),
                    data:archive_sale_orders
                  })
                  commit("setItems",{
                    name:"ALL_"+order_name.toUpperCase(),
                    data:all_sale_orders
                  })
                } 
              }else if (name.toUpperCase()===DATA.ITEMS.ITEM_ORDERS.values.toUpperCase()) { 
                commit("setItems",{
                  name:name.toUpperCase(),
                  data:items
                })
                commit("setItems",{
                  name:"ARCHIVED_"+name.toUpperCase(),
                  data:archived_items
                })
                commit("setItems",{
                  name:"ALL_"+name.toUpperCase(),
                  data:all_items
                })

                if (all_items) {
                  let order_name = DATA.ITEMS.ITEM_ORDERS.values
                  let sale_orders = []
                  let all_sale_orders = []
                  let archive_sale_orders = []
                  for (const saleOrders of all_items) {
                    if (saleOrders) { 
                      for (const k in saleOrders) {
                        if (k == "key") {
                          console.log(saleOrders,k,'----------');
                        }
                        let order = {
                          key:k,
                          ...saleOrders[k]
                        }
                        all_sale_orders.push(order)
                        if (order.archive) {
                          archive_sale_orders.push(order)
                        } else {
                          sale_orders.push(order)
                        }
                      }
                    }
                  } 
                  commit("setItems",{
                    name:order_name.toUpperCase(),
                    data:sale_orders
                  })
                  commit("setItems",{
                    name:"ARCHIVED_"+order_name.toUpperCase(),
                    data:archive_sale_orders
                  })
                  commit("setItems",{
                    name:"ALL_"+order_name.toUpperCase(),
                    data:all_sale_orders
                  })
                } 
              }else if (name.toUpperCase()===DATA.ITEMS.ITEM_ORDERS.values.toUpperCase()) { 
                commit("setItems",{
                  name:name.toUpperCase(),
                  data:items
                })
                commit("setItems",{
                  name:"ARCHIVED_"+name.toUpperCase(),
                  data:archived_items
                })
                commit("setItems",{
                  name:"ALL_"+name.toUpperCase(),
                  data:all_items
                })

                if (all_items) {
                  let order_name = DATA.ITEMS.ITEM_ORDERS.values
                  let sale_orders = []
                  let all_sale_orders = []
                  let archive_sale_orders = []
                  for (const saleOrders of all_items) {
                    if (saleOrders) { 
                      for (const k in saleOrders) {
                        if (k == "key") {
                          console.log(saleOrders,k,'----------');
                        }
                        let order = {
                          key:k,
                          ...saleOrders[k]
                        }
                        all_sale_orders.push(order)
                        if (order.archive) {
                          archive_sale_orders.push(order)
                        } else {
                          sale_orders.push(order)
                        }
                      }
                    }
                  } 
                  commit("setItems",{
                    name:order_name.toUpperCase(),
                    data:sale_orders
                  })
                  commit("setItems",{
                    name:"ARCHIVED_"+order_name.toUpperCase(),
                    data:archive_sale_orders
                  })
                  commit("setItems",{
                    name:"ALL_"+order_name.toUpperCase(),
                    data:all_sale_orders
                  })
                } 
              }else if (name.toUpperCase()===DATA.ITEMS.COMPANY_USER_GROUPS.values.toUpperCase()) { 
                const app_state = app_store.state
                const defaults = app_state.DEFAULT_COMPANY_USER_GROUPS
                let data = [...defaults]
                if(items){
                  data = [
                    ...defaults,
                    ...items,
                  ]
                } 
                let all_data = [...defaults]
                
                if(all_items){
                  all_data = [
                    ...defaults,
                    ...all_items,
                  ]
                }


                commit("setItems",{
                  name:name.toUpperCase(),
                  data:data
                })
                commit("setItems",{
                  name:"ARCHIVED_"+name.toUpperCase(),
                  data:archived_items
                })
                commit("setItems",{
                  name:"ALL_"+name.toUpperCase(),
                  data:all_data
                }) 
              }else if (name) {
                if (false) { 
                  commit("updateItemsByKey",{
                    name:name.toUpperCase(),
                    data:items
                  })
                  commit("updateItemsByKey",{
                    name:"ARCHIVED_"+name.toUpperCase(),
                    data:archived_items
                  })
                  commit("updateItemsByKey",{
                    name:"ALL_"+name.toUpperCase(),
                    data:all_items
                  })  
                } else {
                  commit("setItems",{
                    name:name.toUpperCase(),
                    data:items
                  })
                  commit("setItems",{
                    name:"ARCHIVED_"+name.toUpperCase(),
                    data:archived_items
                  })
                  commit("setItems",{
                    name:"ALL_"+name.toUpperCase(),
                    data:all_items
                  })
                }
              }else{
                response.error = "name not found"
                commit("ERROR",response)
                reject(response)
              }  
              resolve({success:true})
            } 
          } catch (error) {
            response.error = error 
            commit("ERROR",response)
            reject.apply(response)
          }
        })
      },
      
      item_data_saver({commit,dispatch,state},payload){
        return new Promise(async (resolve,reject)=>{
          let response = {
            from:'item_data_saver',
            page:NAME, 
            payload:payload
          }
          try {
            let name = payload.name
            let action = payload.action
            let data = payload.data 
            let fi_data = payload.fi_data 
            let cash = payload.cash 
            let db = payload.db 
            let local_data = payload.local_data 
            let company_key = payload.company_key 
            let child_keys = payload.child_keys 
            let grouped_key = payload.grouped_key 
            let is_not_array = payload.is_not_array 
            let is_array_data = payload.is_array_data 

            let isChild = child_keys?true:false
            let isCompany = company_key?true:false

            if (!name) {
              response.error = "no name"
              commit("ERROR",response)
              reject(response)
              return
            } 
            if (!action) {
              action = name
            }  
            commit('updateProcesses', {name: 'getting/'+action})   

            if (is_not_array) {
              let order_name=TEXT_UP(name)
              if (order_name == TEXT_UP(DATA.ITEMS.SELECTED_COMPANIES.value)) {
                if (data) {
                  data.key = data.company_key 
                }
              } 
              commit("setItems",{
                name:order_name,
                data:data
              }) 

              //
              if (cash && !local_data) { 
                try { 
                  await DB.addItems({
                    name:name, 
                    data:data,
                    company_key:company_key,
                    clear:isCompany||isChild?false:true,
                  })   
                } catch (error) {
                  response.error = error
                  commit("ERROR",response) 
                }  
              }
            }else if (is_array_data) {
              let order_name=TEXT_UP(name) 
              let archive_data = data?.filter(item=>item.archive)
              let unarchive_data = data?.filter(item=>item.archive)
              commit("setItems",{
                name:order_name,
                data:unarchive_data
              }) 
              commit("setItems",{
                name:"ARCHIVED_"+order_name,
                data:archive_data
              })
              commit("setItems",{
                name:"ALL_"+order_name,
                data:data
              }) 

              //
              if (cash && !local_data) { 
                try { 
                  await DB.addItems({
                    name:name, 
                    data:data,
                    company_key:company_key,
                    clear:isCompany||isChild?false:true,
                  })   
                } catch (error) {
                  response.error = error
                  commit("ERROR",response) 
                }  
              }
            }else if (name.toUpperCase()===DATA.ITEMS.ACTION_HOLDERS.values.toUpperCase()) {
              let holderObject = data
              let ACTIONS = []
              let HOLDERS = [] 
              for (const key in holderObject) {
                  let i = 1
                  let actions = holderObject[key]
                  HOLDERS.push({
                      i:i,
                      key:key,
                      actions:actions
                  })
                  i++  
                  for (const key2 in actions) {
                      let i2 = 1
                      let item = actions[key2]
                      ACTIONS.push({
                          i2:i2,
                          key:key2,
                          holder_key:key,
                          ...item
                      })
                      i2++ 
                  } 
              } 
              state.ACTION_HOLDERS = ACTIONS   
              return
            }else {  

              let items = []
              let all_items = []
              let archived_items = []
              let i = 1
              let add_item = (item)=>{ 
                all_items.push(item)
                if (item.archive) {
                  archived_items.push(item)
                }else{
                  items.push(item)
                }  
                i++
              }

              if (name.toUpperCase()!=DATA.ITEMS.DAYS_SHIFTS_SALES.values.toUpperCase()&& (db || isChild) ){   
                // -------------[It is ARRAY]-----------
                if (typeof data == "object") {
                  for (const item of data) { 
                    all_items.push(item)
                    if (item.archive) {
                      archived_items.push(item)
                    }else{
                      items.push(item)
                    }   
                  }   
                } 
              }else if (grouped_key && local_data){   
                // -------------[It is ARRAY]-----------
                if (typeof data == "object") {
                  for (const item of data) { 
                    all_items.push(item)
                    if (item.archive) {
                      archived_items.push(item)
                    }else{
                      items.push(item)
                    }   
                  }   
                } 
              }else{ 
                // -------------[CHANGE OBJECT TO ARRAY]------------
                const obj = data    
                for (const key in obj) {
                  let item = obj[key] 
                  let item_type = typeof item  

                  if (item_type == "number") {
                    item = {
                      i:i,
                      key:key,
                      number : item
                    } 
                  }
                  // else if (TEXT_UP(name) == TEXT_UP(DATA.ITEMS.ITEM_ORDERS.values)) { 
                  //   item =  item
                    
                  // }
                  else if (grouped_key) {
                    const group_obj = item 
                    if (typeof group_obj == "object") {
                      for (const group_key in group_obj) {
                        let grouped_item = group_obj[group_key]
                        let grouped_item_type = typeof grouped_item   
                        if (grouped_item_type == "number") { 
                          let update_item = {
                            i:i,
                            key:group_key,
                            number : item, 
                          }
                          if (grouped_key.parent_key_value) {
                            update_item['_'+grouped_key.parent_key_value]=key
                          }
                          add_item(update_item) 
                        }else {
                          let update_item = {
                            i:i,
                            key:group_key,
                            ...grouped_item
                          }
                          if (grouped_key.parent_key_value) {
                            update_item['_'+grouped_key.parent_key_value]=key
                          }
                          add_item(update_item) 
                        }
                      }
                    } else {
                      add_item({
                        i:i,
                        key:key,
                        ...item
                      }) 
                    }
                  } else {
                    add_item({
                      i:i,
                      key:key,
                      ...item
                    }) 
                  }
                }    
              } 
 
              //===================SAVE DATA===================
              let store_data = (all_items,items,archived_items)=>{
                let order_name=TEXT_UP(name)
                if (TEXT_UP(name) == TEXT_UP(DATA.ITEMS.DAYS_SHIFTS_SALES.values)) {
                  order_name = TEXT_UP(DATA.ITEMS.SALE_ORDERS.values) 
                }
                commit("setItems",{
                  name:order_name,
                  data:items
                })
                commit("setItems",{
                  name:"ARCHIVED_"+order_name,
                  data:archived_items
                })
                commit("setItems",{
                  name:"ALL_"+order_name,
                  data:all_items
                }) 
              }
              if (cash && !local_data) { 
                try {
                  let res = await DB.addItems({
                    name:name, 
                    data:all_items,
                    company_key:company_key,
                    clear:isCompany||isChild?false:true,
                  })  
                } catch (error) {
                  response.error = error
                  commit("ERROR",response) 
                } 
                store_data(all_items,items,archived_items)
              }else{
                store_data(all_items,items,archived_items)
              } 
              resolve({success:true})
            }  
          } catch (error) {
            response.error = error
            commit("ERROR",response) 
            reject.apply(response)
          }
        })
      },  
      add_items({ commit,dispatch,state},payload) {
        let response = {
          from:'add_items',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try { 
            let name = payload?payload.name:null  
            let data = payload?payload.data:null  
            
            if (!name) { 
              response = {
                error:"payload required"
              } 
              commit("ERROR",response)
              reject(response)
              return  
            }  

            commit("setItems",{
              name:name.toUpperCase(),
              data:data
            }) 
            resolve({
              success:true, 
            })
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      },
      
      //-----------------[ FI GET ]--------------\   
      fi_get_item({ commit,dispatch,state},payload) { 
        return new Promise(async(resolve,reject)=>{
          let response = {
            from:'fi_get_item',
            page:NAME, 
            payload:payload
          }
          try { 
            const name = payload?payload.name:null
            let action = payload?payload.action:null
            const on = payload?payload.on:null 
            const cash = payload?payload.cash:null 
            const get = payload?payload.get:null 
            const filters = payload?payload.filters:null 
            const limit = filters?.limit
            const first = filters?.first
            const last = filters?.last
            const order_by = filters?.order_by
            const equal_to = filters?.equal_to
            const company_key = payload?payload.company_key:null 
            let child_keys = payload?payload.child_keys:null
            let local_child_keys = payload?payload.local_child_keys:null
            let grouped_key = payload?payload.grouped_key:null
            let data_saver = payload?payload.data_saver:null
            let is_not_array = false
            let is_array_data = false
            let ITEM_REF = null 
            
            if (name) {
              setTimeout(async () => {
                if (!action) {action=name} 
                commit('updateProcesses', {
                  add: true,
                  name: 'getting/'+name
                })  
                commit('advanceProcess', {
                  name: name,
                  action: child_keys?'getting_keys':'getting', 
                  loading: true,
                })  
  
                //CHECK DOWNLOAD REQUEST
                let dr = state.DOWNLOAD_REQUESTS.find(item=>item.name==name)
                if (dr) { 
                  let dr_on = dr?dr.on:null
                  let dr_company_key = dr?dr.company_key:null
                  let dr_ref = dr?dr.ref:null
                  let start_size = dr?dr.start_size:null
                  let run_size = dr?dr.run_size:null
                  let download_size = dr?dr.download_size:null 
                  // console.log(dr,name+'-------dr--------//-**'+download_size);
                  // if (run_size) {
                  //   if (dr_ref) {
                  //     await dr_ref.off()
                  //   }
                  //   if (dr_company_key) {
                  //     let dr_res = await dispatch("initialize_company_data_ref",{
                  //       name:name, 
                  //       company_key:company_key?company_key:dr_company_key, 
                  //     })  
                  //     console.log(dr_res,'dr_res---------------**');
                  //   } else {
                  //     let dr_res = await dispatch("initialize_items_ref",{
                  //       name:name,  
                  //     })  
                  //     console.log(dr_res,'dr_res---------------**');
                  //   }
                  // }
                }
   
                if (name.toUpperCase()===DATA.ITEMS.SELECTED_COMPANIES.value.toUpperCase()) {
                  ITEM_REF = REF_SELECTED_COMPANY 
                  data_saver = true
                  is_not_array = true
                }else if (name.toUpperCase()===DATA.ITEMS.BILLING_PLANS.values.toUpperCase()) {
                  ITEM_REF = REF_BILLING_PLANS  
                  data_saver = true
                }else if (name.toUpperCase()===DATA.ITEMS.BILLING_PLAN_FEATURES.values.toUpperCase()) {
                  ITEM_REF = REF_BILLING_PLAN_FEATURES  
                  data_saver = true
                }else if (name.toUpperCase()===DATA.ITEMS.CURRENT_BILLING_PLANS.values.toUpperCase()) {
                  ITEM_REF = REF_CURRENT_BILLING_PLANS  
                  data_saver = true
                  is_not_array = true
                }else if (name.toUpperCase()===DATA.ITEMS.SUBSCRIPTIONS.values.toUpperCase()) {
                  ITEM_REF = REF_SUBSCRIPTIONS  
                  data_saver = true
                }
                
                else if (name.toUpperCase()===DATA.ITEMS.ERRORS.values.toUpperCase()) {
                  ITEM_REF = REF_ERRORS  
                }else if (name.toUpperCase()===DATA.ITEMS.MESSAGES.values.toUpperCase()) {
                  ITEM_REF = REF_MESSAGES   
                }else if (name.toUpperCase()===DATA.ITEMS.COUNTRIES.values.toUpperCase()) { 
                  ITEM_REF = REF_COUNTRIES    
                }else if (name.toUpperCase()===DATA.ITEMS.REGIONS.values.toUpperCase()) {
                  ITEM_REF = REF_REGIONS    
                }else if (name.toUpperCase()===DATA.ITEMS.DISTRICTS.values.toUpperCase()) {
                  ITEM_REF = REF_DISTRICTS    
                }
                
                else if (name.toUpperCase()===DATA.ITEMS.ATTENDANCES.values.toUpperCase()) {
                  grouped_key=grouped_key?grouped_key:{
                    parent_key_value:'day_shift_key'
                  }
                  data_saver = true
                  ITEM_REF = REF_ATTENDANCES    
                }else if (name.toUpperCase()===DATA.ITEMS.DESIGNATIONS.values.toUpperCase()) {
                  ITEM_REF = REF_DESIGNATIONS    
                }
                
                else if (name.toUpperCase()===DATA.ITEMS.ID_TYPES.values.toUpperCase()) { 
                  ITEM_REF = REF_ID_TYPES
                }else if (name.toUpperCase()===DATA.ITEMS.DELIVERING_STEPS.values.toUpperCase()) {
                  ITEM_REF = REF_DELIVERING_STEPS
                }else if (name.toUpperCase()===DATA.ITEMS.ACTION_HOLDERS.values.toUpperCase()) {
                  ITEM_REF = REF_ACTION_HOLDERS
                }else if (name.toUpperCase()===DATA.ITEMS.ORDERS.values.toUpperCase()) { 
                  ITEM_REF = REF_BANK_ORDERS
                }else if (name.toUpperCase()===DATA.ITEMS.CANCELLATION_FEES.values.toUpperCase()) {
                  ITEM_REF = REF_CANCELLATION_FEES
                }
                
                else if (name.toUpperCase()===DATA.ITEMS.MEASUREMENT_UNITS.values.toUpperCase()) {
                  ITEM_REF = REF_MEASUREMENT_UNITS
                }else if (name.toUpperCase()===DATA.ITEMS.COMPANIES.values.toUpperCase()) {
                  ITEM_REF = REF_COMPANIES
                }else if (name.toUpperCase()===DATA.ITEMS.COMPANY_TYPES.values.toUpperCase()) {
                  ITEM_REF = REF_COMPANY_TYPES
                }else if (name.toUpperCase()===DATA.ITEMS.COMPANY_SIZES.values.toUpperCase()) {
                  ITEM_REF = REF_COMPANY_SIZES
                }else if (name.toUpperCase()===DATA.ITEMS.COMPANY_USERS.values.toUpperCase()) {
                  ITEM_REF = REF_COMPANY_USERS
                }else if (name.toUpperCase()===DATA.ITEMS.COMPANY_USER_GROUPS.values.toUpperCase()) {
                  ITEM_REF = REF_COMPANY_USER_GROUPS
                }
                
                else if (name.toUpperCase()===DATA.ITEMS.COMPANY_QUICK_ACTIONS.values.toUpperCase()) {
                  ITEM_REF = REF_COMPANY_QUICK_ACTIONS
                }else if (name.toUpperCase()===DATA.ITEMS.DEVICES.values.toUpperCase()) {
                  ITEM_REF = REF_DEVICES
                }else if (name.toUpperCase()===DATA.ITEMS.CARDS.values.toUpperCase()) {
                  ITEM_REF = REF_CARDS
                }else if (name.toUpperCase()===DATA.ITEMS.TILLS.values.toUpperCase()) {
                  ITEM_REF = REF_TILLS
                }else if (name.toUpperCase()===DATA.ITEMS.PRICING_TIERS.values.toUpperCase()) {
                  ITEM_REF = REF_PRICING_TIERS
                }else if (name.toUpperCase()===DATA.ITEMS.DAYS_SHIFTS.values.toUpperCase()) {
                  ITEM_REF = REF_DAYS_SHIFTS
                }else if (name.toUpperCase()===DATA.ITEMS.DAYS_SHIFTS_SALES.values.toUpperCase()) {
                  grouped_key=grouped_key?grouped_key:{
                    parent_key_value:'day_shift_key'
                  }
                  data_saver = true
                  ITEM_REF = REF_DAYS_SHIFTS_SALES
                }else if (name.toUpperCase()===DATA.ITEMS.CASHUPS.values.toUpperCase()) {
                  ITEM_REF = REF_CASHUPS
                }else if (name.toUpperCase()===DATA.ITEMS.STOCK_OUTLETS.values.toUpperCase()) {
                  ITEM_REF = REF_STOCK_OUTLETS
                }else if (name.toUpperCase()===DATA.ITEMS.STOCK_ORDERS.values.toUpperCase()) {
                  ITEM_REF = REF_STOCK_ORDERS
                }else if (name.toUpperCase()===DATA.ITEMS.SALE_ORDERS.values.toUpperCase()) {
                  ITEM_REF = REF_SALE_ORDERS
                }else if (name.toUpperCase()===DATA.ITEMS.QUOTATIONS_AND_INVOICES.values.toUpperCase()) {
                  ITEM_REF = REF_QUOTATIONS_AND_INVOICES
                }else if (name.toUpperCase()===DATA.ITEMS.QUOTATIONS.values.toUpperCase()) {
                  grouped_key=grouped_key?grouped_key:{
                    parent_key_value:'day_shift_key'
                  }
                  data_saver = true
                  ITEM_REF = REF_QUOTATIONS
                }else if (name.toUpperCase()===DATA.ITEMS.RETURNED_SALES.values.toUpperCase()) {
                  data_saver = true
                  ITEM_REF = REF_RETURNED_SALES
                }else if (name.toUpperCase()===DATA.ITEMS.BRANDS.values.toUpperCase()) {
                  ITEM_REF = REF_BRANDS
                }else if (name.toUpperCase()===DATA.ITEMS.MANUFACTURERS.values.toUpperCase()) {
                  ITEM_REF = REF_MANUFACTURERS
                }else if (name.toUpperCase()===DATA.ITEMS.SUPPLIERS.values.toUpperCase()) {
                  ITEM_REF = REF_SUPPLIERS
                }else if (name.toUpperCase()===DATA.ITEMS.SUPPLIER_CATEGORIES.values.toUpperCase()) {
                  ITEM_REF = REF_SUPPLIER_CATEGORIES
                }else if (name.toUpperCase()===DATA.ITEMS.CUSTOMERS.values.toUpperCase()) {
                  ITEM_REF = REF_CUSTOMERS
                }else if (name.toUpperCase()===DATA.ITEMS.CUSTOMER_GROUPS.values.toUpperCase()) {
                  ITEM_REF = REF_CUSTOMER_GROUPS
                }else if (name.toUpperCase()===DATA.ITEMS.ITEMS.values.toUpperCase()) {
                  ITEM_REF = REF_ITEMS
                }else if (name.toUpperCase()===DATA.ITEMS.ITEM_CATEGORIES.values.toUpperCase()) {
                  ITEM_REF = REF_ITEM_CATEGORIES
                }else if (name.toUpperCase()===DATA.ITEMS.ITEM_DEPARTMENTS.values.toUpperCase()) {
                  ITEM_REF = REF_ITEM_DEPARTMENTS
                }else if (name.toUpperCase()===DATA.ITEMS.ITEM_ORDERS.values.toUpperCase()) {
                  grouped_key=grouped_key?grouped_key:{
                    parent_key_value:'day_shift_key'
                  }
                  data_saver = true
                  ITEM_REF = REF_ITEM_ORDERS
                }else if (name.toUpperCase()===DATA.ITEMS.PATIENT_ORDERS.values.toUpperCase()) {
                  ITEM_REF = REF_PATIENT_ORDERS
                }else if (name.toUpperCase()===DATA.ITEMS.PATIENT_HISTORIES.values.toUpperCase()) {
                  ITEM_REF = REF_PATIENT_HISTORIES
                }else if (name.toUpperCase()===DATA.ITEMS.ITEM_ORDER_OPTIONS.values.toUpperCase()) {
                  ITEM_REF = REF_ITEM_ORDER_OPTIONS
                }else if (name.toUpperCase()===DATA.ITEMS.ITEM_KITS.values.toUpperCase()) {
                  ITEM_REF = REF_ITEM_KITS
                }
                
                else if (name.toUpperCase()===DATA.ITEMS.ASSETS.values.toUpperCase()) {
                  ITEM_REF = REF_ASSETS
                }else if (name.toUpperCase()===DATA.ITEMS.ASSET_TYPES.values.toUpperCase()) {
                  ITEM_REF = REF_ASSET_TYPES
                }else if (name.toUpperCase()===DATA.ITEMS.PAYABLES.values.toUpperCase()) {
                  ITEM_REF = REF_PAYABLES
                }else if (name.toUpperCase()===DATA.ITEMS.RECEIVABLES.values.toUpperCase()) {
                  ITEM_REF = REF_RECEIVABLES
                }else if (name.toUpperCase()===DATA.ITEMS.PAID_INVOICES.values.toUpperCase()) {
                  grouped_key=grouped_key?grouped_key:{
                    parent_key_value:'day_shift_key'
                  }
                  data_saver = true
                  ITEM_REF = REF_PAID_INVOICES
                }else if (name.toUpperCase()===DATA.ITEMS.UNPAID_INVOICES.values.toUpperCase()) {
                  ITEM_REF = REF_UNPAID_INVOICES
                }else if (name.toUpperCase()===DATA.ITEMS.CREDITORS.values.toUpperCase()) {
                  ITEM_REF = REF_CREDITORS
                }else if (name.toUpperCase()===DATA.ITEMS.DEBTORS.values.toUpperCase()) {
                  ITEM_REF = REF_DEBTORS
                }else if (name.toUpperCase()===DATA.ITEMS.PAYMENTS.values.toUpperCase()) {
                  ITEM_REF = REF_PAYMENTS
                }else if (name.toUpperCase()===DATA.ITEMS.EXPENSES.values.toUpperCase()) {
                  ITEM_REF = REF_EXPENSES
                }else if (name.toUpperCase()===DATA.ITEMS.EXPENSE_CATEGORIES.values.toUpperCase()) {
                  ITEM_REF = REF_EXPENSE_CATEGORIES
                }else if (name.toUpperCase()===DATA.ITEMS.EXPENSE_ACCOUNTS.values.toUpperCase()) {
                  ITEM_REF = REF_EXPENSE_ACCOUNTS
                }else if (name.toUpperCase()===DATA.ITEMS.TRACKING_UPDATES.values.toUpperCase()) { 
                  ITEM_REF = REF_TRACKING_UPDATES
                }else if (name.toUpperCase()===DATA.ITEMS.COMPANY_SETTINGS.values.toUpperCase()) { 
                  ITEM_REF = REF_COMPANY_SETTINGS
                }else if (name.toUpperCase()===DATA.ITEMS.EXPIRIES.values.toUpperCase()) { 
                  ITEM_REF = REF_EXPIRIES
                }else if (name.toUpperCase()===DATA.ITEMS.PAYMENT_METHODS.values.toUpperCase()) { 
                  ITEM_REF = REF_PAYMENT_METHODS
                }else if (name.toUpperCase()===DATA.ITEMS.MONEY_ACCOUNTS.values.toUpperCase()) { 
                  ITEM_REF = REF_MONEY_ACCOUNTS
                }else if (name.toUpperCase()===DATA.ITEMS.MONEY_ACCOUNT_TRANSACTIONS.values.toUpperCase()) { 
                  ITEM_REF = REF_MONEY_ACCOUNT_TRANSACTIONS
                }else if (name.toUpperCase()===DATA.ITEMS.STOCK_TAKINGS.values.toUpperCase()) { 
                  ITEM_REF = REF_STOCK_TAKINGS
                }else if (name.toUpperCase()===DATA.ITEMS.STOCK_TAKING_ORDERS.values.toUpperCase()) { 
                  ITEM_REF = REF_STOCK_TAKING_ORDERS
                }
  
                let ref_action = {
                  name:name, 
                  action:action,  
                  cash:cash, 
                  on:on, 
                  get:get, 
                  company_key:company_key, 
                  child_keys:child_keys, 
                  grouped_key:grouped_key,
                  ref:ITEM_REF
                }
  
                //LOCAL GET
                if (get) {
                  let res = await DB.getItems({
                    name:name,
                    company_key:company_key,
                    // update_store:true,
                  })
  
                  let data_details = {
                    ...ref_action,
                    data:res?res.data:null,
                    res:res, 
                    local_data:true, 
                    is_not_array:is_not_array,
                    is_array_data:is_array_data
                  }
                  if (data_saver) { 
                    dispatch('item_data_saver',data_details) 
                  } else {
                    dispatch('f_save_items',data_details) 
                  }
                } 
                //GET REF
                if (ITEM_REF) { 
                  let getSnap = async snapshot => {
                    let fi_data = snapshot
                    let data = snapshot.val()
                    let data_details = {
                      ...ref_action,
                      data:data,
                      fi_data:fi_data, 
                      is_not_array:is_not_array,
                      is_array_data:is_array_data,
                    }
                    if (name.toUpperCase()===DATA.ITEMS.COMPANY_USERS.values.toUpperCase()) { 
                      try {
                        let http_res = await dispatch('http_request',{
                          data:{
                            company_key:ref_action.company_key,
                            name:ref_action.name,
                          },
                          path:"getCompanyUsers"
                        }) 
                        let body = http_res?.body 
                        let users = body?.users
                        data_details.data = users
                        data_details.is_array_data = true 
                      } catch (error) {
                        console.log(error,'http_res error------------///****');
                      }
                    }

                    else if (name.toUpperCase()===DATA.ITEMS.ITEM_ORDERS.values.toUpperCase()) { 
                    }
                    
                    if (data_saver) { 
                      await dispatch('item_data_saver',data_details) 
                    } else {
                      await dispatch('f_save_items',data_details) 
                    }
                    commit("updateDownloadRequest",{
                      ...ref_action,
                      status:'downloaded', 
                    }) 
                  }
                  if (on) {  
                    commit("updateDownloadRequest",{
                      ...ref_action,
                      status:'started', 
                    }) 
                    if (child_keys && !local_child_keys ) { 
                      ITEM_REF.set(child_keys)
                      ITEM_REF.on("value", async snapshot=>{
                        let itemRefs = snapshot.val()
                        Promise.all(itemRefs.map((itemRefs) => firebase.database().ref(itemRefs).once("value")))
                        .then(itemSnapshots=>{
                          // Process data for all companies
                          itemSnapshots.forEach((snapshot) => {
                            const companyData = snapshot.val(); 
                          });
                        })
                      })
                    } else {
                    }
                    if (limit) {
                      // ITEM_REF.orderByChild(order_by).equalTo(equal_to).limitToLast(limit).on('value', getSnap) 
                      ITEM_REF.limitToLast(limit).on('value', getSnap) 
                    }else{
                      ITEM_REF.on('value', getSnap) 
                    } 
                    resolve({success:true})  
                  } else { 
                    if (child_keys) { 
                      let finLocalKey = key=>{
                        if(!local_child_keys){return null}
                        return local_child_keys.find(record=>record.key == key)
                      }
                      let update_records = []
                      let changed_keys = [] 
                      // find changed keys
                      for (const child_key of child_keys) { 
                        let local_key = finLocalKey(child_key.key)
                        let local_key_update = toNumber(local_key?local_key.updates:null)
                        let key_update = toNumber(child_key?child_key.updates:null)
                        
                        if (!local_key || (key_update!=local_key_update)) {
                          changed_keys.push(child_key.key)
                          //console.log(child_key.key,local_key_update,key_update,'update!........'); 
                        }else{
                          //console.log(child_key.key,local_key_update,key_update,'same!........');
                        } 
                      }
  
                      //get changed key
                      let changed_keys_size = changed_keys.length 
                      if (changed_keys_size<100) { 
                        for (const child_key of changed_keys) {  
                          let snapshot = await ITEM_REF.child(child_key).once('value')
                          let data = snapshot?snapshot.val():null
                          update_records.push({...data,key:child_key})  
                        } 
                      }else{ 
                        commit('updateProcesses', { 
                          name: 'getting/'+name
                        }) 
                        await dispatch('fi_get_item',{
                          company_key:company_key,
                          name:name,
                          cash:cash, 
                        }) 
                        resolve({success:true}) 
                        return
                      } 
  
                      //update changes
                      if (update_records.length>0) { 
                        let state_data = state[TEXT_UP(name)]
                        if (state_data) {
                          update_records = await dispatch('combine_data',{
                            data1:[...state_data],
                            data2:update_records
                          })   
                        }
                        if (update_records) { 
                          await dispatch('f_save_items',{...ref_action,data:update_records}) 
                        }
                      }else{  
                        commit('updateProcesses', { 
                          name: 'getting/'+name
                        }) 
                        await dispatch('fi_get_item',{
                          company_key:company_key,
                          name:name,
                          cash:cash, 
                        })
                        resolve({success:true}) 
                        return
                      } 
                      resolve({success:true,data:update_records,child_keys:child_keys}) 
                    }else{
                      ITEM_REF.once('value').then(async snapshot => {
                        let fi_data = snapshot
                        let data = snapshot.val()
                        let data_details = {
                          ...ref_action,
                          data:data,
                          fi_data:fi_data, 
                          is_not_array:is_not_array
                        }
                        if (data_saver) { 
                          await dispatch('item_data_saver',data_details) 
                        } else {
                          await dispatch('f_save_items',data_details) 
                        } 
                        commit("updateDownloadRequest",{
                          ...ref_action,
                          status:'downloaded', 
                        }) 
                        resolve({success:true,data:data,fi_data:fi_data}) 
                      }).catch(error=>{
                        response.error = error 
                        reject(response)
                      })
                    }
                  }  
                  commit("updateDownloadRequest",{
                    ...ref_action,
                    status:'running', 
                  }) 
                }else{
                  response.error = "no ref"
                  commit("ERROR",response)
                  reject(response)
                }  
                
              }, 10);
            } else {
              response.error = "no name"
              commit("ERROR",response)
              reject(response)
            }
          } catch (error) {
            response.error = error
            commit("ERROR",response)
            reject(response)
          } 
        })
      },
      fi_get_day_sales({ commit,dispatch,state},payload) { 
        return new Promise(async(resolve,reject)=>{
          let response = {
            from:'fi_get_day_sales',
            page:NAME, 
            payload:payload
          }
          try { 
            let name = DATA.ITEMS.DAY_SALES.values
            let path = payload?payload.path:null
            let get = payload?payload.get:null

             
            if (path) { 
              if (get) {  
                let res = await DB.getItems({
                  name:DATA.ITEMS.DAYS_SHIFTS_SALES.values, 
                  // update_store:true,
                })
                let data  = res?res.data:null
                commit("setItems",{
                  name:name.toUpperCase(),
                  data:data
                })  
                commit("setItems",{
                  name:"ALL_"+name.toUpperCase(),
                  data:data
                })
              } 
              if (REF_DAY_SALES) {
                REF_DAY_SALES.off
              }  
              REF_DAY_SALES = firebase.database().ref(path)  
              REF_DAY_SALES.on('value', async snapshot => {
                let fi_data = snapshot
                let obj = snapshot.val() 
                let data = []
                let i = 0
                for (const key in obj) {
                  let item = obj[key]   
                  data.push(item)
                  i++ 
                }  
                commit("setItems",{
                  name:name.toUpperCase(),
                  data:data
                })
                commit("setItems",{
                  name:"ALL_"+name.toUpperCase(),
                  data:data
                })  
                DB.addItems({
                  name:DATA.ITEMS.DAYS_SHIFTS_SALES.values, 
                  data:data
                  // update_store:true,
                })

                let data_details = {
                  ...REF_DAY_SALES,
                  data:data,
                  fi_data:fi_data, 
                }
                resolve({success:true,...data_details})  
              })    
               
            } else {
              response.error = "no PATH" 
              reject(response)
            }
          } catch (error) {
            response.error = error 
            reject(response)
          } 
        })
      }, 
      fi_get_day_orders({ commit,dispatch,state},payload) { 
        return new Promise(async(resolve,reject)=>{
          let response = {
            from:'fi_get_day_orders',
            page:NAME, 
            payload:payload
          }
          try { 
            let name = DATA.ITEMS.DAY_ORDERS.values
            let path = payload?payload.path:null
            let get = payload?payload.get:null

             
            if (path) { 
              if (get) {  
                let res = await DB.getItems({
                  name:DATA.ITEMS.DAYS_SHIFTS_ORDERS.values, 
                  // update_store:true,
                })
                let data  = res?res.data:null
                commit("setItems",{
                  name:name.toUpperCase(),
                  data:data
                })  
                commit("setItems",{
                  name:"ALL_"+name.toUpperCase(),
                  data:data
                })
              } 
              if (REF_ITEM_ORDER_OPTIONS) {
                REF_ITEM_ORDER_OPTIONS.off
              }  
              REF_ITEM_ORDER_OPTIONS = firebase.database().ref(path)  
              REF_ITEM_ORDER_OPTIONS.on('value', async snapshot => {
                let fi_data = snapshot
                let obj = snapshot.val() 
                let data = []
                let i = 0
                for (const key in obj) {
                  let item = obj[key]   
                  data.push(item)
                  i++ 
                }  
                commit("setItems",{
                  name:name.toUpperCase(),
                  data:data
                })
                commit("setItems",{
                  name:"ALL_"+name.toUpperCase(),
                  data:data
                })  
                DB.addItems({
                  name:DATA.ITEMS.DAYS_SHIFTS_ORDERS.values, 
                  data:data
                  // update_store:true,
                })

                let data_details = {
                  ...REF_DAY_ORDERS,
                  data:data,
                  fi_data:fi_data, 
                }
                resolve({success:true,...data_details})  
              })    
               
            } else {
              response.error = "no PATH" 
              reject(response)
            }
          } catch (error) {
            response.error = error 
            reject(response)
          } 
        })
      }, 
      fi_get_day_sale_item_orders({ commit,dispatch,state},payload) { 
        return new Promise(async(resolve,reject)=>{
          let response = {
            from:'fi_get_day_sale_item_orders',
            page:NAME, 
            payload:payload
          }
          try { 
            let name = DATA.ITEMS.ITEM_ORDERS.values
            let path = payload?payload.path:null
            let get = payload?payload.get:null
            let on = payload?payload.on:null

             
            if (path) { 
              if (get) {  
                let res = await DB.getItems({
                  name:DATA.ITEMS.ITEM_ORDERS.values, 
                  // update_store:true,
                })
                let data  = res?res.data:null
                commit("setItems",{
                  name:name.toUpperCase(),
                  data:data
                })  
                commit("setItems",{
                  name:"ALL_"+name.toUpperCase(),
                  data:data
                })
              } 
              if (REF_ITEM_ORDERS) {
                REF_ITEM_ORDERS.off
              }  
              REF_ITEM_ORDERS = firebase.database().ref(path)  
              

              const save_data = snapshot=>{
                let fi_data = snapshot
                  let obj = snapshot.val() 
                  let data = []
                  let i = 0
                  for (const key in obj) {
                    let item = obj[key]   
                    data.push(item)
                    i++ 
                  }  
                  commit("setItems",{
                    name:name.toUpperCase(),
                    data:data
                  })
                  commit("setItems",{
                    name:"ALL_"+name.toUpperCase(),
                    data:data
                  })  
  
                  console.log(data,'fi_get_day_sale_item_orders+++++++++++');
                  DB.addItems({
                    name:DATA.ITEMS.ITEM_ORDERS.values, 
                    data:data,
                    clear:true
                    // update_store:true,
                  })
  
                  let data_details = {
                    ...REF_ITEM_ORDERS,
                    data:data,
                    fi_data:fi_data, 
                  }
                  resolve({success:true,...data_details})  
              }
              if (on) {
                REF_ITEM_ORDERS.on('value', async snapshot => {
                  save_data(snapshot)
                })     
              } else {
                REF_ITEM_ORDERS.once('value').then(async snapshot => {
                  save_data(snapshot)
                }).catch(error=>{
                  response.error = error 
                  reject(response)
                })
              }
               
            } else {
              response.error = "no PATH" 
              reject(response)
            }
          } catch (error) {
            response.error = error 
            reject(response)
          } 
        })
      }, 
      fi_get_my_company({ commit,dispatch,state},payload) { 
        return new Promise(async(resolve,reject)=>{
          let response = {
            from:'fi_get_day_sales',
            page:NAME, 
            payload:payload
          }
          try { 
            let database = firebase.database();
            let userCompanyKeysPath = "/MPOS/COMPANIES/"; // Path to user's company keys (adjust as needed)
            let childKeys = ['/MPOS/COMPANIES/testing-bar', '/MPOS/COMPANIES/butcher-shop', '/MPOS/COMPANIES/mangichi']; // Example company keys (modify accordingly)

            database.ref(userCompanyKeysPath).once('value') // Use `once()` for initial retrieval
            .then((snapshot) => {
                const companyKeys = snapshot.val() || []; // Handle potential null value, initialize with empty array if necessary

                console.error(companyKeys,'companyKeys+++++++.');
                if (companyKeys.length === 0) {
                    console.error('No company keys found for the user.');
                    return; // Terminate if no companies found
                }

                // Filter or select keys based on user's requirements:
                // const filteredKeys = companyKeys.filter(/* your filtering logic */);

                Promise.all(companyKeys.map((companyKey) => database.ref(companyKey).once('value')))
                .then((companySnapshots) => {
                  companySnapshots.forEach((snapshot) => {
                      const companyData = snapshot.val(); 
                      // Process company data as needed
                  });
                })
                .catch((error) => {
                    console.error('Error fetching company data:', error);
                });
            })
            .catch((error) => {
                console.error('Error reading user company keys:', error);
            });
             
             
          } catch (error) {
            response.error = error 
            reject(response)
          } 
        })
      }, 
      fi_check_key({ commit,dispatch},payload) {
        return new Promise(async(resolve,reject)=>{
          let response = {
            from:'fi_check_key',
            page:NAME, 
          }
          try { 
            let key = payload?payload.key:null
            let path = payload?payload.path:null 
            if (path) { 
              if (key) {
                if (path.slice(path.length - 1)=="/") {
                  path = path+key
                } else {
                  path = path+"/"+key
                }
              }
              commit('updateLoading', true)
              commit('updateProcesses', {
                add: true,
                name: 'checking/key'
              })  
              let PATH_REF = firebase.database().ref(path)
              await PATH_REF.once('value', async(snapshot) => {
                let data = await snapshot.val()
                response = {
                  success:true,
                  data:data,
                  path:path,
                  available:data?false:true
                }
              })   
              //not
              commit('updateLoading', false)
              commit('updateProcesses', { 
                name: 'checking/key'
              })
              commit('updateResponses', response)
              resolve(response)
            } else { 
              response = {
                error:"no key or path",
                payload:payload,
                from:'fi_check_key',
                page:NAME, 
              }
            }
          } catch (error) { 
            response = {
              error:error,
              payload:payload,
              from:'fi_check_key',
              page:NAME, 
            } 
            commit("ERROR",response)
          }  
          reject(response)  
        })
      },
      fi_get_path_data({ commit,dispatch},payload) {
        return new Promise(async(resolve,reject)=>{
          let response = {
            from:'fi_get_path_data',
            page:NAME, 
          }
          try { 
            let key = payload?payload.key:null
            let path = payload?payload.path:null 
            if (path) { 
              if (key) {
                if (path.slice(path.length - 1)=="/") {
                  path = path+key
                } else {
                  path = path+"/"+key
                }
              }
              commit('updateLoading', true)
              commit('updateProcesses', {
                add: true,
                name: 'checking/key'
              })  
              let PATH_REF = firebase.database().ref(path)
              await PATH_REF.once('value', async(snapshot) => {
                let data = await snapshot.val()
                response = {
                  success:true,
                  data:data,
                  path:path,
                  available:data?false:true
                }
              })   
              //not
              commit('updateLoading', false)
              commit('updateProcesses', { 
                name: 'checking/key'
              })
              commit('updateResponses', response)
              resolve(response)
            } else { 
              response = {
                error:"no key or path",
                payload:payload,
                from:'fi_get_path_data',
                page:NAME, 
              }
            }
          } catch (error) { 
            response = {
              error:error,
              payload:payload,
              from:'fi_get_path_data',
              page:NAME, 
            } 
            commit("ERROR",response)
          }  
          reject(response)  
        })
      },
      
      //-----------------[ ACTION ]--------------\  
      //SELECT
      http_request({ commit,dispatch,state},payload) {
        return new Promise(async(resolve,reject)=>{
          let response = {
            from:'http_request',
            page:NAME, 
            payload:payload
          }
          try { 
            let data = payload?payload.data:null
            let path = payload?payload.path:null
            let uid = users.state.USER.uid
            if (!data) {
                reject({...response,error:"no data"})
                return
            }
            let prams = {
                key:DATA.APP.key.apiKey,
                uid:uid,
                ...data
            }
            let http = this._vm.$http  
            let res = await http.post(DATA.APP.SERVER_LINK+'app/'+path,prams)
            let body = res?res.body:null
            let code = body?body.code:null
            resolve({
                response:"success",
                code:code,
                body:body
            }) 
          } catch (error) { 
            response.error = error
            commit("ERROR",response)
          }  
          reject(response)  
        })
      },
      http_get_item({ commit,dispatch,state},payload) {
        return new Promise(async(resolve,reject)=>{
          let response = {
            from:'http_request',
            page:NAME, 
            payload:payload
          }
          try { 
            let uid = users.state.USER.uid
            let company_key = payload?payload.company_key:null 
            let data = payload?payload.data:null
            let path = payload?payload.path:null
            let name = payload?payload.name:null
            let action = payload?payload.action:null 
            let cash = payload?payload.cash:null 
            let get = payload?payload.get:null 
            let grouped_key = payload?payload.grouped_key:null
            let data_saver = payload?payload.data_saver:null
            let is_not_array = false
            let is_array_data = true

            if (!data) {
              commit("ERROR",response)
              reject({...response,error:"no data"})
              return
            }else if (!data) {
              response.error = "no name"
              commit("ERROR",response)
              reject(response)
              return
            } 

            if (!action) {action=name} 
            commit('updateProcesses', {
              add: true,
              name: 'getting/'+name
            })  
            commit('advanceProcess', {
              name: name,
              action:  'getting', 
              loading: true,
            }) 

            let prams = { 
              key:DATA.APP.key.apiKey,
              uid:uid,
              ...data
            }
            let ref_action = {
              name:name, 
              action:action,  
              cash:cash,  
              get:get, 
              company_key:company_key,  
              grouped_key:grouped_key,
              ref:prams
            }

            //LOCAL GET
            if (get) {
              let res = await DB.getItems({
                name:name,
                company_key:company_key,
                // update_store:true,
              })

              let data_details = {
                ...ref_action,
                data:res?res.data:null,
                res:res, 
                local_data:true, 
                is_not_array:is_not_array,
                is_array_data:is_array_data
              }
              dispatch('item_data_saver',data_details)  
            } 
            
            const http = this._vm.$http  
            const res = await http.post(DATA.APP.SERVER_LINK+'app/'+path,prams)
            const body = res?.body
            const code = body?.code 
            let data_details = {
              ...ref_action,
              response:"success",
              code:code,
              body:body,
              data:body?.data
            }
            await dispatch('item_data_saver',data_details) 
            resolve(data_details) 
          } catch (error) { 
            response.error = error
            commit("ERROR",response)
          }  
          reject(response)  
        })
      },
      select_company({ commit,dispatch,state},payload) {
        return new Promise(async(resolve,reject)=>{
          let response = {
            from:'select_company',
            page:NAME, 
            payload:payload
          }
          try { 
            let company = payload?payload.company:null 
            if (company) { 
              // state.SELECTED_COMPANY = company
              // state.SELECTED_COMPANY_KEY = company?company.company_key:null
              commit("setItems",{
                name:"SELECTED_COMPANY_KEY",
                data:company?company.company_key:null
              })
              commit("setItems",{
                name:"SELECTED_COMPANY",
                data:company 
              })
              resolve({
                success:true,
                selected:true,
                company:company
              })
            } else { 
              response.error = "no company"
            }
          } catch (error) { 
            response.error = error
            commit("ERROR",response)
          }  
          reject(response)  
        })
      },
      calculate_stock({ commit,dispatch,state},payload) {
        let response = {
          from:'calculate_stock',
          page:NAME, 
          payload:payload
        }
        return new Promise(async(resolve,reject)=>{
          try { 
            let orders = payload?payload.data:null  
            
            if (!orders) { 
              response = {
                error:"payload required"
              } 
              commit("ERROR",response)
              reject(response)  
            } 

            //opening
            let opening_stocks = []
            let all_opening_stocks = []
            let archived_opening_stocks = []

            let opening_stock_items = []
            let all_opening_stock_items = []
            let archived_opening_stock_items = []
            
            //received
            let received_stocks = []
            let all_received_stocks = []
            let archived_received_stocks = []

            let received_stock_items = []
            let all_received_stock_items = []
            let archived_received_stock_items = []
            
            //returned
            let returned_stocks = []
            let all_returned_stocks = []
            let archived_returned_stocks = []

            let returned_stock_items = []
            let all_returned_stock_items = []
            let archived_returned_stock_items = []
            
            //adjust
            let adjusted_stocks = []
            let all_adjusted_stocks = []
            let archived_adjusted_stocks = []

            let adjusted_stock_items = []
            let all_adjusted_stock_items = []
            let archived_adjusted_stock_items = []

            orders.forEach(order => {
                //opening
                if (order.order_type == DATA.ITEMS.OPENING_STOCK.value) { 
                    // save
                    all_opening_stocks.push(order)
                    if (order.archive) {
                        archived_opening_stocks.push(order)
                    } else {
                        opening_stocks.push(order)
                    }  

                    //get items
                    if (order.items) {
                        order.items.forEach(item => {
                            let s_item = {
                                ...item,
                                order_key:order.key,
                                archive_order:order.archive?true:false
                            }
                            all_opening_stock_items.push(s_item)
                            if (order.archive) {
                                archived_opening_stock_items.push(s_item)
                            } else {
                                opening_stock_items.push(s_item)
                            }  
                        });
                    }
                } 
                
                //Received
                if (order.order_type == DATA.ITEMS.STOCK_RECEIVES.value) { 
                    // save
                    all_received_stocks.push(order)
                    if (order.archive) {
                        archived_received_stocks.push(order)
                    } else {
                        received_stocks.push(order)
                    }  

                    //get items
                    if (order.items) {
                        order.items.forEach(item => {
                            let s_item = {
                                ...item,
                                order_key:order.key,
                                archive_order:order.archive?true:false
                            }
                            all_received_stock_items.push(s_item)
                            if (order.archive) {
                                archived_received_stock_items.push(s_item)
                            } else {
                                received_stock_items.push(s_item)
                            }  
                        });
                    }
                } 
                
                //Returned
                if (order.order_type == DATA.ITEMS.STOCKS_RETURN.value) { 
                    // save
                    all_returned_stocks.push(order)
                    if (order.archive) {
                        archived_returned_stocks.push(order)
                    } else {
                        returned_stocks.push(order)
                    }  

                    //get items
                    if (order.items) {
                        order.items.forEach(item => {
                            let s_item = {
                                ...item,
                                order_key:order.key,
                                archive_order:order.archive?true:false
                            }
                            all_returned_stock_items.push(s_item)
                            if (order.archive) {
                                archived_returned_stock_items.push(s_item)
                            } else {
                                returned_stock_items.push(s_item)
                            }  
                        });
                    }
                } 
                
                //Adjust
                if (order.order_type == DATA.ITEMS.STOCK_ADJUSTMENTS.value) { 
                    // save
                    all_adjusted_stocks.push(order)
                    if (order.archive) {
                        archived_adjusted_stocks.push(order)
                    } else {
                        adjusted_stocks.push(order)
                    }  

                    //get items
                    if (order.items) {
                        order.items.forEach(item => {
                            let s_item = {
                                ...item,
                                order_key:order.key,
                                archive_order:order.archive?true:false
                            }
                            all_adjusted_stock_items.push(s_item)
                            if (order.archive) {
                                archived_adjusted_stock_items.push(s_item)
                            } else {
                                adjusted_stock_items.push(s_item)
                            }  
                        });
                    }
                } 
            }); 

            //opening 
            commit("setItems",{
                name:DATA.ITEMS.OPENING_STOCK.values.toUpperCase(),
                data:opening_stocks
            }) 
            commit("setItems",{
                name:"ALL_"+DATA.ITEMS.OPENING_STOCK.values.toUpperCase(),
                data:all_opening_stocks
            })  
            commit("setItems",{
                name:"ARCHIVED_"+DATA.ITEMS.OPENING_STOCK.values.toUpperCase(),
                data:archived_opening_stocks
            })
            commit("setItems",{
                name:DATA.ITEMS.OPENING_STOCK.values.toUpperCase()+"_ITEMS",
                data:opening_stock_items
            }) 
            commit("setItems",{
                name:"ALL_"+DATA.ITEMS.OPENING_STOCK.values.toUpperCase()+"_ITEMS",
                data:all_opening_stock_items
            })  
            commit("setItems",{
                name:"ARCHIVED_"+DATA.ITEMS.OPENING_STOCK.values.toUpperCase()+"_ITEMS",
                data:archived_opening_stock_items
            })
            
            //received 
            commit("setItems",{
                name:DATA.ITEMS.STOCK_RECEIVES.values.toUpperCase(),
                data:received_stocks
            }) 
            commit("setItems",{
                name:"ALL_"+DATA.ITEMS.STOCK_RECEIVES.values.toUpperCase(),
                data:all_received_stocks
            })  
            commit("setItems",{
                name:"ARCHIVED_"+DATA.ITEMS.STOCK_RECEIVES.values.toUpperCase(),
                data:archived_received_stocks
            }) 
            commit("setItems",{
                name:DATA.ITEMS.STOCK_RECEIVES.values.toUpperCase()+"_ITEMS",
                data:received_stock_items
            }) 
            commit("setItems",{
                name:"ALL_"+DATA.ITEMS.STOCK_RECEIVES.values.toUpperCase()+"_ITEMS",
                data:all_received_stock_items
            })  
            commit("setItems",{
                name:"ARCHIVED_"+DATA.ITEMS.STOCK_RECEIVES.values.toUpperCase()+"_ITEMS",
                data:archived_received_stock_items
            }) 
            
            //return 
            commit("setItems",{
                name:DATA.ITEMS.STOCKS_RETURN.values.toUpperCase(),
                data:returned_stocks
            }) 
            commit("setItems",{
                name:"ALL_"+DATA.ITEMS.STOCKS_RETURN.values.toUpperCase(),
                data:all_returned_stocks
            })  
            commit("setItems",{
                name:"ARCHIVED_"+DATA.ITEMS.STOCKS_RETURN.values.toUpperCase(),
                data:archived_returned_stocks
            }) 
            commit("setItems",{
                name:DATA.ITEMS.STOCKS_RETURN.values.toUpperCase()+"_ITEMS",
                data:returned_stock_items
            }) 
            commit("setItems",{
                name:"ALL_"+DATA.ITEMS.STOCKS_RETURN.values.toUpperCase()+"_ITEMS",
                data:all_returned_stock_items
            })  
            commit("setItems",{
                name:"ARCHIVED_"+DATA.ITEMS.STOCKS_RETURN.values.toUpperCase()+"_ITEMS",
                data:archived_returned_stock_items
            }) 
            
            //Adjusted 
            commit("setItems",{
                name:DATA.ITEMS.STOCK_ADJUSTMENTS.values.toUpperCase(),
                data:adjusted_stocks
            }) 
            commit("setItems",{
                name:"ALL_"+DATA.ITEMS.STOCK_ADJUSTMENTS.values.toUpperCase(),
                data:all_adjusted_stocks
            })  
            commit("setItems",{
                name:"ARCHIVED_"+DATA.ITEMS.STOCK_ADJUSTMENTS.values.toUpperCase(),
                data:archived_adjusted_stocks
            })  
            commit("setItems",{
                name:DATA.ITEMS.STOCK_ADJUSTMENTS.values.toUpperCase()+"_ITEMS",
                data:adjusted_stock_items
            }) 
            commit("setItems",{
                name:"ALL_"+DATA.ITEMS.STOCK_ADJUSTMENTS.values.toUpperCase()+"_ITEMS",
                data:all_adjusted_stock_items
            })  
            commit("setItems",{
                name:"ARCHIVED_"+DATA.ITEMS.STOCK_ADJUSTMENTS.values.toUpperCase()+"_ITEMS",
                data:archived_adjusted_stock_items
            })

            resolve({
              success:true, 
            })
          } catch (error) { 
            response = {
              error:error,
              ...response
            } 
            commit("ERROR",response)
            reject(response)  
          }  
        })
      }, 
    },
}