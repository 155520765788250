import users from '../users'   
import DATA from '../../plugins/DATA' 
import DB from '../../plugins/DB'   
import items_store from '../items' 
import  { 
  toDateTime, 
  toDate,
  toTime,
  SIZE,
  TEXT_UP,
  TEXT_UP1,
  TEXT_UP11,
  TEXT_UP0,
  TEXT_EQ,
  TEXT_SPLIT_N,
  TEXT_SPACE0, 
  DEFINED_ONLY
} from '../../plugins/MBS_actions' 
import formatNumber  from '../../plugins/formatNumber'   
import moment from 'moment'      
const toNumber=formatNumber.toNumber
const toMoney=formatNumber.toMoney
const toSameLength=formatNumber.toSameLength
const showCard=formatNumber.showCard
  
let NAME = "STORE-PRINT"

export default{
    state: {   
      PRINTER:null,   
       
    },
    //___________________-GETTERS-_________________
    getters: {   
      
    },
    //___________________-MUTATIONS-___________________
    mutations:{  
      
    },
    //____________________[ACTIONS]_____________________
    actions: {  
       
      MERGE_DUPLICATED_ITEMS({commit,getters},payload){
        let response = { 
            page:NAME,
            from:"MERGE_DUPLICATED_ITEMS",
            payload:payload
        }   
        try {  
            const items = payload?.items 
            const merge_doc_type = payload?.merge_doc_type 
            let outlet = payload?.outlet 
            if (!outlet) {
                outlet = getters.getCurrentOutlet
            }
            const receipt_merge_duplicate_items = outlet?.receipt_merge_duplicate_items 
            const bill_merge_duplicate_items = outlet?.bill_merge_duplicate_items 
            const docket_merge_duplicate_items = outlet?.docket_merge_duplicate_items 
            const order_merge_duplicate_items = outlet?.order_merge_duplicate_items 
            
            const merge = ()=>{
                let merge_items = []  
                if (items) {
                    items.forEach(item => {
                        const key = item.key
                        const _key = item._key
                        const code = item.code
                        const _code = item._code
                        let index = null
                        if (_key) {   
                            index = merge_items.findIndex(item=>item._key == _key)
                        }else if(_code) {
                            index = merge_items.findIndex(item=>item._code == _code)
                        }else if(key) {
                            index  = merge_items.findIndex(item=>item.key == key)
                        }else if(code) {
                            index = merge_items.findIndex(item=>item.code == code)
                        } 

                        const sold_price = toNumber(item.sold_price)
                        const sold_cost_price = toNumber(item.sold_cost_price)
                        const quantity = toNumber(item.quantity)
                        const discount = toNumber(item.discount)

                        if (index == -1) {
                            merge_items.push({
                                ...item,
                                sold_price:sold_price,
                                sold_cost_price:sold_cost_price,
                                quantity:quantity,
                                discount:discount,
                            })
                        } else {
                            merge_items[index].quantity =  merge_items[index].quantity+quantity
                            merge_items[index].discount =  merge_items[index].discount+discount 
                        } 
                    });
                }  
                return merge_items
            }

            if(!items){return null}
            if (receipt_merge_duplicate_items && (merge_doc_type =="sale_order"||merge_doc_type =="receipt")) {
                return  merge()
            }else if (bill_merge_duplicate_items && merge_doc_type =="bill") {
                return  merge()
            }else if (docket_merge_duplicate_items && merge_doc_type =="docket") {
                return  merge()
            }else if (order_merge_duplicate_items && merge_doc_type =="order") {
                return  merge()
            }  
            return  items
        } catch (error) {
            response = {
                ...response,
                error:error
            }
            commit("ERROR",response)
        } 
      }, 
      async CALCULATE_TOTAL_CASHUPS({commit,dispatch},cashups,shift){
        try {  
            let total_floating_cash = shift?toNumber(shift.total_floating_cash):0
            let cashup_option = shift?shift.cashup_option:null

            let sales_retail = 0
            let sales_wholesales= 0

            let sales_on_account = 0 
            let total_pricing_tiers = []
            let day_shift_total_sales = 0

            let sales_cash_payments = 0
            let invoices_cash_payments= 0

            let sales_total_other_option_payments = 0
            let invoices_total_other_option_payments = 0
            let invoices_other_option_payments = [] 
            let sales_other_option_payments = []
            let all_other_option_payments = []
            
            let total_open_shortage = 0
            let total_open_shortages = 0
            let total_open_overages = 0

            let total_close_shortage = 0
            let total_close_shortages = 0
            let total_close_overages = 0
            

            let closing_cashes = 0
            let total_closing_cash = 0
            let opening_cashes = 0
            let total_opening_cash = 0 

            if (cashups) {
                for (const cashup of cashups) {
                    day_shift_total_sales = day_shift_total_sales+toNumber(cashup.cashup_total_sales)

                    //INPUT PAYMENTS
                    const input_payments = cashup.input_payments?cashup.input_payments:cashup.InputPayments
                    let input_cash_payments = 0
                    let input_other_payments = 0

                    let shortage =toNumber(cashup.shortage)
                    let isShortage = cashup.isShortage

                    let opening_cash =toNumber(cashup.opening_cash )
                    // let expected_opening_cash =toNumber(cashup.expected_opening_cash )
                    let expected_opening_cash = 
                        cashup_option =='submit_cash_after_every_cashup'? 
                       toNumber(cashup.floating_cash ):
                       toNumber(cashup.expected_opening_cash )
                    let opening_shortage = opening_cash-expected_opening_cash
                    let isOpenShortage = opening_shortage<0?true:false

                    //Input payments
                    if (input_payments) { 
                        input_payments.forEach(payment => {
                            if (payment.key == "cash") {
                                input_cash_payments = input_cash_payments+toNumber(payment.given_amount)
                            } else {
                                input_other_payments = input_other_payments+toNumber(payment.given_amount)
                                //add to sales
                                let index = sales_other_option_payments.findIndex(option=>{
                                    if(!payment.key){
                                        return option.payment_option_code  == payment.code 
                                    }
                                    return option.payment_option_key  == payment.key
                                }) 
                                const pay_option = store.getters.getPaymentMethodByCode(payment.code)
                                if (index == -1) {  
                                    sales_other_option_payments.push({ 
                                        ...payment, 
                                        payment_method:pay_option,
                                        payment_method_name:pay_option?pay_option.name:'...',
                                        payment_option_code:payment.code,
                                        payment_option_key:payment.key,
                                        amount:payment.given_amount,
                                    })
                                } else {
                                    sales_other_option_payments[index].amount = sales_other_option_payments[index].amount+toNumber(payment.given_amount)
                                } 

                                //add to all
                                // let index_all = all_other_option_payments.findIndex(option=>{
                                //     return option.payment_option_code  == payment.code
                                // }) 
                                let index_all = all_other_option_payments.findIndex(option=>{
                                    if (!option.payment_option_key) {
                                        return option.payment_option_code  == payment.payment_option_code 
                                    }
                                    return option.payment_option_key  == payment.payment_option_key
                                }) 
                                if (index_all == -1) { 
                                    all_other_option_payments.push({
                                        ...payment, 
                                        payment_method:pay_option,
                                        payment_method_name:pay_option?pay_option.name:'...',
                                        payment_option_code:payment.code,
                                        amount:payment.given_amount,
                                    })
                                } else {
                                    all_other_option_payments[index_all].amount = all_other_option_payments[index_all].amount+toNumber(payment.given_amount)
                                }  
                            }
                            
                        });
                    }

                    //Pricing 
                    if (cashup.cashup_pricing_tiers) {
                        cashup.cashup_pricing_tiers.forEach(pricing_tier => {
                            let index = total_pricing_tiers.findIndex(item=>item.key==pricing_tier.key)
                            let sum_amount =toNumber(pricing_tier.sum_amount)
                            let sold_price =toNumber(pricing_tier.sold_price)
                            let quantity =toNumber(pricing_tier.quantity)
                            let size =toNumber(pricing_tier.size)
                            if (index==-1) {
                                total_pricing_tiers.push({
                                    ...pricing_tier,
                                    sum_amount:sum_amount,
                                    sold_price:sold_price,
                                    quantity:quantity,
                                    size:size,
                                })
                            } else {
                                total_pricing_tiers[index].sum_amount = total_pricing_tiers[index].sum_amount+sum_amount
                                total_pricing_tiers[index].sold_price = total_pricing_tiers[index].sold_price+sold_price
                                total_pricing_tiers[index].quantity = total_pricing_tiers[index].quantity+quantity
                                total_pricing_tiers[index].size = total_pricing_tiers[index].size+size
                            }
                        });
                    }

                    if (cashup.force_closed) {
                        
                    }else if (cashup.closed) {
                        closing_cashes = closing_cashes+toNumber(cashup.closing_cash) 
                        opening_cashes = opening_cashes+toNumber(cashup.opening_cash) 

                        sales_retail = sales_retail+toNumber(cashup.sales_retail) 
                        sales_wholesales = sales_wholesales+toNumber(cashup.sales_wholesales) 
                        sales_on_account = sales_on_account+toNumber(cashup.sales_on_account) 
                        
                        // sales_cash_payments = sales_cash_payments+toNumber(cashup.sales_cash_payments)+input_cash_payments
                        sales_cash_payments = sales_cash_payments+toNumber(cashup.sales_cash_payments) 
                        invoices_cash_payments = invoices_cash_payments+toNumber(cashup.invoices_cash_payments) 
                        
                        // sales_total_other_option_payments = sales_total_other_option_payments+toNumber(cashup.sales_total_other_option_payments) +input_other_payments
                        sales_total_other_option_payments = sales_total_other_option_payments+toNumber(cashup.sales_total_other_option_payments)  
                        invoices_total_other_option_payments = invoices_total_other_option_payments+toNumber(cashup.invoices_total_other_option_payments) 

                        let other_sales = await dispatch("CALCULATE_OTHER_PAYMENT_OPTIONS",cashup.sales_other_option_payments) 
                        let other_invoice = await dispatch("CALCULATE_OTHER_PAYMENT_OPTIONS",cashup.invoices_other_option_payments)  
                        if (other_sales) {
                            other_sales.forEach(element => {
                                //add to sales
                                let index = sales_other_option_payments.findIndex(option=>{
                                    return option.payment_option_key  == element.payment_option_key
                                }) 
                                if (index == -1) { 
                                    sales_other_option_payments.push({
                                        ...element, 
                                    })
                                } else {
                                    sales_other_option_payments[index].amount = sales_other_option_payments[index].amount+toNumber(element.amount)
                                } 
                                
                                //add to all
                                // let index_all = all_other_option_payments.findIndex(option=>{
                                //     return option.payment_option_key  == element.payment_option_key
                                // }) 
                                let index_all = all_other_option_payments.findIndex(option=>{
                                    if (!option.payment_option_key) {
                                        return option.payment_option_code  == element.payment_option_code 
                                    }
                                    return option.payment_option_key  == element.payment_option_key
                                }) 
                                if (index_all == -1) { 
                                    all_other_option_payments.push({
                                        ...element, 
                                    })
                                } else {
                                    all_other_option_payments[index_all].amount = all_other_option_payments[index_all].amount+toNumber(element.amount)
                                }  
                            });
                        }
                        
                        if (other_invoice) {
                            other_invoice.forEach(element => {
                                let index = invoices_other_option_payments.findIndex(option=>{
                                    if (!option.payment_option_key) {
                                        return option.payment_option_code  == element.payment_option_code 
                                    }
                                    return option.payment_option_key  == element.payment_option_key
                                }) 
                                if (index == -1) { 
                                    invoices_other_option_payments.push({
                                        ...element, 
                                    })
                                } else {
                                    invoices_other_option_payments[index].amount = invoices_other_option_payments[index].amount+toNumber(element.amount)
                                }  

                                //add to all
                                let index_all = all_other_option_payments.findIndex(option=>{
                                    if (!option.payment_option_key) {
                                        return option.payment_option_code  == element.payment_option_code 
                                    }
                                    return option.payment_option_key  == element.payment_option_key
                                }) 
                                if (index_all == -1) { 
                                    all_other_option_payments.push({
                                        ...element, 
                                    })
                                } else {
                                    all_other_option_payments[index_all].amount = all_other_option_payments[index_all].amount+toNumber(element.amount)
                                }  
                            });
                        }  
                    }else{ 
                    }

                    //open 
                    if (isOpenShortage) {
                        total_open_shortage = total_open_shortage+opening_shortage
                        total_open_shortages = total_open_shortages+opening_shortage
                    } else {
                        total_open_shortage = total_open_shortage+opening_shortage
                        total_open_overages = total_open_overages+opening_shortage
                    }

                    //close calculate shortages 
                    if (isShortage) {
                        total_close_shortage = total_close_shortage+shortage
                        total_close_shortages = total_close_shortages+shortage
                    }else{
                        total_close_shortage = total_close_shortage+shortage
                        total_close_overages = total_close_overages+shortage
                    }
                }  
            }
            let isCloseShortage = total_close_shortage<0?true:false
            let isOpenShortage = total_open_shortage<0?true:false

            //CALCULATE TOTALS
            let total_sales = sales_retail+sales_wholesales
            let total_cash_sales  =  sales_cash_payments
            let total_cash  =  sales_cash_payments+invoices_cash_payments+total_floating_cash

            let total_shortage = total_open_shortage+total_close_shortage

            return {
                sales_retail:sales_retail,
                sales_wholesales:sales_wholesales,
                sales_on_account:sales_on_account,
                total_pricing_tiers:total_pricing_tiers,
                day_shift_total_sales:day_shift_total_sales,
                day_shift_total_sales_:toMoney(day_shift_total_sales),
                

                sales_cash_payments:sales_cash_payments,
                invoices_cash_payments:invoices_cash_payments,
                
                sales_total_other_option_payments:sales_total_other_option_payments,
                invoices_total_other_option_payments:invoices_total_other_option_payments,
                
                sales_other_option_payments:sales_other_option_payments,
                invoices_other_option_payments:invoices_other_option_payments,
                all_other_option_payments:all_other_option_payments,

                //totals
                total_sales:total_sales, 
                total_sales_:toMoney(total_sales), 
                total_cash_sales : total_cash_sales,
                total_cash_sales_ : toMoney(total_cash_sales),
                total_cash : total_cash,
                total_cash_ : toMoney(total_cash), 

                
                total_open_shortage:total_open_shortage,
                total_open_shortage_:toMoney(total_open_shortage),
                total_is_open_shortage:isOpenShortage, 
                total_open_overages:total_open_overages,
                total_open_overages_:toMoney(total_open_overages),
                total_open_shortages:total_open_shortages,
                total_open_shortages_:toMoney(total_open_shortages),
                
                total_close_shortage:total_close_shortage,
                total_close_shortage_:toMoney(total_close_shortage),
                total_is_close_shortage:isCloseShortage, 
                total_close_overages:total_close_overages,
                total_close_overages_:toMoney(total_close_overages),
                total_close_shortages:total_close_shortages,
                total_close_shortages_:toMoney(total_close_shortages),

                total_shortage : total_shortage,
                total_shortage_ : toMoney(total_shortage),
                total_is_shortage : total_shortage<0?true:false,

                total_closing_cash : total_closing_cash,
                total_closing_cash_ : toMoney(total_closing_cash),
                total_opening_cash : total_opening_cash,
                total_opening_cash_ : toMoney(total_opening_cash),
                
                closing_cashes : closing_cashes,
                closing_cashes_ : toMoney(closing_cashes),
                opening_cashes : opening_cashes,
                opening_cashes_ : toMoney(opening_cashes),

            }
        } catch (error) {
          commit("ERROR",error)
        } 
      }, 
      CALCULATE_OTHER_PAYMENT_OPTIONS({commit,getters},other_option_payments){
          try {   
              let options = []
              if (other_option_payments) {
                  for (const key in other_option_payments) {
                      const other_option = other_option_payments[key]; 
                      let index = options.findIndex(option=>{
                          return option.payment_option_key  == key
                      }) 
                      if (index == -1) {
                          let payment_method = getters.getPaymentMethodByKey(key)
                          options.push({
                              ...other_option,
                              payment_method:payment_method,
                              payment_method_name:payment_method?payment_method.name:"---", 
                              money_account_code:payment_method?payment_method.money_account_code:null, 
                          })
                      } else {
                          options[index].amount = options[index].amount+toNumber(other_option.amount)
                      }  
                  }
              }  
              return SIZE(options)>0?options:null
          } catch (error) {
            commit("ERROR",error)
          } 
      }, 
      CALCULATE_SALES_AGGREGATED_ITEMS({commit},payload){
        let response = { 
            page:NAME,
            from:"CALCULATE_SALES_AGGREGATED_ITEMS",
            payload:payload
        }
        try {
            let moment = DateBus.moment
            let sale_orders = payload?.sale_orders
            let filtered_date = payload?.filtered_date
            let difference = filtered_date?filtered_date.difference:null
            let to_date = filtered_date?filtered_date.to_date:null
            let from_date = filtered_date?filtered_date.from_date:null
            if(!sale_orders){return null}
            let items = []
            sale_orders.forEach(order => {
                // let date = moment(order.local_created_at).format("yyyy-MM-D")
                // let momentDate = moment(date) 
                // let isBetween = momentDate.isBetween(from_date,to_date,'','[]')  
                let isBetween = true
                if (isBetween) {
                    const calculated_vat = order.calculated_vat
                    const vat_exclusive = calculated_vat?.vat_exclusive
                    let sold_unjoined_items = order.items
                    let sold_joined_items = order.joined_items
                    let sold_items = sold_joined_items?sold_joined_items:sold_unjoined_items 
                    let total_discount = toNumber(order.total_discount)
                    let item_size = SIZE(sold_items)
                    let average_discount = total_discount/item_size
                    if (sold_items) {
                        sold_items.forEach(item => { 
                            let index = items.findIndex(it=>{
                                return it.item_key  == item.item_key
                            }) 
                            let sold_quantity = toNumber(item.quantity)
                            let sold_cost_price = toNumber(item.sold_cost_price)*sold_quantity
                            let sold_discount = (toNumber(item.discount)*sold_quantity)+average_discount
                            let sold_price = toNumber(item.sold_price)
                            let total_sold_price = (sold_price*sold_quantity)-sold_discount
                            let tax_group_code = item?.tax_group_code 
                            tax_group_code = tax_group_code?tax_group_code:"A" 
                            let item_total_vat = 0
                            let item_sum_amount = 0
                            if (tax_group_code == "A" && vat_exclusive) {
                                item_total_vat = (sold_price*sold_quantity)*16.5/100
                                item_sum_amount = total_sold_price+item_total_vat
                            } else {
                                item_sum_amount = total_sold_price
                            }

                            if (index == -1) { 
                                items.push({
                                    ...item, 
                                    total_vat : item_total_vat,
                                    sum_amount : item_sum_amount,
                                    total_vat_ : toMoney(item_total_vat),
                                    sum_amount_ : toMoney(item_sum_amount),

                                    sold_quantity:sold_quantity,
                                    sold_cost_price:sold_cost_price,
                                    sold_discount:sold_discount,
                                    sold_price:sold_price,
                                    total_sold_price:total_sold_price,
                                    number_of_transactions:1,
                                    
                                    sold_quantity_:toMoney( sold_quantity),
                                    sold_cost_price_:toMoney( sold_cost_price),
                                    sold_discount_:toMoney( sold_discount),
                                    sold_price_:toMoney( sold_price),
                                    total_sold_price_:toMoney( total_sold_price),
                                })
                            } else {
                                items[index].total_vat = items[index].total_vat+item_total_vat
                                items[index].sum_amount = items[index].sum_amount+item_sum_amount
                                items[index].total_vat_ = toMoney(items[index].total_vat)
                                items[index].sum_amount_ = toMoney(items[index].sum_amount)

                                items[index].sold_quantity = items[index].sold_quantity+sold_quantity
                                items[index].sold_cost_price = items[index].sold_cost_price+sold_cost_price
                                items[index].sold_discount = items[index].sold_discount+sold_discount
                                items[index].sold_price = items[index].sold_price+sold_price
                                items[index].total_sold_price = items[index].total_sold_price+total_sold_price
                                items[index].number_of_transactions = items[index].number_of_transactions+1
                                
                                items[index].sold_quantity_ = toMoney( items[index].sold_quantity)
                                items[index].sold_cost_price_ = toMoney( items[index].sold_cost_price)
                                items[index].sold_discount_ = toMoney( items[index].sold_discount)
                                items[index].sold_price_ = toMoney( items[index].sold_price)
                                items[index].total_sold_price_ = toMoney( items[index].total_sold_price)
                            }    
                        });
                        
                    }
                }     
            });  
            return items 
        } catch (error) {
            response = {
                ...response,
                error:error
            }
            commit("ERROR",error)
            return null
            
        } 
      },
      CALCULATE_SALES_ITEMS_PER_TRANSACTION({commit},payload){
          let response = { 
              page:NAME,
              from:"CALCULATE_SALES_ITEMS_PER_TRANSACTION",
              payload:payload
          }
          try {
              let moment = DateBus.moment
              let sale_orders = payload?.sale_orders
              let filtered_date = payload?.filtered_date
              let difference = filtered_date?filtered_date.difference:null
              let to_date = filtered_date?filtered_date.to_date:null
              let from_date = filtered_date?filtered_date.from_date:null
              if(!sale_orders){return null}
              let items = []
              sale_orders.forEach(order => {  
                  // let date = moment(order.local_created_at).format("yyyy-MM-D")
                  // let momentDate = moment(date) 
                  // let isBetween = momentDate.isBetween(from_date,to_date,'','[]')  
                  let isBetween = true 
                  if (isBetween) {
                      const calculated_vat = order.calculated_vat
                      const vat_exclusive = calculated_vat?.vat_exclusive
                      let sold_unjoined_items = order.items
                      let sold_joined_items = order.joined_items
                      let sold_items = sold_joined_items?sold_joined_items:sold_unjoined_items 
                      let total_discount = toNumber(order.total_discount)
                      let item_size = SIZE(sold_items)
                      let average_discount = total_discount/item_size
                      
                      if (sold_items) {
                          sold_items.forEach(item => { 
                              // let index = items.findIndex(it=>{
                              //     return it.item_key  == item.item_key
                              // }) 
                              let tax_group_code = item?.tax_group_code 
                              tax_group_code = tax_group_code?tax_group_code:"A" 
                              let sold_quantity = toNumber(item.quantity)
                              let sold_cost_price = toNumber(item.sold_cost_price)*sold_quantity
                              let sold_discount = (toNumber(item.discount)*sold_quantity)+average_discount
                              let sold_price = toNumber(item.sold_price)
                              let total_sold_price = (sold_price*sold_quantity)-sold_discount
                              let item_total_vat = 0
                              let item_sum_amount = 0
                              if (tax_group_code == "A" && vat_exclusive) {
                                  item_total_vat = (sold_price*sold_quantity)*16.5/100
                                  item_sum_amount = total_sold_price+item_total_vat
                              } else {
                                  item_sum_amount = total_sold_price
                              }
                              

                              items.push({
                                  ...item, 
                                  total_vat : item_total_vat,
                                  sum_amount : item_sum_amount,
                                  total_vat_ : toMoney(item_total_vat),
                                  sum_amount_ : toMoney(item_sum_amount),

                                  sold_quantity:sold_quantity,
                                  sold_cost_price:sold_cost_price,
                                  sold_discount:sold_discount,
                                  sold_price:sold_price,
                                  total_sold_price:total_sold_price,
                                  
                                  sold_quantity_:toMoney( sold_quantity),
                                  sold_cost_price_:toMoney( sold_cost_price),
                                  sold_discount_:toMoney( sold_discount),
                                  sold_price_:toMoney( sold_price),
                                  total_sold_price_:toMoney( total_sold_price),
                                  
                                  transaction_key:order.key,

                                  local_created_at:order.local_created_at,
                                  local_created_at_:order.local_created_at_,
                                  local_created_at_date:order.local_created_at_date,
                                  local_created_at_time:order.local_created_at_time,
                              })
                                    
                          });
                          
                      }
                  }     
              });   
              return items
          } catch (error) {
              response = {
                  ...response,
                  error:error
              }
              commit("ERROR",error)
              return null
              
          } 
      },

      // EPSON ACTIONS
      EPSON_1LINE({commit},payload){ 
        let response = {
            from:"EPSON_1LINE",
            page:NAME,
            payload:payload
        } 
        try {   
            if (!payload) {
                response =  {
                    ...response,
                    success:false,
                    error:'no payload'
                } 
                commit("ERROR",response)
                return null
            } 
            let first = payload.first?payload.first:""
            let last = payload.last?payload.last:""
            let size = payload.size?payload.size:48
            let max1 = payload.max1?payload.max1:30
            let max2 = payload.last?payload.max2:15
            let space_value = payload.space_value?payload.space_value:' '

            let size1 = SIZE(first)
            let size2 = SIZE(last)
            
            
            if (size1>max1) {
                first = first.slice(0,(max1-2))+".."
                size1 = max1
            }if (size2>max2) {
                last = last.slice(0,(max2-2))+".."
                size2 = max2
            }
            
            let total_size = size1+size2
            let space_size = size-total_size
            let space = ''
            for (let index = 0; index < space_size; index++) { 
                space = space+space_value 
            }  
            return first+space+last 
        } catch (error) { 
            response = {
                ...response,
                error:error,
                success:false
            } 
            commit("ERROR",response)
            
        }  
      },  
      async EPSON_SALE_ORDER_REQUEST({commit,dispatch,getters}, payload) {
        return new Promise(async (resolve,reject)=>{
          let response = {
              from:"EPSON_SALE_ORDER_REQUEST",
              page:NAME,
              payload:payload
          } 
          try {    
              let merge_doc_type = payload?.merge_doc_type
              let sale_order = payload?payload.sale_order:null
              if (sale_order?.merge_doc_type) {
                  merge_doc_type = sale_order?.merge_doc_type
              }
              let day_shift = sale_order?sale_order.day_shift:null
              let order_type = sale_order?sale_order.order_type:null
              let bill_sale_order = order_type=="bill_sale_order"?true:false 
              let account_type = sale_order?sale_order.account_type:null
              if(!sale_order){
                  reject({...response,error:"sale_order required"})
                  return null
              }
              let cashup_option = day_shift?day_shift.cashup_option:null
              let cashups = day_shift?day_shift.joined_cashups:null
              let running_cashups = cashups?cashups.filter(c=>!c.closed):null
              let running_cashups_size = SIZE(running_cashups)
              let isRunning = running_cashups_size?true:false
              let isClosed = day_shift?day_shift.closed:null

              //USED TILLS
              let used_floating_cash = 0
              let used_till_keys = []
              if (cashups) {
                  cashups.forEach(element => {
                      let index = used_till_keys.indexOf(element.till_key)
                      if (index==-1) {
                          used_floating_cash = used_floating_cash+toNumber(element.floating_cash)
                          used_till_keys.push(element.till_key)
                      }
                  });
              }

              let tills = day_shift?day_shift.joined_tills?day_shift.joined_tills:day_shift.tills:null
              let total_cashups = await dispatch("CALCULATE_TOTAL_CASHUPS",cashups,day_shift)  
              let report_type = payload?payload.report_type:null
              let print_title = payload?payload.print_title:null
              let printer = payload?payload.printer?payload.printer :{}:{}

              const default_paper_size = 32
              const default_printer_name = "POS80"
              let paper_size = 0
              let printer_name = ""
              let space_value = '.'   
              let print_order = {}

              //SETTINGS
              let settings = items_store?.state?.COMPANY_SETTINGS
              let receipt_show_company_info = settings?settings.receipt_show_company_info:null
              let receipt_show_company_tpin = settings?settings.receipt_show_company_tpin:null
              let receipt_show_receipt_title = settings?settings.receipt_show_receipt_title:null
              let receipt_show_item_numbering = settings?settings.receipt_show_item_numbering:null
              let receipt_show_barcode = settings?settings.receipt_show_barcode:null
              let receipt_show_till = settings?settings.receipt_show_till:null
              let receipt_show_buyer_info = settings?settings.receipt_show_buyer_info:null
              let receipt_show_change_amount = settings?settings.receipt_show_change_amount:null
              let receipt_show_vat = settings?settings.receipt_show_vat:null
              //template
              let template_settings = settings?settings.template_settings:null
              let receipt_settings = template_settings?template_settings.receipt:null
              let disable_kipcount_watermark = receipt_settings?receipt_settings.disable_kipcount_watermark:null

              //PRINTER INFO
              paper_size = printer.paper_size?printer.paper_size:default_paper_size
              printer_name = printer.printer_name?printer.printer_name:default_printer_name
              print_order.paper_size = paper_size
              print_order.printer_name = printer_name 
              print_order.lines = [] 
  
          
              //TOP HEADER
              if (receipt_show_receipt_title) {
                  print_title = print_title?print_title:report_type?report_type:"SALES REPORT"
                  print_order.lines.push({
                      align:"center",
                      bold:false,
                      value:'top_header',
                      text:"*** "+print_title+" ***"
                  })
                  // print_order.lines.push({ text:" "})
              }

              //COMPANY INFO
              const company = sale_order?.company
              const outlet = sale_order?.outlet
              const c_company_name = company?.company_name
              const c_phone_number_cell = company?.company_phone_number
              const c_phone_number_tell = company?.company_phone_number2
              const c_post_address = company?.company_post_address
              const c_email_address = company?.company_email_address
              const c_tpin = company?.company_tpin 
              const c_location_address = company?.company_location_address 
              
              const receipt_company_name = outlet?.receipt_company_name
              const receipt_phone_number_cell = outlet?.receipt_phone_number_cell
              const receipt_phone_number_tell = outlet?.receipt_phone_number_tell
              const receipt_post_address = outlet?.receipt_post_address
              const receipt_email_address = outlet?.receipt_email_address
              const receipt_tpin = outlet?.receipt_tpin
              const receipt_address = outlet?.receipt_address
              
              const company_name = receipt_company_name?receipt_company_name:c_company_name
              const company_phone_number_cell = receipt_phone_number_cell?receipt_phone_number_cell:c_phone_number_cell
              const company_phone_number_tell = receipt_phone_number_tell?receipt_phone_number_tell:c_phone_number_tell
              const company_post_address = receipt_post_address?receipt_post_address:c_post_address
              const company_email_address = receipt_email_address?receipt_email_address:c_email_address
              const company_tpin = receipt_tpin?receipt_tpin:c_tpin
              const company_address =  receipt_address?receipt_address:c_location_address

              const epson_line = async (payload)=>{
                const text = await dispatch("EPSON_1LINE",{
                  first:payload?.first,
                  last:payload?.last,
                  size:payload?.size,
                  max1:payload?.max1,
                  max2:payload.max2
                })  
                return text
              } 

              if (company && receipt_show_company_info) { 
                  print_order.lines.push({
                      align:"center",
                      bold:true, 
                      value:'company_name',
                      text:company_name 
                  })
                  print_order.lines.push({
                      align:"center",
                      bold:false,
                      value:'company_post_address',
                      text:"Post Address: "+company_post_address
                  })
                  print_order.lines.push({
                      align:"center",
                      bold:false,
                      value:'company_email_address',
                      text:"Email: "+company_email_address
                  }) 
                  let phone_details = "(Cell): "+company_phone_number_cell
                  if (company_phone_number_cell) {
                      phone_details = phone_details +" (Tell): "+company_phone_number_tell 
                      if (paper_size<43) {
                          print_order.lines.push({
                              align:"center",
                              bold:false,
                              value:'company_phone_number',
                              text: "(Cell): "+company_phone_number_cell
                          })
                          print_order.lines.push({
                              align:"center",
                              bold:false,
                              value:'company_phone_number',
                              text:"(Tell): "+company_phone_number_tell
                          })
                      }
                  }
                  if (paper_size>42) { 
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'company_phone_number',
                          text:phone_details
                      })  
                  }  
                  if (receipt_show_company_tpin) {  
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'company_tpin',
                          text:"TPIN: "+company_tpin 
                      }) 
                  } 
                  
                  
                  let teller = sale_order.teller 
                  let text_outlet =""
                  if (outlet) {  
                      text_outlet = "Outlet: "+outlet?.name
                  } 
                  if (teller) {  
                      text_outlet = text_outlet+" Teller: "+teller.names 
                  } 

                  if (text_outlet!="") {  
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'company_tpin',
                          text:text_outlet
                      }) 
                  }

                    
              }
  
              //DOWN HEADER
              if (receipt_show_receipt_title) { 
                  // print_order.lines.push({ text:" "})
                  print_order.lines.push({
                      align:"center",
                      bold:false,
                      value:'top_header',
                      text:"******"
                  }) 
                  print_order.lines.push({ text:" "}) 
              }

              //DAY SHIFT DETAILS
              let receipt_number = sale_order.order_key?sale_order.order_key:sale_order.order_id
              let receipt_key = sale_order.key 
              if (true) { 
                  // if (customer && receipt_show_buyer_info) {
                  if (true) {
                      let receipt_type = null
                      if(order_type=="quotation"){
                          receipt_type = TEXT_UP(DATA.ITEMS.QUOTATIONS.value) 
                      }else if(account_type == "on_account"){
                          receipt_type = TEXT_UP(DATA.ITEMS.INVOICES.value)  
                      }else if(order_type == "bill_sale_order"){
                          receipt_type = TEXT_UP(DATA.ITEMS.ORDERS.value)  
                      }else{
                          receipt_type = TEXT_UP(DATA.ITEMS.RECEIPTS.value)  
                      }

                      if (receipt_number) {
                          const t1 = await epson_line({
                            first:receipt_type+" NUMBER:", 
                            last:""+toSameLength(receipt_number,5),
                            size:paper_size
                          }) 
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text: t1
                          })   
                      }

                      const t2 = await epson_line({
                        first:receipt_type+" KEY:", 
                        last:""+toSameLength(receipt_key,5),
                        size:paper_size
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text: t2
                      })  

                      const t3 = await epson_line({
                        first:"DATE:", 
                        last:""+sale_order.local_created_at_date,
                        size:paper_size
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:t3
                      })  
                  }
                  let customer = sale_order.customer 
                  let waiter_name = sale_order.waiter_name 
                  let table_name = sale_order.table_name 
                  if (customer && receipt_show_buyer_info) {
                      if (customer.name) {
                          const t4 = await epson_line({
                            first:"BUYER'S NAME:",
                            last:""+customer.name,
                            size:paper_size,
                            max1:14,
                            max2:paper_size-14
                          }) 
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:t4
                          })  
                      }
                      if (customer.phone_number) {
                          const t5 = await epson_line({
                            first:"BUYER'S PHONE:",
                            last:""+customer.phone_number,
                            size:paper_size,
                            max1:14,
                            max2:paper_size-14
                          }) 
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:t5
                          })  
                      }
                      if (waiter_name) {
                          const t6 = await epson_line({
                            first:"WAITER: ",
                            last:""+waiter_name,
                            size:paper_size,
                            max1:14,
                            max2:paper_size-14
                        })
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:t6 
                          })  
                      }
                      if (table_name) {
                          const t7 = await epson_line({
                            first:"TABLE: ",
                            last:""+table_name,
                            size:paper_size,
                            max1:14,
                            max2:paper_size-14
                          })
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:t7 
                          })  
                      }
                      
                  } 
                  print_order.lines.push({ text:" "}) 
                  
              }
              
              //ITEMS
              let items = await dispatch("MERGE_DUPLICATED_ITEMS",{
                  merge_doc_type:merge_doc_type,
                  items:sale_order.joined_items?sale_order.joined_items:sale_order.items
              })
 
              let item_discounts = 0
              if (items) { 
                  let index = 0
                  items.forEach(async item => {
                      index=index+1
                      // let selected_item_option = item?.selected_item_option
                      let option_name = item?.selected_item_option_names
                      let item_name = item.name?item.name:item.item_name
                      if(!item_name){
                          item_name = "--"
                      }
                      let name =receipt_show_item_numbering?index+". "+item_name:item_name
                      if (option_name) {
                          name = name+" ("+option_name+")"
                      }


                      let quantity = toNumber(item.quantity) 
                      let discount = toNumber(item.discount) *quantity
                      item_discounts = item_discounts+discount
                      let sold_price = toNumber(item.sold_price)
                      let total_sold_price = sold_price*quantity
                      let sold_price_ = toMoney(sold_price)
                      let total_sold_price_ = toMoney(total_sold_price)

                      let item_tax = item.tax 
                      let item_tax_group_code = item.tax_group_code 
                      let usable_tax_group = item_tax_group_code?item_tax_group_code:item_tax?"A":"B"

                      let last = total_sold_price_+" "+usable_tax_group
                      let mid = "QTY:"+quantity+" X "+sold_price_
                      let first = name+"  "+mid
                      let first_size = SIZE(first)
                      let mid_size = SIZE(mid)
                      let max2 = SIZE(last)
                      let max1 = paper_size-max2-3 

                      if (first_size>max1) {
                        let number1 = name.slice(0,max1)
                        let number2 = name.slice((max1))
                        const t8 = await epson_line({
                          first:number1,
                          last:last,
                          size:paper_size,
                          max1:max1,
                          max2:max2
                        }) 
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:t8
                          })  

                          let number3 = number2?number2+" "+mid:mid
                          const t9 = await epson_line({
                            first:number3,
                            last:"",
                            size:paper_size,
                            max1:paper_size-1,
                            max2:1
                          })
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text: t9
                          })  
                      } else {
                          const t10 = await epson_line({
                            first:first,
                            last:last,
                            size:paper_size,
                            max1:max1,
                            max2:max2
                        }) 
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:t10
                          }) 
                            
                      }

                      
                  });  
              }

              //CARD LOADING
              let selected_card_option = sale_order?sale_order.selected_card_option:null
              if (selected_card_option=="load_money") {
                  let selected_card = sale_order.selected_card
                  let card_balance = selected_card?toMoney(selected_card.balance_amount):null
                  let card_code = selected_card?selected_card.code:null
                  let card_number = showCard(card_code)
                  let card_load_amount = toMoney(sale_order.card_load_amount) 
                  let new_balance = toMoney(toNumber(card_balance)+toNumber(card_load_amount))
                  sale_order.total_sold_price = card_load_amount
                  
                  print_order.lines.push({ 
                      text:' '
                  })
                  // print_order.lines.push({
                  //     align:"center",
                  //     bold:false,
                  //     value:"",
                  //     text:'CARD LOADING'
                  // }) 
                  t11 = await epson_line({
                    first:"CARD NUMBER",
                    last:card_number,
                    size:paper_size,
                }) 
                  print_order.lines.push({
                      align:"left",
                      bold:false,
                      value:"",
                      text:t11
                  }) 
                  const t12 = await epson_line({
                    first:"B4 BALANCE:",
                    last:card_balance,
                    size:paper_size,
                }) 
                  print_order.lines.push({
                      align:"left",
                      bold:false,
                      value:"",
                      text:t12 
                  }) 
                  const t13 = await epson_line({
                    first:"LOADED AMOUNT:",
                    last:card_load_amount,
                    size:paper_size,
                  }) 
                  print_order.lines.push({
                      align:"left",
                      bold:false,
                      value:"",
                      text:t13
                  }) 
                  const t14 = await epson_line({
                    first:"NEW BALANCE:",
                    last:new_balance,
                    size:paper_size,
                  }) 
                  print_order.lines.push({
                      align:"left",
                      bold:false,
                      value:"",
                      text:t14
                  }) 
              }
                
              
              //TOTAL  
              if (true) { 
                  // 
                  let total_sold_price = toNumber(sale_order.total_sold_price )
                  let given_amount = toNumber(sale_order.given_amount)
                  let sum_amount = toNumber(sale_order.sum_amount?sale_order.sum_amount:sale_order.paid_amount)
                  let total_price = toNumber(sale_order.total_price)
                  let total_discount = toNumber(sale_order.total_discount)
                  let all_discounts = total_discount+item_discounts
                  let all_discounts_ = toMoney(all_discounts)
                  let change_amount = given_amount-sum_amount
                  let total_sold_price_ = toMoney(total_sold_price)
                  let given_amount_ = toMoney(given_amount)
                  let sum_amount_ = toMoney(sum_amount)
                  let change_amount_ = toMoney(change_amount)

                  let total_price_ = toMoney(total_price+item_discounts)
                  let total_discount_ = toMoney(total_discount)

                  if (all_discounts) { 
                      const t15 = await epson_line({
                        first:"SUB-TOTAL:", 
                        last:""+total_price_,
                        size:paper_size
                    })
                      print_order.lines.push({ value:"line"})
                      print_order.lines.push({
                          align:"left",
                          bold:true,
                          double:false,
                          value:"",
                          text:t15
                      }) 

                      const t16 = epson_line({
                        first:"DISCOUNT:", 
                        last:""+all_discounts_,
                        size:paper_size
                      })
                      print_order.lines.push({
                          align:"left",
                          bold:true,
                          double:false,
                          value:"",
                          text:t16
                      })  
                      // print_order.sub_total = epson_line({
                      //     first:"SUB-TOTAL:", 
                      //     last:""+total_price_,
                      //     size:paper_size
                      // })
                      // print_order.discount = epson_line({
                      //     first:"DISCOUNT:", 
                      //     last:""+all_discounts_,
                      //     size:paper_size
                      // })
                  }

                  const t17 = await epson_line({
                    first:"TOTAL:", 
                    last:""+total_sold_price_,
                    size:paper_size/2
                  }) 
                  print_order.lines.push({ value:"line"})
                  print_order.lines.push({
                      align:"left",
                      bold:true,
                      double:true,
                      value:"",
                      text:t17
                  })   
                  
                  if (order_type!="bill_sale_order") {
                      let account_type = sale_order.account_type
                      let payment_option_key = sale_order.payment_option_key
                      if (account_type == "multiple") {
                          let payment_method = sale_order?sale_order.payment_method:null
                          let joined_input_payments = payment_method?payment_method.joined_input_payments:null
                          let payments_size = SIZE(joined_input_payments)
                          if (receipt_show_change_amount) {
                              if (joined_input_payments) {
                                  joined_input_payments.forEach(async element => {

                                      const t18 = await epson_line({
                                        first:""+element.payment_method_name, 
                                        last:"AM:"+toMoney(element.given_amount)+"  TR:"+(
                                            element.description?element.description:
                                            element.payment_description?element.payment_description:'..'),
                                        size:paper_size
                                      }) 
                                      print_order.lines.push({
                                          align:"left",
                                          bold:false,
                                          value:"",
                                          text: t18
                                      })   
                                        
                                  });
                              }

                              if (payments_size>1) {
                                  const t19 = await epson_line({
                                    first:payments_size>1?"T. PAID:":"CASH", 
                                    last:""+given_amount_,
                                    size:paper_size
                                  })
                                  print_order.lines.push({
                                      align:"left",
                                      bold:false,
                                      value:"",
                                      text:t19 
                                  })   
                              } 
                              const t20 = await epson_line({
                                first:"CHANGE:", 
                                last:""+change_amount_,
                                size:paper_size
                              }) 
                              print_order.lines.push({
                                  align:"left",
                                  bold:false,
                                  value:"",
                                  text:t20
                              })   
                          } 
                      }else if (account_type == "cash" || payment_option_key == "cash") {
                          if (receipt_show_change_amount) {
                              const t21 = await epson_line({
                                first:"CASH:", 
                                last:""+given_amount_,
                                size:paper_size
                              }) 
                              print_order.lines.push({
                                  align:"left",
                                  bold:false,
                                  value:"",
                                  text:t21
                              })   
                              const t22 = await epson_line({
                                first:"CHANGE:", 
                                last:""+change_amount_,
                                size:paper_size
                              }) 
                              print_order.lines.push({
                                  align:"left",
                                  bold:false,
                                  value:"",
                                  text:t22
                              })   
                          } 
                      } else if(account_type == "on_account") {
                          let receivable_name = sale_order?sale_order.receivable_name:null
                          let transaction_id = sale_order?sale_order.transaction_id:null
                          if (receivable_name) {
                              const t23 = await epson_line({
                                first:"PAYMENT:", 
                                last:"On Account",
                                size:paper_size
                              }) 
                              print_order.lines.push({
                                  align:"left",
                                  bold:false,
                                  value:"",
                                  text:t23
                              })   
                              const t24 = await epson_line({
                                first:"ACCOUNT:", 
                                last:""+receivable_name,
                                size:paper_size
                              })
                              print_order.lines.push({
                                  align:"left",
                                  bold:false,
                                  value:"",
                                  text:t24 
                              })   
                          }
                      } else {
                          let payment_method_name = sale_order?sale_order.payment_method_name:null
                          let transaction_id = sale_order?sale_order.transaction_id:null
                          if (payment_method_name) {
                              const t25 =await epson_line({
                                first:"PAYMENT:", 
                                last:""+payment_method_name,
                                size:paper_size
                              }) 
                              print_order.lines.push({
                                  align:"left",
                                  bold:false,
                                  value:"",
                                  text:t25
                              })   
                              const t26 =await epson_line({
                                first:"TRANSACTION ID:", 
                                last:""+transaction_id?transaction_id:'--',
                                size:paper_size
                            }) 
                              print_order.lines.push({
                                  align:"left",
                                  bold:false,
                                  value:"",
                                  text:t26
                              })   
                          }
                      } 
                  }
                  

                  //show VAT
                  if (receipt_show_vat) { 
                      let taxable_a = '0.00'
                      let vat_a = '0.00'
                      let taxable_b = '0.00'
                      let vat_b = '0.00'
                      let total_vat = '0.00'

                      const t27 = await epson_line({
                        first:"TAXABLE A-16.5% ", 
                        last:""+taxable_a,
                        size:paper_size
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:true,
                          value:"",
                          text:t27
                      }) 
                      const t28 = await epson_line({
                        first:"VAT A-16.5% ", 
                        last:""+vat_a,
                        size:paper_size
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:true,
                          value:"",
                          text: t28
                      }) 
                      const t29 = await epson_line({
                        first:"TAXABLE B-16% ", 
                        last:""+taxable_b,
                        size:paper_size
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:true,
                          value:"",
                          text:t29
                      })
                      const t30 = await epson_line({
                        first:"VAT B-16% ", 
                        last:""+vat_b,
                        size:paper_size
                      })  
                      print_order.lines.push({
                          align:"left",
                          bold:true,
                          value:"",
                          text:t30
                      }) 

                      const t31 = await epson_line({
                        first:"TOTAL VAT", 
                        last:""+total_vat,
                        size:paper_size
                      })
                      print_order.lines.push({
                          align:"left",
                          bold:true,
                          value:"",
                          text:t31 
                      })      
                  }
              }  

              //BARCODE
              if (receipt_show_barcode && receipt_key) {
                  let prefix = order_type?TEXT_UP(order_type.slice(0,2)):""

                  print_order.lines.push({
                      align:"center",
                      bold:true, 
                      barcode:true,
                      text:prefix+''+toSameLength(receipt_key,5)
                  })  
              }
              
              //DATE AND TIME
              if (true) {
                print_order.date = sale_order.local_created_at_date
                print_order.time = sale_order.local_created_at_time
                const t32 = await epson_line({
                  first:"DATE:"+print_order.date, 
                  last:"TIME:"+print_order.time,
                  size:paper_size
                })
                  print_order.lines.push({
                      align:"left",
                      bold:false,
                      value:"",
                      text:t32 
                  })   
              }

              //POWERED BY
              if (!disable_kipcount_watermark) { 
                  print_order.lines.push({ value:"line"})
                  print_order.lines.push({
                      align:"center",
                      bold:true, 
                      text:'***POWERED BY KIPCOUNT***'
                  }) 
                  print_order.lines.push({
                      align:"center",
                      bold:true, 
                      text:'***+265993259202***'
                  }) 
                  print_order.lines.push({ value:"line"}) 
              } 

              // return print_order 
              resolve({
                  success:true,
                  data:print_order,
              }) 
          } catch (error) { 
              response = {
                  ...response,
                  error:error,
                  success:false
              } 
              commit("ERROR",response)
              reject(response) 
          }  
      })
      },  
      async EPSON_DOCKET_REQUEST({commit,dispatch,getters}, payload){ 
        return new Promise(async (resolve,reject)=>{
            let response = {
                from:"EPSON_DOCKET_REQUEST",
                page:NAME,
                payload:payload
            } 
            try {    
                let merge_doc_type = payload?payload.merge_doc_type:null
                let sale_order = payload?payload.sale_order:null
                let docket_version = sale_order?sale_order.docket_version:null
                let day_shift = sale_order?sale_order.day_shift:null
                let order_type = sale_order?sale_order.order_type:null
                let bill_sale_order = order_type=="bill_sale_order"?true:false 
                let account_type = sale_order?sale_order.account_type:null
                if(!sale_order){
                    reject({...response,error:"sale_order required"})
                    return null
                }
                let cashup_option = day_shift?day_shift.cashup_option:null
                let cashups = day_shift?day_shift.joined_cashups:null
                let running_cashups = cashups?cashups.filter(c=>!c.closed):null
                let running_cashups_size = SIZE(running_cashups)
                let isRunning = running_cashups_size?true:false
                let isClosed = day_shift?day_shift.closed:null

                //USED TILLS
                let used_floating_cash = 0
                let used_till_keys = []
                if (cashups) {
                    cashups.forEach(element => {
                        let index = used_till_keys.indexOf(element.till_key)
                        if (index==-1) {
                            used_floating_cash = used_floating_cash+toNumber(element.floating_cash)
                            used_till_keys.push(element.till_key)
                        }
                    });
                }

                let tills = day_shift?day_shift.joined_tills?day_shift.joined_tills:day_shift.tills:null
                let total_cashups = await dispatch("CALCULATE_TOTAL_CASHUPS",cashups,day_shift)  
                let report_type = payload?payload.report_type:null
                let print_title = payload?payload.print_title:null
                let printer = payload?payload.printer?payload.printer :{}:{}

                const default_paper_size = 32
                const default_printer_name = "POS80"
                let paper_size = 0
                let printer_name = ""
                let space_value = '.'  
                
                
                let docket_print_order = []
                let joined_items_departments = sale_order?.joined_items_departments
                let joined_items_dockets = sale_order?.joined_items_dockets

                let docket = null
                if(docket_version == "docket_all_updates"){

                }else if(docket_version == "docket_first_update"){
                    docket = joined_items_dockets?joined_items_dockets[0]:null
                }else if(docket_version == "docket_last_update"){
                    docket = joined_items_dockets?joined_items_dockets[joined_items_dockets.length-1]:null
                }else{

                } 
                let update_key = docket?.update_key 
                if (joined_items_departments) {
                    joined_items_departments.forEach(async department => {
                        // return if tip
                        if (department.key == "tip") {
                            return
                        }
                        let joined_items = await dispatch("MERGE_DUPLICATED_ITEMS",{
                            items:department.joined_items,
                            merge_doc_type:merge_doc_type
                        })
                        let department_name = department.name
                        let print_order = {}
    
                        //SETTINGS
                        let settings = items_store.state[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()]
                        let receipt_show_company_info = settings?settings.receipt_show_company_info:null
                        let receipt_show_company_tpin = settings?settings.receipt_show_company_tpin:null
                        let receipt_show_receipt_title = settings?settings.receipt_show_receipt_title:null
                        let receipt_show_item_numbering = settings?settings.receipt_show_item_numbering:null
                        let receipt_show_barcode = settings?settings.receipt_show_barcode:null
                        let receipt_show_till = settings?settings.receipt_show_till:null
                        let receipt_show_buyer_info = settings?settings.receipt_show_buyer_info:null
                        let receipt_show_change_amount = settings?settings.receipt_show_change_amount:null
                        let receipt_show_vat = settings?settings.receipt_show_vat:null
                        //template
                        let template_settings = settings?settings.template_settings:null
                        let receipt_settings = template_settings?template_settings.receipt:null
                        let disable_kipcount_watermark = receipt_settings?receipt_settings.disable_kipcount_watermark:null
    
                        //PRINTER INFO
                        paper_size = printer.paper_size?printer.paper_size:default_paper_size
                        printer_name = printer.printer_name?printer.printer_name:default_printer_name
                        print_order.paper_size = paper_size
                        print_order.printer_name = printer_name 
                        print_order.lines = [] 
            
                    
                        //TOP HEADER
                        if (receipt_show_receipt_title) {
                            // print_title = print_title?print_title:report_type?report_type:"SALES REPORT"
                            print_title = "DOCKET"
                            print_order.lines.push({
                                align:"center",
                                bold:false,
                                value:'top_header',
                                text:"*** "+department_name+" "+print_title+" ***"
                            })
                            // print_order.lines.push({ text:" "})
                        }
     
                        //COMPANY INFO
                        const company = sale_order?.company
                        const outlet = sale_order?.outlet
                        const c_company_name = company?.company_name
                        const c_phone_number_cell = company?.company_phone_number
                        const c_phone_number_tell = company?.company_phone_number2
                        const c_post_address = company?.company_post_address
                        const c_email_address = company?.company_email_address
                        const c_tpin = company?.company_tpin 
                        const c_location_address = company?.company_location_address 
                        
                        const receipt_company_name = outlet?.receipt_company_name
                        const receipt_phone_number_cell = outlet?.receipt_phone_number_cell
                        const receipt_phone_number_tell = outlet?.receipt_phone_number_tell
                        const receipt_post_address = outlet?.receipt_post_address
                        const receipt_email_address = outlet?.receipt_email_address
                        const receipt_tpin = outlet?.receipt_tpin
                        const receipt_address = outlet?.receipt_address
                        
                        const company_name = receipt_company_name?receipt_company_name:c_company_name
                        const company_phone_number_cell = receipt_phone_number_cell?receipt_phone_number_cell:c_phone_number_cell
                        const company_phone_number_tell = receipt_phone_number_tell?receipt_phone_number_tell:c_phone_number_tell
                        const company_post_address = receipt_post_address?receipt_post_address:c_post_address
                        const company_email_address = receipt_email_address?receipt_email_address:c_email_address
                        const company_tpin = receipt_tpin?receipt_tpin:c_tpin
                        const company_address =  receipt_address?receipt_address:c_location_address
                        if (company && receipt_show_company_info) { 
                            print_order.lines.push({
                                align:"center",
                                bold:true, 
                                value:'company_name',
                                text:company_name 
                            })
                            // print_order.lines.push({
                            //     align:"center",
                            //     bold:false,
                            //     value:'company_post_address',
                            //     text:"Address: "+company.company_post_address
                            // })
                            // print_order.lines.push({
                            //     align:"center",
                            //     bold:false,
                            //     value:'company_email_address',
                            //     text:"Email: "+company.company_email_address
                            // }) 
                            // let phone_details = "(Cell): "+company.company_phone_number
                            // if (company.company_phone_number2) {
                            //     phone_details = phone_details +" (Tell): "+company.company_phone_number2 
                            //     if (paper_size<43) {
                            //         print_order.lines.push({
                            //             align:"center",
                            //             bold:false,
                            //             value:'company_phone_number',
                            //             text: "(Cell): "+company.company_phone_number
                            //         })
                            //         print_order.lines.push({
                            //             align:"center",
                            //             bold:false,
                            //             value:'company_phone_number',
                            //             text:"(Tell): "+company.company_phone_number2 
                            //         })
                            //     }
                            // }
                            // if (paper_size>42) { 
                            //     print_order.lines.push({
                            //         align:"center",
                            //         bold:false,
                            //         value:'company_phone_number',
                            //         text:phone_details
                            //     })  
                            // }  
                            // if (receipt_show_company_tpin) {  
                            //     print_order.lines.push({
                            //         align:"center",
                            //         bold:false,
                            //         value:'company_tpin',
                            //         text:"TPIN: "+company.company_tpin 
                            //     }) 
                            // } 
                            
                            let outlet = sale_order.outlet 
                            let teller = sale_order.teller 
                            let text_outlet =""
                            if (outlet) {  
                                text_outlet = "Outlet: "+outlet.name
                            } 
                            if (teller) {  
                                text_outlet = text_outlet+" Teller: "+teller.names 
                            } 
    
                            // if (text_outlet!="") {  
                            //     print_order.lines.push({
                            //         align:"center",
                            //         bold:false,
                            //         value:'company_tpin',
                            //         text:text_outlet
                            //     }) 
                            // }
    
                             
                        }
            
                        //DOWN HEADER
                        if (receipt_show_receipt_title) { 
                            // print_order.lines.push({ text:" "})
                            print_order.lines.push({
                                align:"center",
                                bold:false,
                                value:'top_header',
                                text:"******"
                            }) 
                            print_order.lines.push({ text:" "}) 
                        }
    
                        //DETAILS
                        let receipt_number = sale_order.order_key?sale_order.order_key:sale_order.order_id
                        let receipt_key = sale_order.key 
                        if (true) { 
                            // if (customer && receipt_show_buyer_info) {
                            if (true) {
                                let receipt_type = "BILL"
                                 
    
                                if (receipt_number) {
                                    const t1 = await dispatch("EPSON_1LINE",{
                                      first:receipt_type+" NUMBER:", 
                                      last:""+toSameLength(receipt_number,5),
                                      size:paper_size
                                    }) 
                                    print_order.lines.push({
                                        align:"left",
                                        bold:false,
                                        value:"",
                                        text:t1
                                    })   
                                }
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:await dispatch("EPSON_1LINE",{
                                        first:receipt_type+" KEY:", 
                                        last:""+toSameLength(receipt_key,5),
                                        size:paper_size
                                    }) 
                                })  
                                print_order.lines.push({
                                    align:"left",
                                    bold:false,
                                    value:"",
                                    text:await dispatch("EPSON_1LINE",{
                                        first:"DATE:", 
                                        last:""+sale_order.local_created_at_,
                                        size:paper_size
                                    }) 
                                })  
                            }
                            let customer = sale_order.customer 
                            let customer_name = sale_order.customer_name 
                            let table_name = sale_order.table_name 
                            let waiter_name = sale_order.waiter_name 
                            if (receipt_show_buyer_info) {
                                if (waiter_name) {
                                    print_order.lines.push({
                                        align:"left",
                                        bold:false,
                                        value:"",
                                        text:await dispatch("EPSON_1LINE",{
                                            first:"WAITER: ",
                                            last:""+waiter_name,
                                            size:paper_size,
                                            max1:14,
                                            max2:paper_size-14
                                        }) 
                                    })  
                                    
                                }if (table_name) {
                                    print_order.lines.push({
                                        align:"left",
                                        bold:false,
                                        value:"",
                                        text:await dispatch("EPSON_1LINE",{
                                            first:"TABLE: ",
                                            last:""+table_name,
                                            size:paper_size,
                                            max1:14,
                                            max2:paper_size-14
                                        }) 
                                    })  
                                    
                                }if (customer_name) {
                                    print_order.lines.push({
                                        align:"left",
                                        bold:false,
                                        value:"",
                                        text:await dispatch("EPSON_1LINE",{
                                            first:"CUSTOMER: ",
                                            last:""+customer_name,
                                            size:paper_size,
                                            max1:14,
                                            max2:paper_size-14
                                        }) 
                                    })  
                                    
                                }
                            }
                            print_order.lines.push({ text:" "}) 
                           
                        }
                        
                        //ITEMS
                        let items =joined_items?joined_items: sale_order.joined_items?sale_order.joined_items:sale_order.items
                        let items_size = SIZE(items)
                        let item_quantity = 0
                        let item_discounts = 0
                        let index = 0
                        
                        if (items ) { 
                            //---------------ITEM HEADER------------
                            print_order.lines.push({ value:"line"})
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:await dispatch("EPSON_1LINE",{
                                    first:"ITEMS",
                                    last:"QYT",
                                    size:paper_size,
                                    max1:14,
                                    max2:paper_size-14
                                }) 
                            }) 
                            
                            items.forEach(async item => {
                                let item_update_key = item?.update_key
                                let on_docket = false
                                if(!update_key){
                                    on_docket = true
                                }else if(item_update_key==update_key){
                                    on_docket = true
                                }else {
                                    on_docket = false
                                }
                                if (on_docket) {
                                    index=index+1 
                                    // let selected_item_option = item?.selected_item_option
                                    let option_name = item?.selected_item_option_names
                                    let item_name = item.name?item.name:item.item_name
                                    let name = receipt_show_item_numbering?index+". "+item_name:item_name
                                    if (option_name) {
                                        name = name+" ("+option_name+")"
                                    }

                                    let quantity = toNumber(item.quantity) 
                                    item_quantity=item_quantity+quantity
                                    let discount = toNumber(item.discount) *quantity
                                    item_discounts = item_discounts+discount
                                    let sold_price = toNumber(item.sold_price)
                                    let total_sold_price = sold_price*quantity
                                    let sold_price_ = toMoney(sold_price)
                                    let total_sold_price_ = toMoney(total_sold_price)
        
                                    let item_tax = item.tax 
                                    let item_tax_group_code = item.tax_group_code 
                                    let usable_tax_group = item_tax_group_code?item_tax_group_code:item_tax?"A":"B"
            
                                    // let last = total_sold_price_+" "+usable_tax_group
                                    // let mid = "QTY:"+quantity+" X "+sold_price_
                                    // let first = name+"  "+mid
                                    let last = quantity+" "+usable_tax_group
                                    let mid = ":" 
                                    let first = " "+name 
                                    let first_size = SIZE(first)
                                    let mid_size = SIZE(mid)
                                    let max2 = SIZE(last)
                                    let max1 = paper_size-max2-3 
        
                                    if (first_size>max1) {
                                        let number1 = name.slice(0,max1)
                                        let number2 = name.slice((max1))
                                        print_order.lines.push({
                                            align:"left",
                                            bold:false,
                                            value:"",
                                            text:await dispatch("EPSON_1LINE",{
                                                first:number1,
                                                last:last,
                                                size:paper_size,
                                                max1:max1,
                                                max2:max2
                                            }) 
                                        })  
        
                                        let number3 = number2?number2+" "+mid:mid
                                        print_order.lines.push({
                                            align:"left",
                                            bold:false,
                                            value:"",
                                            text:await dispatch("EPSON_1LINE",{
                                                first:number3,
                                                last:"",
                                                size:paper_size,
                                                max1:paper_size-1,
                                                max2:1
                                            }) 
                                        })  
                                    } else {
                                        print_order.lines.push({
                                            align:"left",
                                            bold:false,
                                            value:"",
                                            text:await dispatch("EPSON_1LINE",{
                                                first:first,
                                                last:last,
                                                size:paper_size,
                                                max1:max1,
                                                max2:max2
                                            }) 
                                        }) 
                                         
                                    }  
                                }
                                
                            });  
                        }
    
                        //CARD LOADING
                        let selected_card_option = sale_order?sale_order.selected_card_option:null
                        if (selected_card_option=="load_money") {
                            let selected_card = sale_order.selected_card
                            let card_balance = selected_card?toMoney(selected_card.balance_amount):null
                            let card_code = selected_card?selected_card.code:null
                            let card_number = showCard(card_code)
                            let card_load_amount = toMoney(sale_order.card_load_amount) 
                            let new_balance = toMoney(toNumber(card_balance)+toNumber(card_load_amount))
                            sale_order.total_sold_price = card_load_amount
                            
                            print_order.lines.push({ 
                                text:' '
                            })
                            // print_order.lines.push({
                            //     align:"center",
                            //     bold:false,
                            //     value:"",
                            //     text:'CARD LOADING'
                            // }) 
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:await dispatch("EPSON_1LINE",{
                                    first:"CARD NUMBER",
                                    last:card_number,
                                    size:paper_size,
                                }) 
                            }) 
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:await dispatch("EPSON_1LINE",{
                                    first:"B4 BALANCE:",
                                    last:card_balance,
                                    size:paper_size,
                                }) 
                            }) 
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:await dispatch("EPSON_1LINE",{
                                    first:"LOADED AMOUNT:",
                                    last:card_load_amount,
                                    size:paper_size,
                                }) 
                            }) 
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                value:"",
                                text:await dispatch("EPSON_1LINE",{
                                    first:"NEW BALANCE:",
                                    last:new_balance,
                                    size:paper_size,
                                }) 
                            }) 
                        }
                       
                        //TOTAL  
                        if (true) { 
                            //  
                            let total_sold_price = toNumber(sale_order.total_sold_price )
                            let given_amount = toNumber(sale_order.given_amount)
                            let sum_amount = toNumber(sale_order.sum_amount?sale_order.sum_amount:sale_order.paid_amount)
                            let total_price = toNumber(sale_order.total_price)
                            let total_discount = toNumber(sale_order.total_discount)
                            let all_discounts = total_discount+item_discounts
                            let all_discounts_ = toMoney(all_discounts)
                            let change_amount = given_amount-sum_amount
                            let total_sold_price_ = toMoney(total_sold_price)
                            let given_amount_ = toMoney(given_amount)
                            let sum_amount_ = toMoney(sum_amount)
                            let change_amount_ = toMoney(change_amount)
    
                            let total_price_ = toMoney(total_price+item_discounts)
                            let total_discount_ = toMoney(total_discount)
    
                           
                                 
    
                            print_order.lines.push({ value:"line"})
                            print_order.lines.push({
                                align:"left",
                                bold:true,
                                double:true,
                                value:"",
                                text:await dispatch("EPSON_1LINE",{
                                    first:"TOTAL ITEMS:", 
                                    last:""+items_size,
                                    size:paper_size/2
                                }) 
                            })  
                            print_order.lines.push({
                                align:"left",
                                bold:false,
                                double:false,
                                value:"",
                                text:await dispatch("EPSON_1LINE",{
                                    first:"TOTAL QYT:", 
                                    last:""+item_quantity,
                                    size:paper_size
                                }) 
                            })   
                             
                             
                        }  

                        //POWERED BY
                        if (!disable_kipcount_watermark) { 
                            print_order.lines.push({ value:"line"})
                            print_order.lines.push({
                                align:"center",
                                bold:false, 
                                text:'***POWERED BY KIPCOUNT.COM***'
                            }) 
                            print_order.lines.push({ value:" "}) 
                        } 

                        if (index) {
                            docket_print_order.push(print_order) 
                        }
                    }); 
                }

                
                // return print_order 
                resolve({
                    success:true,
                    data:docket_print_order,
                }) 
            } catch (error) { 
                response = {
                    ...response,
                    error:error,
                    success:false
                } 
                commit("ERROR",response)
                reject(response) 
            }  
        })
      },
      async EPSON_SALE_PAYMENT_REQUEST({commit,dispatch,getters},payload){ 
          return new Promise(async (resolve,reject)=>{
              let response = {
                  from:"EPSON_SALE_PAYMENT_REQUEST",
                  page:NAME,
                  payload:payload
              } 
              try {    
                  let sale_order = payload?payload.sale_order:null
                  let day_shift = sale_order?sale_order.day_shift:null
                  let cashup = sale_order?sale_order.cashup:null
                  
                  let receivable = sale_order?sale_order.receivable:null
                  let customer = sale_order?sale_order.customer:null
                  let till = sale_order?sale_order.till:null
                  let teller = sale_order?sale_order.teller:null
                  let payment_method = sale_order?sale_order.payment_method:null
                  let money_account = sale_order?sale_order.money_account:null
                  let order_type = sale_order?sale_order.order_type:null 
                  let invoices = sale_order?sale_order.joined_paid_invoices:null 
                  if(!sale_order){
                      reject({...response,error:"sale_order required"})
                      return null
                  }
                  


                  let print_title = payload?payload.print_title:null
                  let printer = payload?payload.printer?payload.printer :{}:{}

                  const default_paper_size = 32
                  const default_printer_name = "POS80"
                  let paper_size = 0
                  let printer_name = ""
                  let space_value = '.'   
                  let print_order = {}

                  //SETTINGS
                  let settings = items_store.state[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()]
                  let receipt_show_company_info = settings?settings.receipt_show_company_info:null
                  let receipt_show_company_tpin = settings?settings.receipt_show_company_tpin:null
                  let receipt_show_receipt_title = settings?settings.receipt_show_receipt_title:null
                  let receipt_show_item_numbering = settings?settings.receipt_show_item_numbering:null
                  let receipt_show_barcode = settings?settings.receipt_show_barcode:null
                  let receipt_show_till = settings?settings.receipt_show_till:null
                  let receipt_show_buyer_info = settings?settings.receipt_show_buyer_info:null
                  let receipt_show_change_amount = settings?settings.receipt_show_change_amount:null
                  let receipt_show_vat = settings?settings.receipt_show_vat:null
                  //template
                  let template_settings = settings?settings.template_settings:null
                  let receipt_settings = template_settings?template_settings.receipt:null
                  let disable_kipcount_watermark = receipt_settings?receipt_settings.disable_kipcount_watermark:null

                  //PRINTER INFO
                  paper_size = printer.paper_size?printer.paper_size:default_paper_size
                  printer_name = printer.printer_name?printer.printer_name:default_printer_name
                  print_order.paper_size = paper_size
                  print_order.printer_name = printer_name 
                  print_order.lines = [] 
      
              
                  //TOP HEADER
                  if (receipt_show_receipt_title) {
                      print_title = print_title?print_title:"PAYMENT"
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'top_header',
                          text:"*** "+print_title+" ***"
                      })
                      // print_order.lines.push({ text:" "})
                  }

                  //COMPANY INFO
                  const company = sale_order?.company
                  const outlet = sale_order?.outlet
                  const c_company_name = company?.company_name
                  const c_phone_number_cell = company?.company_phone_number
                  const c_phone_number_tell = company?.company_phone_number2
                  const c_post_address = company?.company_post_address
                  const c_email_address = company?.company_email_address
                  const c_tpin = company?.company_tpin 
                  const c_location_address = company?.company_location_address 
                  
                  const receipt_company_name = outlet?.receipt_company_name
                  const receipt_phone_number_cell = outlet?.receipt_phone_number_cell
                  const receipt_phone_number_tell = outlet?.receipt_phone_number_tell
                  const receipt_post_address = outlet?.receipt_post_address
                  const receipt_email_address = outlet?.receipt_email_address
                  const receipt_tpin = outlet?.receipt_tpin
                  const receipt_address = outlet?.receipt_address
                  
                  const company_name = receipt_company_name?receipt_company_name:c_company_name
                  const company_phone_number_cell = receipt_phone_number_cell?receipt_phone_number_cell:c_phone_number_cell
                  const company_phone_number_tell = receipt_phone_number_tell?receipt_phone_number_tell:c_phone_number_tell
                  const company_post_address = receipt_post_address?receipt_post_address:c_post_address
                  const company_email_address = receipt_email_address?receipt_email_address:c_email_address
                  const company_tpin = receipt_tpin?receipt_tpin:c_tpin
                  const company_address =  receipt_address?receipt_address:c_location_address
                  if (company && receipt_show_company_info) { 
                      print_order.lines.push({
                          align:"center",
                          bold:true, 
                          value:'company_name',
                          text:company_name 
                      })
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'company_post_address',
                          text:"Address: "+company_post_address
                      })
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'company_email_address',
                          text:"Email: "+company_email_address
                      }) 
                      let phone_details = "(Cell): "+company_phone_number_cell
                      if (company_phone_number_tell) {
                          phone_details = phone_details +" (Tell): "+company_phone_number_tell
                          if (paper_size<43) {
                              print_order.lines.push({
                                  align:"center",
                                  bold:false,
                                  value:'company_phone_number',
                                  text: "(Cell): "+company_phone_number_cell
                              })
                              print_order.lines.push({
                                  align:"center",
                                  bold:false,
                                  value:'company_phone_number',
                                  text:"(Tell): "+company_phone_number_tell
                              })
                          }
                      }
                      if (paper_size>42) { 
                          print_order.lines.push({
                              align:"center",
                              bold:false,
                              value:'company_phone_number',
                              text:phone_details
                          })  
                      }  
                      if (receipt_show_company_tpin) {  
                          print_order.lines.push({
                              align:"center",
                              bold:false,
                              value:'company_tpin',
                              text:"TPIN: "+company_tpin 
                          }) 
                      } 
                      
                      let outlet = sale_order.outlet 
                      let teller = sale_order.teller 
                      let text_outlet =""
                      if (outlet) {  
                          text_outlet = "Outlet: "+outlet.name
                      } 
                      if (teller) {  
                          text_outlet = text_outlet+" Teller: "+teller.names 
                      } 

                      if (text_outlet!="") {  
                          print_order.lines.push({
                              align:"center",
                              bold:false,
                              value:'company_tpin',
                              text:text_outlet
                          }) 
                      }

                      
                  }
      
                  //DOWN HEADER
                  if (receipt_show_receipt_title) { 
                      // print_order.lines.push({ text:" "})
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'top_header',
                          text:"******"
                      }) 
                      print_order.lines.push({ text:" "}) 
                  }

                  //DAY SHIFT DETAILS
                  let receipt_number = sale_order.payment_number?sale_order.payment_number:sale_order.code
                  let receipt_key = sale_order.key 
                  if (true) { 
                      if (true) {
                          let receipt_type = "RECEIPT" 
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:receipt_type+" NUMBER:", 
                                  last:""+toSameLength(receipt_number,5),
                                  size:paper_size
                              }) 
                          })  
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"DATE:", 
                                  last:""+sale_order.created_at_server_,
                                  size:paper_size
                              }) 
                          })   
                      }  
                      if (customer && receipt_show_buyer_info) {
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"Bill To:",
                                  last:""+sale_order.customer_name                                    ,
                                  size:paper_size,
                                  max1:14,
                                  max2:paper_size-14
                              }) 
                          }) 
                          if (customer.phone_number) {
                              print_order.lines.push({
                                  align:"left",
                                  bold:false,
                                  value:"",
                                  text:await dispatch("EPSON_1LINE",{
                                      first:"PHONE:",
                                      last:""+customer.phone_number,
                                      size:paper_size,
                                      max1:14,
                                      max2:paper_size-14
                                  }) 
                              }) 
                          }
                          
                      }
                      if (receivable) {
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"ACCOUNT:",
                                  last:""+receivable.name,
                                  size:paper_size,
                                  max1:14,
                                  max2:paper_size-14
                              }) 
                          })   
                      }
                      print_order.lines.push({ text:" "}) 
                  }
                  
                  //INVOICES
                  
                  if (invoices) { 
                      print_order.lines.push({
                          align:"left",
                          bold:true,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"INVOICE(S):", 
                              last:"AMOUNT",
                              size:paper_size
                          }) 
                      })   
                      
                      invoices.forEach(async invoice => { 
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:" No.:"+receipt_number, 
                                  last:""+toMoney(invoice.amount),
                                  size:paper_size
                              }) 
                          })   
                      }); 
                      
                      print_order.lines.push({
                          align:"left",
                          bold:true,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"DEPOSIT:", 
                              last:""+sale_order.invoice_paid_amount_                                ,
                              size:paper_size
                          }) 
                      })
                      print_order.lines.push({
                          align:"left",
                          bold:true,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"BALANCE:", 
                              last:""+sale_order.invoice_balance_amount_,
                              size:paper_size
                          }) 
                      })
                  }

            
                  
                
                  //TOTAL  
                  if (true) { 
                      
                      //    
                      print_order.lines.push({ value:"line"})
                      print_order.lines.push({
                          align:"left",
                          bold:true,
                          double:true,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"TOTAL PAID:", 
                              last:""+sale_order.amount_                                ,
                              size:paper_size/2
                          }) 
                      })   
                      
                      
                      if (payment_method) {
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"PAYMENT:", 
                                  last:""+payment_method.name,
                                  size:paper_size
                              }) 
                          })   
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"TRANSACTION ID:", 
                                  last:""+payment_method.transaction_id?payment_method.transaction_id:'..',
                                  size:paper_size
                              }) 
                          })   
                      }
                      
                  }  

                  //BARCODE
                  if (receipt_show_barcode && receipt_key) {
                      let prefix = "PA"

                      print_order.lines.push({
                          align:"center",
                          bold:true, 
                          barcode:true,
                          text:prefix+''+toSameLength(sale_order.day_shift_key+" "+sale_order.code,5)
                      })  
                  }
                  
                  //DATE AND TIME
                  if (true) {
                      print_order.date = sale_order.created_at_server_date
                      print_order.time = sale_order.created_at_server_time
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"DATE:"+print_order.date, 
                              last:"TIME:"+print_order.time,
                              size:paper_size
                          }) 
                      })   
                  }

                  //POWERED BY
                  if (!disable_kipcount_watermark) { 
                      print_order.lines.push({ value:"line"})
                      print_order.lines.push({
                          align:"center",
                          bold:true, 
                          text:'***POWERED BY KIPCOUNT***'
                      }) 
                      print_order.lines.push({
                          align:"center",
                          bold:true, 
                          text:'***+265993259202***'
                      }) 
                      print_order.lines.push({ value:"line"}) 
                  } 

                  // return print_order 
                  resolve({
                      success:true,
                      data:print_order,
                  }) 
              } catch (error) { 
                  response = {
                      ...response,
                      error:error,
                      success:false
                  } 
                  commit("ERROR",response)
                  reject(response) 
              }  
          })
      }, 
      async EPSON_CASHUP_REQUEST({commit,dispatch,getters},payload){ 
          return new Promise(async (resolve,reject)=>{
              try {  
                  let response = {
                      from:"EPSON_CASHUP_REQUEST",
                      // page:NAME,
                      payload:payload
                  } 
                  let cashup = payload?payload.cashup:null 
                  let day_shift = payload?payload.day_shift:null
                  let data = payload?payload.data:null
                  if (data&&!cashup) {
                      const res =  await dispatch("join_cashup",{data})
                      cashup =  res?.data
                  }
                  if (cashup&&!day_shift) { 
                      const res =  await dispatch("join_day_shift",{data:cashup.day_shift})
                      day_shift =  res?.data
                  }
                  
                  if (!cashup||!day_shift) { 
                      return {
                          ...response,
                          success:false,
                          error:'no payload'
                      }
                  } 
                  
                  let print_order = {}
                  let settings = items_store.state[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()]
                  let receipt_show_company_info = settings?settings.receipt_show_company_info:null
                  let receipt_show_company_tpin = settings?settings.receipt_show_company_tpin:null
                  let receipt_show_receipt_title = settings?settings.receipt_show_receipt_title:null
                  let receipt_show_item_numbering = settings?settings.receipt_show_item_numbering:null
                  let receipt_show_barcode = settings?settings.receipt_show_barcode:null
                  let receipt_show_till = settings?settings.receipt_show_till:null
                  let receipt_show_buyer_info = settings?settings.receipt_show_buyer_info:null
                  let receipt_show_change_amount = settings?settings.receipt_show_change_amount:null
                  let receipt_show_vat = settings?settings.receipt_show_vat:null
                  
                  //PRINTER INFO 
                  const default_paper_size = 32
                  const default_printer_name = "POS80"
                  let printer = payload.printer?payload.printer :{} 
                  let paper_size = printer.paper_size?printer.paper_size:default_paper_size
                  let printer_name = printer.printer_name?printer.printer_name:default_printer_name
                  print_order.paper_size = paper_size
                  print_order.printer_name = printer_name 
                  print_order.lines = []  
      
              
                  //TOP HEADER
                  if (true) {
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'top_header',
                          text:"*** CASHUP ***"
                      })
                  }
                  
                  //COMPANY INFO
                  const company = day_shift?.company
                  const outlet = day_shift?.outlet
                  const c_company_name = company?.company_name
                  const c_phone_number_cell = company?.company_phone_number
                  const c_phone_number_tell = company?.company_phone_number2
                  const c_post_address = company?.company_post_address
                  const c_email_address = company?.company_email_address
                  const c_tpin = company?.company_tpin 
                  const c_location_address = company?.company_location_address 
                  
                  const receipt_company_name = outlet?.receipt_company_name
                  const receipt_phone_number_cell = outlet?.receipt_phone_number_cell
                  const receipt_phone_number_tell = outlet?.receipt_phone_number_tell
                  const receipt_post_address = outlet?.receipt_post_address
                  const receipt_email_address = outlet?.receipt_email_address
                  const receipt_tpin = outlet?.receipt_tpin
                  const receipt_address = outlet?.receipt_address
                  
                  const company_name = receipt_company_name?receipt_company_name:c_company_name
                  const company_phone_number_cell = receipt_phone_number_cell?receipt_phone_number_cell:c_phone_number_cell
                  const company_phone_number_tell = receipt_phone_number_tell?receipt_phone_number_tell:c_phone_number_tell
                  const company_post_address = receipt_post_address?receipt_post_address:c_post_address
                  const company_email_address = receipt_email_address?receipt_email_address:c_email_address
                  const company_tpin = receipt_tpin?receipt_tpin:c_tpin
                  const company_address =  receipt_address?receipt_address:c_location_address
                  if (company) { 
                      print_order.lines.push({
                          align:"center",
                          bold:true, 
                          value:'company_name',
                          text:company_name 
                      })
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'company_post_address',
                          text:"Address: "+company_post_address
                      })
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'company_email_address',
                          text:"Email: "+company_email_address
                      }) 
                      let phone_details = "(Cell): "+company_phone_number_cell
                      if (company_phone_number_tell) {
                          phone_details = phone_details +" (Tell): "+company_phone_number_tell 
                          if (paper_size<43) {
                              print_order.lines.push({
                                  align:"center",
                                  bold:false,
                                  value:'company_phone_number_cell',
                                  text: "(Cell): "+company_phone_number_cell
                              })
                              print_order.lines.push({
                                  align:"center",
                                  bold:false,
                                  value:'company_phone_number_cell',
                                  text:"(Tell): "+company_phone_number_tell 
                              })
                          }
                      }
                      if (paper_size>42) { 
                          print_order.lines.push({
                              align:"center",
                              bold:false,
                              value:'company_phone_number',
                              text:phone_details
                          })  
                      }
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'company_tpin',
                          text:"TPIN: "+company_tpin 
                      })   
                  }
      
                  //DOWN HEADER
                  if (true) {
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'top_header',
                          text:"*******"
                      })
                  }
      
                  print_order.lines.push({
                      align:"left",
                      bold:false,
                      value:"cashup_key",
                      text:await dispatch("EPSON_1LINE",{
                          first:"CASHUP NUMBER:", 
                          last:cashup.key,
                          size:paper_size
                      })
      
                  }) 
                  print_order.lines.push({
                      align:"left",
                      bold:false,
                      value:"",
                      text:await dispatch("EPSON_1LINE",{
                          first:"OPERATING DATE:", 
                          last:""+cashup.operating_date_,  
                          size:paper_size
                      })
      
                  }) 
                  print_order.lines.push({
                      align:"left",
                      bold:false,
                      value:"cashup_key",
                      text:await dispatch("EPSON_1LINE",{
                          first:"START:"+cashup.open_time_, 
                          last:"CLOSE:"+cashup.close_time_,  
                          size:paper_size
                      })
      
                  })  
                  if (cashup.teller) {
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"teller_name",
                          text:await dispatch("EPSON_1LINE",{
                              first:"TELLER NAME:", 
                              last:cashup.teller_name,
                              size:paper_size
                          })
      
                      }) 
                  }
                  if (cashup.till_name) {
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"till_name",
                          text:await dispatch("EPSON_1LINE",{
                              first:"Till:", 
                              last:cashup.till_name,
                              size:paper_size
                          })
      
                      }) 
                  }
      
                  //summary
                  if (true) {
                      // print_order.lines.push({ 
                      //     value:"line",  
                      // }) 
                      // print_order.lines.push({
                      //     align:"center",
                      //     bold:false,
                      //     value:'',
                      //     text:"SUMMARY"
                      // })
                      
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"CASH SALES:", 
                              last:toMoney(cashup.sales_cash_payments),
                              size:paper_size
                          })
      
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"OTHER SALES:", 
                              last:toMoney(cashup.sales_total_other_option_payments),
                              size:paper_size
                          })
      
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"CASH INVOICE(S):", 
                              last:toMoney(cashup.invoices_cash_payments),
                              size:paper_size
                          })
      
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"OTHER INVOICE(S):", 
                              last:toMoney(cashup.invoices_total_other_option_payments),
                              size:paper_size
                          })
      
                      }) 
                      // print_order.lines.push({
                      //     align:"left",
                      //     bold:false,
                      //     value:"",
                      //     text:await dispatch("EPSON_1LINE",{
                      //         first:"CASH CARD LOAD:", 
                      //         last:toMoney(cashup.invoices_cash_payments),
                      //         size:paper_size
                      //     })
      
                      // }) 
                      // print_order.lines.push({
                      //     align:"left",
                      //     bold:false,
                      //     value:"",
                      //     text:await dispatch("EPSON_1LINE",{
                      //         first:"OTHER CARD LOAD:", 
                      //         last:toMoney(cashup.invoices_total_other_option_payments),
                      //         size:paper_size
                      //     })
      
                      // }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"ON ACCOUNT:", 
                              last:toMoney(cashup.sales_on_account),
                              size:paper_size
                          })
      
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:true, 
                          double:false, 
                          text:await dispatch("EPSON_1LINE",{
                              first:"TOTAL SALES:", 
                              last:toMoney(toNumber(cashup.sales_retail)+toNumber(cashup.sales_wholesales)),
                              size:paper_size
                          })
      
                      }) 
                  }
                  
                  //OPENING BALANCE
                  let opening_currencies = cashup.opening_currencies?cashup.opening_currencies:null 
                  if (opening_currencies) {
                      print_order.lines.push({ 
                          text:" ",   
                      })
                      let first = await dispatch("EPSON_1LINE",{
                          first:"NOTES(CASH) ", 
                          last:"QTY(NO.)",
                          size:paper_size/1.8
                      })
                      print_order.lines.push({
                          align:"left",
                          bold:true, 
                          text:await dispatch("EPSON_1LINE",{
                              first:first, 
                              last:"AMOUNT(MWK)",
                              size:paper_size
                          })
      
                      }) 
                      opening_currencies.forEach(async currency => {
                          let total =  toNumber(currency.value)*toNumber(currency.quantity) 
                          let quantity = await dispatch("EPSON_1LINE",{
                              first:currency.name+' ', 
                              last:' '+toNumber(currency.quantity),
                              size:paper_size/2,
                              space_value:'-'
                          })
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:quantity+' ', 
                                  last:' '+toMoney(total),
                                  size:paper_size,
                                  space_value:'-'
                              })
          
                          }) 
                          
                      }); 
                      print_order.lines.push({
                          align:"left",
                          bold:true,  
                          text:await dispatch("EPSON_1LINE",{
                              first:"OPENING BALANCE",
                              last:cashup.opening_cash_,
                              size:paper_size
                          })
      
                      }) 
                  }
                  //CLOSING CASH
                  let closing_currencies = cashup.closing_currencies?cashup.closing_currencies:null 
                  if (closing_currencies) {
                      print_order.lines.push({ 
                          text:"",  
                      }) 
                      closing_currencies.forEach(async currency => {
                          let total =  toNumber(currency.value)*toNumber(currency.quantity) 
                          let quantity = await dispatch("EPSON_1LINE",{
                              first:currency.name+' ', 
                              last:' '+toNumber(currency.quantity),
                              size:paper_size/2,
                              space_value:'-'
                          })
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:quantity+' ', 
                                  last:' '+toMoney(total),
                                  size:paper_size,
                                  space_value:'-'
                              })
          
                          }) 
                          
                      });
                      print_order.lines.push({
                          align:"left",
                          bold:true,  
                          text:await dispatch("EPSON_1LINE",{
                              first:"CLOSING BALANCE",
                              last:cashup.closing_cash_,
                              size:paper_size
                          })
      
                      }) 
                  }
                  
                  
                  //SHORTAGES
                  if (false) {
                      // print_order.lines.push({ 
                      //     value:"doted_line",  
                      // }) 
                      print_order.lines.push({ 
                          text:" ",   
                      }) 
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'shortages',
                          text:"SHORTAGE(S) / OVERAGE(S)"
                      })
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"shortage",
                          text:await dispatch("EPSON_1LINE",{
                              first:"Shortage:", 
                              last:'0.00',
                              size:paper_size
                          })
      
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"shortage",
                          text:await dispatch("EPSON_1LINE",{
                              first:"Overage:", 
                              last:'0.00',
                              size:paper_size
                          })
      
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false, 
                          text:await dispatch("EPSON_1LINE",{
                              first:"TOTAL:", 
                              last:'0.00',
                              size:paper_size
                          })
      
                      }) 
                  }
                  
                  //totals
                  if (true) {
                      let closed = cashup.closed
                      print_order.lines.push({ 
                          text:" ",  
                      }) 
                      print_order.lines.push({ 
                          value:"line",  
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:true, 
                          double:false, 
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"EXPECTED:", 
                              last:toMoney(cashup.expected_cash),
                              size:paper_size
                          })
      
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:true, 
                          double:false, 
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"TOTAL CASH:", 
                              last:toMoney(toNumber(cashup.closing_cash)),
                              size:paper_size
                          })
      
                      }) 
                      let shortage = toNumber(cashup.closing_cash)-toNumber(cashup.expected_cash)
                      let isOverage = shortage>0
                      print_order.lines.push({
                          align:"left",
                          bold:true, 
                          double:false, 
                          text:await dispatch("EPSON_1LINE",{
                              first:isOverage?"OVERAGE: ":'SHORTAGE: ', 
                              last:!closed?"Running...":isOverage?toMoney(shortage):"("+toMoney(shortage*-1)+")",
                              size:paper_size
                          })
      
                      }) 
                      let closing_money = toNumber(cashup.closing_cash)-toNumber(cashup.opening_cash)
                      print_order.lines.push({
                          align:"left",
                          bold:true, 
                          double:false, 
                          text:await dispatch("EPSON_1LINE",{
                              first:"CLOSING CASH:", 
                              last:!closed?"Running...":toMoney(closing_money<0?0:closing_money),
                              size:paper_size
                          })
      
                      })  
                  } 

                  // powered by 
                  if (true) {
                      print_order.lines.push({ 
                          text:" ",  
                      }) 
                      print_order.lines.push({ 
                          value:"line",  
                      }) 
                      print_order.lines.push({ 
                          align:"center",
                          text:"*** POWERED BY KIPCOUNT ***",  
                      }) 
                      print_order.lines.push({ 
                          align:"center",
                          text:"*** +265 993 259 202 ***",  
                      }) 
                      print_order.lines.push({ 
                          value:"line",  
                      })  
                  }
                  // return print_order 
                  resolve({
                      success:true,
                      data:print_order,
                  }) 
              } catch (error) { 
                  response = {
                      ...response,
                      error:error,
                      success:false
                  } 
                  commit("ERROR",response)
                  reject(response) 
              }  
          })
      },
      async EPSON_DAY_SHIFT_REQUEST({commit,dispatch,getters},payload){ 
          return new Promise(async (resolve,reject)=>{
              let response = {
                  from:"EPSON_DAY_SHIFT_REQUEST",
                  page:NAME,
                  payload:payload
              } 
              try {   
                  let day_shift = payload?payload.day_shift:null
                  if(!day_shift){
                    reject({success:false,error:"dayshift required",payload})
                    return 
                  }
                  let cashup_option = day_shift?day_shift.cashup_option:null
                  let cashups = day_shift?day_shift.joined_cashups:null
                  let running_cashups = cashups?cashups.filter(c=>!c.closed):null
                  let running_cashups_size = SIZE(running_cashups)
                  let isRunning = running_cashups_size?true:false
                  let isClosed = day_shift?day_shift.closed:null

                  //USED TILLS
                  let used_floating_cash = 0
                  let used_till_keys = []
                  if (cashups) {
                      cashups?.forEach(element => {
                          let index = used_till_keys.indexOf(element.till_key)
                          if (index==-1) {
                              used_floating_cash = used_floating_cash+toNumber(element.floating_cash)
                              used_till_keys.push(element.till_key)
                          }
                      });
                  }

                  let tills = day_shift?day_shift.joined_tills?day_shift.joined_tills:day_shift.tills:null
                  let total_cashups = await dispatch("CALCULATE_TOTAL_CASHUPS",cashups,day_shift)  
                  let report_type = payload?payload.report_type:null
                  let printer = payload?payload.printer?payload.printer :{}:{}

                  console.log(total_cashups,'total_cashups*******************');
                  

                  const default_paper_size = 32
                  const default_printer_name = "POS80"
                  let paper_size = 0
                  let printer_name = ""
                  let space_value = '.'   
                  let print_order = {}

                  //SETTINGS
                  let settings = items_store.state[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()]
                  let receipt_show_company_info = settings?settings.receipt_show_company_info:null
                  let receipt_show_company_tpin = settings?settings.receipt_show_company_tpin:null
                  let receipt_show_receipt_title = settings?settings.receipt_show_receipt_title:null
                  let receipt_show_item_numbering = settings?settings.receipt_show_item_numbering:null
                  let receipt_show_barcode = settings?settings.receipt_show_barcode:null
                  let receipt_show_till = settings?settings.receipt_show_till:null
                  let receipt_show_buyer_info = settings?settings.receipt_show_buyer_info:null
                  let receipt_show_change_amount = settings?settings.receipt_show_change_amount:null
                  let receipt_show_vat = settings?settings.receipt_show_vat:null

                  //PRINTER INFO
                  paper_size = printer.paper_size?printer.paper_size:default_paper_size
                  printer_name = printer.printer_name?printer.printer_name:default_printer_name
                  print_order.paper_size = paper_size
                  print_order.printer_name = printer_name 
                  print_order.lines = [] 
      
              
                  //TOP HEADER
                  if (true) {
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'top_header',
                          text:"*** DAY SHIFT ***"
                      })
                  }

                  
                  //COMPANY INFO
                  const company = day_shift?.company
                  const outlet = day_shift?.outlet
                  console.log(day_shift,'day_shift.....');
                  console.log(outlet,'outlet.....');
                  console.log(company,'company.....');
                  const c_company_name = company?.company_name
                  const c_phone_number_cell = company?.company_phone_number
                  const c_phone_number_tell = company?.company_phone_number2
                  const c_post_address = company?.company_post_address
                  const c_email_address = company?.company_email_address
                  const c_tpin = company?.company_tpin 
                  const c_location_address = company?.company_location_address 
                  
                  const receipt_company_name = outlet?.receipt_company_name
                  const receipt_phone_number_cell = outlet?.receipt_phone_number_cell
                  const receipt_phone_number_tell = outlet?.receipt_phone_number_tell
                  const receipt_post_address = outlet?.receipt_post_address
                  const receipt_email_address = outlet?.receipt_email_address
                  const receipt_tpin = outlet?.receipt_tpin
                  const receipt_address = outlet?.receipt_address
                  
                  const company_name = receipt_company_name?receipt_company_name:c_company_name
                  const company_phone_number_cell = receipt_phone_number_cell?receipt_phone_number_cell:c_phone_number_cell
                  const company_phone_number_tell = receipt_phone_number_tell?receipt_phone_number_tell:c_phone_number_tell
                  const company_post_address = receipt_post_address?receipt_post_address:c_post_address
                  const company_email_address = receipt_email_address?receipt_email_address:c_email_address
                  const company_tpin = receipt_tpin?receipt_tpin:c_tpin
                  const company_address =  receipt_address?receipt_address:c_location_address
                  if (company) { 
                      print_order.lines.push({
                          align:"center",
                          bold:true, 
                          value:'company_name',
                          text:company_name 
                      })
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'company_post_address',
                          text:"Address: "+company_post_address
                      })
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'company_email_address',
                          text:"Email: "+company_email_address
                      }) 
                      let phone_details = "(Cell): "+company_phone_number_cell
                      if (company_phone_number_tell) {
                          phone_details = phone_details +" (Tell): "+company_phone_number_tell 
                          if (paper_size<43) {
                              print_order.lines.push({
                                  align:"center",
                                  bold:false,
                                  value:'company_phone_number_cell',
                                  text: "(Cell): "+company_phone_number_cell
                              })
                              print_order.lines.push({
                                  align:"center",
                                  bold:false,
                                  value:'company_phone_number_cell',
                                  text:"(Tell): "+company_phone_number_tell 
                              })
                          }
                      }
                      if (paper_size>42) { 
                          print_order.lines.push({
                              align:"center",
                              bold:false,
                              value:'company_phone_number_cell',
                              text:phone_details
                          })  
                      }
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'company_tpin',
                          text:"TPIN: "+company_tpin 
                      })   
                  }
      
                  //DOWN HEADER
                  if (report_type) {
                      print_order.lines.push({text:" "})
                      print_order.lines.push({value:"line"})
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'top_header',
                          text:"**** "+report_type+" ****"
                      })
                      print_order.lines.push({value:"line"})
                      print_order.lines.push({text:" "})
                  }

                  //DAY SHIFT DETAILS
                  if (true) {
                      // print_order.lines.push({ text:' '})  
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"DAY:", 
                              last:""+day_shift.operating_date_,  
                              size:paper_size
                          }) 
                      }) 
                      
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"OPEN BY:", 
                              last:""+day_shift.user_name,  
                              size:paper_size
                          })
          
                      })
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"OPEN AT:", 
                              last:""+day_shift.local_created_at_,  
                              size:paper_size
                          })
          
                      })

                      if (report_type=="X-READ") {
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"PRINTED AT:", 
                                  last:""+day_shift.local_updated_at_,  
                                  size:paper_size
                              })
              
                          }) 
                      }else if (isClosed) {
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"CLOSE AT:", 
                                  last:""+day_shift.local_updated_at_,  
                                  size:paper_size
                              })
              
                          }) 
                      }else{
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"CLOSE AT:", 
                                  last:" [Running...]",  
                                  size:paper_size
                              })
              
                          }) 
                      }
                      if (tills) { 
                          let till_names = '' 
                          tills?.forEach((till,index) => {
                              if (index == 0) {
                                  till_names = till_names+till.name 
                              }else{
                                  till_names = till_names+", "+till.name 
                              }
                          });
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"TILLS:", 
                                  last:"["+till_names+"]",  
                                  size:paper_size
                              })
              
                          })  
                      }
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"TOTAL FLOATING CASH:", 
                              last:""+day_shift.total_floating_cash_,  
                              size:paper_size
                          })
          
                      }) 
                  }
                  
                  //CASHUPS
                  if (cashups) {
                      print_order.lines.push({ text:" "})
                      print_order.lines.push({
                          align:"center",
                          bold:false, 
                          text:"CASHUPS(S) / REGISTER(S)"
                      })

                      cashups?.forEach(async cashup => {
                          let cashup_closed = cashup.closed 
                          let opening_cash = toNumber(cashup.opening_cash )
                          let expected_opening_cash = cashup_option =='submit_cash_after_every_cashup'? 
                              toNumber(cashup.floating_cash ):
                              toNumber(cashup.expected_opening_cash )
                          let isOpenShortage = opening_cash!=expected_opening_cash?true:false
                          let opening_shortage = opening_cash-expected_opening_cash 
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"TELLER: ", 
                                  last:" "+cashup.teller_name,  
                                  size:paper_size,
                                  space_value:space_value
                              }) 
                          }) 
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"TILL: ", 
                                  last:" "+cashup.till_name,  
                                  size:paper_size,
                                  space_value:space_value
                              }) 
                          }) 
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"OPEN AT: ", 
                                  last:" "+day_shift.local_created_at_,  
                                  size:paper_size,
                                  space_value:space_value
                              }) 
                          })  
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"CLOSE AT: ", 
                                  last:" "+(cashup_closed?cashup.close_date_time_:'['+cashup.close_date_time_+']'),  
                                  size:paper_size,
                                  space_value:space_value
                              }) 
                          }) 
                          
                          
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"OPENING BALANCE: ", 
                                  last:" "+cashup.opening_cash_,  
                                  size:paper_size,
                                  space_value:space_value
                              }) 
                          }) 
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"CASH SALES: ", 
                                  last:" "+toMoney(cashup.sales_cash_payments),  
                                  size:paper_size,
                                  space_value:space_value
                              }) 
                          })  
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"OTHER SALES: ", 
                                  last:" "+toMoney(cashup.sales_total_other_option_payments),  
                                  size:paper_size,
                                  space_value:space_value
                              }) 
                          }) 
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"ON ACCOUNT: ", 
                                  last:" "+toMoney(cashup.sales_on_account),  
                                  size:paper_size,
                                  space_value:space_value
                              }) 
                          }) 

                          let total_invoice = toNumber(cashup.invoices_cash_payments)
                              +toNumber(cashup.invoices_total_other_option_payments)
                          
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"INVOICE PAYMENTS: ", 
                                  last:" "+toMoney(total_invoice),  
                                  size:paper_size,
                                  space_value:space_value
                              }) 
                          })
                          
                          if (!cashup.closed) {
                              print_order.lines.push({
                                  align:"left",
                                  bold:false,
                                  value:"",
                                  text:await dispatch("EPSON_1LINE",{
                                      first:"CURRENT SALES: ", 
                                      last:" "+cashup.total_sales_,  
                                      size:paper_size,
                                      space_value:space_value
                                  }) 
                              })
                          }else {
                              print_order.lines.push({
                                  align:"left",
                                  bold:false,
                                  value:"",
                                  text:await dispatch("EPSON_1LINE",{
                                      first:"TOTAL SALES: ", 
                                      last:" "+cashup.total_sales_,  
                                      size:paper_size,
                                      space_value:space_value
                                  }) 
                              }) 
                              print_order.lines.push({
                                  align:"left",
                                  bold:false,
                                  value:"",
                                  text:await dispatch("EPSON_1LINE",{
                                      first:"CLOSING BALANCE: ", 
                                      last:" "+cashup.closing_cash_,  
                                      size:paper_size,
                                      space_value:space_value
                                  }) 
                              })  
                          }
                          if (isOpenShortage) {
                              if (opening_cash>expected_opening_cash) {
                                  print_order.lines.push({
                                      align:"left",
                                      bold:true,
                                      value:"",
                                      text:await dispatch("EPSON_1LINE",{
                                          first:"OPEN [OVER..]: ", 
                                          last:" "+toMoney(opening_shortage),  
                                          size:paper_size,
                                          space_value:space_value
                                      }) 
                                  }) 
                              } else {
                                  print_order.lines.push({
                                      align:"left",
                                      bold:true,
                                      value:"",
                                      text:await dispatch("EPSON_1LINE",{
                                          first:"OPEN [SHORT..]: ", 
                                          last:" ("+toMoney(opening_shortage*-1)+")",  
                                          size:paper_size,
                                          space_value:space_value
                                      }) 
                                  }) 
                              } 
                          }

                          if (cashup.shortage) {
                              print_order.lines.push({
                                  align:"left",
                                  bold:true,
                                  value:"",
                                  text:await dispatch("EPSON_1LINE",{
                                      first:cashup.isShortage?"CLOSE [SHORT..]: ":"CLOSE [OVER..]: ", 
                                      last:cashup.isShortage?cashup.shortage_:cashup.shortage_,  
                                      size:paper_size,
                                      space_value:space_value
                                  }) 
                              })   
                          }
                          print_order.lines.push({ text:" "})
                      });
                      
                      
                  }
                  
                  //PAYMENT OPTIONS
                  if (total_cashups) {
                      print_order.lines.push({ text:" "})
                      print_order.lines.push({ value:"line"})
                      print_order.lines.push({
                          align:"center",
                          bold:false, 
                          text:"PAYMENT(S)"
                      })

                      total_cashups?.all_other_option_payments?.forEach(async payment => { 
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:payment.payment_method_name, 
                                  last:toMoney(payment.amount),  
                                  size:paper_size
                              }) 
                          }) 
                          
                      });
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"TOTAL:", 
                              last:toMoney(
                                  toNumber(total_cashups.sales_total_other_option_payments)+
                                  toNumber(total_cashups.invoices_total_other_option_payments)),  
                              size:paper_size
                          }) 
                      }) 
                      print_order.lines.push({ text:" "})
                      
                      
                  }
                  
                  //SHORTAGES
                  if (total_cashups) {
                      print_order.lines.push({ text:" "})
                      print_order.lines.push({ value:"line"})
                      print_order.lines.push({
                          align:"center",
                          bold:false, 
                          text:"SHORTAGE(S) / OVERAGE(S)"
                      })

                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"OPENING SHORTAGES: ", 
                              // last:" ("+toMoney(total_cashups.total_open_shortages*-1)+")",  
                              last:" "+toMoney(total_cashups.total_open_shortages*-1),  
                              size:paper_size
                          }) 
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"OPENING OVERAGES: ", 
                              last:" "+toMoney(total_cashups.total_open_overages),  
                              size:paper_size
                          }) 
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"CLOSING SHORTAGES: ", 
                              // last:" ("+toMoney(total_cashups.total_close_shortages*-1)+")",  
                              last:" "+toMoney(total_cashups.total_close_shortages*-1),  
                              size:paper_size
                          }) 
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"CLOSING OVERAGES: ", 
                              last:" "+total_cashups.total_close_overages_,  
                              size:paper_size
                          }) 
                      })  
                      
                      print_order.lines.push({
                          align:"left",
                          bold:true,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"TOTAL: ", 
                              last:" "+(
                                  total_cashups.total_is_shortage?"("+
                                  toMoney(total_cashups.total_shortage*-1)+")":
                                  toMoney(total_cashups.total_shortage)
                              ),  
                              size:paper_size
                          }) 
                      }) 
                      print_order.lines.push({ text:" "})
                  }

                  //CASHUPS SUMMARY
                  if (total_cashups) {
                      print_order.lines.push({ text:" "})
                      print_order.lines.push({ value:"line"})
                      print_order.lines.push({
                          align:"center",
                          bold:false, 
                          text:"CASHUPS(S) SUMMARY"
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"RETAIL:", 
                              last:toMoney(total_cashups.sales_retail),  
                              size:paper_size
                          }) 
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"WHOLESALE:", 
                              last:toMoney(total_cashups.sales_wholesales),  
                              size:paper_size
                          }) 
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"CASH SALES:", 
                              last:toMoney(total_cashups.sales_cash_payments),  
                              size:paper_size
                          }) 
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"OTHER SALES:", 
                              last:toMoney(total_cashups.sales_total_other_option_payments),  
                              size:paper_size
                          }) 
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"ON ACCOUNT:", 
                              last:toMoney(total_cashups.sales_on_account),  
                              size:paper_size
                          }) 
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"CASH INVOICE:", 
                              last:toMoney(total_cashups.invoices_cash_payments),  
                              size:paper_size
                          }) 
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"OTHER INVOICE:", 
                              last:toMoney(total_cashups.invoices_total_other_option_payments),  
                              size:paper_size
                          }) 
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"TOTAL SALES:", 
                              last:toMoney(total_cashups.total_sales),  
                              size:paper_size
                          }) 
                      })  
                  }
                  
                  //TOTAL SUMMARY
                  if (report_type != "X-READ" && total_cashups) { 
                      print_order.lines.push({ text:" "}) 
                      print_order.lines.push({ value:"line"})
                      let closing_cash = toNumber(total_cashups.closing_cashes) 
                            + toNumber(total_cashups.total_open_shortage)
                            - toNumber(total_cashups.opening_cashes)
                      
                      if (isRunning) {
                          print_order.lines.push({
                              align:"left",
                              bold:true,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"CURRENT EXPECTED:", 
                                  last:""+total_cashups.total_cash_,  
                                  size:paper_size
                              }) 
                          }) 
                          print_order.lines.push({
                              align:"left",
                              bold:true,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"CURRENT TOTAL:", 
                                  last:" "+toMoney(closing_cash+used_floating_cash),  
                                  size:paper_size
                              }) 
                          }) 
                          
                          print_order.lines.push({
                              align:"left",
                              bold:true,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"CLOSING:", 
                                  last:" [Waiting...]",  
                                  size:paper_size
                              }) 
                          }) 
                      }else{
                          print_order.lines.push({
                              align:"left",
                              bold:true,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"EXPECTED:", 
                                  last:""+total_cashups.total_cash_,  
                                  size:paper_size
                              }) 
                          }) 
                          
                          print_order.lines.push({
                              align:"left",
                              bold:true,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"TOTAL CASH:", 
                                  last:""+toMoney(closing_cash+used_floating_cash),   
                                  size:paper_size
                              }) 
                          }) 
                          print_order.lines.push({
                              align:"left",
                              bold:true,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"USED TILL(S) FLOAT:", 
                                  last:""+toMoney(used_floating_cash),  
                                  size:paper_size
                              }) 
                          })
                          
                          print_order.lines.push({
                              align:"left",
                              bold:true,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"CLOSING:", 
                                  last:" "+toMoney(closing_cash),  
                                  size:paper_size
                              }) 
                          })     
                      }
                  } 

                  //POWERED BY
                  if (true) {
                      print_order.lines.push({ text:" "}) 
                      print_order.lines.push({ text:" "}) 
                      print_order.lines.push({ value:"line"})
                      print_order.lines.push({
                          align:"center",
                          bold:true, 
                          text:'POWERED BY KIPCOUNT'
                      }) 
                      print_order.lines.push({
                          align:"center",
                          bold:true, 
                          text:'+265993259202'
                      }) 
                  } 
                  // return print_order 
                  resolve({
                      success:true,
                      data:print_order,
                  }) 
              } catch (error) { 
                  response = {
                      ...response,
                      error:error,
                      success:false
                  } 
                  commit("ERROR",response)
                  reject(response) 
              }  
          })
      }, 
      async EPSON_DAY_ITEM_REQUEST({commit, dispatch},payload){ 
          return new Promise(async (resolve,reject)=>{
              let response = {
                  from:"EPSON_DAY_ITEM_REQUEST",
                  page:NAME,
                  payload:payload
              } 
              try {   
                  const day_shift = payload?.data?.day_shift  
                  const sale_orders = payload?.data?.sale_orders  
                  let aggregated_items = payload?.data?.aggregated_items 
                  if (!aggregated_items) {
                      aggregated_items = await dispatch("CALCULATE_SALES_AGGREGATED_ITEMS",{
                          sale_orders:sale_orders
                      })
                      
                  } 
                  if(!day_shift){
                      reject({error:'no dayshift'})
                      return null}
                  let cashup_option = day_shift?day_shift.cashup_option:null
                  let cashups = day_shift?day_shift.joined_cashups:null
                  let running_cashups = cashups?cashups.filter(c=>!c.closed):null
                  let running_cashups_size = SIZE(running_cashups)
                  let isRunning = running_cashups_size?true:false
                  let isClosed = day_shift?day_shift.closed:null

                  //USED TILLS
                  let used_floating_cash = 0
                  let used_till_keys = []
                  if (cashups) {
                      cashups?.forEach(element => {
                          let index = used_till_keys.indexOf(element.till_key)
                          if (index==-1) {
                              used_floating_cash = used_floating_cash+toNumber(element.floating_cash)
                              used_till_keys.push(element.till_key)
                          }
                      });
                  }

                  let tills = day_shift?day_shift.joined_tills?day_shift.joined_tills:day_shift.tills:null
                  let total_cashups = await dispatch("CALCULATE_TOTAL_CASHUPS",cashups,day_shift)  
                  let report_type = payload?payload.report_type:null
                  let printer = payload?payload.printer?payload.printer :{}:{}

                  const default_paper_size = 32
                  const default_printer_name = "POS80"
                  let paper_size = 0
                  let printer_name = ""
                  let space_value = '.'   
                  let print_order = {}

                  //SETTINGS
                  let settings = items_store.state[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()]
                  let receipt_show_company_info = settings?settings.receipt_show_company_info:null
                  let receipt_show_company_tpin = settings?settings.receipt_show_company_tpin:null
                  let receipt_show_receipt_title = settings?settings.receipt_show_receipt_title:null
                  let receipt_show_item_numbering = settings?settings.receipt_show_item_numbering:null
                  let receipt_show_barcode = settings?settings.receipt_show_barcode:null
                  let receipt_show_till = settings?settings.receipt_show_till:null
                  let receipt_show_buyer_info = settings?settings.receipt_show_buyer_info:null
                  let receipt_show_change_amount = settings?settings.receipt_show_change_amount:null
                  let receipt_show_vat = settings?settings.receipt_show_vat:null

                  //PRINTER INFO
                  paper_size = printer.paper_size?printer.paper_size:default_paper_size
                  printer_name = printer.printer_name?printer.printer_name:default_printer_name
                  print_order.paper_size = paper_size
                  print_order.printer_name = printer_name 
                  print_order.lines = [] 
      
              
                  //TOP HEADER
                  if (true) {
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'top_header',
                          text:"*** DAY SOLD ITEMS ***"
                      })
                  }

                  
                  //COMPANY INFO
                  const company = day_shift?.company
                  const outlet = day_shift?.outlet
                  console.log(day_shift,'day_shift.....');
                  console.log(outlet,'outlet.....');
                  console.log(company,'company.....');
                  const c_company_name = company?.company_name
                  const c_phone_number_cell = company?.company_phone_number
                  const c_phone_number_tell = company?.company_phone_number2
                  const c_post_address = company?.company_post_address
                  const c_email_address = company?.company_email_address
                  const c_tpin = company?.company_tpin 
                  const c_location_address = company?.company_location_address 
                  
                  const receipt_company_name = outlet?.receipt_company_name
                  const receipt_phone_number_cell = outlet?.receipt_phone_number_cell
                  const receipt_phone_number_tell = outlet?.receipt_phone_number_tell
                  const receipt_post_address = outlet?.receipt_post_address
                  const receipt_email_address = outlet?.receipt_email_address
                  const receipt_tpin = outlet?.receipt_tpin
                  const receipt_address = outlet?.receipt_address
                  
                  const company_name = receipt_company_name?receipt_company_name:c_company_name
                  const company_phone_number_cell = receipt_phone_number_cell?receipt_phone_number_cell:c_phone_number_cell
                  const company_phone_number_tell = receipt_phone_number_tell?receipt_phone_number_tell:c_phone_number_tell
                  const company_post_address = receipt_post_address?receipt_post_address:c_post_address
                  const company_email_address = receipt_email_address?receipt_email_address:c_email_address
                  const company_tpin = receipt_tpin?receipt_tpin:c_tpin
                  const company_address =  receipt_address?receipt_address:c_location_address
                  if (company) { 
                      print_order.lines.push({
                          align:"center",
                          bold:true, 
                          value:'company_name',
                          text:company_name 
                      })
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'company_post_address',
                          text:"Address: "+company_post_address
                      })
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'company_email_address',
                          text:"Email: "+company_email_address
                      }) 
                      let phone_details = "(Cell): "+company_phone_number_cell
                      if (company_phone_number_tell) {
                          phone_details = phone_details +" (Tell): "+company_phone_number_tell 
                          if (paper_size<43) {
                              print_order.lines.push({
                                  align:"center",
                                  bold:false,
                                  value:'company_phone_number_cell',
                                  text: "(Cell): "+company_phone_number_cell
                              })
                              print_order.lines.push({
                                  align:"center",
                                  bold:false,
                                  value:'company_phone_number_cell',
                                  text:"(Tell): "+company_phone_number_tell 
                              })
                          }
                      }
                      if (paper_size>42) { 
                          print_order.lines.push({
                              align:"center",
                              bold:false,
                              value:'company_phone_number_cell',
                              text:phone_details
                          })  
                      }
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'company_tpin',
                          text:"TPIN: "+company_tpin 
                      })   
                  }
      
                  //DOWN HEADER
                  if (report_type) {
                      print_order.lines.push({text:" "})
                      print_order.lines.push({value:"line"})
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'top_header',
                          text:"**** "+report_type+" ****"
                      })
                      print_order.lines.push({value:"line"})
                      print_order.lines.push({text:" "})
                  }

                  //DAY SHIFT DETAILS
                  if (true) {
                      // print_order.lines.push({ text:' '})  
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"DAY:", 
                              last:""+day_shift.operating_date_,  
                              size:paper_size
                          }) 
                      }) 
                      
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"OPEN BY:", 
                              last:""+day_shift.user_name,  
                              size:paper_size
                          })
          
                      })
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"OPEN AT:", 
                              last:""+day_shift.local_created_at_,  
                              size:paper_size
                          })
          
                      })

                      if (report_type=="X-READ") {
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"PRINTED AT:", 
                                  last:""+day_shift.local_updated_at_,  
                                  size:paper_size
                              })
              
                          }) 
                      }else if (isClosed) {
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"CLOSE AT:", 
                                  last:""+day_shift.local_updated_at_,  
                                  size:paper_size
                              })
              
                          }) 
                      }else{
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"CLOSE AT:", 
                                  last:" [Running...]",  
                                  size:paper_size
                              })
              
                          }) 
                      }
                      if (tills) { 
                          let till_names = '' 
                          tills?.forEach((till,index) => {
                              if (index == 0) {
                                  till_names = till_names+till.name 
                              }else{
                                  till_names = till_names+", "+till.name 
                              }
                          });
                          print_order.lines.push({
                              align:"left",
                              bold:false,
                              value:"",
                              text:await dispatch("EPSON_1LINE",{
                                  first:"TILLS:", 
                                  last:"["+till_names+"]",  
                                  size:paper_size
                              })
              
                          })  
                      }
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"TOTAL FLOATING CASH:", 
                              last:""+day_shift.total_floating_cash_,  
                              size:paper_size
                          })
          
                      }) 
                  }
                  
                  //CASHUPS
                  let items = aggregated_items
                  let items_size = SIZE(items)
                  let item_quantity = 0
                  let item_discounts = 0
                  let index = 0
                  if (items) {
                      print_order.lines.push({ text:" "})
                      print_order.lines.push({
                          align:"center",
                          bold:false, 
                          text:"AGGREGATED SOLD ITEM(S)"
                      })
                      print_order.lines.push({ text:" "})

                      
                      items?.forEach(async item => {
                          index=index+1 
                          let item_name = item?.name?item?.name:item?.item_name
                          let option_name = item?.selected_item_option_names 
                          let name = receipt_show_item_numbering?index+". "+item_name:item_name
                          if (option_name) {
                              name = name+" ("+option_name+")"
                          }

                          let quantity = toNumber(item.sold_quantity) 
                          item_quantity=item_quantity+quantity
                          let discount = toNumber(item.sold_discount) *quantity
                          item_discounts = item_discounts+discount
                          let sold_price = toNumber(item.sold_price)
                          let total_sold_price = sold_price*quantity
                          let sold_price_ = toMoney(sold_price)
                          let total_sold_price_ = toMoney(total_sold_price)

                          let item_tax = item.tax 
                          let item_tax_group_code = item.tax_group_code 
                          let usable_tax_group = item_tax_group_code?item_tax_group_code:item_tax?"A":"B"

                          // let last = total_sold_price_+" "+usable_tax_group
                          // let mid = "QTY:"+quantity+" X "+sold_price_
                          // let first = name+"  "+mid
                          let last = quantity+" "+usable_tax_group
                          let mid = ":" 
                          let first = ""+name 
                          let first_size = SIZE(first)
                          let mid_size = SIZE(mid)
                          let max2 = SIZE(last)
                          let max1 = paper_size-max2-3 

                          if (first_size>max1) {
                              let number1 = name.slice(0,max1)
                              let number2 = name.slice((max1))
                              print_order.lines.push({
                                  align:"left",
                                  bold:false,
                                  value:"",
                                  text:await dispatch("EPSON_1LINE",{
                                      first:number1,
                                      last:last,
                                      size:paper_size,
                                      max1:max1,
                                      max2:max2
                                  }) 
                              })  

                              let number3 = number2?number2+" "+mid:mid
                              print_order.lines.push({
                                  align:"left",
                                  bold:false,
                                  value:"",
                                  text:await dispatch("EPSON_1LINE",{
                                      first:number3,
                                      last:"",
                                      size:paper_size,
                                      max1:paper_size-1,
                                      max2:1
                                  }) 
                              })  
                          } else {
                              print_order.lines.push({
                                  align:"left",
                                  bold:false,
                                  value:"",
                                  text:await dispatch("EPSON_1LINE",{
                                      first:first,
                                      last:last,
                                      size:paper_size,
                                      max1:max1,
                                      max2:max2
                                  }) 
                              }) 
                                  
                          }  
                      });  
                  } 

                  //CASHUPS SUMMARY
                  if (true) {
                      print_order.lines.push({ text:" "})
                      print_order.lines.push({ value:"line"})
                      print_order.lines.push({
                          align:"center",
                          bold:false, 
                          text:"SALES SUMMARY"
                      })  
                      
                      
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:" ALL ITEMS QTY:", 
                              last:toMoney(item_quantity),  
                              size:paper_size
                          }) 
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"CASH SALES:", 
                              // last:toMoney(total_cashups.sales_cash_payments),  
                              last:'---',
                              size:paper_size
                          }) 
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"OTHER SALES:", 
                              // last:toMoney(total_cashups.sales_total_other_option_payments),  
                              last:'---',
                              size:paper_size
                          }) 
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"ON ACCOUNT:", 
                              // last:toMoney(total_cashups.sales_on_account),  
                              last:'---',
                              size:paper_size
                          }) 
                      }) 
                      print_order.lines.push({
                          align:"left",
                          bold:false,
                          value:"",
                          text:await dispatch("EPSON_1LINE",{
                              first:"TOTAL SALES:", 
                              // last:toMoney(total_cashups.total_sales),  
                              last:'---',
                              size:paper_size
                          }) 
                      })  
                  }
                
                  //POWERED BY
                  if (true) {
                      print_order.lines.push({ text:" "}) 
                      print_order.lines.push({ text:" "}) 
                      print_order.lines.push({ value:"line"})
                      print_order.lines.push({
                          align:"center",
                          bold:true, 
                          text:'POWERED BY KIPCOUNT'
                      }) 
                      print_order.lines.push({
                          align:"center",
                          bold:true, 
                          text:'+265993259202'
                      }) 
                  } 
                  // return print_order 
                  resolve({
                      success:true,
                      data:print_order,
                  }) 
              } catch (error) { 
                  response = {
                      ...response,
                      error:error,
                      success:false
                  } 
                  commit("ERROR",response)
                  reject(response) 
              }  
          })
      }, 
      async EPSON_ORDER_ITEMS_REQUEST({commit,dispatch},payload){ 
          return new Promise(async (resolve,reject)=>{
              let response = {
                  from:"EPSON_ORDER_ITEMS_REQUEST",
                  page:NAME,
                  payload:payload
              } 
              try {    
                  const order = payload?payload.order:null
                  const print_title = payload?payload.print_title:null
                  const aggregated_items = await dispatch("CALCULATE_SALES_AGGREGATED_ITEMS",{
                      sale_orders:order
                  })
                  
                  let printer = payload?payload.printer?payload.printer :{}:{}

                  const default_paper_size = 48
                  const default_printer_name = "POS80"
                  let paper_size = 0
                  let printer_name = ""
                  let space_value = '.'   
                  let print_order = {}
                  
                  //SETTINGS
                  let settings = items_store.state[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()]
                  let receipt_show_company_info = settings?settings.receipt_show_company_info:null
                  let receipt_show_company_tpin = settings?settings.receipt_show_company_tpin:null
                  let receipt_show_receipt_title = settings?settings.receipt_show_receipt_title:null
                  let receipt_show_item_numbering = settings?settings.receipt_show_item_numbering:null
                  let receipt_show_barcode = settings?settings.receipt_show_barcode:null
                  let receipt_show_till = settings?settings.receipt_show_till:null
                  let receipt_show_buyer_info = settings?settings.receipt_show_buyer_info:null
                  let receipt_show_change_amount = settings?settings.receipt_show_change_amount:null
                  let receipt_show_vat = settings?settings.receipt_show_vat:null
                  //template
                  let template_settings = settings?settings.template_settings:null
                  let receipt_settings = template_settings?template_settings.receipt:null
                  let disable_kipcount_watermark = receipt_settings?receipt_settings.disable_kipcount_watermark:null

                  //PRINTER INFO
                  paper_size = printer.paper_size?printer.paper_size:default_paper_size
                  printer_name = printer.printer_name?printer.printer_name:default_printer_name
                  print_order.paper_size = paper_size
                  print_order.printer_name = printer_name 
                  print_order.lines = [] 
      
              
                  //TOP HEADER
                  if (receipt_show_receipt_title) { 
                      print_title = print_title?print_title:"SOLD ITEMS"
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'top_header',
                          text:"*** "+print_title+" ***"
                      })
                      // print_order.lines.push({ text:" "})
                  }

                  //COMPANY INFO
                  let company = {}
                  if (company && receipt_show_company_info) { 
                      print_order.lines.push({
                          align:"center",
                          bold:true, 
                          value:'company_name',
                          text:company.company_name 
                      })
                      
                  }
      
                  //DOWN HEADER
                  if (receipt_show_receipt_title) { 
                      // print_order.lines.push({ text:" "})
                      print_order.lines.push({
                          align:"center",
                          bold:false,
                          value:'top_header',
                          text:"******"
                      }) 
                      print_order.lines.push({ text:" "}) 
                  }
          



                

                  // if (joined_items_departments) {
                  //     joined_items_departments.forEach(department => {
                  //         let joined_items = department.joined_items
                  //         let department_name = department.name
                            
                          //ITEMS
                          let items = aggregated_items
                          let items_size = SIZE(items)
                          let item_quantity = 0
                          let item_discounts = 0
                          let index = 0
                          
                          if (items ) { 
                              //---------------ITEM HEADER------------
                              print_order.lines.push({ value:"line"})
                              print_order.lines.push({
                                  align:"left",
                                  bold:false,
                                  value:"",
                                  text:await dispatch("EPSON_1LINE",{
                                      first:"ITEMS",
                                      last:"QYT",
                                      size:paper_size,
                                      max1:14,
                                      max2:paper_size-14
                                  }) 
                              }) 
                              
                              items.forEach(async item => {
                                  if (true) {
                                      index=index+1 
                                      let option_name = item?.selected_item_option_names
                                      let item_name = item.name?item.name:item.item_name
                                      let name = receipt_show_item_numbering?index+". "+item_name:item_name
                                      if (option_name) {
                                          name = name+" ("+option_name+")"
                                      }

                                      let quantity = toNumber(item.sold_quantity) 
                                      item_quantity=item_quantity+quantity
                                      let discount = toNumber(item.sold_discount) *quantity
                                      item_discounts = item_discounts+discount
                                      let sold_price = toNumber(item.sold_price)
                                      let total_sold_price = sold_price*quantity
                                      let sold_price_ = toMoney(sold_price)
                                      let total_sold_price_ = toMoney(total_sold_price)
          
                                      let item_tax = item.tax 
                                      let item_tax_group_code = item.tax_group_code 
                                      let usable_tax_group = item_tax_group_code?item_tax_group_code:item_tax?"A":"B"
              
                                      // let last = total_sold_price_+" "+usable_tax_group
                                      // let mid = "QTY:"+quantity+" X "+sold_price_
                                      // let first = name+"  "+mid
                                      let last = quantity+" "+usable_tax_group
                                      let mid = ":" 
                                      let first = ""+name 
                                      let first_size = SIZE(first)
                                      let mid_size = SIZE(mid)
                                      let max2 = SIZE(last)
                                      let max1 = paper_size-max2-3 
          
                                      if (first_size>max1) {
                                          let number1 = name.slice(0,max1)
                                          let number2 = name.slice((max1))
                                          print_order.lines.push({
                                              align:"left",
                                              bold:false,
                                              value:"",
                                              text:await dispatch("EPSON_1LINE",{
                                                  first:number1,
                                                  last:last,
                                                  size:paper_size,
                                                  max1:max1,
                                                  max2:max2
                                              }) 
                                          })  
          
                                          let number3 = number2?number2+" "+mid:mid
                                          print_order.lines.push({
                                              align:"left",
                                              bold:false,
                                              value:"",
                                              text:await dispatch("EPSON_1LINE",{
                                                  first:number3,
                                                  last:"",
                                                  size:paper_size,
                                                  max1:paper_size-1,
                                                  max2:1
                                              }) 
                                          })  
                                      } else {
                                          print_order.lines.push({
                                              align:"left",
                                              bold:false,
                                              value:"",
                                              text:await dispatch("EPSON_1LINE",{
                                                  first:first,
                                                  last:last,
                                                  size:paper_size,
                                                  max1:max1,
                                                  max2:max2
                                              }) 
                                          }) 
                                          
                                      }  
                                  }
                                  
                              });  
                          }
      
                        
                          //TOTAL  
                          if (true) {  
                              print_order.lines.push({ value:"line"})
                              print_order.lines.push({
                                  align:"left",
                                  bold:true,
                                  double:true,
                                  value:"",
                                  text:await dispatch("EPSON_1LINE",{
                                      first:"TOTAL ITEMS:", 
                                      last:""+items_size,
                                      size:paper_size/2
                                  }) 
                              })  
                              print_order.lines.push({
                                  align:"left",
                                  bold:false,
                                  double:false,
                                  value:"",
                                  text:await dispatch("EPSON_1LINE",{
                                      first:"TOTAL QYT:", 
                                      last:""+item_quantity,
                                      size:paper_size
                                  }) 
                              })   
                              
                              
                          }  

                          //POWERED BY
                          if (!disable_kipcount_watermark) { 
                              print_order.lines.push({ value:"line"})
                              print_order.lines.push({
                                  align:"center",
                                  bold:false, 
                                  text:'***POWERED BY KIPCOUNT.COM***'
                              }) 
                              print_order.lines.push({ value:" "}) 
                          } 

                  //     }); 
                  // }

                  
                  // return print_order 
                  resolve({
                      success:true,
                      data:print_order,
                  }) 
              } catch (error) { 
                  response = {
                      ...response,
                      error:error,
                      success:false
                  } 
                  commit("ERROR",response)
                  reject(response) 
              }  
          })
      },
    },
}