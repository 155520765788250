import users from '../users'   
import DATA from '../../plugins/DATA' 
import DB from '../../plugins/DB'  
import { EventBus } from '../../plugins/eventBus'  

import  { 
  toDateTime, 
  toDate,
  toTime,
  TEXT_UP,
  TEXT_UP1,
  TEXT_UP11,
  TEXT_UP0,
  TEXT_EQ,
  TEXT_SPLIT_N,
  TEXT_SPACE0, 
  DEFINED_ONLY
} from '../../plugins/MBS_actions' 
import formatNumber  from '../../plugins/formatNumber'   
import moment from 'moment'      
const toNumber=formatNumber.toNumber
const toMoney=formatNumber.toMoney
const toSameLength=formatNumber.toSameLength
  
let NAME = "STORE-LAN"

export default{
    state: {   
      LOCAL_DAYS_SHIFTS:null, 
      LOCAL_CASHUPS:null, 
      LOCAL_SALE_ORDERS:null, 
      LOCAL_ITEM_ORDERS:null, 
      
      DAYS_SHIFTS:null, 
      LAN_CASHUPS:null,  
      LAN_SALE_ORDERS:null,  
      LAN_ITEM_ORDERS:null,

      CUSTOMERS:null,   
      ITEMS:null,    
      LINK_LAN1:null,    
      LINK_LAN2:null,    
      EVENT:null,    
      ONLINE_LAN1:false,    
      ONLINE_LAN2:false,    
       
    },
    //___________________-GETTERS-_________________
    getters: {   
      getOnlineLanStatus(state) { 
        return state.ONLINE_LAN1
      },  


    },
    //___________________-MUTATIONS-___________________
    mutations:{  
      setLANs(state, payload) {
        try {
          let name = payload?payload.name:null
          let data = payload?payload.data:null 
          state[name]=data  
        } catch (error) {
          console.log(error,'error.....');
        }
      }, 
    },
    //____________________[ACTIONS]_____________________
    actions: {   
      
      //MANAGE SERVER
      lan_start({ commit,dispatch,getters}, payload) {
        let response = {
          from:'lan_start',
          payload,
          page:NAME, 
        }
        return new Promise((resolve,reject)=>{
          try { 
            const us = users?users.state.USER:null
            const uid = us?us.uid:null  
            const device = payload?.device
            const lan = payload?.lan
            let server = device?.server
            const server_code = device?.server_code 
            if (server_code && !server) {
              server = getters.getDeviceByCode(server_code)    
            }
            if (!server) {
              reject({success:false,error:'no server',payload}) 
            }
            const network_ip_address_link = server?.network_ip_address_link 
            const link = network_ip_address_link+"/api/"

            console.log(link,'*************START SERVER***********');
            
            if (network_ip_address_link) {
              const prams ={uid,link,device}
              const path = "Receipt/Connect"
              const http = this._vm.$http 
              http.post(link+path,{prams})
              .then(res=>{
                const body = res?.body 
                let data_details = { 
                  response:"success", 
                  res,body,link,lan,
                  data:body?.data
                } 
                dispatch("lan_link",{link,lan})
                // EventBus.START_NFC(link+DATA.APP.NFC_EVENT)
                if (lan==1) {
                  EventBus.START_LAN1(link+DATA.APP.LOCAL_DB_EVENT) 
                } else {
                  EventBus.START_LAN2(link+DATA.APP.LOCAL_DB_EVENT) 
                }
                resolve(data_details) 
              }).catch(error=>{
                // EventBus.CLOSE_NFC()
                if (lan==1) {
                  EventBus.CLOSE_LAN1() 
                } else {
                  EventBus.CLOSE_LAN2() 
                }
                dispatch("lan_link",{link:null,lan})
                const body = error?.body 
                let data_details = { 
                  ...response, 
                  error,body,lan,
                  data:body?.data
                }
                reject(data_details) 
              }) 
            } else {
              if (lan==1) {
                EventBus.CLOSE_LAN1() 
              } else {
                EventBus.CLOSE_LAN2() 
              }
              dispatch("lan_link",{data:null})
              reject({
                ...response,
                error:"No network address",
                network_ip_address_link,
              }) 
            } 

     
          } catch (error) {
            commit("ERROR",{
              response,
              error 
            })
          } 
        })
      },
      lan_status({ commit,state}, payload) {
        try { 
          const name = payload?.lan == 1?"ONLINE_LAN1":"ONLINE_LAN2"
          commit("setLANs",{name:name,data:payload?.data})
   
        } catch (error) {
          commit("ERROR",{
            error:{
              error:error,
              data:payload
            },
            from:'lan_status',
            page:NAME, 
          })
        }
      },
      lan_link({ commit,state}, payload) {
        try { 
          const lan = payload?.lan
          const link = payload?.link
          const name = lan==1?"LINK_LAN1":"LINK_LAN2"
          console.log(payload,'***************link*************************');
              
          commit("setLANs",{name,data:link})
   
        } catch (error) {
          commit("ERROR",{
            error:{
              error:error,
              data:payload
            },
            from:'lan_status',
            page:NAME, 
          })
        }
      },

      //MANAGE DATA
      lan_set({ commit,state}, payload) {
        return new Promise(async (resolve,reject)=>{
          let response = {
            from:'lan_set',
            page:NAME, 
            payload:payload
          }
          try { 
            const lan_created_at = moment().valueOf()
            let uid = payload?.uid
            const lan = payload?.lan
            const action = payload?.action
            const name = payload?.name
            const data = payload?.data
            const code = payload?.code
            const key = payload?.key
            const id = payload?.id
            const description = payload?.description
            const multiple = payload?.multiple
            let lan_link = payload?.lan_link
            if (!uid) {
              let us = users?users.state.USER:null
              uid = us?us.uid:null  
            }
            if(!lan_link){
              lan_link = lan==1?state.LINK_LAN1: state.LINK_LAN2
            } 
            if (!uid||!data) {
              commit('updateResponses', {
                res: 'lan_set/'+action+'/error',
                val: "field required"
              })
              reject({
                ...response,
                error:"field required"
              })
              return
            } 
            
            const prams = {
              uid,action,name,code,key,id,description,
              data:{...data,key:""+data.key,lan_created_at}
            }
            const http = this._vm.$http  
            http.post(lan_link+'Mongo/CreateItem',prams)
            .then(res=>{
              const body = res?.body 
              const data = body?.data 
              const is_array = data?Array.isArray(data):null
              let data_details = { 
                response:"success", 
                res,body,data,is_array
              }
              resolve(data_details) 
            }).catch(error=>{
              const body = error?.body 
              const data = body?.data 
              const is_array = data?Array.isArray(data):null
              let data_details = { 
                response:"error", 
                error,body,data,is_array
              }
              reject(data_details) 
            }) 
          } catch (error) {
            commit("ERROR",{
              error:{
                error:error,
                data:payload
              },
              from:'lan_set',
              page:NAME, 
            })
          } 
        })
      },  
      lan_update({ commit,state}, payload) {
        return new Promise(async (resolve,reject)=>{
          let response = {
            from:'lan_update',
            page:NAME, 
            payload:payload
          }
          try { 
            const lan_updated_at = moment().valueOf()
            let uid = payload?.uid
            const lan = payload?.lan
            const action = payload?.action
            const name = payload?.name
            const data = payload?.data
            const code = payload?.code
            const key = payload?.key
            const id = payload?.id
            const description = payload?.description
            const multiple = payload?.multiple
            let lan_link = payload?.lan_link
            if (!uid) {
              let us = users?users.state.USER:null
              uid = us?us.uid:null  
            }
            if(!lan_link){
              lan_link = lan==1?state.LINK_LAN1: state.LINK_LAN2
            } 
            if (!uid||!data) {
              commit('updateResponses', {
                res: 'lan_set/'+action+'/error',
                val: "field required"
              })
              reject({
                ...response,
                error:"field required"
              })
              return
            } 

            delete data._id
            
            const prams = {
              uid,action,name,code,key,id,description,
              data:{...data,lan_updated_at}
            }

            console.log(prams,'lan_update---------***');
            
            const http = this._vm.$http  
            http.post(lan_link+'Mongo/UpdateItems',prams)
            .then(res=>{
              const body = res?.body 
              const data = body?.data
              const is_array = data?Array.isArray(data):null
              let data_details = { 
                response:"success", 
                res,body,data,is_array
              }
              resolve(data_details) 
            }).catch(error=>{
              const body = error?.body 
              const data = body?.data
              const is_array = data?Array.isArray(data):null
              let data_details = { 
                response:"error", 
                error,body,data,is_array
              }
              reject(data_details) 
            }) 
          } catch (error) {
            commit("ERROR",{
              error:{
                error:error,
                data:payload
              },
              from:'lan_update',
              page:NAME, 
            })
          } 
        })
      },   
      lan_get({ commit,state}, payload) {
        return new Promise(async (resolve,reject)=>{
          let response = {
            from:'lan_get',
            page:NAME, 
            payload:payload
          }
          try { 
            const lan_updated_at = moment().valueOf()
            let uid = payload?.uid
            const lan = payload?.lan
            const action = payload?.action
            const name = payload?.name
            const data = payload?.data
            const code = payload?.code
            const key = payload?.key
            const id = payload?.id
            const description = payload?.description
            const multiple = payload?.multiple
            let lan_link = payload?.lan_link
            if (!uid) {
              let us = users?users.state.USER:null
              uid = us?us.uid:null  
            }
            if(!lan_link){
              lan_link = lan==1?state.LINK_LAN1: state.LINK_LAN2
            } 
            if (!uid||!data) {
              commit('updateResponses', {
                res: 'lan_set/'+action+'/error',
                val: "field required"
              })
              reject({
                ...response,
                error:"field required"
              })
              return
            } 
            
            const prams = {
              uid,action,name,code,key,id,description,
              data:{...data,lan_updated_at}
            }
            const http = this._vm.$http  
            http.post (lan_link+'Mongo/GetItems',prams)
            .then(res=>{
              const body = res?.body 
              const data = body?.data 
              const is_array = data?Array.isArray(data):null
              let data_details = { 
                response:"success", 
                res,body,data,is_array
              }
              commit("setLANs",{name:action,data})
              resolve(data_details) 
            }).catch(error=>{
              const body = error?.body 
              const data = body?.data 
              const is_array = data?Array.isArray(data):null
              let data_details = { 
                response:"error", 
                error,body,data,is_array
              }
              reject(data_details) 
            }) 
          } catch (error) {
            commit("ERROR",{
              error:{
                error:error,
                data:payload
              },
              from:'lan_update',
              page:NAME, 
            })
          } 
        })
      }, 
      lan_event({ commit,state}, payload) {
        try { 
          commit("setLANs",{name:"EVENT",data:payload})
          const {lan_type,lan_event,action,data} = payload
          console.log(payload,'-------------------------eeeeeeeeeeeee-----------------');
          
        } catch (error) {
          commit("ERROR",{
            error:{
              error:error,
              data:payload
            },
            from:'lan_status',
            page:NAME, 
          })
        }
      },
      
      //MANAGE PRINT
      lan_print({ commit,dispatch,state}, payload) {
        return new Promise(async (resolve,reject)=>{
          let response = {
            from:'lan_print',
            page:NAME, 
            payload:payload
          }
          try {  
            let uid = payload?.uid
            const printer = payload?.printer
            const print_option = payload?.print_option
            const print_data = payload?.print_data 
            const data = print_data?.data
            const join = print_data?.join
            const processed = print_data?.processed
            
            const printer_type_code = printer?.printer_type_code
            const document_type = print_option?.document_type
            const document_title = print_option?.document_title
            const open_drawer = print_option?.open_drawer
           
    
            if (!uid) {
              let us = users?users.state.USER:null
              uid = us?us.uid:null  
            } 
            if (!uid||!printer||!print_option) { 
              reject({
                ...response,
                error:"field required"
              })
              return
            } 

            let res = null
            let success = false
            let error = false
            let message = null

            //------PROCESS DATA
            let processed_print_data = null
            if (processed) {
              processed_print_data = data
            } else {
              if (document_type == "dayshift") {
                let join_data = null
                if (join) {
                  join_data = data
                } else {
                  const res = await dispatch("join_day_shift",{
                    data:data, 
                  })
                  join_data = res?.data
                }
                const res = await dispatch("EPSON_DAY_SHIFT_REQUEST",{
                  printer,
                  day_shift:join_data,
                  print_title:document_title,
                })
                processed_print_data = res?.data 
              }else if (document_type == "cashup") {
                let join_data = null
                if (join) {
                  join_data = data
                } else {
                  const res = await dispatch("join_cashup",{
                    data:data, 
                  })
                  join_data = res?.data
                }
                const res = await dispatch("EPSON_CASHUP_REQUEST",{
                  printer,
                  sale_order:join_data,
                  print_title:document_title,
                })
                processed_print_data = res?.data 
              }else if (document_type == "sale_order") {
                let join_data = null
                if (join) {
                  join_data = data
                } else {
                  const res = await dispatch("join_sale_orders",{
                    data:data, 
                  })
                  join_data = res?.data
                }
                const res = await dispatch("EPSON_SALE_ORDER_REQUEST",{
                  printer,
                  sale_order:join_data,
                  print_title:document_title,
                })
                processed_print_data = res?.data 
              }else{
                processed_print_data = data
              } 
            } 

            if (processed_print_data || open_drawer) {
              const print_request = {
                ...payload,
                print_data:{
                  ...print_data,
                  ...processed_print_data,
                  processed_print_data
                } 
              }
              if (printer_type_code == "1") {
                const action = open_drawer && !data?'lan_fp700_print_open_drawer':'lan_fp700_print' 
                res = await dispatch(action,print_request)
                success = true
                message = "Successfully, printed"
              } else if (printer_type_code == "3") {
                const action = open_drawer && !data?'lan_epson_open_drawer':'lan_epson_print'
                res = await dispatch(action,print_request)
                success = true
                message = "Successfully, printed"
              } else {
                error = true
                message = "Unknown printer"
              } 
            } else {
              error = true
              message = "No processed print data"
            } 
            resolve({res,success,message})
             
          } catch (error) {
            commit("ERROR",{
              error:{
                error:error,
                data:payload
              },
              from:'lan_update',
              page:NAME, 
            })
          } 
        })
      },  
      lan_epson_print({ commit,state}, payload) {
        return new Promise(async (resolve,reject)=>{
          let response = {
            from:'lan_epson_print',
            page:NAME, 
            payload:payload
          } 
          try {   
            let uid = payload?.uid
            const printer = payload?.printer
            const print_option = payload?.print_option
            const print_data = payload?.print_data
            let print_link = payload?.print_link  
            const lan = payload?.lan
            const server_link = lan?lan==1?state.LINK_LAN1: state.LINK_LAN2:null
            const defined_link = print_link?print_link:server_link
            const print_type = print_option?.print_type
            const print_document_type = print_option?.print_document_type

            const printer_name = printer?.name
            const printer_paper_size = printer?.paper_size
            const printer_network_ip_address = printer?.network_ip_address_link
            const printer_server = printer?.server
            const printer_server_link = printer_server?.network_ip_address_link

            const option_open_drawer = print_option?.open_drawer?"yes":""
            const option_print_barcode = print_option?.print_barcode
            const option_type= print_option?.type

            const lines = print_data?.lines
            const data_title = print_data?.title
           
    
            if (!uid) {
              let us = users?users.state.USER:null
              uid = us?us.uid:null  
            } 
            if (!uid||!printer||!print_data) { 
              reject({
                ...response,
                error:"field required"
              })
              return
            } 
            const link = printer_server_link?printer_server_link+"/api/":defined_link

            let url = link?link:'http://localhost:5000/api/'   
            let data_conf = {
                "printerName": printer_name,
                "title": ""+data_title, 
                "printBarcode": ""+option_print_barcode, 
                "openDrawer": ""+option_open_drawer,
                "paperSize": ""+printer_paper_size,
                "lines": lines, 
            }
            const prams = {
              uid,
              ...data_conf
            }
            const http = this._vm.$http  
            http.post (url+'Receipt/EpsonPrintLines',prams)
            .then(res=>{
              const body = res?.body 
              const data = body?.data 
              let data_details = { 
                response:"success", 
                res,body,data
              } 
              resolve(data_details) 

              
            }).catch(error=>{
              const body = error?.body 
              let data_details = { 
                response:"error", 
                error:error,
                body:body,
                data:body?.data
              }
              reject(data_details) 
            }) 
          } catch (error) {
            commit("ERROR",{
              error:{
                error:error,
                data:payload
              },
              from:'lan_update',
              page:NAME, 
            })
          } 
        })
      }, 
      lan_fp700_print({ commit,dispatch,state}, payload) {
        return new Promise(async (resolve,reject)=>{
          let response = {
            from:'lan_fp700_print',
            page:NAME, 
            payload:payload
          } 
          try {   
            let uid = payload?.uid
            const printer = payload?.printer
            const print_option = payload?.print_option
            const print_data = payload?.print_data
            let print_link = payload?.print_link  
            const lan = payload?.lan
            const server_link = lan?lan==1?state.LINK_LAN1: state.LINK_LAN2:null
            const defined_link = print_link?print_link:server_link
            const print_type = print_option?.print_type
            const print_document_type = print_option?.print_document_type

            const printer_name = printer?.name
            const com_port = printer?.com_port
            const baud_rate = printer?.baud_rate
            const printer_network_ip_address = printer?.network_ip_address_link
            const printer_server = printer?.server
            const printer_server_link = printer_server?.network_ip_address_link

            const option_open_drawer = print_option?.open_drawer?"yes":""
            const option_print_barcode = print_option?.print_barcode
            const option_duplicate = print_option?.duplicate
            const option_type= print_option?.type

            const data_title = print_data?.title
            const sale_order = print_data?.data
            const order_key = sale_order?.key
            const items = sale_order?.items
            let products = []
            let joined_items = null
            try {
              let res = await dispatch("join",{
                name:"ALL_"+DATA.ITEMS.ITEMS.values,
                data:items
              }) 
              let join_data = res?res.data:null
              joined_items = join_data?join_data.joined:null 
            } catch (error) {  
              console.log(error,'e........');
            } 
            if (joined_items) {
              joined_items.forEach(element => {
                let productName = element.name?element.name.slice(0,35):"......."
                let item_tax = element?element.tax:null
                let item_tax_group_code = element?element.tax_group_code:null
                // let usable_tax_group = item_tax_group_code?item_tax_group_code:
                // item_tax?"A":"B"
                
                let usable_tax_group = item_tax_group_code?item_tax_group_code:"A"
                products.push({
                  "productName":productName,
                  "taxGroup":TEXT_UP(usable_tax_group),
                  "singlePrice":""+element.sold_price, 
                  "quantity":""+element.quantity,
                })
              });
              
            }

           
    
            if (!uid) {
              let us = users?users.state.USER:null
              uid = us?us.uid:null  
            } 
            if (!uid||!printer||!print_data) { 
              reject({
                ...response,
                error:"field required"
              })
              return
            } 
            const link = printer_server_link?printer_server_link+"/api/":defined_link 
            let url = link?link:'http://localhost:5000/api/'   

            let data_conf = {
                "printerName": printer_name,
                "title": ""+data_title, 
                "printBarcode": ""+option_print_barcode, 
                "openDrawer": ""+option_open_drawer,
                "comPort": ""+com_port,
                "baudRate": ""+baud_rate,
                "products": products, 
            }
            const prams = {
              uid,
              ...data_conf
            }
            const http = this._vm.$http  
            http.post (url+'Receipt/FiscalReceipt',prams)
            .then(res=>{
              const body = res?.body 
              const data = body?.data 
              let data_details = { 
                response:"success", 
                res,body,data
              } 
              resolve(data_details) 

              
            }).catch(error=>{
              const body = error?.body 
              let data_details = { 
                response:"error", 
                error:error,
                body:body,
                data:body?.data
              }
              reject(data_details) 
            }) 
          } catch (error) {
            commit("ERROR",{
              error:{
                error:error,
                data:payload
              },
              from:'lan_update',
              page:NAME, 
            })
          } 
        })
      }, 
      lan_epson_open_drawer({ commit,state}, payload) {
        return new Promise(async (resolve,reject)=>{
          let response = {
            from:'lan_epson_open_drawer',
            page:NAME, 
            payload:payload
          } 
          try {   
            let uid = payload?.uid
            const printer = payload?.printer 
            const print_link = payload?.print_link  
            const lan = payload?.lan
            const server_link = lan?lan==1?state.LINK_LAN1: state.LINK_LAN2:null
            const defined_link = print_link?print_link:server_link 

            const printer_name = printer?.name 
            const printer_network_ip_address = printer?.network_ip_address_link
            const printer_server = printer?.server
            const printer_server_link = printer_server?.network_ip_address_link

           
    
            if (!uid) {
              let us = users?users.state.USER:null
              uid = us?us.uid:null  
            } 
            if (!uid||!printer) { 
              reject({
                ...response,
                error:"field required"
              })
              return
            } 
            const link = printer_server_link?printer_server_link+"/api/":defined_link

            let url = link?link:'http://localhost:5000/api/'   
            let data_conf = {
                "printerName": printer_name,
                "title": "drawer", 
                "printBarcode": "drawer",  
            }  
            const prams = {
              uid,
              ...data_conf
            }
            const http = this._vm.$http  
            http.post (url+'Receipt/openDrawer',prams)
            .then(res=>{
              const body = res?.body 
              const data = body?.data 
              let data_details = { 
                response:"success", 
                res,body,data
              } 
              resolve(data_details)  
            }).catch(error=>{
              const body = error?.body 
              let data_details = { 
                response:"error", 
                error:error,
                body:body,
                data:body?.data
              }
              reject(data_details) 
            }) 
          } catch (error) {
            commit("ERROR",{
              error:{
                error:error,
                data:payload
              },
              from:'lan_update',
              page:NAME, 
            })
          } 
        })
      },   
      lan_display({ commit,dispatch,state}, payload) {
        return new Promise(async (resolve,reject)=>{
          let response = {
            from:'lan_display',
            page:NAME, 
            payload:payload
          }
          try {  
            let uid = payload?.uid
            const display = payload?.display 
            const customer_display_type_code = display?.customer_display_type_code 
            const display_data = payload?.display_data 
            const data = display_data?.data
            const join = display_data?.join
            const processed = display_data?.processed
            
            
            if (!uid) {
              let us = users?users.state.USER:null
              uid = us?us.uid:null  
            } 
            if (!uid||!display||!data) { 
              reject({
                ...response,
                error:"field required"
              })
              return
            } 

            let res = null
            let success = false
            let error = false
            let message = null

            //------PROCESS DATA
             

            if (true) {
              const lan_request = {
                ...payload
              }
              if (customer_display_type_code == "LED8N") {
                const action = 'lan_display_red8n'
                res = await dispatch(action,lan_request)
                success = true
                message = "Successfully, Display"
              } else if (customer_display_type_code == "VFD") {
                const action = 'lan_display_red8n'
                res = await dispatch(action,lan_request)
                success = true
                message = "Successfully, Display"
              } else {
                error = true
                message = "Unknown Display"
              } 
            } else {
              error = true
              message = "No processed print data"
            } 
            resolve({res,success,message})
             
          } catch (error) {
            commit("ERROR",{
              error:{
                error:error,
                data:payload
              },
              from:'lan_update',
              page:NAME, 
            })
          } 
        })
      }, 
      lan_display_red8n({ commit,state}, payload) {
        return new Promise(async (resolve,reject)=>{
          let response = {
            from:'lan_display_red8n',
            page:NAME, 
            payload:payload
          } 
          try {   
            let uid = payload?.uid
            const display = payload?.display  
            const display_data = payload?.display_data 
            const data = display_data?.data
            const lan = payload?.lan
            const server_link = lan?lan==1?state.LINK_LAN1: state.LINK_LAN2:null 
 
            const display_server = display?.server
            const display_server_link = display_server?.network_ip_address_link
            
            const com_port = display?.com_port
            const baud_rate = display?.baud_rate
            const text = data?.text
            const light_status = data?.light_status
           
    
            if (!uid) {
              let us = users?users.state.USER:null
              uid = us?us.uid:null  
            } 
            if (!uid||!display) { 
              reject({
                ...response,
                error:"field required"
              })
              return
            } 
            const link = display_server_link?display_server_link+"/api/":server_link

            let url = link?link:'http://localhost:5000/api/'   
            let data_conf = {
                "comPort": com_port,
                "baudRate": baud_rate, 
                "messageLineOne": text,
                "lightStatus": light_status,  
            }  
            const prams = {
              uid,
              ...data_conf
            }
            const http = this._vm.$http  
            http.post (url+'Receipt/DisplayD1',prams)
            .then(res=>{
              const body = res?.body 
              const data = body?.data 
              let data_details = { 
                response:"success", 
                res,body,data
              } 
              resolve(data_details)  
            }).catch(error=>{
              const body = error?.body 
              let data_details = { 
                response:"error", 
                error:error,
                body:body,
                data:body?.data
              }
              reject(data_details) 
            }) 
          } catch (error) {
            commit("ERROR",{
              error:{
                error:error,
                data:payload
              },
              from:'lan_update',
              page:NAME, 
            })
          } 
        })
      },
        
     
    },
}