<template>
  <div v-if="show">
    <audio id="myAudio" :src="SOUND_PATH"></audio>  
  </div>
</template>

<script>
  import DATA from "../../plugins/DATA"
  import {mapState } from "vuex"
  export default {
    props:['path'],
    beforeCreate(){},
    created(){
      this.MBS.events.$on('PLAY_SOUND', this.PLAY_SOUND); 
    },
    mounted(){
        
    },
    data(){
      return{
        show:false, 
        DATA:DATA,
        sound_path_:'/sounds/s1.mp3',
        sound_path:null,
      }
        
    },
    computed:{
      //common 
      ...mapState({
        loading:state=>state.load.loading,
        processes:state=>state.load.processes,
        responses:state=>state.load.responses,
      }),
      ...mapState({
          us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
          ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
          SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
          K_CONNECTOR: state=> state.items['K_CONNECTOR'],
      }),
      ...mapState({ 
        Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
        JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()],  
            
      }),
      
      SOUND_PATH(){
        const path = this.path
        const sound_path = this.sound_path
        const sound_path_ = this.sound_path_
        const d_sound = path?path:sound_path_
        return sound_path?sound_path:d_sound
      }
      
    },
    methods:{ 
      playSound(path) {
        this.sound_path = path
        this.show = false
        setTimeout(() => {
          this.show = true
          setTimeout(() => {
            const audio = document.getElementById('myAudio');
            if (audio) {
              audio.play();  
            }else{
              this.playSound(path)
            }
          }, 10);
        }, 100);
      },
      PLAY_SOUND(payload){  
        this.playSound(payload?.path)
      },
    },
    beforeDestroy(){
        this.MBS.events.$off('PLAY_SOUND', this.PLAY_SOUND);     
    },
    watch:{
        
      }

  }
</script>
 
