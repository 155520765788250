<template>
    <v-card flat color="transparent"> 
        <v-layout 
            :style="'--color:'+MBS.color.black+'; --size:1px; '"
            row wrap class="ma-0" justify-center>
            <span  v-for="(action,index) in ITEMS" :key="index" > 
                
                <v-card v-if="FieldFunction(action.show,action)" outlined  class="ma-1"
                width="105"  @click="DISABLED(action)?'':ITEM_ACTION(action)"> 
                    <v-menu  v-if="action.menu"
                        v-model="menu_item_select[index]"
                        :close-on-content-click="true" 
                        offset-y  offset-overflow
                        :nudge-width="action.menu.width?action.menu.width:90" 
                        rounded="xl" >

                        <template v-slot:activator="{ on, attrs }">   
                            <v-card v-on="on" height="80" 
                                :disabled="DISABLED(action)" 
                                :color="DISABLED(action)?'grey':action.color?action.color:'primary'"  >
                                <v-layout fill-height class="ma-0" column>
                                    <!-- <v-spacer></v-spacer> -->
                                    <v-badge  style="height: 100%;"
                                        class=""
                                        :value="Badge(action).show"  
                                        :content="Badge(action).value"
                                        :color="Badge(action).color"
                                        :bordered="true"
                                        :overlap="true"  >
                                        <v-card height="90%" width="100%" flat  class="" color="p" >
                                            <v-layout column fill-height justify-center align-center class="ma-0 pa-2 pt-3">
                                                <v-icon v-if="action.icon">{{action.icon}}</v-icon>
                                                <div class="font-weight-bold grey--text text--darken-2  not-f text-center"
                                                    :class="MBS.actions.SIZE(action.name)>11?'not-f3':''">{{action.name}}</div> 
                                            </v-layout>
                                        </v-card>
                                    </v-badge>
                                </v-layout> 
                            </v-card>
                        </template> 

                        <v-card   
                            color="lighten-1" class="">  
                            <v-card-title class="secondary white--text py-" dark>
                                {{action.menu.name}} 
                                <!-- <v-layout row wrap>
                                    <v-spacer></v-spacer>
                                </v-layout> -->
                                <v-btn color="white" small  outlined class="px-1 ml-2  " >
                                    {{ Badge(action).value_text }}
                                    <v-icon small class="ml-1" color="">mdi-bell</v-icon>
                                </v-btn>
                            </v-card-title> 
                            <v-list dense> 
                                <div v-for="(item,index) in action.menu.items" :key="index">
                                    <v-list-item v-if="FieldFunction(item.show,item)"
                                        @click="ITEM_ACTION(item)">
                                        <v-list-item-action class="ma-2">
                                            <v-icon>{{item.icon}}</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            {{item.name}}
                                        </v-list-item-content>
                                    </v-list-item> 
                                </div>
                            </v-list>  
                        </v-card>  
                    </v-menu> 
                    <v-card v-else height="80" 
                        :disabled="DISABLED(action)" 
                        :color="DISABLED(action)?'grey':action.color?action.color:'primary'"  >
                        <v-layout fill-height class="ma-0 mbs-borde" column>  
                            <!-- <v-spacer></v-spacer> -->
                            <v-badge  style="height: 100%;"
                                class=""
                                :value="Badge(action).show"  
                                :content="Badge(action).value"
                                :color="Badge(action).color"
                                :bordered="true"
                                :overlap="true"  >
                                <v-card height="90%" width="100%" flat  class="" color="p" >
                                    <v-layout column fill-height justify-center align-center class="ma-0 pa-2 pt-3">
                                        <v-icon v-if="action.icon">{{action.icon}}</v-icon>
                                        <div class="font-weight-bold grey--text text--darken-2  not-f text-center"
                                            :class="MBS.actions.SIZE(action.name)>11?'not-f3':''">{{action.name}}</div> 
                                    </v-layout>
                                </v-card>
                            </v-badge>
                        </v-layout> 
                    </v-card> 
                </v-card>   
            </span>
        </v-layout> 
    </v-card>
</template>

<script>
    import DATA from '../../plugins/DATA';
    import {mapState} from "vuex" 
    let PAGE_NAME  = "ACTIONS_FIELD" 
    
    export default {
        name:PAGE_NAME,
        props:[
            'show','value', 
            'items',
            'data',
            'disabled',
            'disabled_types',
            'disabled_fields',  
            'select_items', 
            'update_item',  
            'waiter_uid',  
        ],
        data() {
            return { 
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,
  
                excelSelecter:false,
                EXCEL_DATA_ITEMS:[],
                generatedCode:null, 
                inputCode:null, 
                inputCodeLoading:false, 
                menu_date_picker:{},
                menu_time_picker:{},
                menu_item_select:{},
                dialog_time_picker:{},
                dialogInput:{},
                search:'',  
                search_input:null,   
                input:this.value,
                selected_field_action:null, 
            }
        },
        created() {
            try {
                // this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {   
                 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
             
            ITEMS(){  
                let data = this.data
                let items = this.items 
                if (!items) {return null} 
                let filtered_items = items.filter(item=>{
                    let show_if = true 
                    if (typeof item.show_if == "function") {
                        show_if = item.show_if(data)
                    }else if (typeof item.show_if == "boolean") {
                        show_if = item.show_if
                    }else{
                         
                    }
                    return show_if
                })  
                return filtered_items
            }, 
            Disable(){  
                let disabled = this.disabled
                let disabled_fields = this.disabled_fields
                let disabled_types = this.disabled_types
                if(disabled){return true}
                return (action)=>{
                    if (this.MBS.actions.SIZE(disabled_fields)>0) {
                        return disabled_types.some(item=>{return item == action.action})
                    }
                    if (this.MBS.actions.SIZE(disabled_types)>0) { 
                        let isType = disabled_types.some(item=>{return item == action.type}) 
                        return isType
                    }
                    return false
                }
            },
            DISABLED(){  
                let data = this.data 
                return item=>{
                    let disabled = true 
                    if (typeof item.disable == "function") {
                        disabled = item.disable(data)
                    }else if (typeof item.disable == "boolean") {
                        disabled = item.disable
                    }else{
                        disabled = this.Disable(item)
                    }
                    return disabled
                }
            }, 
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
                SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'], 
                SHOW_OFFLINE: state=> state.items['SHOW_OFFLINE'],
                online: state=> state.users['ONLINE_STATUS'],
                online_lan1: state=> state.lan['ONLINE_LAN1'],
                online_lan2: state=> state.lan['ONLINE_LAN2'],
                link_lan1: state=> state.lan['LINK_LAN1'],
                link_lan2: state=> state.lan['LINK_LAN2'],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                CompanyQuickActions: state=> state.items[(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values).toUpperCase()], 
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                ItemOrders: state=> state.items[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
                JoinedItemOrders: state=> state.join[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
                LanItemOrders: state=> state.lan["LAN_ITEM_ORDERS"],
                JoinedLanItemOrders: state=> state.join["LAN_ITEM_ORDERS"],
                LocalItemOrders: state=> state.items[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
                JoinedLocalItemOrders: state=> state.join[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
                ItemOrderOptions: state=> state.items[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()],
                JoinedItemOrderOptions: state=> state.join[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()], 

                Customers: state=> state.items[(DATA.ITEMS.CUSTOMERS.values).toUpperCase()], 
                JoinedCustomers: state=> state.join[(DATA.ITEMS.CUSTOMERS.values).toUpperCase()], 
                CustomerGroups: state=> state.items[(DATA.ITEMS.CUSTOMER_GROUPS.values).toUpperCase()], 
                JoinedCustomerGroups: state=> state.join[(DATA.ITEMS.CUSTOMER_GROUPS.values).toUpperCase()], 
   
                
                DaysShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                JoinedDaysShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()], 
                LocalDaysShifts: state=> state.locals[(DATA.ITEMS.LOCAL_DAYS_SHIFTS.values).toUpperCase()], 
                LocalCashups: state=> state.locals[(DATA.ITEMS.LOCAL_CASHUPS.values).toUpperCase()], 
                   
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                Tills: state=> state.items[(DATA.ITEMS.TILLS.values).toUpperCase()],
                JoinedTills: state=> state.join[(DATA.ITEMS.TILLS.values).toUpperCase()],  
 
            }), 
            OutletsData(){
                return this.JoinedOutlets?this.JoinedOutlets:this.Outlets
            }, 
            TillsData(){
                return this.JoinedTills?this.JoinedTills:this.Tills
            }, 
            CurrentTill(){
                let tills = this.TillsData
                let cashup = this.LocalCashup  
                if(!tills || !cashup){return null}
                let filled = tills.find(item=>{
                    return item.key == cashup.till_key
                })   
                return filled
            },
            CurrentOutlet(){
                let outlets = this.OutletsData
                let till = this.CurrentTill   
                if(!outlets || !till){return null}
                let filled = outlets.find(outlet=>outlet.code==till.outlet_code)
                return filled
            }, 
            LocalCashup(){    
                let cashups = this.LocalCashups 
                let local_cashup = cashups?cashups.find(item=>{
                    return !item.closed
                }):null 
                this.day_shift_key = local_cashup?.day_shift_key 
                return local_cashup
            }, 
            ItemOrdersData(){    
                return this.JoinedItemOrders?this.JoinedItemOrders:this.ItemOrders
            },  
            LanItemOrdersData(){  
                const join = this.JoinedLanItemOrders
                const items = this.LanItemOrders
                const data = join?join:items 
                
                return data
            },
            LocalItemOrdersData(){  
                return this.JoinedLocalItemOrders?this.JoinedLocalItemOrders:this.LocalItemOrders
            }, 
            NewItemOrders(){
                const assistant = this.Assistant  
                const cashup = this.LocalCashup  
                const cashup_key = cashup?.key   
                const day_shift_key = cashup?.day_shift_key   
                const waiter_uid = this.waiter_uid  
                const us = this.us  
                const uid = us?.uid
                const current_uid = waiter_uid?waiter_uid:uid  
                const filters = item=>{
                    const item_day_shift_key = item.day_shift_key
                    const created_uid = item.created_uid
                    if (!assistant) {
                        return !item.deleted && item_day_shift_key==day_shift_key && created_uid == current_uid
                    }else if (waiter_uid && waiter_uid!=uid ) {
                        return !item.deleted && item_day_shift_key==day_shift_key && created_uid == current_uid
                    }
                    return !item.deleted && item_day_shift_key==day_shift_key
                }
                const lan = this.LanItemOrdersData?.filter(filters)
                const local = this.LocalItemOrdersData?.filter(filters)
                const live = this.ItemOrdersData?.filter(filters)
                let new_lan = []
                let new_live = []
                let new_items = []
                if (!this.MBS.actions.SIZE(local)) {
                    if(this.MBS.actions.SIZE(lan)){
                        new_lan.push(...lan)
                        new_items.push(...lan)
                    }
                    if(this.MBS.actions.SIZE(live)){
                        live.forEach(item => {
                            const live_index = new_live.findIndex(it=>it.key == item.key)
                            const item_index = new_items.findIndex(it=>it.key == item.key)
                            if (live_index == -1) {
                                new_live.push(item)  
                            }else{
                                new_live[live_index] = {
                                    ...new_live[live_index],
                                    ...item
                                }
                            }
                            
                            if (item_index == -1) {
                                new_items.push(item)  
                            }else{
                                new_items[item_index] = {
                                    ...new_items[item_index],
                                    ...item
                                }

                            }
                            
                        });
                    }
                }else{
                    if(this.MBS.actions.SIZE(lan)){
                       lan.forEach(item => {
                            const index = local.findIndex(it=>it.key == item.key)
                            if (index == -1) {
                                new_lan.push(item)
                                new_items.push(item)   
                            }
                       });
                    }
                    if(this.MBS.actions.SIZE(live)){
                        live.forEach(item => {
                            const index = local.findIndex(it=>it.key == item.key)
                            const live_index = new_live.findIndex(it=>it.key == item.key)
                            const item_index = new_items.findIndex(it=>it.key == item.key)
                            if(index == -1 && live_index == -1) {
                                new_live.push(item)  
                            }else if(index == -1 && live_index != -1){
                                new_live[live_index] = {
                                    ...new_live[live_index],
                                    ...item
                                }
                            }
                            
                            if(index == -1 && item_index == -1) {
                                new_items.push(item)  
                            }else if(index == -1 && item_index != -1){
                                new_items[item_index] = {
                                    ...new_items[item_index],
                                    ...item
                                }

                            }
                       });
                    }
                } 
                const new_data = {
                    new_lan,new_live,new_items,lan,local,live
                } 
                
                return new_data
            },
            Assistant(){    
                const cashup = this.LocalCashup
                const assistant_uid = cashup?.assistant_uid
                if(!assistant_uid){return null} 
                let assistant = {assistant_uid}
                const user = this.$store.getters.getUserByUid(assistant_uid) 
                if (user) {
                    assistant = {...user,assistant_uid}
                } 
                return assistant
            }, 
            IsRestaurant(){
                const outlet = this.CurrentOutlet
                return outlet?.outlet_type_code == "restaurant"
            },
            HasAssistant(){
                const till = this.CurrentTill
                const tills = this.TillsData
                if(!till || !tills){return false}
                const assistant = tills.find(item=>item.cashier_till_key == till.key)
                return assistant?true:false
            },
            DataSelector(){
                return{
                    NewItemOrders:this.NewItemOrders,
                }
            },
            FieldFunction(){ 
                return (action,field)=>{
                    if (!action) {
                       return true 
                    }else  if (typeof action == "function") {
                        return action(this,field,this.DataSelector)
                    }else {
                        return action
                    } 
                }
            },
            Badge(){ 
                return (action)=>{
                    let badge = {
                        show:false,
                        color:"",
                        value:"",
                        value_text:""
                    }
                    const indicator = action?.indicator
                    if(!indicator){return badge}
                    badge.show=this.FieldFunction(indicator?.show,action)
                    badge.color=this.FieldFunction(indicator?.color,action)
                    badge.value=this.FieldFunction(indicator?.value,action)
                    badge.value_text=this.FieldFunction(indicator?.value_text,action)
                    return badge
                }
            }
            
              
        },
        methods: {   
            ITEM_ACTION(action){
                try {   
                    this.selected_field_action = action
                    setTimeout(() => { 
                        this.$emit("ITEM_ACTION",action.action,action)
                    }, 0); 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FIELD_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            
            //---------------[MAIN FUNCTIONS]------------  
            DIALOG_YES(action){ 
                try {
                    if (action.code) {  
                        if (action.code ===PAGE_NAME+'=AUTO-GENERATE-CODE') { 
                            if (action.YES) {
                                this.EXCEL_DATA_ITEMS= this.MBS.actions.CODE_AUTO_GENERATE(this.EXCEL_DATA_ITEMS) 
                                setTimeout(() => { 
                                    this.MBS.actions.dialog({
                                        show:true,
                                        fixed:true,
                                        title:'GENERATED',
                                        text:"Code, successfully generated.",
                                        btnYes:"OK"
                                    }) 
                                }, this.MBS.data.ACTION_REFRESH_TIME); 
                            } else { 
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=AUTO-SET-CODE') { 
                            if (action.YES) {
                                this.inputCodeLoading = true
                                setTimeout(() => { 
                                    this.setAutoCode()
                                }, this.MBS.data.ACTION_REFRESH_TIME); 
                            } else {
                                
                            }
                        } 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);    
        },
        watch: { 
            us(v){ 
            },  
            input(currentValue) {  
                this.$emit('input', {...currentValue})
            },     
        },
  }
</script>

<style  >
    .v-badge__badge{
        z-index:5;
        left: -1%;
    }
</style>