<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12">
                    <br>  
                    <v-card :min-height="vsh/2" > 
                        <v-card>
                            <mbs-page-tab 
                                :items="MY_LINKS"/>   
                        </v-card> 
                        <!-- <v-layout class="ma-5">
                            JOINING:
                            {{isJoining}}
                        </v-layout>
                        <v-layout class="ma-5">
                            CALCULATING:
                            {{isCalculating}}
                        </v-layout> -->
                        <mbs-item-manager
                            :add_vertical_lines="true"
                            v-model="input"
                            :table_tabs="TABLE_TABS"
                            :top_label="false"
                            :name_items="ThisStockOutlet?ThisStockOutlet.name+' '+NAME_ITEMS:NAME_ITEMS"
                            :name_item="NAME_ITEM+'--'"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM"
                            :field_items="DATA.FIELDS.items"
                            :header_items="HEADER_ITEMS"
                            :header_items2="HEADER_ITEMS2"
                            :data_items="InventoryItems"  
                            :is_joined="true"  
                            :path_item="PATH_ITEM"  
                            :select_items="Selectors"  
                            :total_item_values="TOTAL_ITEM_VALUES"  

                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                        />  
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  

        <!-- //DIALOGS -->
        <div>
            <div v-if="ADDING_ITEM">
                <mbs-item-manager 
                    :show="false" 
                    :add_item_action="ADDING_ITEM.add_item_action"
                    :name_items="ADDING_ITEM.names"
                    :name_item="ADDING_ITEM.name"
                    :value_items="ADDING_ITEM.values"
                    :value_item="ADDING_ITEM.value"
                    :field_items="ADDING_ITEM.fields"   
                    :path_item="ADDING_ITEM.path"  
                    :select_items="Selectors"  
                    @ADDED="ADDED" 
                /> 
            </div>
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    import {mapState,mapGetters } from "vuex"
    import { mdiFormatListGroupPlus } from '@mdi/js';
    let PAGE_NAME = DATA.ITEMS.ITEMS.values    
    export default {
        name:PAGE_NAME, 
        props:['id','stock_outlet_code'], 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,
                input:{},

                NAME_ITEMS:DATA.ITEMS.ITEMS.names,
                NAME_ITEM:DATA.ITEMS.ITEMS.name,
                VALUE_ITEMS:DATA.ITEMS.ITEMS.values,
                VALUE_ITEM:DATA.ITEMS.ITEMS.value, 
                ADDING_ITEM:null,
                table_actions:[
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},   
                    {type:"action",btn:true,icon:'mdi-tag',actions:[
                        {icon:"mdi-tag-plus",action:"create_item",text:'Create New Item'},
                        {icon:"mdi-tag",action:"manage_items",text:'Manage Items'},
                    ],color:"secondary", outlined:false,  text:"Items"},  
                    {type:"action",btn:true,icon:'mdi-group',actions:[
                        {icon:"mdi-plus-box",action:"create_item_kit",text:'Create New Item Kit'},
                        {icon:"mdi-group",action:"manage_item_kit",text:'Manage Item Kits'},
                    ],color:"secondary", outlined:false,  text:"Item Kit"}, 
                    {type:"action",btn:true,icon:'mdi-contrast-box',actions:[
                        {icon:"mdi-package-variant-plus",action:"receive_stock",text:'Receive Stock'},
                        {icon:"mdi-contrast-box",action:"stock_control",text:'Move to Stock Control'},
                        {icon:"mdi-warehouse",action:"warehouse",text:'Move to Warehouse'},
                    ],color:"secondary", outlined:false,  text:"Stock Control"},   
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  
                ],
                
                DATA:DATA,
                TOTAL_ITEM_VALUES:[ 
                    // {name:'Closing Stock (QTY)',value:'number_quantity',show_value:"number_quantity"},
                    // {name:'Total Cost',value:'number_total_cost',show_value:'number_total_cost_',money:true},
                ], 
            }
        },
        created() {
            try { 
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                // console.log(this.SelectedCompany);
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                }) 
                this.FEEDBACKS(1000)  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
            /////////
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                ItemCategories: state=> state.items[(DATA.ITEMS.ITEM_CATEGORIES.values).toUpperCase()],
                Brands: state=> state.items[(DATA.ITEMS.BRANDS.values).toUpperCase()],
                Manufacturers: state=> state.items[(DATA.ITEMS.MANUFACTURERS.values).toUpperCase()], 
                TaxGroups: state=> state.app[(DATA.ITEMS.TAX_GROUPS.values).toUpperCase()], 
                StockOutlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedStockOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                SelectedCompany: state=> state.items["SELECTED_COMPANY"],   
                PricingTiers: state=> state.items[(DATA.ITEMS.PRICING_TIERS.values).toUpperCase()], 
                JoinedPricingTiers: state=> state.join[(DATA.ITEMS.PRICING_TIERS.values).toUpperCase()], 
                DefaultPricingTiers: state=> state.app['DEFAULT_PRICING_TIERS'], 
            }),  
            StockOutletsData(){ 
                let items = this.JoinedStockOutlets?this.JoinedStockOutlets:this.StockOutlets 
                return items
            }, 
            ItemsData(){ 
                let Items = this.Items
                let JoinedItems = this.JoinedItems
                let CalculatedItems = this.CalculatedItems 
                if (JoinedItems) {
                    return JoinedItems
                } 
                return Items
            },
            InventoryItems(){ 
                const items = this.ItemsData 
                const filtered = items?.filter(item=>{
                    const service = item.item_type_key == "service"
                    const child = item.item_type_key == "child"
                    const parent = item.item_type_key == "parent"
                    const inventory_item_code = item?.inventory_item_code
                    const inventory_item_key = item?.inventory_item_key
                    const inventory_item_keycode = inventory_item_key?inventory_item_key:inventory_item_code
                    const inventory = !inventory_item_keycode || inventory_item_keycode == "main"?true:false
                    return inventory && !service && !child && !parent
                })  
                return filtered
            },
            PricingTiersData(){
                return this.JoinedPricingTiers?this.JoinedPricingTiers:this.PricingTiers
            }, 
            AllPricingTiers(){
                let defaults = this.DefaultPricingTiers
                let tiers = this.PricingTiersData 
                let all = []
                if (defaults) {
                    all.push(...defaults)
                }if (tiers) {
                    tiers.forEach(tier => {
                        let index = all.findIndex(item=>item.key == tier.key)
                        if (index==-1) {
                            all.push(tier) 
                        } else {
                            all[index] = {
                                ...all[index],
                                ...tier
                            }
                        }
                    });
                }
                return all
            }, 
            ThisStockOutlet(){ 
                let stock_outlet_code = this.stock_outlet_code
                let outlets = this.StockOutletsData
                if(!outlets || !stock_outlet_code){return null}
                let item = outlets.find(outlet=>outlet.code == stock_outlet_code)
                return item
            },
            selectedCompany(){  
                return this.$store.getters.getSelectedCompany
            },  
            isCalculating(){  
                return this.$store.getters.isCalculating("ITEMS")
            },  
            isJoining(){  
                return this.$store.getters.isJoining("ITEMS")
            },  
            MY_LINKS(){  
                let outlets = this.StockOutletsData 
                
                if(!outlets){return null}
                let allLinks = []
                outlets.forEach(element => {
                    allLinks.push({
                        ...element,
                        name:element.name+" Stock",
                        link:this.MBS.actions.COMPANY_LINK("/stock/manage/"+element.code,true),
                        icon:'link',
                        // image:'/future_tours/svg/SVG/my_parcel.svg',
                        image_background_color:'primary lighten-2',
                        image_size:'80',
                        
                    })
                });  
                return allLinks
            }, 
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true) 
                return path
            },  
            TABLE_TABS(){
                return [
                    {name:"All Items",show_if:(this_,item)=>{ 
                            let stock = this.toNumber(item[this.stock_outlet_code+"_number_quantity"])>0?true:false
                            return !item.item_kits  
                        },indicator:{
                        color:'primary',
                        value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}}
                    }},
                    
                    {name:"Stock",show_if:(this_,item)=>{ 
                            let stock = this.toNumber(item[this.stock_outlet_code+"_number_quantity"])>0?true:false
                            return !item.item_kits && stock
                        },indicator:{
                        color:'primary',
                        value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}}
                    }},  
                    {name:"Out of Stock",show_if:(this_,item)=>{ 
                            let stock = this.toNumber(item[this.stock_outlet_code+"_number_quantity"])>0?true:false
                            return !item.item_kits && !stock
                        },indicator:{
                        color:'primary',
                        value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}}
                    }},  
                    {name:"Kits",show_if:(this_,item)=>item.item_kits,indicator:{
                        color:'primary',
                        value:(this_,MBS,data)=>{if(MBS){return MBS.SIZE(data)}}
                    }}, 
                ]
            },
            HEADER_ITEMS(){
                let input = this.input
                let current_table_tab = input?input.current_table_tab:null
                let tiers = this.AllPricingTiers
                let headers = [
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"No.",value:'no',show:false},  
                    {id:0,name:"",value:'icon_image',color:"",size:35,tile:true,show:true},   
                    {id:0,name:"Barcode",value:"code_",align:"center",show:true},   
                    {id:0,name:"Name",show:true}, 
                    {id:0,name:"Department",value:"item_department_name",cellClass:'',class:' b ',show:true}, 
                    {id:0,name:"Department Code",value:"item_department_code",cellClass:'',class:' b ',show:false},
                    {id:0,name:"Category",value:"category_name",show:true}, 
                    {id:0,name:"Category Code",value:"category_code",show:false}, 
                    {id:0,name:"Brand",value:"brand_name",show:false}, 
                    {id:0,name:"Brand Code",value:"brand_code",show:false}, 
                    {id:0,name:"Manufacturers",value:"manufacturer_name",show:false}, 
                    {id:0,name:"Manufacturer Code",value:"manufacturer_code",show:false},
                    {id:0,name:"Item Cost Price",value:"cost_price_",show:false}, 
                ]

                if (tiers) {
                    tiers.forEach(tier => {
                        headers.push({
                           name:tier.name, 
                           value:tier.key, 
                           class:' b ',show:false
                        })
                    });
                } 

                if (current_table_tab==3) { 
                    headers.push(...[
                        // {id:0,name:"Open Stock",value:this.stock_outlet_code+"_all_opening_stocks",align:"center",show:true}, 
                        {id:0,name:"Ordered",value:this.stock_outlet_code+"_number_stock_receives",align:"center",show:false}, 
                        {id:0,name:"Return Stock",value:this.stock_outlet_code+"_number_stocks_return",align:"center",show:false}, 
                        {id:0,name:"Adjustments",value:this.stock_outlet_code+"_number_stock_adjustments",align:"center",show:false}, 
                        {id:0,name:"Return Sales",value:this.stock_outlet_code+"_number_returned_sales",align:"center",show:false}, 
                        {id:0,name:"Sold",value:this.stock_outlet_code+"_number_sales",align:"center",show:true},  
                        // {id:0,name:"Stock",value:this.stock_outlet_code+"_number_quantity",align:"center",show:true},  
                        
                        {id:0,name:"Tax Group",value:"tax_group_name",align:"center",show:false}, 
                        {id:0,name:"Tax Group Code",value:"tax_group_code",align:"center",show:false}, 
                        {id:0,name:"action",align:"right",show:false,item:[  
                            {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"mdi-pencil"},
                            {name:"Archive "+this.NAME_ITEM,action:"archive_item",icon:"mdi-delete"},
                        ]}, 
                    ])
                }else{ 
                    headers.push(...[
                        {id:0,name:"Open Stock",value:this.stock_outlet_code+"_all_opening_stocks",align:"center",show:true}, 
                        // {id:0,name:"Ordered",value:this.stock_outlet_code+"_number_stock_receives",align:"center",show:true}, 
                        {id:0,name:"Received",value:this.stock_outlet_code+"_number_stock_receives",align:"center",show:true}, 
                        {id:0,name:"Transferred",value:this.stock_outlet_code+"_number_stock_transfers",align:"center",show:false}, 
                        {id:0,name:"Return Stock",value:this.stock_outlet_code+"_number_stocks_return",align:"center",show:false}, 
                        {id:0,name:"Adjustments",value:this.stock_outlet_code+"_number_stock_adjustments",align:"center",show:false}, 
                        {id:0,name:"Return Sales",value:this.stock_outlet_code+"_number_returned_sales",align:"center",show:false}, 
                        {id:0,name:"Sold",value:this.stock_outlet_code+"_number_sales",align:"center",show:true},  
                        {id:0,name:"Stock",value:this.stock_outlet_code+"_number_quantity",align:"center",show:true},  
                        
                        {id:0,name:"Tax Group",value:"tax_group_name",align:"center",show:false}, 
                        {id:0,name:"Tax Group Code",value:"tax_group_code",align:"center",show:false}, 
                        {id:0,name:"action",align:"right",show:false,item:[  
                            {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"mdi-pencil"},
                            {name:"Archive "+this.NAME_ITEM,action:"archive_item",icon:"mdi-delete"},
                        ]}, 
                    ])
                }
                
                return headers
            },
            HEADER_ITEMS2(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"No.",value:'no',show:true},  
                    {id:0,name:"Barcode",value:"code",align:"center",show:true},      
                    {id:0,name:"Name",show:true}, 
                    {id:0,name:"Category",value:"category_code",show:true}, 
                    {id:0,name:"Brand",value:"brand_code",show:true}, 
                    {id:0,name:"Cost",value:"cost_price",show:true}, 
                    {id:0,name:"Retail",value:"retail_price",show:true}, 
                    {id:0,name:"Wholesale",value:"wholesale_price",show:true}, 
                    {id:0,name:"Tax",value:"tax",show:true}, 
                    {id:0,name:"action",align:"right",show:true,item:[  
                        {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"mdi-pencil"},
                        {name:"Delete "+this.NAME_ITEM,action:"delete_item",icon:"mdi-delete"},
                    ]}, 
                ]
            },  
            Selectors(){ 
                let Outlets = this.StockOutletsData 
                let Items = this.ItemsData  
                let ItemCategories = this.ItemCategories
                let Brands = this.Brands
                let Manufacturers = this.Manufacturers
                let TaxGroups = this.TaxGroups
                let PricingTiers = this.AllPricingTiers
                return{
                    Outlets: Outlets,
                    Items: Items,
                    ItemCategories: ItemCategories,
                    Brands: Brands,
                    Manufacturers: Manufacturers, 
                    TaxGroups: TaxGroups, 
                    PricingTiers: PricingTiers, 
                }
            },
            
        },
        methods: {  
            TABLE_ACTION(action,item){
                try {
                    let route = this.route
                    let fullPath = route?route.fullPath:null
                     console.log(action,'action');
                    if (action == "refresh") {
                        let mbs_text = this.MBS.text.item_action("Item Data","Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=RELOAD-ITEMS", 
                            } 
                        })
                    }else if (action == "create_item") {
                        this.CREATE_ITEM()
                    }else if (action == "create_item_kit") {
                        this.CREATE_ITEM_KIT()
                    }else if (action == "manage_items") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/items",true))
                    }else if (action == "manage_item_kit") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/item-kit",true))
                    }else if (action == "receive_stock") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control/receive-stock",true))
                    }else if (action == "stock_control") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/stock/stock-control",true))
                    }else if (action == "warehouse") {
                        this.MBS.actions.go(this.MBS.actions.COMPANY_LINK("/warehouse",true))
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CREATE_ITEM(input){
                try {     
                    this.ADDING_ITEM = {...DATA.ITEMS.ITEMS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.items
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'Create',
                        action_doing:'Creating'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEMS.name,
                            action:"add_item",
                            input:{name:input}
                        }) 
                        console.log(this.ADDING_ITEM,'create item,,,......'); 
                    }, 50);
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            CREATE_ITEM_KIT(input){
                try {     
                    this.ADDING_ITEM = {...DATA.ITEMS.ITEMS}
                    this.ADDING_ITEM.path =  this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)
                    this.ADDING_ITEM.fields =  DATA.FIELDS.items_kit
                    this.ADDING_ITEM.add_item_action =  {
                        action_do:'Create',
                        action_doing:'Creating'
                    }
                    setTimeout(() => {
                        this.MBS.events.$emit("ITEM_ACTION",{
                            NAME_ITEM:DATA.ITEMS.ITEMS.name,
                            action:"add_item",
                            input:{name:input}
                        }) 
                        console.log(this.ADDING_ITEM,'create item,,,......'); 
                    }, 50);
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_ITEM_KIT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ADDED(res){
                try { 
                    this.RELOAD_ITEMS()
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADDED',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            RELOAD_ITEMS(){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    this.MBS.actions.progressDialog({
                        show:true,
                        text:"Refresh..."
                    })
                    this.$store.dispatch('fi_get_item',{
                        name:DATA.ITEMS.ITEMS.values,
                        on:false, 
                        cash:true, 
                        get:false, 
                        company_key:company_key, 
                    }).then(res=>{
                        console.log(res,'res.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.ITEMS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    }).catch(error=>{
                        console.log(error,'error.......');
                        this.MBS.actions.progressDialog()
                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.ITEMS.values,"Refresh")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo, 
                        })
                    })
                } catch (error) {
                    
                }

            },

            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=RELOAD-ITEMS') {
                            if (action.YES) {
                                this.RELOAD_ITEMS()
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            if (!gettingItems) {
                                this.PAGE_LOAD =true
                            }  
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        watch: {  
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            }, 
        },
    }
</script>
 
