<template>
    <div>     
        <mbs-input-fields v-if="show"
            :show="show"
            v-model="input_fields"
            :field_items="field_items"
            :select_items="select_items"
            :disabled_fields="disabled_fields"
            :update_item="update_item"
            :updating_item="updating_item"
            :outlined="outlined"
            :dense="dense"
            :filled="filled"
            :px="px" 
            :py="py"
            />
 
        <!-- //DIALOGS -->
        <div>
             

        </div>
    </div> 
</template>

<script>
    import DATA from '../../plugins/DATA';
    import {mapState} from "vuex" 
    let PAGE_NAME  = "INPUT_FILTERS"  
    export default {
        name:PAGE_NAME,
        props:[
             'value', 
            'data_items',
            'field_items',
            'select_items', 
            'filter_date', 
            'input_filter_Date', 
            'filter_date_value', 
            'filter_date_only_display', 
            'disabled_fields',  
            'update_item',  
            'updating_item',  
            'outlined','dense','filled',  
            'py','px'
        ],
        data() {
            return { 
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,
  
                input_fields:{},   
                input:this.value,
                loading_input:false,
                DATA: DATA,
                show: true,
            }
        },
        created() {
            try {
                // this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);   
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try { 
                let input = this.input  
                this.AT_INPUT_CHANGE()
                
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),  
            moment(){  
                return this.MBS.date.moment
            },
        },
        methods: {  
            FIELDS_ACTION(action){
                try {    
                    this.selected_field_action = action
                    // if (action.action=="add_item") {
                        setTimeout(() => { 
                            this.MBS.events.$emit("ITEM_ACTION",action,action.action)
                        }, 0);
                    // }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FIELD_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            AT_INPUT_CHANGE(){
                try { 
                    let input = this.input  
                    let input_size = this.MBS.actions.SIZE(input)   
                    if (input_size==0) { 
                        this.input_fields = {}
                        this.show=false
                        setTimeout(() => {
                            this.show=true
                        }, 500);
                    }
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FIELD_ACTION',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            FILTER_DATA(){
                try { 
                    let input = this.input_fields 
                    let filtered_input = {}
                    let filters = this.field_items 
                    let filter_date = this.filter_date 
                    let data = this.data_items 
                    let input_filter_Date = this.input_filter_Date 

                    // 
                    if (input) {
                        for (const key in input) {
                            const element = input[key];
                            if (key != 'errors') {
                                if (this.MBS.actions.SIZE(element)) {
                                    filtered_input[key]=element 
                                }
                            } 
                        } 
                    }
                    if(!data){return null}
                    

                    //1. DATE FILTER
                    let date_filter = item=>{  
                        let input_filter_Date = this.input_filter_Date
                        let filter_date_value = this.filter_date_value
                        let filter_date_only_display = this.filter_date_only_display
                        let from_date = input_filter_Date?input_filter_Date.from_date:null
                        let to_date = input_filter_Date?input_filter_Date.to_date:null  
                         
                        if (!input_filter_Date||!filter_date_value||filter_date_only_display) {
                            return true
                        }

                        let mDate = null
                        let date = null
                        if (filter_date_value) {  
                            const date_value = this.toNumber(item[filter_date_value]) 
                            date = this.moment(item[filter_date_value]).format("yyyy-MM-D")  
                            if (!date) {
                                date = this.moment(date_value).format("yyyy-MM-D")  
                            }
                        }else{
                            date = this.moment(item.created_at).format("yyyy-MM-D")
                        }
                        mDate = this.moment(date) 
                        let between = mDate.isBetween(from_date,to_date,'','[]')  
                        return between 
                    }

                    //2. FILTERS
                    let custom_filters = item=>{
                        if (!filtered_input||!filters) {
                            return data
                        }
                        let is = false 
                        filters.forEach(filter => {
                            let item_value = item[filter.search_value?filter.search_value:filter.value]
                            let input_value = filtered_input[filter.value]

                            // if (item_value == "Cash" || input_value == "Cash") {
                            // }
                            // console.log(item_value,input_value,'input_value.... ..*');
                                
                            // if (!input_value) {
                            //     is = true
                            // }
                            if (item_value && this.MBS.actions.SIZE(input_value)) {
                                if (filter.type == "autocomplete"||filter.type == "select") {
                                    //let index = input_value.indexOf(item_value)
                                    let index = input_value.indexOf(item_value)
                                    

                                    if (filter.barcode) { 
                                        index = input_value.findIndex(item=>this.MBS.actions.NUMBER_SAME_LENGTH(item)==
                                        this.MBS.actions.NUMBER_SAME_LENGTH(item_value))
                                    }
                                    if (index==-1) {
                                        is = false
                                    } else {
                                        is = true
                                    }

                                    //include
                                    if(true && input_value && item_value){
                                        if (typeof item_value == "string" ) {
                                            input_value.forEach(value => {
                                                let index2 = item_value.indexOf(value)
                                                if (index2==-1) {
                                                        
                                                } else {
                                                    is = true
                                                }
                                            }); 
                                        }
                                    }
                                } else { 
                                    if (input_value!=item_value) {
                                        is = false
                                    } else {
                                        is = true
                                    }
                                } 
                            }else{    
                            }
                        });
                        return is   
                    }
                    
                   
                    if (!this.MBS.actions.SIZE(filtered_input)) {
                        return data
                    }
                    let filtered = data.filter(item=>{
                        let on_date = date_filter(item)
                        let on_custom = custom_filters(item)
                        let returnValue = on_date && on_custom
                         
                        return returnValue
                    })
                    return filtered
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FILTER_DATA',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            INPUT_VALUE(){
                try { 
                    let input = this.input_fields 
                    let filters = this.field_items   

                    console.log(filters,'filters.....') 
                    if(!filters||!input){return null}
                    let filtered = []
                    filters.forEach(filter => {
                        if (this.MBS.actions.SIZE(input[filter.value])) {
                            filtered.push({
                                value:filter.value,
                                label:filter.label,
                                type:filter.type,
                                input:input[filter.value],
                                filter:filter
                            })  
                        }
                    }); 
                    return this.MBS.actions.SIZE(filtered)?filtered:null
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'INPUT_VALUE',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
            
            //---------------[MAIN FUNCTIONS]------------
            DIALOG_YES(action){ 
                try {
                    if (action.code) {  
                        if (action.code ===PAGE_NAME+'=AUTO-SET-CODE') { 
                            if (action.YES) {
                                setTimeout(() => { 

                                }, this.MBS.data.ACTION_REFRESH_TIME); 
                            } else {
                                
                            }
                        } 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
        },
        watch: { 
            us(v){ 
            },  
            value(value) { 
                this.input = value
            },
            input(currentValue,change_data) { 
                this.AT_INPUT_CHANGE()    
            },
            input_filter_Date(value) { 
                let data_filtered = this.FILTER_DATA()
                let input_value = this.INPUT_VALUE()
                this.input.input_filter_Date=value
                this.input.input_fields=this.input_fields
                this.input.data_filtered=data_filtered
                this.input.input_value=input_value
                this.input.data_items =this.data_items 
                
                this.$emit('input',{...this.input}) 
            }, 
            input_fields(currentValue) { 
                let data_filtered = this.FILTER_DATA()
                let input_value = this.INPUT_VALUE()
                this.input.input_fields=currentValue
                this.input.input_filter_Date=this.input_filter_Date
                this.input.data_filtered=data_filtered
                this.input.input_value=input_value
                this.input.data_items =this.data_items 
                
                this.$emit('input',{...this.input}) 
            },    
        },
  }
</script>