<template>
    <div>   
        <v-container grid-list-xl > 
            <div> 
                <br>
                <h1 class="grey--text text--darken-1">Stock Adjustment</h1>
                <br>
                <form id="form_order_details" @submit.prevent="CREATE_ORDER()" >
                    <v-row  >
                        <v-col cols="12" md="4">
                            <h4 class="grey--text text--darken-1">Order Details</h4>
                            <div class="text-justify">
                                By adjusting stock, you are adding new quantities of the stock in your warehouse. This helps you increase or decrease the goods you hold in stock. It is generally used to write-off damaged stock, or to adjust quantities after a stock take. 
                            </div>
                        </v-col> 
                        <v-col cols="12" md="8"  > 
                            <mbs-page-spinner v-if="!show_input"
                                height="500"/>  
                            <mbs-input-fields v-else
                                v-model="input2" 
                                :field_items="DATA.FIELDS.adjust_stock"
                                :select_items="DataSource"
                                :disabled_fields="DisabledFields"
                                />  
                        </v-col> 
                    </v-row> 
                    <br><br><br>
                    <hr>
                    <br><br><br>
                    <h4 class="grey--text text--darken-1">Items</h4>
                    <v-row >
                        <v-col cols="12" md="3">
                            <div class="text-justify">
                               Select items you want to adjust by searching or importing the excel document.
                            </div>
                        </v-col>
                        <v-col cols="12" md="9">
                            <v-card :height="vsh-170" outlined rounded="sm">
                                <v-layout fill-height column class="ma-0">
                                    <div>
                                        <v-toolbar rounded="lg" dark color="primary" class="ma-">
                                            <v-autocomplete 
                                                v-model="search_item"
                                                :disabled="inputLoading"
                                                :items="MainInventoryItems" dense
                                                append-icon="search"
                                                outlined :rounded="false"
                                                label="Search or Scan to Add Item"
                                                color="blue-grey lighten-2" 
                                                item-text="name"
                                                item-value="key" 
                                                @update:search-input="AT_SEARCH_INPUT"
                                                :filter="(item, queryText, itemText) => { 
                                                        let index = (a,b)=>{
                                                            if(!a||!b){return false}
                                                            a = ''+a; b = ''+b
                                                            return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                                        } 
                                                        let first = index(itemText,queryText)
                                                        let second = item?index(item.code,queryText):false 
                                                        if(!item){return first} 
                                                        return first || second 
                                                    }"
                                                >
                                                <template v-slot:prepend-item  v-if="!search_input">
                                                    <v-row class="ma-0 pl-3 pr-2">
                                                        <v-col cols="12" class="px-0"
                                                            :sm="'6'" >
                                                            <v-btn  
                                                                color="primary" 
                                                                @click="ADD_NEW_ITEM()"
                                                                class=" my-2" width="97%"  
                                                                depressed  >
                                                                <v-icon  >add</v-icon>
                                                                <div class="ml-2 font-weight-bol">
                                                                    Add New Item
                                                                </div>
                                                            </v-btn>  
                                                        </v-col> 
                                                        <v-col cols="12" class="px-0"
                                                            :sm="'6'" >
                                                            <v-btn  
                                                                color="primary" 
                                                                :to="MBS.actions.COMPANY_LINK('/stock/items',true)"
                                                                class=" my-2" width="97%"  
                                                                depressed  >
                                                                <v-icon  >add</v-icon>
                                                                <div class="ml-2 font-weight-bol">
                                                                    Manage Items
                                                                </div>
                                                            </v-btn>  
                                                        </v-col> 
                                                    </v-row>
                                                </template>
                                                <template v-slot:no-data   v-if="search_input">
                                                    <v-row class="ma-0 pl-3 pr-2">
                                                        <v-col cols="12" class="px-0"
                                                            :sm="'12'"  >
                                                            <v-btn  
                                                                color="primary" 
                                                                @click="ADD_NEW_ITEM(search_input)"
                                                                class=" my-2" width="97%"  
                                                                depressed  >
                                                                <v-icon >add</v-icon> 
                                                                <div class="ml-2 font-weight-bol ">
                                                                    Add "{{search_input}}" as New Item
                                                                </div>
                                                            </v-btn> 
                                                        </v-col> 
                                                    </v-row>
                                                </template> 
                                                <template v-slot:selection="data">
                                                    {{ data.item.name }}
                                                </template>
                                                <template v-slot:item="data">
                                                    <template v-if="(typeof data.item !=='object')" >
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                    </template>
                                                    <template v-else>
                                                    <v-list-item-avatar tile color="grey">
                                                        <img :src="data.item.image" v-if="data.item.image">
                                                        <v-icon v-else>local_offer</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                        <v-list-item-subtitle v-html="data.item.code_"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete> 
                                            <v-menu  
                                                v-model="menu_selling_mode"
                                                :close-on-content-click="true" 
                                                offset-y  offset-overflow
                                                :nudge-width="90" rounded="xl"
                                                >
                                                <template v-slot:activator="{ on, attrs }">  
                                                    <v-btn  v-on="on" class=" ml-5" :loading="excel_loading"
                                                        ref="btn_selling_mode" 
                                                        color="secondary">
                                                        <v-icon class="mr-2">mdi-microsoft-excel </v-icon>
                                                            {{selling_model?selling_model:'Select'}}
                                                        <v-icon class="ml-2">arrow_drop_down</v-icon>
                                                    </v-btn> 
                                                </template> 

                                                <v-card  v-if="ud"  
                                                    color="lighten-1" class="">  
                                                    <v-card-title class="secondary white--text py-" dark>
                                                        Select 
                                                    </v-card-title> 
                                                    <v-list dense> 
                                                        <div v-for="(model,index) in sellingModels" :key="index">
                                                            <v-list-item 
                                                                :class="model == selling_model?'secondary--text':''" 
                                                                @click="SELECT_ACTION(model)">{{model.name}}</v-list-item> 
                                                        </div>
                                                    </v-list>  
                                                </v-card>  
                                            </v-menu> 
                                        </v-toolbar> 
                                    </div>
                                    <mbs-adjust-stock-selected-items
                                        v-model="input3"
                                        :height="(vsh-320)" 
                                        :outlet_code="input2.outlet_code"
                                        :stock_adjustment_option_code="input2.stock_adjustment_option_code" 
                                        @checked_items="checked_items=$event"
                                        @item_multiple_select="item_multiple_select=$event"
                                    />

                                    <v-spacer></v-spacer>
                                    <v-divider></v-divider>
                                    
                                    <input ref="btn_submit_order" type="submit" v-show="false">
                                    <v-card @click="REMOVE_ITEM(checked_items)"  v-if="MBS.actions.SIZE(checked_items) && item_multiple_select" 
                                        :disabled="MBS.actions.SIZE(checked_items)<1 || inputLoading" 
                                        :flat="MBS.actions.SIZE(checked_items)<1" 
                                        hover  
                                        class="mx-5 my-4 v-btn" :color="MBS.actions.SIZE(checked_items)<1?'grey darken-1':'primary'" dark >
                                        <v-layout class="ma-0 py-2 px-4" align-center v-if="!inputLoading">
                                            <v-icon class="mr-2">delete</v-icon>
                                            <div class="font-weight-bold not-f7">Remove</div>
                                            <div class="pl-2 font-weight-bold not-f3">{{MBS.actions.SIZE(checked_items)}} Items</div>
                                            <v-spacer></v-spacer>
                                            <div class="font-weight-bold not-f7">TOTAL CHANGES {{CALCULATE_TOTAL_CHANGE(checked_items)
                                                }}</div> 
                                        </v-layout> 
                                        <v-layout v-else class="ma-0 pa-2" justify-center>
                                            <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                                        </v-layout>
                                    </v-card>
                                    <v-card v-else @click="SUBMIT()"  
                                        :disabled="MBS.actions.SIZE(SelectedItems)<1 || inputLoading" 
                                        :flat="MBS.actions.SIZE(SelectedItems)<1" 
                                        hover  
                                        class="mx-5 my-4 v-btn" :color="MBS.actions.SIZE(SelectedItems)<1?'grey darken-1':'primary'" dark >
                                        <v-layout class="ma-0 py-2 px-4" align-center v-if="!inputLoading">
                                            <div class="font-weight-bold not-f7">Adjust</div>
                                            <div class="pl-2 font-weight-bold not-f3">{{MBS.actions.SIZE(SelectedItems)}} Items</div>
                                            <v-spacer></v-spacer>
                                            <div class="font-weight-bold not-f7">TOTAL CHANGES {{CALCULATE_TOTAL_CHANGE(SelectedItems)
                                                }}</div> 
                                        </v-layout> 
                                        <v-layout v-else class="ma-0 pa-2" justify-center>
                                            <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                                        </v-layout>
                                    </v-card>
                                </v-layout> 
                            </v-card>    
                        </v-col> 
                    </v-row> 
                </form>
            </div> 
        </v-container>  

        <!-- //DIALOGS -->
        <div> 
            <!-- EXCEL IMPORTED ITEMS -->
            <v-dialog
                v-model="dialog_import_excel_items"
                :scrollable="true"  
                :persistent="false" :overlay="true"
                max-width="800px"
                transition="dialog-transition" >
                <v-card height="100%" rounded="sm" color="b1" v-if="excel_selected_items">
                    <v-card-title class="primary pa-2" >
                        <v-list-item dark>
                            <v-list-item-action>
                                <v-icon>mdi-microsoft-excel</v-icon> 
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>IMPORT EXCEL ITEMS</v-list-item-title> 
                                <v-list-item-subtitle>{{ MBS.actions.SIZE(excel_selected_items)}} Item(s) Found</v-list-item-subtitle>  
                            </v-list-item-content>  
                            <v-list-item-action>
                                <v-btn icon @click="CLOSE_EXCEL_IMPORT()"><v-icon>mdi-close</v-icon></v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-card-title>
                    <v-card tile outlined class="mx-5 my-2" v-if="excel_selected_items"> 
                        <v-simple-table  class="  mr-2 ml-4" dense :height="vsh-250" fixed-header > 
                            <thead>  
                                <tr class="font-weight-bold "> 
                                    <th class="ma-0 pa-0 " style="width:'5px'"> 
                                        <!-- <v-switch :inset="false"    style="height: ''; margin: 5px;"
                                            v-model="item_multiple_select"
                                            label=""   hide-details
                                            class="ml-1"
                                        ></v-switch>  -->
                                    </th>
                                    <th class=" text-left b  ">
                                        ITEM NAME
                                    </th> 
                                    <th class="text-center  b  ">
                                        CURRENT    
                                    </th>
                                    <th class="text-center  "> 
                                        CHANGE 
                                    </th>
                                    <th class="text-center  ">
                                        NEW QUANTITY
                                    </th>
                                    <th class="text-center  b  "> 
                                    </th>  
                                </tr>
                                
                                <!-- <v-divider /> -->
                            </thead>
                            <tbody>
                                <tr class="pointer "  
                                    v-for="(item,index) in excel_selected_items"
                                    :key="item.key" 
                                    :class="item.checked?'checked':''" 
                                    > 
                                    <td class=" text-center with-divider-lr  ma-0 pa-0 table-background1" style="width:40px;"> 
                                        <v-simple-checkbox @click.stop="" class="ma-0 pa-0"
                                            v-if="false" icon 
                                            color="primary" 
                                            :value="item.checked" 
                                            @input="INPUT_UPDATE($event, item,true)" 
                                            />  
                                        <span v-else icon >
                                            <v-icon v-if="item.expand">expand_more</v-icon>
                                            <v-icon v-else>chevron_right</v-icon>
                                        </span>  
                                    </td>
                                    <td class=" with-divider ">
                                        {{ item.name }}
                                    </td>
                                    <td class=" text-center with-divider "> 
                                        {{ toNumber(item[input2.outlet_code+'_number_quantity']) }}
                                    </td>  
                                    <td class=" text-center pa-0 with-divider " style="width:130px; position: relative;">
                                        {{ item.quantity }}
                                    </td>
                                    <td class=" text-center pa-0  with-divider  " style="width: 130px;">
                                        {{ item.new_quantity }}
                                    </td> 
                                    <td class=" text-center with-divider  px-2">
                                        <v-btn icon class="ma-0 pa-0" @click.stop="REMOVE_EXCEL_ITEM(item,index)"><v-icon>delete</v-icon></v-btn>
                                    </td>
                                </tr>
                                <v-divider />
                            </tbody>
                        </v-simple-table> 
                    </v-card>  
                    <v-layout  class="ma-0 px-5 pb-2">
                        <v-flex >
                            <v-btn @click="IMPORT_EXCEL_ITEMS()" width="100%" color="primary">Import Items</v-btn> 
                        </v-flex>
                        <v-btn @click="CLOSE_EXCEL_IMPORT()" width="180" class="ml-1" color="secondary">Cancel</v-btn> 
                    </v-layout>
                </v-card> 
            </v-dialog>  
            <!-- select -->
            <div v-if="excelSelector" >
                <mbs-excel-selector 
                    :notShow="excelSelector"
                    @notNo="excelSelector =!excelSelector"
                    @notYes="FILE_SELECTED"/>
            </div>

            <mbs-excel-selector
                :notShow="false"
                @onFileSelected="EXCEL_FILE_SELECTED" 
                @onNoFileSelected="NO_EXCEL_FILE_SELECTED" 
                @onLoadingFile="LOADING_FILE" 
                @onError="ON_FILE_ERROR" 
                />  
            <!-- export excel -->
            <mbs-excel-export 
                :file_name="ExcelTemplate.name"
                :sheet_name="ExcelTemplate.sheet_name"
                :data_items="ItemData?ItemData:ExcelTemplate.sample_data"
                :header_items="ExcelTemplate.headers"
            /> 
            <mbs-item-manager
                :show="false" 
                :name_items="DATA.ITEMS.ITEMS.names"
                :name_item="DATA.ITEMS.ITEMS.name"
                :value_items="DATA.ITEMS.ITEMS.values"
                :value_item="DATA.ITEMS.ITEMS.value"
                :field_items="DATA.FIELDS.items"   
                :path_item="MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)"  
                :select_items="DataSource"   
            />
        </div>
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import DB from '../../../plugins/DB';
    import {mapState,mapGetters } from "vuex"
    let PAGE_NAME = "TRANSFER-STOCK"  
    export default {
        name:PAGE_NAME, 
        data() { 
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10, 
                search_item:'',  
                inputLoading:false,
                input:{},
                input2:{},
                input3:{},
                search_input:null, 
                mask_money:"### ### ###", 
                DATA:DATA, 
                order_code:null, 
                show_input:true,
                excel_selected_items:null, 
                dialog_import_excel_items:false, 
                excel_loading:false, 
                menu_selling_mode:false, 
                sellingModels:[
                    {code:1,icon:"",name:"Clear Item(s)"},
                    {code:2,icon:"",name:"Import Excel"},
                    {code:3,icon:"",name:"Download Excel Template"},
                ], 
                item_multiple_select:true,
                checked_items:null,
                selling_model:null,
                excelSelector:false,
                
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
                this.INITIALIZE_LOCAL_ADJUSTMENT_DETAILS() 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.MBS.actions.toolbar({
                    show:true, 
                }) 
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
             
            /////////    
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                ItemCategories: state=> state.items[(DATA.ITEMS.ITEM_CATEGORIES.values).toUpperCase()],
                Brands: state=> state.items[(DATA.ITEMS.BRANDS.values).toUpperCase()],
                Manufacturers: state=> state.items[(DATA.ITEMS.MANUFACTURERS.values).toUpperCase()], 

                StockOrders: state=> state.items[(DATA.ITEMS.STOCK_ORDERS.values).toUpperCase()],
                JoinedStockOrders: state=> state.join[(DATA.ITEMS.STOCK_ORDERS.values).toUpperCase()],
                QuotationsAndInvoices: state=> state.items[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
                JoinedQuotationsAndInvoices: state=> state.join[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
                Suppliers: state=> state.items[(DATA.ITEMS.SUPPLIERS.values).toUpperCase()],
                SupplierCategories: state=> state.items[(DATA.ITEMS.SUPPLIER_CATEGORIES.values).toUpperCase()],
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                PaymentMethods: state=> state.items[(DATA.ITEMS.DEFAULT_PAYMENT_METHODS.values).toUpperCase()], 
                SelectedCompany: state=> state.items["SELECTED_COMPANY"], 
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                StockAdjustmentReasons: state=> state.app["STOCK_ADJUSTMENT_REASONS"], 
                StockAdjustmentReasonTypes: state=> state.app["STOCK_ADJUSTMENT_REASON_TYPES"], 
                PricingTiers: state=> state.items[(DATA.ITEMS.PRICING_TIERS.values).toUpperCase()], 
                JoinedPricingTiers: state=> state.join[(DATA.ITEMS.PRICING_TIERS.values).toUpperCase()], 
                DefaultPricingTiers: state=> state.app['DEFAULT_PRICING_TIERS'], 
            }), 
            PricingTiersData(){
                return this.JoinedPricingTiers?this.JoinedPricingTiers:this.PricingTiers
            }, 
            AllPricingTiers(){
                let defaults = this.DefaultPricingTiers
                let tiers = this.PricingTiersData 
                let all = []
                if (defaults) {
                    all.push(...defaults)
                }if (tiers) {
                    tiers.forEach(tier => {
                        let index = all.findIndex(item=>item.key == tier.key)
                        if (index==-1) {
                            all.push(tier) 
                        } else {
                            all[index] = {
                                ...all[index],
                                ...tier
                            }
                        }
                    });
                }
                return all
            }, 
            ItemData(){ 
                let Items = this.Items
                let JoinedItems = this.JoinedItems
                // let CalculatedItems = this.CalculatedItems
                // if (CalculatedItems) {
                //     return CalculatedItems
                // }else  
                if (JoinedItems) {
                    return JoinedItems
                } 
                return Items
            },
            MainInventoryItems(){ 
                const items = this.ItemData 
                const filtered = items?.filter(item=>{
                    const service = item.item_type_key == "service"
                    const child = item.item_type_key == "child"
                    const parent = item.item_type_key == "parent"
                    const inventory_item_code = item?.inventory_item_code
                    const inventory_item_key = item?.inventory_item_key
                    const inventory_item_keycode = inventory_item_key?inventory_item_key:inventory_item_code
                    const inventory = !inventory_item_keycode || inventory_item_keycode == "main"?true:false
                    return inventory && !service&& !child&& !parent
                })  
                return filtered
            },
            AllInventoriesItems(){ 
                const items = this.ItemData 
                const filtered = items?.filter(item=>{
                    const service = item.item_type_key == "service"
                    const child = item.item_type_key == "child"
                    const parent = item.item_type_key == "parent"
                    const inventory_item_code = item?.inventory_item_code
                    const inventory_item_key = item?.inventory_item_key
                    const inventory_item_keycode = inventory_item_key?inventory_item_key:inventory_item_code
                    const inventory = !inventory_item_keycode || inventory_item_keycode == "main"?true:false
                    return inventory  
                })  
                return filtered
            },  
            StockOrdersData(){ 
                let StockOrders = this.StockOrders
                let JoinedStockOrders = this.JoinedStockOrders 
                return JoinedStockOrders?JoinedStockOrders:StockOrders
            }, 
            RemoveStockReasons(){ 
                let reasons = this.StockAdjustmentReasons 
                if(!reasons){return null}
                let filtered = reasons.filter(item=>item.type == "remove"||item.type == "both")
                return reasons
            }, 
            AddStockReasons(){ 
                let reasons = this.StockAdjustmentReasons 
                if(!reasons){return null}
                let filtered = reasons.filter(item=>item.type == "add"||item.type == "both")
                return filtered
            }, 
            
            RequestForQuotations(){
                let items = this.JoinedQuotationsAndInvoices
                if(!items){return null}
                let filtered_items = items.filter(quotation => {
                    return this.MBS.actions.TEXT_UP(quotation.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.REQUEST_FOR_QUOTATIONS.value)  
                });
                return filtered_items
            }, 
            Quotations(){
                let items = this.Joined_QuotationsAndInvoices
                if(!items){return null}
                let filtered_items = items.filter(quotation => {
                    return this.MBS.actions.TEXT_UP(quotation.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.QUOTATIONS.value)  
                });
                return filtered_items
            }, 
            Invoices(){
                let items = this.JoinedQuotationsAndInvoices
                if(!items){return null}
                let filtered_items = items.filter(quotation => {
                    return this.MBS.actions.TEXT_UP(quotation.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.INVOICES.value)  
                });
                return filtered_items
            },  
            Outlet(){
                let input = this.input2
                let outlet_code = input?input.outlet_code:null
                if(!outlet_code){return null} 
                let outlet = this.$store.getters.getStockOutletByCode(outlet_code)
                return outlet
            },  
            ReceivedOrders(){
                let items = this.JoinedStockOrders
                if(!items){return null}
                let filtered_items = items.filter(item => {
                    return this.MBS.actions.TEXT_UP(item.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.STOCK_RECEIVES.value)  
                });
                return filtered_items
            },  
            CALCULATE_TOTAL_CHANGE(){
                return items=>{
                    let total_change = 0 
                    try { 
                        if (!items) {return 0}
                        items.forEach(item => { 
                            const quantity = this.toNumber(item.quantity)  
                            total_change = total_change+quantity
                        });  
                        return total_change
                    } catch (error) {
                        this.MBS.actions.error({
                            error:error,
                            from:'CALCULATE_TOTAL_CHANGE',
                            page:PAGE_NAME, 
                        }) 
                        return 0
                    } 
                } 
            }, 
            DataSource(){
                let Suppliers = this.Suppliers
                let Outlets = this.Outlets
                let PaymentMethods = this.PaymentMethods
                let RequestForQuotations = this.RequestForQuotations
                let Quotations = this.Quotations
                let Invoices = this.Invoices
                let ReceivedOrders = this.ReceivedOrders
                let SupplierCategories = this.SupplierCategories
                let RemoveStockReasons = this.RemoveStockReasons 
                let AddStockReasons = this.AddStockReasons 
                let StockAdjustmentReasonTypes = this.StockAdjustmentReasonTypes 
                let PricingTiers = this.AllPricingTiers 
                return {
                    Suppliers:Suppliers,
                    Outlets:Outlets,
                    PaymentMethods:PaymentMethods,
                    RequestForQuotations:RequestForQuotations,
                    Quotations:Quotations,
                    Invoices:Invoices,
                    ReceivedOrders:ReceivedOrders,
                    SupplierCategories:SupplierCategories,
                    RemoveStockReasons:RemoveStockReasons,
                    AddStockReasons:AddStockReasons,
                    StockAdjustmentReasonTypes:StockAdjustmentReasonTypes,
                    PricingTiers: PricingTiers, 
                }
            },
            DisabledFields(){
                let order_code = this.order_code 
                if (order_code) {
                    return []
                }
                return []
            },
            SelectedItems(){
                let input = this.input3 
                let selected_items = input?input.selected_items:null
                return selected_items
            }, 
            ExcelTemplate(){
                let outlet_code = this.input2.outlet_code
                let excel_template = {
                    name:outlet_code+"STOCK ADJUST TEMPLATE (v1)",
                    sheet_name:"items",
                    headers:[
                        {id:0,name:"code",value:"code",align:"center",show:true},      
                        {id:0,name:"name",value:"name",show:true},  
                        {id:0,name:"cost_price",value:"cost_price_",show:true}, 
                        {id:0,name:"current_quantity",value:outlet_code+"_number_quantity",show:true}, 
                        {id:0,name:"change_quantity",value:"quantity",import_name_value:true,show:true},  
                        {id:0,name:"new_quantity",value:"new_quantity",show:true},  
                    ],
                    sample_data:[
                        {code:1,name:"Sample Item",cost_price:1000,discount:0.2,quantity:10},   
                    ],
                }
                return excel_template
            } 
        },
        methods: {  
            ADD_NEW_ITEM(input){
                try {    
                    this.MBS.events.$emit("ITEM_ACTION",{
                        NAME_ITEM:DATA.ITEMS.ITEMS.name,
                        action:"add_item",
                        input:{name:input}
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_NEW_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            AT_SEARCH_INPUT(input){
                try {  
                    this.search_input = input
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'AT_SEARCH_INPUT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            TABLE_ACTION(action,item){
                try {
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SELECT_ACTION(action,item){
                try {
                    console.log(action,111111);
                    if (!action) {
                        
                    }else if (action.code == 1) {
                        let mbs_text = this.MBS.text.item_action("All Selected Items","Clear")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            icon:'delete',
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            btnNo:mbs_text.btnNo,
                            action:{
                                code:PAGE_NAME+"=CLEAR-ITEMS",  
                            }
                        }) 
                        
                    }else if (action.code == 2) {
                        let input = this.input2
                        let outlet_code = input?input.outlet_code:null
                        if (outlet_code) {
                            this.SELECT_EXCEL()  
                        } else {
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:"SELECT OUTLET",
                                text:"You must select outlet first before importing or selecting item(s)",
                                btnYes:"Okay",
                                action:{
                                    code:PAGE_NAME+"=SELECT-OUTLET"
                                }
                            })
                        }
                    }else if (action.code == 3) { 
                        let input = this.input2
                        let outlet_code = input?input.outlet_code:null
                        if (outlet_code) {
                            this.EXPORT_EXCEL()
                        } else {
                            this.MBS.actions.dialog({
                                show:true,
                                fixed:true,
                                title:"SELECT OUTLET",
                                text:"You must select outlet first before importing or selecting item(s)",
                                btnYes:"Okay",
                                action:{
                                    code:PAGE_NAME+"=SELECT-OUTLET"
                                }
                            })
                        }
                    }else {
                        
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            FILE_SELECTED(wb){
                try {
                    this.WB = wb
                    this.excelSelector = false
                    this.EXCEL_DATA_ITEMS=[]  
                    let worksheet_item = wb.Sheets[this.MBS.actions.TEXT_UP0(this.VALUE_ITEMS)]  
                    if (!worksheet_item) {
                        this.input.check = true
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:this.MBS.text.working_sheet_not_found(this.NAME_ITEMS).title,
                            text:this.MBS.text.working_sheet_not_found(this.NAME_ITEMS).text,
                            btnYes:this.MBS.text.working_sheet_not_found(this.NAME_ITEMS).btnYes,
                            btnNo:this.MBS.text.working_sheet_not_found(this.NAME_ITEMS).btnNo,
                            action:{
                                code:PAGE_NAME+"=SELECT-EXCEL" 
                            }
                        })  
                    } else {
                        let json_item = this.XLSX.utils.sheet_to_json(worksheet_item)  
                        let data_item = []
                        let number = 0
                        json_item.forEach(excel_item => {
                            let dt = new Date
                            let date = dt.toISOString()

                            //CREATE ARRAY
                            let item_array = {}
                            this.FIELD_ITEMS.forEach(element => {
                                if (excel_item[element.value] && element.value) {
                                    item_array[element.value] = excel_item[element.value] 
                                }
                            }); 
                            //ADD
                            data_item.push({
                                ...item_array,
                                created_at:date, 
                                uid:this.us.uid,
                                no:number
                            })
                        }); 
                        this.EXCEL_DATA_ITEMS = data_item
                        this.dialog_selected_item = true 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FILE_SELECTED',
                        page:PAGE_NAME, 
                    }) 
                }
            }, 
            INITIALIZE_LOCAL_ADJUSTMENT_DETAILS(){
                try { 
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    DB.getItems({ 
                        name:DATA.ITEMS.LOCAL_STOCK_ADJUSTMENT_DETAILS.values,
                        key:company_key,  
                    }).then(res=>{
                        let data = res?res.data:null
                        if (data) {
                            this.input2 = data 
                            this.show_input = false
                            setTimeout(() => {
                                this.show_input = true
                            }, 1000);
                        }
                        console.log(this.input2,'data');
                    }).catch(error=>{
                        console.log(error,'err...');
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'INITIALIZE_LOCAL_ADJUSTMENT_DETAILS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            UPDATE_LOCAL_STOCK_ADJUSTMENT_DETAILS(input){
                try {
                    let company = this.SelectedCompany
                    let company_key = company?company.company_key:null
                    let details = {
                        ...input,
                        key:company_key
                    } 
                    DB.addItems({
                        data:details,
                        name:DATA.ITEMS.LOCAL_STOCK_ADJUSTMENT_DETAILS.values, 
                        update_locals:false,
                            
                    })
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'UPDATE_LOCAL_STOCK_ADJUSTMENT_DETAILS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SUBMIT(){
                try {
                    let btn_submit_order = this.$refs.btn_submit_order
                    if (btn_submit_order) { 
                        btn_submit_order.click()
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ADD_ITEM(item_key){
                try {
                    let input = this.input2
                    let outlet_code = input?input.outlet_code:null 
                    let items = this.ItemData

                    let company = this.SelectedCompany 
                    let company_key = company?company.key:null
                    const settings = this.CompanySettings  
                    const duplicate_selling_item = settings?settings.duplicate_selling_item:null


                    this.MBS.events.$emit("ADD_SELECTED_SELLING_ITEMS",{
                        outlet_code:outlet_code,
                        item_key:item_key,  
                        company_key:company_key, 
                    })  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            async CLEAR_ITEMS(){
                try {
                    await DB.deleteItems({
                        name:DATA.ITEMS.LOCAL_SELECTED_STOCK_ADJUSTMENTS.values, 
                        update_locals:true, 
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CLEAR_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            async CLEAR_DETAILS(){
                try {

                    console.log('del11111111111111111111.....'); 
                    this.show_input = false
                    this.input2 = {}
                    await DB.deleteItems({
                        name:DATA.ITEMS.LOCAL_STOCK_ADJUSTMENT_DETAILS.values, 
                        update_locals:true, 
                    })  

                    setTimeout(async() => {
                        this.show_input = true
                        console.log('de222222222222222.....');
                    }, 1000);
                    
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CLEAR_DETAILS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CLOSE_EXCEL_IMPORT(){
                try {
                    this.dialog_import_excel_items = false
                    this.excel_selected_items = null
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CLOSE_EXCEL_IMPORT',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            REMOVE_ITEM(data){
                try {   
                    if (data) {
                        data.forEach(item => {
                            DB.deleteItems({
                                name:DATA.ITEMS.LOCAL_SELECTED_STOCK_ADJUSTMENTS.values,
                                key:item.key,
                                update_locals:true, 
                            }) 
                            
                        });
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'REMOVE_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            REMOVE_EXCEL_ITEM(item){
                try {
                    let items = this.excel_selected_items
                    if(!items ||!item){return}
                    let filtered = items.filter(it=>it.key != item.key)
                    this.excel_selected_items = filtered
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'REMOVE_EXCEL_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            async IMPORT_EXCEL_ITEMS(){
                try {
                    let items = this.excel_selected_items
                    if(!items){return}
                    let import_items = []
                    const moment = this.MBS.date.moment
                    let selecting_list_key = moment?moment().valueOf():null
                    items.forEach((item,index) => {
                        selecting_list_key=selecting_list_key-index
                        import_items.push({
                            ...item,
                            _key:item.key,
                            _code:item.code,
                            key:selecting_list_key,
                            selecting_list_key:selecting_list_key,
                        })
                    });
                    let res = await DB.addItems({
                        data:import_items,
                        name:DATA.ITEMS.LOCAL_SELECTED_STOCK_ADJUSTMENTS.values, 
                        update_locals:true,   
                    })
                    this.CLOSE_EXCEL_IMPORT()
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'IMPORT_EXCEL_ITEMS ',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            async KEEP_SELECTED_ITEMS(){
                try {
                    let items = this.SelectedItems
                    let input = this.input2
                    let outlet_code = input?input.outlet_code:null
                    if(!items ||!outlet_code){return}
                    let import_items = []
                    const moment = this.MBS.date.moment
                    let selecting_list_key = moment?moment().valueOf():null
                    items.forEach((item,index) => {
                        let number_quantity = this.toNumber(item[outlet_code+'_number_quantity'])
                        let quantity = this.toNumber(item.quantity)
                        import_items.push({
                            ...item,
                            new_quantity:number_quantity+quantity, 
                        })
                    });
                    let res = await DB.addItems({
                        data:import_items,
                        name:DATA.ITEMS.LOCAL_SELECTED_STOCK_ADJUSTMENTS.values, 
                        update_locals:true,   
                    })
                    this.CLOSE_EXCEL_IMPORT()
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'IMPORT_EXCEL_ITEMS ',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let price = this.toNumber(item.price)
                    let discount = this.toNumber(item.discount)
                    let quantity = this.toNumber(item.quantity)
                    return quantity*(price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CREATE_ORDER(){
                try {
                    let input = this.input2 
                    let code = input?input.code:null  
                    let outlet_code = input?input.outlet_code:null  
                    let stock_adjustment_option_code = input?input.stock_adjustment_option_code:null  
                    let stock_adjustment_reason_code = input?input.stock_adjustment_reason_code:null  
                    let adjusted_date = input?input.adjusted_date:null   
                    let description = input?input.description:null
                    let selected_items = this.SelectedItems
                    let items_size = this.MBS.actions.SIZE(selected_items)

                    if (items_size<1) {
                        console.log("error..");
                    }
                    
                    let stock_order_items = []
                    selected_items.forEach(element => {  
                        stock_order_items.push({
                            item_key:element._key, 
                            key:element._key, 
                            code:element.code, 
                            average_cost_price:this.toNumber(element.average_cost_price),  
                            cost_price:this.toNumber(element.cost_price),  
                            retail_price:this.toNumber(element.retail_price),  
                            wholesale_price:this.toNumber(element.wholesale_price),  
                            quantity:this.toNumber((element.quantity)), 
                            new_quantity:this.toNumber((element.new_quantity)), 
                            current_quantity:this.toNumber((element[outlet_code+'_number_quantity'])), 
                        })
                    });
                    let order_details = {
                        code:code,   
                        outlet_code:outlet_code,    
                        stock_adjustment_option_code:stock_adjustment_option_code,    
                        stock_adjustment_reason_code:stock_adjustment_reason_code,    
                        order_type:DATA.ITEMS.STOCK_ADJUSTMENTS.value,
                        items:stock_order_items
                    }
                     
                    if (adjusted_date) {
                        order_details.adjusted_date = adjusted_date
                    }  
                    if (description) {
                        order_details.description = description
                    }  
  
                    console.log(order_details,'order_details.....');
                    let mbs_text = this.MBS.text.item_action(DATA.ITEMS.STOCKS.name,"Adjust")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=CREATE-ORDER", 
                            data:order_details
                        }
                    })
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_ORDER',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SUBMIT_ORDER(order){
                try {  
                    let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.stock_orders,true) 
                    if (!order || !path) {
                        console.log(order,'order error');
                    } 
                    console.log(order,'order......');
                     
                    this.inputLoading = true
                    this.MBS.actions.progressDialog({
                        show:true,
                        title:"Uploading..",
                    }) 
                    this.$store.dispatch("fi_add",{
                        action:DATA.ITEMS.STOCK_ORDERS.values,
                        data:order,
                        path:path
                    }).then(res=>{ 
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.STOCKS.value,"Adjust")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        }) 
                        // let quotationLink = this.MBS.actions.COMPANY_LINK("/stock/stock-control/",true)
                        this.CLEAR_ITEMS()
                        this.CLEAR_DETAILS()
                        // this.MBS.actions.go(-1) 
                         
                    }).catch(error=>{
                        this.MBS.actions.error({
                            error:error,
                            from:'SUBMIT_ORDER',
                            page:PAGE_NAME, 
                        })  
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_failed(DATA.ITEMS.STOCKS.value,"Adjust")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_ORDER',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ADD_REQUEST_FOR_QUOTATION(order_code){
                try {  
                    this.show_input = false
                    let invoices = this.ReceivedOrders  
                    console.log(invoices,order_code,'000');
                    if(!order_code){this.show_input = false; return}
                    if(!invoices){this.show_input = false; return}
                    let invoice = invoices.find(q=>{
                        return q.code == order_code
                    })   
                    if (invoice) {
                        this.selected_items = invoice.joined_items  
                        this.input = {
                            ...this.input, 
                            description : invoice.description,
                            supplier_code : invoice.supplier_code, 
                            transferred_outlet_code : invoice.outlet_code, 
                            payment_method : invoice.payment_method, 
                        }  
                        setTimeout(() => { 
                            this.show_input = true 
                        }, 1000);
                    }else{
                        this.show_input = false
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_REQUEST_FOR_QUOTATION',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
             
            //---------------[MAIN FUNCTIONS]------------ 
            EXPORT_EXCEL(){
                try {   
                    this.MBS.events.$emit("EXPORT_EXCEL")
                    this.MBS.actions.dialog()
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EXPORT_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            onPickFile(){ 
                this.MBS.events.$emit('SELECT_EXCEL')
            },
            LOADING_FILE(file){
                try {
                    this.excel_loading = true 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'LOADING_FILE',
                        page:PAGE_NAME, 
                    })
                } 
            },
            ON_FILE_ERROR(error){
                try {
                    this.excel_loading = false 
                    console.log(error);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'LOADING_FILE',
                        page:PAGE_NAME, 
                    })
                } 
            },
            EXCEL_FILE_SELECTED(file,file_details,res){
                try {
                    this.loadingValue = 90
                    setTimeout(() => {
                        this.loadingValue = 100
                        setTimeout(() => { 
                            this.excel_loading = false
                            this.WB = file
                            this.WB_SHEETS = file?file.SheetNames:null
                            this.WB_PROPS = file?file.Props:null
                            this.WB_FILE_DETAILS = file_details
                            this.JSON_SHEETS = res?res.data:null 
                            let sheets = this.JSON_SHEETS
                            let itemsJS = null
                            if (sheets) {
                                sheets.forEach(element => {
                                    if (element.name == DATA.ITEMS.ITEMS.values ) {
                                        itemsJS = this.CREATE_SHEET_DATA(element.json,this.ExcelTemplate.headers)
                                    }
                                });
                            }  
                            if (itemsJS) {
                                let items = this.ItemData
                                let selected_items = [] 
                                if (items) { 
                                    itemsJS.forEach(element => {
                                        let input = this.input2
                                        let outlet_code = input?input.outlet_code:null
                                        let code = element.code?element.code:element.item_code
                                        let key = element.key?element.key:element.item_key
                                        let change_quantity = this.toNumber(element.change_quantity) 
                                        let new_quantity = this.toNumber(element.new_quantity)

                                        let item = null
                                        if (code) {
                                            item = items.find(it=>{
                                                return it.code == code
                                            }) 
                                        }else if (key) {
                                            item = items.find(it=>{
                                                return it.key == key
                                            }) 
                                        }
                                        
                                        if (item) {
                                            let quantity = this.toNumber(item[outlet_code+'_number_quantity'])
                                            let item_details = null
                                            
                                            if (change_quantity) {
                                                item_details = {
                                                    ...item,
                                                    quantity:change_quantity,
                                                    new_quantity :quantity+change_quantity
                                                }
                                            }else if (new_quantity) {
                                                item_details = {
                                                    ...item,
                                                    quantity:new_quantity-quantity,
                                                    new_quantity :new_quantity
                                                }
                                            }


                                            if (item_details) {
                                                selected_items.push(item_details) 
                                            }
                                        }
                                    });
                                } 
                                this.excel_selected_items = selected_items
                                this.dialog_import_excel_items = true
 
                            } else {
                                this.MBS.actions.dialog({
                                    show:true,
                                    fixed:true,
                                    title:"FILE ERROR",
                                    text:"The selected file does not contain \"items\" sheet. Download, Receiving Stock Item Template. ",
                                    btnYes:'Download Template', 
                                    btnNo:'Already have', 
                                    action:{
                                        code:PAGE_NAME+"=DOWNLOAD-TEMPLATE"
                                    }
                                }) 
                            }
                        }, 500);
                    }, 1000);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EXCEL_FILE_SELECTED',
                        page:PAGE_NAME, 
                    })
                } 
            },
            NO_EXCEL_FILE_SELECTED(file){
                try {
                    this.excel_loading = false 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'NO_EXCEL_FILE_SELECTED',
                        page:PAGE_NAME, 
                    })
                } 
            },
            CREATE_SHEET_DATA (json, headers) {
                try {
                    if(!json||!headers){return null}
                    let data_item = []
                    let number = 0 
 
                    json.forEach(excel_item => {
                        let dt = new Date
                        let date = dt.toISOString()

                        //CREATE ARRAY
                        let item_array = {}
                        headers.forEach(element => {
                            let value = element.import_name_value?element.name:element.value
                            if (excel_item[value] && value) {
                                item_array[value] = excel_item[value] 
                            }
                        }); 
                        //ADD
                        data_item.push({
                            ...item_array,
                            created_at:date, 
                            uid:this.us.uid,
                            no:number
                        })
                    }); 
                    return data_item
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_SHEET_DATA',
                        page:PAGE_NAME, 
                    })
                } 
            }, 
            SELECT_EXCEL(){  
                try { 
                    this.onPickFile()
                    // this.dialog_add_item = false
                    // this.excelSelector = !this.excelSelector
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            ON_QR_SCAN(code){
                try {
                    if (code) { 
                         
                        let size = this.MBS.actions.SIZE(code)
                        if (size<3) { 
                        }else{
                            let first = code.slice(0,1)
                            let last = code.slice(-1)
                            let mid = code.slice(1,-1)


                            if (first=="%" && last=="?") {
                                code = mid
                                // this.DESELECT_CARD()
                                // this.SELECT_CARD(null,code)  
                            } else { 
                                console.log(code,'code...........');
                                this.ADD_ITEM(null,(code)) 
                            }   
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_QR_SCAN',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=CREATE-ORDER') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.SUBMIT_ORDER(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }
                        if (action.code ===PAGE_NAME+'=CLEAR-ITEMS') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.CLEAR_ITEMS()
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=CHANGED-OUTLET') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.KEEP_SELECTED_ITEMS()
                                }, this.MBS.data.ACTION_REFRESH_TIME);   
                            } else {
                                setTimeout(() => { 
                                    this.CLEAR_ITEMS()
                                }, this.MBS.data.ACTION_REFRESH_TIME);   
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })  
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
            this.UPDATE_LOCAL_STOCK_ADJUSTMENT_DETAILS(this.input2)  
        },
        watch: {
            search_item(value){ 
                if (value) {
                    this.ADD_ITEM(value) 
                    setTimeout(() => {
                        this.search_item=null 
                    }, 500);
                }
            },
            input(v){ 
                console.log(v,'v.........'); 
                let order_code =v?v.order_code:null 
                if ( order_code && order_code != this.order_code) {
                    this.order_code = order_code
                    // this.ADD_REQUEST_FOR_QUOTATION(order_code) 
                }else{ 
                }
            },
            input2(v){ 
                // console.log(this.MBS.actions.SIZE(v),'+++++++++++++');
                // if (this.MBS.actions.SIZE(v)>4){
                //     this.UPDATE_LOCAL_STOCK_ADJUSTMENT_DETAILS(v)  
                // }
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            },
            Outlet(value,value2){
                let code1 = value?value.code:null
                let code2 = value2?value2.code:null
                let items = this.SelectedItems
                if (items && value2 && code1 != code2) {
                    console.log(value,'v1..........');
                    console.log(value2,'v2..........');
                    let mbs_text = this.MBS.text.item_action("Outlet","Changed")
                    this.MBS.actions.dialog({
                        show:true,
                        fixed:true,
                        icon:'mdi-swap-horizontal-circle-outline',
                        title:mbs_text.title,
                        text:"You have Changed Outlet From "+value2.name+" To "+value.name+". You can Clear or Keep Selected Items.",
                        btnYes:"KEEP ITEMS",
                        btnNo:"CLEAR ITEMS",
                        action:{
                            code:PAGE_NAME+"=CHANGED-OUTLET", 
                            outlet:value,
                            outlet2:value2,
                        }
                    }) 
                }
            } 
        },
    }
</script>
 
