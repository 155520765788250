<template>
    <div>   
        <v-container grid-list-xl> 
            <div> 
                <br>
                <h1 class="grey--text text--darken-1">Add Received Quotation</h1>
                <br>
                <form id="form_order_details" @submit.prevent="CREATE_ORDER()" >
                    <h4 class="grey--text text--darken-1">Order Details</h4>
                    <v-row>
                        <v-col cols="12" md="4">
                            <div class="text-justify">
                                Add details of the received quotations to keep the records of all bidders in the tender you released.
                            </div>
                        </v-col>
                        <v-col cols="12" md="8"  > 
                            <mbs-page-spinner v-if="!show_input"
                                height="500"/>  
                            <mbs-input-fields v-else
                                v-model="input"
                                :field_items="DATA.FIELDS.receive_quotation"
                                :select_items="SelectItems"
                                :disabled_fields="DisabledFields"
                                />  
                        </v-col> 
                    </v-row> 
                    <br><br><br>
                    <hr>
                    <br><br><br>
                    <h4 class="grey--text text--darken-1">Items</h4>
                    <v-row>
                        <v-col cols="12" md="3">
                            <div class="text-justify">
                                Select items against your bill by searching or importing the excel document.
                            </div>
                        </v-col>
                        <v-col cols="12" md="9">
                            <v-card :height="vsh-170" outlined rounded="sm">
                                <v-layout fill-height column class="ma-0">
                                    <div>
                                        <v-toolbar rounded="lg" dark color="primary" class="ma-">
                                            <v-autocomplete 
                                                v-model="search_item"
                                                :disabled="inputLoading"
                                                :items="MainInventoryItems" dense
                                                append-icon="search"
                                                outlined :rounded="false"
                                                label="Search or Scan to Add Item"
                                                color="blue-grey lighten-2" 
                                                item-text="name"
                                                item-value="key" 
                                                @update:search-input="AT_SEARCH_INPUT"
                                                :filter="(item, queryText, itemText) => { 
                                                    let first = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 
                                                    if(!item){return first}
                                                    if(!item.group){return first}
                                                    let second = item.group.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 
                                                    return first || second 
                                                }"
                                                >
                                                <template v-slot:prepend-item  v-if="!search_input">
                                                    <v-row class="ma-0 pl-3 pr-2">
                                                        <v-col cols="12" class="px-0"
                                                            :sm="'6'" >
                                                            <v-btn  
                                                                color="primary" 
                                                                @click="ADD_NEW_ITEM()"
                                                                class=" my-2" width="97%"  
                                                                depressed  >
                                                                <v-icon  >add</v-icon>
                                                                <div class="ml-2 font-weight-bol">
                                                                    Add New Item
                                                                </div>
                                                            </v-btn>  
                                                        </v-col> 
                                                        <v-col cols="12" class="px-0"
                                                            :sm="'6'" >
                                                            <v-btn  
                                                                color="primary" 
                                                                :to="MBS.actions.COMPANY_LINK('/stock/items',true)"
                                                                class=" my-2" width="97%"  
                                                                depressed  >
                                                                <v-icon  >add</v-icon>
                                                                <div class="ml-2 font-weight-bol">
                                                                    Manage Items
                                                                </div>
                                                            </v-btn>  
                                                        </v-col> 
                                                    </v-row>
                                                </template>
                                                <template v-slot:no-data   v-if="search_input">
                                                    <v-row class="ma-0 pl-3 pr-2">
                                                        <v-col cols="12" class="px-0"
                                                            :sm="'12'"  >
                                                            <v-btn  
                                                                color="primary" 
                                                                @click="ADD_NEW_ITEM(search_input)"
                                                                class=" my-2" width="97%"  
                                                                depressed  >
                                                                <v-icon >add</v-icon> 
                                                                <div class="ml-2 font-weight-bol ">
                                                                    Add "{{search_input}}" as New Item
                                                                </div>
                                                            </v-btn> 
                                                        </v-col> 
                                                    </v-row>
                                                </template> 
                                                <template v-slot:selection="data">
                                                    {{ data.item.name }}
                                                </template>
                                                <template v-slot:item="data">
                                                    <template v-if="(typeof data.item !=='object')" >
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                    </template>
                                                    <template v-else>
                                                    <v-list-item-avatar tile color="grey">
                                                        <img :src="data.item.image" v-if="data.item.image">
                                                        <v-icon v-else>local_offer</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                        <v-list-item-subtitle v-html="data.item.code_"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete> 
                                            <v-btn :disabled="inputLoading"  class="ml-5" color="secondary">Import <v-icon class="ml-2">mdi-microsoft-excel </v-icon></v-btn> 
                                        </v-toolbar> 
                                    </div>
                                    <v-list dense class="font-weight-bold not-f3 ">
                                        <v-list-item dense >
                                            <v-list-item-action> <v-card flat width="45">
                                                
                                            </v-card> </v-list-item-action>
                                            <v-list-item-content>Item Name</v-list-item-content>
                                            <v-list-item-action class="pr-2">
                                                <div>Current <br> Inventory</div>
                                            </v-list-item-action>
                                            <v-list-item-action class="pr-2">
                                                <v-card flat width="90">
                                                    <v-layout class="ma-0" justify-center>
                                                        QTY
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action>
                                            <v-list-item-action class="pr-2">
                                                <v-card flat width="100">
                                                    <v-layout class="ma-0" >
                                                        Price  
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action>
                                            <v-list-item-action class="pr-2">
                                                <v-card flat width="80">
                                                    <v-layout class="ma-0" >
                                                        Discount
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action> 
                                            <v-list-item-action>
                                                <v-card flat width="100">
                                                    <v-layout class="ma-0 pl-2" justify-center>
                                                    <div class="text-center">Total Price (MWK)</div> 
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action>  
                                             
                                            <v-list-item-action>
                                                <v-card flat width="25">
                                                    <v-layout class="ma-0" justify-center>
                                                        
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action> 
                                        </v-list-item>
                                    </v-list>
                                    <v-divider></v-divider>
                                    <v-layout v-if="!selected_items" fill-height align-center justify-center >
                                        No Item
                                    </v-layout>
                                    <v-virtual-scroll v-else 
                                        class=""
                                        :bench="2"
                                        :items="selected_items"
                                        :height="vsh-300"
                                        item-height="70"  > 
                                        <template v-slot="{ item,index }">
                                                <v-list-item  
                                                    :key="index" :to="item.link">
                                                    <v-list-item-action> 
                                                        <v-avatar
                                                            size="45" tile rounded="lg"
                                                            color="grey"  >
                                                            <v-img v-if="item.image"></v-img>
                                                            <v-icon v-else>local_offer</v-icon>
                                                        </v-avatar>
                                                    </v-list-item-action>
                                                    <v-list-item-content>{{item.name}}</v-list-item-content>
                                                    <v-list-item-action class="pr-2">
                                                        <v-card flat color="" width="70">
                                                            <v-text-field
                                                                :disabled="inputLoading || rfq_code?true:false"
                                                                v-mask="currencyMask" outlined
                                                                filled dense  required
                                                                v-model="item.quantity"/>
                                                        </v-card>
                                                    </v-list-item-action>  
                                                    <v-list-item-action class="pr-2">
                                                        <v-card flat color="" width="100">
                                                            <v-text-field
                                                                :disabled="inputLoading" required
                                                                v-mask="currencyMask" filled dense  outlined
                                                                v-model="item.price" />
                                                        </v-card>
                                                    </v-list-item-action>
                                                    <v-list-item-action class="pr-2">
                                                        <v-card flat color="" width="80">
                                                            <v-text-field
                                                                :disabled="inputLoading"
                                                                v-mask="currencyMask" filled dense  outlined required 
                                                                v-model="item.discount" />
                                                        </v-card>
                                                    </v-list-item-action>
                                                    <v-list-item-action>
                                                        <v-card color="transparent" flat width="100">
                                                            <v-layout class="ma-0">
                                                                <v-spacer></v-spacer>
                                                                {{MBS.actions.money(CALCULATE_PRICE(item))}}
                                                            </v-layout>
                                                        </v-card>
                                                    </v-list-item-action> 
                                                    <v-list-item-action >
                                                        <v-btn @click="REMOVE_ITEM(item)" :disabled="inputLoading"
                                                    small icon><v-icon>delete</v-icon></v-btn></v-list-item-action> 
                                                </v-list-item> 
                                                <v-divider ></v-divider> 
                                        </template>  
                                    </v-virtual-scroll> 

                                    <v-spacer></v-spacer>
                                    <v-divider></v-divider>
                                    
                                    <input ref="btn_submit_order" type="submit" v-show="false">
                                    <v-card @click="SUBMIT()"  
                                        :disabled="MBS.actions.SIZE(selected_items)<1 || inputLoading" 
                                        :flat="MBS.actions.SIZE(selected_items)<1" 
                                        hover  
                                        class="mx-5 my-4 v-btn" :color="MBS.actions.SIZE(selected_items)<1?'grey darken-1':'primary'" dark >
                                        <v-layout class="ma-0 py-2 px-4" align-center v-if="!inputLoading">
                                            <div class="font-weight-bold not-f7">Submit</div>
                                            <div class="pl-2 font-weight-bold not-f3">{{MBS.actions.SIZE(selected_items)}} Items</div>
                                            <v-spacer></v-spacer>
                                            <div class="font-weight-bold not-f7">TOTAL MWK {{
                                                MBS.actions.money(CALCULATE_TOTAL_QT_PRICE(selected_items))
                                                }}</div> 
                                        </v-layout> 
                                        <v-layout v-else class="ma-0 pa-2" justify-center>
                                            <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                                        </v-layout>
                                    </v-card>
                                </v-layout> 
                            </v-card>   
                            
                        </v-col> 
                    </v-row> 
                </form>
            </div> 
        </v-container>  
    </div> 
</template>

<script>
    import DATA from '../../../plugins/DATA'
    import {mapState,mapGetters } from "vuex"
    let PAGE_NAME = "RECEIVE-QUOTATION"  
    export default {
        name:PAGE_NAME, 
        data() { 
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10, 
                search_item:'', 
                selected_items:null,
                inputLoading:false,
                input:{},
                search_input:null, 
                mask_money:"### ### ###", 
                DATA:DATA, 
                rfq_code:null, 
                show_input:true, 
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.MBS.actions.toolbar({
                    show:true, 
                }) 
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        computed: {
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                QuotationsAndInvoices: state=> state.items[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
                JoinedQuotationsAndInvoices: state=> state.join[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
                Suppliers: state=> state.items[(DATA.ITEMS.SUPPLIERS.values).toUpperCase()],
                SupplierCategories: state=> state.items[(DATA.ITEMS.SUPPLIER_CATEGORIES.values).toUpperCase()],
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
            }), 
            ItemData(){ 
                let Items = this.Items
                let JoinedItems = this.JoinedItems
                // let CalculatedItems = this.CalculatedItems
                // if (CalculatedItems) {
                //     return CalculatedItems
                // }else 
                if (JoinedItems) {
                    return JoinedItems
                } 
                return Items
            },
            MainInventoryItems(){ 
                const items = this.ItemData 
                const filtered = items?.filter(item=>{
                    const service = item.item_type_key == "service"
                    const child = item.item_type_key == "child"
                    const parent = item.item_type_key == "parent"
                    const inventory_item_code = item?.inventory_item_code
                    const inventory_item_key = item?.inventory_item_key
                    const inventory_item_keycode = inventory_item_key?inventory_item_key:inventory_item_code
                    const inventory = !inventory_item_keycode || inventory_item_keycode == "main"?true:false
                    return inventory && !service&& !child&& !parent
                })  
                return filtered
            },
            AllInventoriesItems(){ 
                const items = this.ItemData 
                const filtered = items?.filter(item=>{
                    const service = item.item_type_key == "service"
                    const inventory = item.inventory_link_key?true:false
                    return inventory
                })  
                return filtered
            },
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            }, 
            ///////// 

            PaymentMethods(){
                return this.$store.getters.getDefaultPaymentMethods
            }, 
            selectedCompany(){  
                return this.$store.getters.getSelectedCompany
            },  
            RequestForQuotations(){
                let items = this.JoinedQuotationsAndInvoices
                if(!items){return null}
                let filtered_items = items.filter(quotation => {
                    return this.MBS.actions.TEXT_UP(quotation.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.REQUEST_FOR_QUOTATIONS.value)  
                });
                return filtered_items
            },  
            CALCULATE_TOTAL_QT_PRICE(){
                return items=>{
                    let total_price = 0 
                    try { 
                        if (!items) {return 0}
                        items.forEach(item => { 
                            let price = this.toNumber(item.price)
                            let discount = this.toNumber(item.discount)
                            let quantity = item.quantity?this.toNumber(item.quantity):1
                            total_price = total_price + (quantity*(price-discount))
                        });  
                        return total_price
                    } catch (error) {
                        this.MBS.actions.error({
                            error:error,
                            from:'CALCULATE_TOTAL_QT_PRICE',
                            page:PAGE_NAME, 
                        }) 
                        return 0
                    } 
                } 
            }, 
            SelectItems(){
                let Suppliers = this.Suppliers
                let Outlets = this.Outlets
                let PaymentMethods = this.PaymentMethods
                let RequestForQuotations = this.RequestForQuotations
                let SupplierCategories = this.SupplierCategories
                return {
                    Suppliers:Suppliers,
                    Outlets:Outlets,
                    PaymentMethods:PaymentMethods,
                    RequestForQuotations:RequestForQuotations,
                    SupplierCategories:SupplierCategories,
                }
            },
            DisabledFields(){
                let order_code = this.rfq_code
                if (order_code) {
                    return ['supplier_code']
                }
                return null
            } 
        },
        methods: { 
            ADD_NEW_ITEM(input){
                try {    
                    this.MBS.events.$emit("ITEM_ACTION",{
                        NAME_ITEM:DATA.ITEMS.ITEMS.name,
                        action:"add_item",
                        input:{name:input}
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_NEW_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            AT_SEARCH_INPUT(input){
                try {  
                    this.search_input = input
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'AT_SEARCH_INPUT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            TABLE_ACTION(action,item){
                try {
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SUBMIT(){
                try {
                    let btn_submit_order = this.$refs.btn_submit_order
                    if (btn_submit_order) { 
                        btn_submit_order.click()
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ADD_ITEM(item_key){
                try {
                    let items = this.ItemData
                    if (items) {
                        setTimeout(() => {
                            this.search_item=null 
                        }, 500);
                        let item_selected = items.find(item=>{
                            return item.key == item_key
                        })
                        if (!item_selected) {
                            console.log(item_key,'not found');
                            return 
                        }
                        if (!this.selected_items) {
                            this.selected_items = []
                        }

                        let item = this.selected_items.find(item=>{
                            return item.key == item_key
                        }) 
                        if (item) {
                            item.quantity = this.toNumber(item.quantity)+1
                        } else {
                            this.selected_items.push({
                                ...item_selected, 
                                quantity:1,
                                discount:0,
                                price:item_selected.cost_price
                            })   
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            REMOVE_ITEM(selected_item){
                try {
                    let items = this.selected_items
                    if (items) {
                        let filtered_item = items.filter(item=>{
                            return item.key != selected_item.key
                        })
                        this.selected_items = filtered_item

                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let price = this.toNumber(item.price)
                    let discount = this.toNumber(item.discount)
                    let quantity = item.quantity?this.toNumber(item.quantity):1
                    return quantity*(price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CREATE_ORDER(){
                try {
                    let input = this.input
                    // let outlet_code = input?input.outlet_code:null
                    // let invoice_number = input?input.invoice_number:null
                    // let delivery_date = input?input.delivery_date:null
                    let code = input?input.code:null
                    let rfq_code = input?input.rfq_code:null
                    let quotation_number = input?input.quotation_number:null
                    let supplier_code = input?input.supplier_code:null
                    let expire_date = input?input.expire_date:null
                    let description = input?input.description:null
                    let selected_items = this.selected_items
                    let items_size = this.MBS.actions.SIZE(selected_items)

                    if (items_size<1) {
                        console.log("error..");
                    }
                    
                    let stock_order_items = []
                    selected_items.forEach(element => {
                        stock_order_items.push({
                            item_key:element.key, 
                            price:this.toNumber(element.price), 
                            discount:this.toNumber(element.discount), 
                            quantity:this.toNumber(element.quantity), 
                        })
                    });
                    let order_details = {
                        code:code, 
                        quotation_number:quotation_number,
                        supplier_code:supplier_code,  
                        order_type:DATA.ITEMS.QUOTATIONS.value,
                        items:stock_order_items
                    }
                    if (rfq_code) {
                        order_details.rfq_code = rfq_code
                    }  
                    if (expire_date) {
                        order_details.expire_date = expire_date
                    }  
                    if (description) {
                        order_details.description = description
                    }  

                    console.log(input,'input....');
                    console.log(order_details,'order_details....');
                    let mbs_text = this.MBS.text.item_action(DATA.ITEMS.QUOTATIONS.value,"Submit")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=CREATE-ORDER", 
                            data:order_details
                        }
                    })
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_ORDER',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SUBMIT_ORDER(order){
                try {  
                    let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.quotations_and_invoices,true) 
                    if (!order || !path) {
                        console.log(order,'order error');
                    } 
                     
                    this.inputLoading = true
                    this.MBS.actions.progressDialog({
                        show:true,
                        title:"Uploading..",
                    }) 
                    this.$store.dispatch("fi_add",{
                        action:DATA.ITEMS.QUOTATIONS.values,
                        data:order,
                        path:path
                    }).then(res=>{  
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.QUOTATIONS.value,"Submitted")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        }) 
                        // let quotationLink = this.MBS.actions.COMPANY_LINK("/stock/quotations&invoices/",true)
                        this.MBS.actions.go(-1) 
                         
                    }).catch(error=>{
                        this.MBS.actions.error({
                            error:error,
                            from:'SUBMIT_ORDER',
                            page:PAGE_NAME, 
                        })  
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_fail(DATA.ITEMS.QUOTATIONS.value,"Submitted")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_ORDER',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ADD_REQUEST_FOR_QUOTATION(rfq_code){
                try {  
                    this.show_input = false
                    let quotations = this.RequestForQuotations  
                    if(!rfq_code){return}
                    if(!quotations){return}
                    let quotation = quotations.find(q=>{
                        return q.code == rfq_code
                    }) 
                    if (quotation) { 
                        this.selected_items = []
                        if (quotation.joined_items) {
                            setTimeout(() => {
                                quotation.joined_items.forEach(element => {
                                    this.selected_items.push({
                                        ...element,
                                        price:element.cost_price,
                                        discount : 0
                                    })
                                }); 
                            }, 1);
                            
                        }
                        this.input = {
                            ...this.input,
                            description : quotation.description,
                            supplier_code : quotation.supplier_code
                        } 
                        setTimeout(() => {
                            this.show_input = true
                            
                        }, 1000);
                    }else{
                        this.show_input = false
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_REQUEST_FOR_QUOTATION',
                        page:PAGE_NAME, 
                    }) 
                } 
            }, 
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=CREATE-ORDER') {
                            if (action.YES) {
                                setTimeout(() => {
                                    console.log(action.data);
                                    this.SUBMIT_ORDER(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })  
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
        },
        watch: {
            search_item(value){ 
                if (value) {
                    this.ADD_ITEM(value) 
                }
            },
            input(v){  
                let rfq_code =v?v.rfq_code:null

                if ( rfq_code && rfq_code != this.rfq_code) {
                    this.rfq_code = rfq_code
                    this.ADD_REQUEST_FOR_QUOTATION(rfq_code) 
                }else{ 
                }
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
