<template>
    <div>   
        <v-container grid-list-xl> 
            <div> 
                <br>
                <br>
                <form id="form_order_details" @submit.prevent="CREATE_ORDER()" >
                    <h4 class="grey--text text--darken-1">Order Details</h4>
                    <v-row>
                        <v-col cols="12" md="4">
                            <h1 class="grey--text text--darken-1">Add Received Invoice Without Stock</h1>
                            <div class="text-justify">
                                Adding details to this bill helps you to specify the terms of the invoice you have received and provides information on the available methods of payment.
                            </div>
                        </v-col>
                        <v-col cols="12" md="8"  > 
                            <mbs-page-spinner v-if="!show_input"
                                height="500"/>  
                            <mbs-input-fields v-else
                                v-model="input"
                                :field_items="DATA.FIELDS.receive_invoice"
                                :select_items="SelectItems"
                                :disabled_fields="DisabledFields"
                                />  
                        </v-col> 
                    </v-row> 
                    <br><br><br>
                    <hr>
                    <br><br><br>
                    <h4 class="grey--text text--darken-1">Items</h4>
                    <v-row>
                        <v-col cols="12" md="3">
                            <div class="text-justify">
                                Select items against your bill by searching or importing the excel document.
                            </div>
                        </v-col>
                        <v-col cols="12" md="9">
                            <v-card :height="vsh-170" outlined rounded="sm">
                                <v-layout fill-height column class="ma-0">
                                    <div>
                                        <v-toolbar rounded="lg" dark color="primary" class="ma-">
                                            <v-autocomplete 
                                                v-model="search_item"
                                                :disabled="inputLoading"
                                                :items="MainInventoryItems" dense
                                                append-icon="search"
                                                outlined :rounded="false"
                                                label="Search or Scan to Add Item"
                                                color="blue-grey lighten-2" 
                                                item-text="name"
                                                item-value="key" 
                                                @update:search-input="AT_SEARCH_INPUT"
                                                :filter="(item, queryText, itemText) => { 
                                                    let first = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 
                                                    if(!item){return first}
                                                    if(!item.group){return first}
                                                    let second = item.group.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 
                                                    return first || second 
                                                }"
                                                >
                                                <template v-slot:prepend-item  v-if="!search_input">
                                                    <v-row class="ma-0 pl-3 pr-2">
                                                        <v-col cols="12" class="px-0"
                                                            :sm="'6'" >
                                                            <v-btn  
                                                                color="primary" 
                                                                @click="ADD_NEW_ITEM()"
                                                                class=" my-2" width="97%"  
                                                                depressed  >
                                                                <v-icon  >add</v-icon>
                                                                <div class="ml-2 font-weight-bol">
                                                                    Add New Item
                                                                </div>
                                                            </v-btn>  
                                                        </v-col> 
                                                        <v-col cols="12" class="px-0"
                                                            :sm="'6'" >
                                                            <v-btn  
                                                                color="primary" 
                                                                :to="MBS.actions.COMPANY_LINK('/stock/items',true)"
                                                                class=" my-2" width="97%"  
                                                                depressed  >
                                                                <v-icon  >add</v-icon>
                                                                <div class="ml-2 font-weight-bol">
                                                                    Manage Items
                                                                </div>
                                                            </v-btn>  
                                                        </v-col> 
                                                    </v-row>
                                                </template>
                                                <template v-slot:no-data   v-if="search_input">
                                                    <v-row class="ma-0 pl-3 pr-2">
                                                        <v-col cols="12" class="px-0"
                                                            :sm="'12'"  >
                                                            <v-btn  
                                                                color="primary" 
                                                                @click="ADD_NEW_ITEM(search_input)"
                                                                class=" my-2" width="97%"  
                                                                depressed  >
                                                                <v-icon >add</v-icon> 
                                                                <div class="ml-2 font-weight-bol ">
                                                                    Add "{{search_input}}" as New Item
                                                                </div>
                                                            </v-btn> 
                                                        </v-col> 
                                                    </v-row>
                                                </template> 
                                                <template v-slot:selection="data">
                                                    {{ data.item.name }}
                                                </template>
                                                <template v-slot:item="data">
                                                    <template v-if="(typeof data.item !=='object')" >
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                    </template>
                                                    <template v-else>
                                                    <v-list-item-avatar tile color="grey">
                                                        <img :src="data.item.image" v-if="data.item.image">
                                                        <v-icon v-else>local_offer</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                        <v-list-item-subtitle v-html="data.item.code_"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete> 
                                            <!-- <v-btn :disabled="inputLoading"  class="ml-5" color="secondary">Import <v-icon class="ml-2">mdi-microsoft-excel </v-icon></v-btn>  -->
                                            <v-menu  
                                                v-model="menu_selling_mode"
                                                :close-on-content-click="true" 
                                                offset-y  offset-overflow
                                                :nudge-width="90" rounded="xl"
                                                >
                                                <template v-slot:activator="{ on, attrs }">  
                                                    <v-btn  v-on="on" class=" ml-5" :loading="excel_loading"
                                                        ref="btn_selling_mode" 
                                                        color="secondary">
                                                        <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
                                                            {{selling_model?selling_model:'Select'}}
                                                        <v-icon class="ml-2">arrow_drop_down</v-icon>
                                                    </v-btn> 
                                                </template> 

                                                <v-card  v-if="ud"  
                                                    color="lighten-1" class="">  
                                                    <v-card-title class="secondary white--text py-" dark>
                                                        Select 
                                                    </v-card-title> 
                                                    <v-list dense> 
                                                        <div v-for="(model,index) in sellingModels" :key="index">
                                                            <v-list-item 
                                                                :class="model == selling_model?'secondary--text':''" 
                                                                @click="SELECT_ACTION(model)">{{model.name}}</v-list-item> 
                                                        </div>
                                                    </v-list>  
                                                </v-card>  
                                            </v-menu> 
                                        </v-toolbar> 
                                    </div>
                                    <v-list dense class="font-weight-bold not-f3 ">
                                        <v-list-item dense >
                                            <v-list-item-action> <v-card flat width="45">
                                                
                                            </v-card> </v-list-item-action>
                                            <v-list-item-content>Item Name</v-list-item-content>
                                            <v-list-item-action class="pr-2">
                                                <div>Current <br> Inventory</div>
                                            </v-list-item-action>
                                            <v-list-item-action class="pr-2">
                                                <v-card flat width="90">
                                                    <v-layout class="ma-0" justify-center>
                                                        QTY
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action>
                                            <v-list-item-action class="pr-2">
                                                <v-card flat width="100">
                                                    <v-layout class="ma-0" >
                                                        Price  
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action>
                                            <v-list-item-action class="pr-2">
                                                <v-card flat width="80">
                                                    <v-layout class="ma-0" >
                                                        Discount
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action> 
                                            <v-list-item-action>
                                                <v-card flat width="100">
                                                    <v-layout class="ma-0 pl-2" justify-center>
                                                    <div class="text-center">Total Price (MWK)</div> 
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action>  
                                             
                                            <v-list-item-action>
                                                <v-card flat width="25">
                                                    <v-layout class="ma-0" justify-center>
                                                        
                                                    </v-layout>
                                                </v-card>
                                            </v-list-item-action> 
                                        </v-list-item>
                                    </v-list>
                                    <v-divider></v-divider>
                                    <v-layout v-if="!selected_items" fill-height align-center justify-center >
                                        No Item
                                    </v-layout>

                                    <v-card v-else   flat target="">
                                        <v-virtual-scroll class=""
                                            :bench="2"
                                            :items="selected_items"
                                            :height="vsh-380"
                                            item-height="70" >
                                            <template v-slot="{ item,index }">
                                                <v-list-group   
                                                    :style="item.expand?'border-left: 5px solid '+MBS.data.color.primary:''"
                                                    :key="index"  
                                                    v-click-outside="item.expand = false"
                                                    v-model="item.expand"
                                                    :no-action ="true" 
                                                    :sub-group ="false"
                                                    append-icon="" >
                                                    <template v-slot:activator> 
                                                        <v-list-item-action>  
                                                            <v-layout row wrap>
                                                                <v-btn  icon>
                                                                    <v-icon v-if="item.expand">expand_more</v-icon>
                                                                    <v-icon v-else>chevron_right</v-icon>
                                                                </v-btn> 
                                                                <v-avatar :class="item.expand?'':''"
                                                                    size="40"   
                                                                    rounded="sm"
                                                                    color="grey"  >
                                                                    <v-img v-if="item.image"></v-img>
                                                                    <v-icon v-else>local_offer</v-icon>
                                                                </v-avatar> 
                                                            </v-layout>
                                                        </v-list-item-action>  
                                                        <v-list-item-content>
                                                            {{item.quantity}} {{item.name}}
                                                        </v-list-item-content>
                                                          
                                                        <v-list-item-action><v-card color="transparent" flat width="100">
                                                            <v-layout class="ma-0">
                                                                <v-spacer></v-spacer>
                                                                {{MBS.actions.money(CALCULATE_PRICE(item))}}
                                                            </v-layout>
                                                        </v-card></v-list-item-action>  
                                                        <v-list-item-action >
                                                            <v-btn @click="REMOVE_ITEM(item,index)" 
                                                            :disabled="inputLoading" small icon><v-icon>delete</v-icon></v-btn>
                                                        </v-list-item-action> 
                                                    </template>
                                                    <v-card  tile    class="mbs-z-index2 mx-" color="">
                                                        <v-card-text class="b1">
                                                            <v-row>
                                                                <v-col cols="12" sm="5" class="py-0 pr-0"> 
                                                                    <div>Price</div> 
                                                                    <v-text-field dense color="primary"   
                                                                        filled outlined v-mask="currencyMask"
                                                                        append-icon="edit"
                                                                        v-model="item.cost_price"  
                                                                        @click="EDIT_PRICE(item)"
                                                                        @focus="EDIT_PRICE(item)"
                                                                        @click:append="EDIT_PRICE(item)"
                                                                        outline readonly
                                                                    ></v-text-field>  
                                                                </v-col>
                                                                <v-col cols="12" sm="2" class="py-0 px-1" >
                                                                    <div>QTY</div>
                                                                        <v-text-field dense
                                                                            filled outlined autofocus
                                                                            v-model="item.quantity"
                                                                            outline
                                                                        ></v-text-field> 
                                                                </v-col>
                                                                <v-col cols="12" sm="5" class="py-0 pl-0">
                                                                    <div>Discount</div>
                                                                        <v-text-field dense
                                                                            filled outlined 
                                                                            v-model="item.discount"
                                                                            outline v-mask="currencyMask"
                                                                        ></v-text-field> 
                                                                </v-col>
                                                                <v-col cols="12" sm="6" class="pr-1">
                                                                    <div>Manufacturing Date</div>
                                                                    <v-text-field dense
                                                                            filled outlined 
                                                                            v-model="item.manufacturing_date" 
                                                                            outline type="date"
                                                                        ></v-text-field> 
                                                                </v-col>
                                                                <v-col cols="12" sm="6" class="pl-">
                                                                    <div>Expiring Date</div>
                                                                    <v-text-field dense
                                                                            filled outlined 
                                                                            v-model="item.expiring_date"
                                                                            outline type="date"
                                                                        ></v-text-field> 
                                                                </v-col>
                                                            </v-row> 
                                                        </v-card-text>  
                                                    </v-card> 
                                                </v-list-group>
                                                <v-divider :key="'d'+index" ></v-divider>  
                                            </template>
                                        </v-virtual-scroll> 
                                    </v-card>  

                                    <v-virtual-scroll v-if="false" 
                                        class=""
                                        :bench="2"
                                        :items="selected_items"
                                        :height="vsh-300"
                                        item-height="70"  > 
                                        <template v-slot="{ item,index }">
                                                <v-list-item  
                                                    :key="index" :to="item.link">
                                                    <v-list-item-action> 
                                                        <v-avatar
                                                            size="45" tile rounded="lg"
                                                            color="grey"  >
                                                            <v-img v-if="item.image"></v-img>
                                                            <v-icon v-else>local_offer</v-icon>
                                                        </v-avatar>
                                                    </v-list-item-action>
                                                    <v-list-item-content>{{item.name}}</v-list-item-content>
                                                    <v-list-item-action class="pr-2">
                                                        <v-card flat color="" width="70">
                                                            <v-text-field
                                                                :disabled="inputLoading "
                                                                v-mask="'####'" outlined
                                                                filled dense  required
                                                                v-model="item.quantity"/>
                                                        </v-card>
                                                    </v-list-item-action>  
                                                    <v-list-item-action class="pr-2">
                                                        <v-card flat color="" width="100">
                                                            <v-text-field
                                                                :disabled="inputLoading || order_code?true:false" required
                                                                v-mask="currencyMask" filled dense  outlined
                                                                v-model="item.price" />
                                                        </v-card>
                                                    </v-list-item-action>
                                                    <v-list-item-action class="pr-2">
                                                        <v-card flat color="" width="80">
                                                            <v-text-field
                                                                :disabled="inputLoading || order_code?true:false" 
                                                                v-mask="'#####'" filled dense  outlined
                                                                v-model="item.discount" />
                                                        </v-card>
                                                    </v-list-item-action>
                                                    <v-list-item-action>
                                                        <v-card color="transparent" flat width="100">
                                                            <v-layout class="ma-0">
                                                                <v-spacer></v-spacer>
                                                                {{MBS.actions.money(CALCULATE_PRICE(item))}}
                                                            </v-layout>
                                                        </v-card>
                                                    </v-list-item-action> 
                                                    <v-list-item-action >
                                                        <v-btn @click="REMOVE_ITEM(item)" :disabled="inputLoading"
                                                    small icon><v-icon>delete</v-icon></v-btn></v-list-item-action> 
                                                </v-list-item> 
                                                <v-divider ></v-divider> 
                                        </template>  
                                    </v-virtual-scroll> 

                                    <v-spacer></v-spacer>
                                    <v-divider></v-divider>
                                    
                                    <input ref="btn_submit_order" type="submit" v-show="false">
                                    <v-card @click="SUBMIT()"  
                                        :disabled="MBS.actions.SIZE(selected_items)<1 || inputLoading" 
                                        :flat="MBS.actions.SIZE(selected_items)<1" 
                                        hover  
                                        class="mx-5 my-4 v-btn" :color="MBS.actions.SIZE(selected_items)<1?'grey darken-1':'primary'" dark >
                                        <v-layout class="ma-0 py-2 px-4" align-center v-if="!inputLoading">
                                            <div class="font-weight-bold not-f7">Submit</div>
                                            <div class="pl-2 font-weight-bold not-f3">{{MBS.actions.SIZE(selected_items)}} Items</div>
                                            <v-spacer></v-spacer>
                                            <div class="font-weight-bold not-f7">TOTAL MWK {{
                                                MBS.actions.money(CALCULATE_TOTAL_QT_PRICE(selected_items))
                                                }}</div> 
                                        </v-layout> 
                                        <v-layout v-else class="ma-0 pa-2" justify-center>
                                            <v-progress-circular indeterminate :value="20"></v-progress-circular> 
                                        </v-layout>
                                    </v-card>
                                </v-layout> 
                            </v-card>   
                            
                        </v-col> 
                    </v-row> 
                </form>
            </div> 
        </v-container>  

        <!-- //DIALOGS -->
        <div>
            <!--EDIT PRICE-->
            <div v-if="dialog_edit_price">
                <v-dialog 
                    v-model="dialog_edit_price"
                    scrollable   
                    :persistent="false" :overlay="false"
                    max-width="800px"
                    transition="dialog-transition" >
                    <v-card rounded="lg">
                        <v-card-title class="primary">
                            <div class="font-weight-bold b2--text">EDIT ITEM PRICE</div> 
                            <v-spacer></v-spacer> 
                            <v-btn dark icon class="ml-5" @click="dialog_edit_price=false" ><v-icon>close</v-icon></v-btn>
                        </v-card-title>
                        <v-card-text>
                            <br>
                            <v-card outlined class="ma-4 pa-2" v-if="EditingItem">
                                <v-layout class="ma-0">
                                    <div> 
                                        <v-icon class="ma-2">mdi-tag</v-icon>
                                    </div>
                                    <div>
                                        <div>Barcode (code): {{ EditingItem.code }}</div>
                                        <div>Item Name: {{ EditingItem.name }} </div>
                                        <div>Cost Price: {{ EditingItem.cost_price }}</div>
                                        <div>Retail Price: {{ EditingItem.retail_price }} </div>
                                        <div>Wholesale Price: {{ EditingItem.wholesale_price }}</div>
                                    </div>
                                </v-layout>
                            </v-card>
                            <br> 
                            <form @submit.prevent="CONFIRM_EDIT_ITEM_PRICE(input_edit_item)">
                                <div class="ma-5" >  
                                    <div> 
                                        <mbs-input-fields  
                                            v-model="input_edit_item"
                                            :field_items="DATA.FIELDS.edit_item_price"
                                            :select_items="SelectItems" 
                                            /> 
                                    </div>
                                    <div class=" mt-5">Make sure you fill above form before continuing.</div>  
                                    <v-layout row wrap class="ma-0 mt-3">
                                        <v-btn @click="dialog_edit_price = false" large color="primary">CANCEL</v-btn>
                                        <v-flex class="pa-0 ml-1" > 
                                            <v-btn width="100%" :loading="inputLoading" type="submit" large color="secondary">UPDATE ITEM PRICE</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </div> 
                            </form>
                        </v-card-text> 
                    </v-card> 
                </v-dialog>   
            </div>

            <!-- select -->
            <div v-if="excelSelector" >
                <mbs-excel-selector 
                    :notShow="excelSelector"
                    @notNo="excelSelector =!excelSelector"
                    @notYes="FILE_SELECTED"/>
            </div>

            <mbs-excel-selector
                :notShow="false"
                @onFileSelected="EXCEL_FILE_SELECTED" 
                @onNoFileSelected="NO_EXCEL_FILE_SELECTED" 
                @onLoadingFile="LOADING_FILE" 
                @onError="ON_FILE_ERROR" 
                />  
            <!-- export excel -->
            <mbs-excel-export 
                :file_name="excel_template.name"
                :sheet_name="excel_template.sheet_name"
                :data_items="Items?Items:excel_template.sample_data"
                :header_items="excel_template.headers"
            /> 
            <mbs-item-manager
                :show="false" 
                :name_items="DATA.ITEMS.ITEMS.names"
                :name_item="DATA.ITEMS.ITEMS.name"
                :value_items="DATA.ITEMS.ITEMS.values"
                :value_item="DATA.ITEMS.ITEMS.value"
                :field_items="DATA.FIELDS.items"   
                :path_item="MBS.actions.COMPANY_LINK(DATA.PATHS.items,true)"  
                :select_items="SelectItems"   
            />
        </div>
    </div> 
</template>

<script>
    import moment from 'moment';
    import DATA from '../../../plugins/DATA'
    import {mapState,mapGetters } from "vuex"
    let PAGE_NAME = "RECEIVE-INVOICE"  
    export default {
        name:PAGE_NAME, 
        data() { 
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10, 
                search_item:'', 
                selected_items:null,
                select_to_edit_item:null,
                inputLoading:false,
                input:{},
                input_edit_item:{
                    cost_price:'',
                    retail_price:'',
                    wholesale_price:''
                },
                search_input:null, 
                dialog_edit_price:false, 
                mask_money:"### ### ###", 
                DATA:DATA, 
                invoice_code:null, 
                show_input:true, 
                excel_loading:false, 
                menu_selling_mode:false, 
                sellingModels:[
                    {code:1,icon:"",name:"Import Excel"},
                    {code:2,icon:"",name:"Download Excel Template"},
                ], 
                selling_model:null,
                excelSelector:false,
                excel_template:{
                    name:"STOCK RECEIVED TEMPLATE (v1)",
                    sheet_name:"items",
                    headers:[
                        {id:0,name:"code",value:"code",align:"center",show:true},      
                        {id:0,name:"name",value:"name",show:true},  
                        {id:0,name:"cost_price",value:"cost_price",show:true}, 
                        {id:0,name:"discount",value:"discount",show:true}, 
                        {id:0,name:"quantity",value:"quantity",show:true},  
                    ],
                    sample_data:[
                        {code:1,name:"Sample Item",cost_price:1000,discount:0.2,quantity:10},   
                    ],
                },
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try {
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                })
                this.MBS.actions.toolbar({
                    show:true, 
                }) 
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            } 
        },
        computed: {
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            selectedCompany(){ 
                return this.$store.getters.getSelectedCompany
            },

            //
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                ItemCategories: state=> state.items[(DATA.ITEMS.ITEM_CATEGORIES.values).toUpperCase()],
                Brands: state=> state.items[(DATA.ITEMS.BRANDS.values).toUpperCase()],
                Manufacturers: state=> state.items[(DATA.ITEMS.MANUFACTURERS.values).toUpperCase()], 

                StockOrders: state=> state.items[(DATA.ITEMS.STOCK_ORDERS.values).toUpperCase()],
                JoinedStockOrders: state=> state.join[(DATA.ITEMS.STOCK_ORDERS.values).toUpperCase()],
                QuotationsAndInvoices: state=> state.items[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
                JoinedQuotationsAndInvoices: state=> state.join[(DATA.ITEMS.QUOTATIONS_AND_INVOICES.values).toUpperCase()],
                Suppliers: state=> state.items[(DATA.ITEMS.SUPPLIERS.values).toUpperCase()],
                SupplierCategories: state=> state.items[(DATA.ITEMS.SUPPLIER_CATEGORIES.values).toUpperCase()],
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                PaymentMethods: state=> state.items[(DATA.ITEMS.DEFAULT_PAYMENT_METHODS.values).toUpperCase()], 
                TaxGroups: state=> state.app[(DATA.ITEMS.TAX_GROUPS.values).toUpperCase()], 
                SelectedCompany: state=> state.items["SELECTED_COMPANY"], 
                PricingTiers: state=> state.items[(DATA.ITEMS.PRICING_TIERS.values).toUpperCase()], 
                JoinedPricingTiers: state=> state.join[(DATA.ITEMS.PRICING_TIERS.values).toUpperCase()], 
                DefaultPricingTiers: state=> state.app['DEFAULT_PRICING_TIERS'], 
            }), 
            PricingTiersData(){
                return this.JoinedPricingTiers?this.JoinedPricingTiers:this.PricingTiers
            }, 
            AllPricingTiers(){
                let defaults = this.DefaultPricingTiers
                let tiers = this.PricingTiersData 
                let all = []
                if (defaults) {
                    all.push(...defaults)
                }if (tiers) {
                    tiers.forEach(tier => {
                        let index = all.findIndex(item=>item.key == tier.key)
                        if (index==-1) {
                            all.push(tier) 
                        } else {
                            all[index] = {
                                ...all[index],
                                ...tier
                            }
                        }
                    });
                }
                return all
            }, 
            
            ItemData(){ 
                let Items = this.Items
                let JoinedItems = this.JoinedItems
                let return_data = JoinedItems?JoinedItems:Items 
                setTimeout(() => { 
                    this.UPDATE_SELECTED_ITEM(return_data)
                }, 1);
                return return_data
            }, 
            MainInventoryItems(){ 
                const items = this.ItemData 
                const filtered = items?.filter(item=>{
                    const service = item.item_type_key == "service"
                    const child = item.item_type_key == "child"
                    const parent = item.item_type_key == "parent"
                    const inventory_item_code = item?.inventory_item_code
                    const inventory_item_key = item?.inventory_item_key
                    const inventory_item_keycode = inventory_item_key?inventory_item_key:inventory_item_code
                    const inventory = !inventory_item_keycode || inventory_item_keycode == "main"?true:false
                    return inventory && !service&& !child&& !parent
                })  
                return filtered
            },
            AllInventoriesItems(){ 
                const items = this.ItemData 
                const filtered = items?.filter(item=>{
                    const service = item.item_type_key == "service"
                    const inventory = item.inventory_link_key?true:false
                    return inventory
                })  
                return filtered
            },
            EditingItem(){ 
                let items = this.ItemData 
                let selected = this.select_to_edit_item  
                let item_key = selected?selected.key:null
                if(!items || !item_key){return null}
                let filtered_item = items.find(item=>item.key == item_key)
                let item_stock_settings = this.MBS.actions.ITEM_STOCK_SETTINGS(filtered_item,null,null,false)
                if (item_stock_settings) {
                    filtered_item = {
                        ...filtered_item,
                        active_settings:item_stock_settings.active_settings,
                        item:item_stock_settings.item
                    }
                } 
                console.log(filtered_item,'item_stock_settings......');  
                return filtered_item
            }, 
            StockOrdersData(){ 
                let StockOrders = this.StockOrders
                let JoinedStockOrders = this.JoinedStockOrders 
                return JoinedStockOrders?JoinedStockOrders:StockOrders
            },  

            
            BillTerms(){
                return this.$store.getters.getBillTerms
            },  
            OrderedStock(){
                let items = this.StockOrdersData
                if(!items){return null}
                let filtered_items = items.filter(item => {
                    return this.MBS.actions.TEXT_UP(item.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.STOCK_ORDERS.value)  
                });
                return filtered_items
            }, 
            RequestForQuotations(){
                let items = this.JoinedQuotationsAndInvoices
                if(!items){return null}
                let filtered_items = items.filter(quotation => {
                    return this.MBS.actions.TEXT_UP(quotation.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.REQUEST_FOR_QUOTATIONS.value)  
                });
                return filtered_items
            }, 
            Quotations(){
                let items = this.JoinedQuotationsAndInvoices
                if(!items){return null}
                let filtered_items = items.filter(quotation => {
                    return this.MBS.actions.TEXT_UP(quotation.order_type) 
                        == this.MBS.actions.TEXT_UP(DATA.ITEMS.QUOTATIONS.value)  
                });
                return filtered_items
            },  
            CALCULATE_TOTAL_QT_PRICE(){
                return items=>{
                    let total_price = 0 
                    try { 
                        if (!items) {return 0}
                        items.forEach(item => { 
                            let price = this.toNumber(item.price)
                            let discount = this.toNumber(item.discount)
                            let quantity = this.toNumber(item.quantity)
                            total_price = total_price + (quantity*(price-discount))
                        });  
                        return total_price
                    } catch (error) {
                        this.MBS.actions.error({
                            error:error,
                            from:'CALCULATE_TOTAL_QT_PRICE',
                            page:PAGE_NAME, 
                        }) 
                        return 0
                    } 
                } 
            }, 
            SelectItems(){
                let ItemCategories = this.ItemCategories
                let Brands = this.Brands
                let Manufacturers = this.Manufacturers

                let Suppliers = this.Suppliers
                let Outlets = this.Outlets
                let PaymentMethods = this.PaymentMethods
                let BillTerms = this.BillTerms
                let RequestForQuotations = this.RequestForQuotations
                let Quotations = this.Quotations
                let OrderedStock = this.OrderedStock 
                let SupplierCategories = this.SupplierCategories 
                let TaxGroups = this.TaxGroups
                let PricingTiers = this.AllPricingTiers
                return {
                    ItemCategories: ItemCategories,
                    Brands: Brands,
                    Manufacturers: Manufacturers, 

                    Suppliers:Suppliers,
                    Outlets:Outlets,
                    PaymentMethods:PaymentMethods,
                    BillTerms:BillTerms,
                    RequestForQuotations:RequestForQuotations,
                    Quotations:Quotations,
                    OrderedStock:OrderedStock,
                    SupplierCategories:SupplierCategories,
                    TaxGroups: TaxGroups, 
                    PricingTiers: PricingTiers, 
                }
            },
            DisabledFields(){
                let order_code = this.order_code
                console.log(order_code,order_code?true:false,'order_code.....');
                if (order_code) {
                    return ['supplier_code']
                }
                return null
            } 
        },
        methods: { 
            onClickOutside (event) {
                console.log('Clicked outside. Event: ', event)
            },
            EDIT_PRICE(item){
                try {    
                    this.select_to_edit_item = item 
                    this.input_edit_item = {
                        key:item.key,
                        cost_price:item?item.cost_price:0,
                        retail_price:item?item.retail_price:0,
                        wholesale_price:item?item.wholesale_price:0,
                    }
                    this.dialog_edit_price = true 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EDIT_PRICE',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            CONFIRM_EDIT_ITEM_PRICE(input){
                try { 
                    if (input) {
                        let data = {
                            key:input.key,
                            cost_price : this.toNumber(input.cost_price),
                            retail_price : this.toNumber(input.retail_price),
                            wholesale_price : this.toNumber(input.wholesale_price),
                        }
                        let mbs_text = this.MBS.text.item_action("Pricing","Update")
                        this.MBS.actions.dialog({
                            show:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes,
                            action:{
                                code:PAGE_NAME+"=UPDATE-PRICING",
                                data:data
                            }
                        })
                        console.log(data,'input........');
                    }   
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_EDIT_ITEM_PRICE',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ADD_NEW_ITEM(input){
                try {    
                    this.MBS.events.$emit("ITEM_ACTION",{
                        NAME_ITEM:DATA.ITEMS.ITEMS.name,
                        action:"add_item",
                        input:{name:input}
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_NEW_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            AT_SEARCH_INPUT(input){
                try {  
                    this.search_input = input
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'AT_SEARCH_INPUT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            TABLE_ACTION(action,item){
                try {
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SELECT_ACTION(action,item){
                try {
                    console.log(action,111111);
                    if (!action) {
                        
                    }else if (action.code == 1) {
                        this.SELECT_EXCEL() 
                    }else if (action.code == 2) {
                        this.EXPORT_EXCEL() 
                    }else {
                        
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            FILE_SELECTED(wb){
                try {
                    this.WB = wb
                    this.excelSelector = false
                    this.EXCEL_DATA_ITEMS=[]  
                    let worksheet_item = wb.Sheets[this.MBS.actions.TEXT_UP0(this.VALUE_ITEMS)]  
                    if (!worksheet_item) {
                        this.input.check = true
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:this.MBS.text.working_sheet_not_found(this.NAME_ITEMS).title,
                            text:this.MBS.text.working_sheet_not_found(this.NAME_ITEMS).text,
                            btnYes:this.MBS.text.working_sheet_not_found(this.NAME_ITEMS).btnYes,
                            btnNo:this.MBS.text.working_sheet_not_found(this.NAME_ITEMS).btnNo,
                            action:{
                                code:PAGE_NAME+"=SELECT-EXCEL" 
                            }
                        })  
                    } else {
                        let json_item = this.XLSX.utils.sheet_to_json(worksheet_item)  
                        let data_item = []
                        let number = 0
                        json_item.forEach(excel_item => {
                            let dt = new Date
                            let date = dt.toISOString()

                            //CREATE ARRAY
                            let item_array = {}
                            this.FIELD_ITEMS.forEach(element => {
                                if (excel_item[element.value] && element.value) {
                                    item_array[element.value] = excel_item[element.value] 
                                }
                            }); 
                            //ADD
                            data_item.push({
                                ...item_array,
                                created_at:date, 
                                uid:this.us.uid,
                                no:number
                            })
                        }); 
                        this.EXCEL_DATA_ITEMS = data_item
                        this.dialog_selected_item = true 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FILE_SELECTED',
                        page:PAGE_NAME, 
                    }) 
                }
            }, 
            SUBMIT(){
                try {
                    let btn_submit_order = this.$refs.btn_submit_order
                    if (btn_submit_order) { 
                        btn_submit_order.click()
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SUBMIT_PRICING(data){
                try {  
                    let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.items,true) 
                    if (!data || !path) {
                        console.log(data,'order error');
                    } 
                     
                    this.inputLoading = true
                    this.MBS.actions.progressDialog({
                        show:true,
                        title:"Uploading..",
                    }) 
                    this.$store.dispatch("fi_update",{
                        action:DATA.ITEMS.STOCK_ORDERS.values,
                        data:data,
                        path:path+data.key
                    })
                    .then(res=>{
                        return  this.$store.dispatch('fi_tracker',{
                        company:this.selectedCompany,
                        names:[
                            {data_save:false,on:false,get:false,cash:true,name:DATA.ITEMS.ITEMS.values}, 
                        ]
                        }) 
                    })
                    .then(res=>{
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        this.dialog_edit_price = false
                        // let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.STOCK_ORDERS.value,"Received")
                        // this.MBS.actions.dialog({
                        //     show:true,
                        //     fixed:true,
                        //     title:mbs_text.title,
                        //     text:mbs_text.text,
                        //     btnYes:mbs_text.btnYes, 
                        // })   
                    }).catch(error=>{
                        this.MBS.actions.error({
                            error:error,
                            from:'SUBMIT_PRICING',
                            page:PAGE_NAME, 
                        })  
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_fail("Pricing","Update")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_PRICING',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ADD_ITEM(item_key){
                try {
                    let items = this.ItemData
                    if (items) {
                        setTimeout(() => {
                            this.search_item=null 
                        }, 500);
                        let item_selected = items.find(item=>{
                            return item.key == item_key
                        })
                        if (!item_selected) {
                            console.log(item_key,'not found');
                            return 
                        }
                        if (!this.selected_items) {
                            this.selected_items = []
                        }

                        let item = this.selected_items.find(item=>{
                            return item.key == item_key
                        }) 
                        if (item) {
                            item.quantity = (item.quantity*1)+1
                        } else {
                            this.selected_items.push({
                                ...item_selected, 
                                quantity:1,
                                discount:0,
                                price:item_selected.cost_price
                            })   
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            REMOVE_ITEM(selected_item){
                try {
                    let items = this.selected_items
                    if (items) {
                        let filtered_item = items.filter(item=>{
                            return item.key != selected_item.key
                        })
                        this.selected_items = filtered_item

                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            UPDATE_SELECTED_ITEM(items){
                try {
                    let selected_items = this.selected_items
                    if (items,selected_items) {
                        let update_items = []
                        selected_items.forEach(item => {
                            let it = items.find(it=>it.key == item.key) 
                            if (it) {
                                update_items.push({
                                    ...it,
                                    quantity:item.quantity,
                                    discount:item.discount, 
                                    expand:item.expand, 
                                    expiring_date:item.expiring_date, 
                                    manufacturing_date:item.manufacturing_date, 
                                }) 
                            } else {
                                update_items.push(item)
                            }
                            
                        });

                        this.selected_items = null
                        setTimeout(() => {
                            this.selected_items=[...update_items]
                            console.log(update_items,'update_items............'); 
                        }, 1);
                     
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'UPDATE_SELECTED_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let price = this.toNumber(item.price)
                    let discount = this.toNumber(item.discount)
                    let quantity = this.toNumber(item.quantity)
                    return quantity*(price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            CREATE_ORDER(){
                try {
                    let input = this.input 
                    let code = input?input.code:null
                    let invoice_number = input?input.invoice_number:null
                    let order_code = input?input.order_code:null
                    let supplier_code = input?input.supplier_code:null 
                    let deliver_to_outlet_code = input?input.deliver_to_outlet_code:null  
                    let delivery_date = input?input.delivery_date:null  
                    let bill_date = input?input.bill_date:null  
                    let bill_terms = input?input.bill_terms:null  
                    let bill_due_date = input?input.bill_due_date:null   
                    let payment_method_code = input?input.payment_method_code:null  
                    let description = input?input.description:null
                    let selected_items = this.selected_items
                    let items_size = this.MBS.actions.SIZE(selected_items)
                    let total_amount = this.CALCULATE_TOTAL_QT_PRICE(selected_items)

                    if (items_size<1) {
                        console.log("error..");
                    }
                    
                    let stock_order_items = []
                    selected_items.forEach(element => {
                        stock_order_items.push({
                            item_key:element.key, 
                            price:this.toNumber(element.price), 
                            discount:this.toNumber(element.discount), 
                            quantity:this.toNumber(element.quantity), 
                        })
                    });
                    let order_details = {
                        code:code,
                        invoice_number:invoice_number, 
                        supplier_code:supplier_code,  
                        deliver_to_outlet_code:deliver_to_outlet_code,  
                        bill_terms:bill_terms,  
                        payment_method_code:"bill",  
                        payment_method_key:"bill",  
                        order_type:DATA.ITEMS.INVOICES.value,
                        items:stock_order_items,
                        total_amount:total_amount,
                        created_uid:this.us?.uid,
                        created_at:moment().valueOf(),
                    }
                    
                    if (order_code) {
                        order_details.order_code = order_code
                    }  
                    if (delivery_date) {
                        order_details.delivery_date = delivery_date
                    }  
                    if (bill_date) {
                        order_details.bill_date = bill_date
                    } 
                    if (bill_due_date) {
                        order_details.bill_due_date = bill_due_date
                    }  
                    if (description) {
                        order_details.description = description
                    }   
                    console.log(order_details,'order_details....');
                    let mbs_text = this.MBS.text.item_action(DATA.ITEMS.INVOICES.value,"Submit")
                    this.MBS.actions.dialog({
                        show:true,
                        title:mbs_text.title,
                        text:mbs_text.text,
                        btnYes:mbs_text.btnYes,
                        btnNo:mbs_text.btnNo,
                        action:{
                            code:PAGE_NAME+"=CREATE-ORDER", 
                            data:order_details
                        }
                    })
                     
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_ORDER',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            SUBMIT_ORDER(order){
                try {  

                    const order_code = order?.code
                    const path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.quotations_and_invoices+order_code,true) 
                    if (!order_code || !path) {
                        console.log(order,'order error');
                    } 
                     
                    this.inputLoading = true
                    this.MBS.actions.progressDialog({
                        show:true,
                        title:"Uploading..",
                    }) 
                    this.$store.dispatch("fi_update",{
                        action:DATA.ITEMS.INVOICES.values,
                        data:{received_with_stock:false, ...order},
                        path:path
                    }).then(res=>{ 
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_success(DATA.ITEMS.INVOICES.value,"Submitted")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        }) 
                        // let quotationLink = this.MBS.actions.COMPANY_LINK("/stock/quotations&invoices/",true)
                        this.MBS.actions.go(-1) 
                         
                    }).catch(error=>{
                        this.MBS.actions.error({
                            error:error,
                            from:'SUBMIT_ORDER',
                            page:PAGE_NAME, 
                        })  
                        this.inputLoading = false
                        this.MBS.actions.progressDialog() 
                        let mbs_text = this.MBS.text.item_action_failed(DATA.ITEMS.INVOICES.value,"Submitted")
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:mbs_text.title,
                            text:mbs_text.text,
                            btnYes:mbs_text.btnYes, 
                        })  
                    }) 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SUBMIT_ORDER',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ADD_ORDER(order_code){
                try {  
                    this.show_input = false
                    let orders = this.OrderedStock  
                    if(!order_code){this.show_input = false; return}
                    if(!orders){this.show_input = false; return}
                    let order = orders.find(q=>{
                        return q.code == order_code
                    })  
                    console.log(order,'000');
                    if (order) {
                        this.selected_items = []
                        setTimeout(() => {
                            this.selected_items = [...order.joined_items] 
                            this.input = {
                                ...this.input,
                                description : order.description,
                                supplier_code : order.supplier_code, 
                                payment_method_code : order.payment_method_code, 
                            }   
                        }, 1);
                        setTimeout(() => { 
                            this.show_input = true 
                        }, 1000);
                    }else{
                        this.show_input = false
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ADD_ORDER',
                        page:PAGE_NAME, 
                    }) 
                } 
            },   

            //---------------[MAIN FUNCTIONS]------------ 
            EXPORT_EXCEL(){
                try {   
                    this.MBS.events.$emit("EXPORT_EXCEL")
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EXPORT_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            onPickFile(){ 
                this.MBS.events.$emit('SELECT_EXCEL')
            },
            LOADING_FILE(file){
                try {
                    this.excel_loading = true 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'LOADING_FILE',
                        page:PAGE_NAME, 
                    })
                } 
            },
            ON_FILE_ERROR(error){
                try {
                    this.excel_loading = false 
                    console.log(error);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'LOADING_FILE',
                        page:PAGE_NAME, 
                    })
                } 
            },
            EXCEL_FILE_SELECTED(file,file_details,res){
                try {
                    this.loadingValue = 90
                    setTimeout(() => {
                        this.loadingValue = 100
                        setTimeout(() => { 
                            this.excel_loading = false
                            this.WB = file
                            this.WB_SHEETS = file?file.SheetNames:null
                            this.WB_PROPS = file?file.Props:null
                            this.WB_FILE_DETAILS = file_details
                            this.JSON_SHEETS = res?res.data:null 
                            let sheets = this.JSON_SHEETS
                            let itemsJS = null
                            if (sheets) {
                                sheets.forEach(element => {
                                    if (element.name == DATA.ITEMS.ITEMS.values ) {
                                        itemsJS = this.CREATE_SHEET_DATA(element.json,this.excel_template.headers)
                                    }
                                });
                            }  
                            if (itemsJS) {
                                let items = this.ItemData
                                let selected_items = []
                                if (items) { 
                                    itemsJS.forEach(element => {
                                        let item = items.find(it=>{
                                            return it.code == element.code
                                        })
                                        if (item) {
                                            if (element.quantity) {
                                                selected_items.push({ 
                                                    // ...item,
                                                    name:item.name,
                                                    key:item.key,
                                                    code:item.code,
                                                    quantity:this.toNumber(element.quantity),
                                                    cost_price:this.toNumber(element.cost_price),
                                                    discount:this.toNumber(element.discount),
                                                }) 
                                            }
                                        }
                                    });
                                } 
                                this.selected_items = selected_items

                                console.log(selected_items,'selected_items....');
                            } else {
                                this.MBS.actions.dialog({
                                    show:true,
                                    fixed:true,
                                    title:"FILE ERROR",
                                    text:"The selected file does not contain \"items\" sheet. Download, Receiving Stock Item Template. ",
                                    btnYes:'Download Template', 
                                    btnNo:'Already have', 
                                    action:{
                                        code:PAGE_NAME+"=DOWNLOAD-TEMPLATE"
                                    }
                                }) 
                            }
                        }, 500);
                    }, 1000);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EXCEL_FILE_SELECTED',
                        page:PAGE_NAME, 
                    })
                } 
            },
            NO_EXCEL_FILE_SELECTED(file){
                try {
                    this.excel_loading = false 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'NO_EXCEL_FILE_SELECTED',
                        page:PAGE_NAME, 
                    })
                } 
            },
            CREATE_SHEET_DATA (json, headers) {
                try {
                    if(!json||!headers){return null}
                    let data_item = []
                    let number = 0 

                    console.log(json,'json..............');
                    console.log(headers,'headers..............');
                    json.forEach(excel_item => {
                        let dt = new Date
                        let date = dt.toISOString()

                        //CREATE ARRAY
                        let item_array = {}
                        headers.forEach(element => {
                            if (excel_item[element.value] && element.value) {
                                item_array[element.value] = excel_item[element.value] 
                            }
                        }); 
                        //ADD
                        data_item.push({
                            ...item_array,
                            created_at:date, 
                            uid:this.us.uid,
                            no:number
                        })
                    }); 
                    return data_item
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_SHEET_DATA',
                        page:PAGE_NAME, 
                    })
                } 
            }, 
            SELECT_EXCEL(){  
                try { 
                    this.onPickFile()
                    // this.dialog_add_item = false
                    // this.excelSelector = !this.excelSelector
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            ON_QR_SCAN(code){
                try {
                    if (code) { 
                         
                        let size = this.MBS.actions.SIZE(code)
                        if (size<3) { 
                        }else{
                            let first = code.slice(0,1)
                            let last = code.slice(-1)
                            let mid = code.slice(1,-1)


                            if (first=="%" && last=="?") {
                                code = mid
                                // this.DESELECT_CARD()
                                // this.SELECT_CARD(null,code)  
                            } else { 
                                console.log(code,'code...........');
                                this.ADD_ITEM(null,(code)) 
                            }   
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_QR_SCAN',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=CREATE-ORDER') {
                            if (action.YES) {
                                setTimeout(() => {
                                    console.log(action.data);
                                    this.SUBMIT_ORDER(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        } 
                        if (action.code ===PAGE_NAME+'=UPDATE-PRICING') {
                            if (action.YES) {
                                setTimeout(() => { 
                                    this.SUBMIT_PRICING(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }  
                        if (action.code ===PAGE_NAME+'=DOWNLOAD-TEMPLATE') {
                            if (action.YES) {
                                setTimeout(() => {
                                    this.EXPORT_EXCEL()
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }   
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })  
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        beforeDestroy(){
            this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);     
        },
        watch: {
            search_item(value){ 
                if (value) {
                    this.ADD_ITEM(value) 
                }
            },
            input(v){  
                let order_code =v?v.order_code:null 
                if ( order_code && order_code != this.order_code) {
                    this.order_code = order_code
                    this.ADD_ORDER(order_code) 
                }else{ 
                }
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
    }
</script>
 
